import React, { Component } from 'react';
import { Button } from '../../components/button/Button';
import api from '../../service/api';
import './negocioForm.scss';
import { TabView, TabPanel } from '../../components/tabview/TabView';
import { Panel } from '../../components/panel/Panel';
import { Dimensionamento } from './dimensionamento';
import { NegocioEndereco } from './endereco';
import { PropTypes } from 'prop-types';
import { InputTextarea } from '../../components/inputtextarea/InputTextarea';
import { Dialog } from '../../components/dialog/Dialog';
import { Dropdown } from '../../components/dropdown/Dropdown';
import { Toast } from '../../components/toast/Toast';
import ContaUnica from './contaUnica';
import { RadioButton } from '../../components/radiobutton/RadioButton';
import { Anexos } from './anexos';

export class NegocioEdit extends Component {

    static defaultProps = {
        id: 1
    }

    static propTypes = {
        id: PropTypes.number
    }

    emptyNegocio = {
        "id": null,
        "descricao": '',
        "idcliente": null,
        "nome": '',
        "idtipoinstalacao": null,
        "tipo": '',
        "datacadastro": '',
        "classificacao": '',
        "idstatus": 1,
    };

    constructor(props) {
        super(props);


        this.state = {
            Negocio: this.emptyNegocio,
            submitted: false,
            globalFilter: null,
            Clientes: [],
            TipoInstalacao: [],
            Edicao: false,
            Conta: this.emptyConta,
            Contas: [],
            ContaDialog: false,
            deleteContaDialog: false,
            selectedConta: null,
            paramPerda: this.emptyParamPerda,
            activeIndex: 0,
        };

        this.onClienteChange = this.onClienteChange.bind(this);
        this.editNegocio = this.editNegocio.bind(this);
        this.saveNegocio = this.saveNegocio.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.setTabView = this.setTabView.bind(this);

    }

    async componentDidMount() {
        const { id } = await this.props.match.params;
        // const response = await api.get(`/negocios/${id}`);
        // this.setState({ Negocio: response.data });
        api.get(`/negocios/${id}`)
            .then(res => {
                this.setState({ Negocio: res.data[0] })
            })
    }

    onClienteChange(e) {
        let Negocio = { ...this.state.Negocio };
        Negocio.idcliente = e.value;

        let index = -1;
        for (let i = 0; i < this.state.Clientes.length; i++) {
            if (this.state.Clientes[i].id === e.value) {
                index = i;
                break;
            }
        }

        Negocio.nome = this.state.Clientes[index].nome;

        this.setState({ Negocio });

    }

    onTipoInstalacaoChange(e) {
        let negocio = { ...this.state.Negocio };
        negocio.idtipoinstalacao = e.value;

        // let index = -1;
        // for (let i = 0; i < this.state.TipoInstalacao.length; i++) {
        //     if (this.state.TipoInstalacao[i].id === e.value) {
        //         index = i;
        //         break;
        //     }
        // }

        // Negocio.tipo = this.state.TipoInstalacao[index].tipo;

        this.setState({ Negocio: negocio });
        this.saveNegocio(negocio);
    }

    onClassificacaoChange(e) {
        let negocio = { ...this.state.Negocio };
        negocio.classificacao = e.value;
        this.setState({ Negocio: negocio });
        this.saveNegocio(negocio);
    }

    editNegocio() {
        this.setState({ Edicao: true });
        api.get('/clientes')
            .then(res => {
                this.setState({ Clientes: res.data });
            })

        api.get('/tipoinstalacao')
            .then(res => {
                this.setState({ TipoInstalacao: res.data });
            })
    }

    hideDialog() {
        this.setState({ Edicao: false });
    }

    onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        const input = e.target;
        const start = input.selectionStart;
        const end = input.selectionEnd;
        let Negocio = { ...this.state.Negocio };
        Negocio[`${name}`] = val;
        this.setState({ Negocio },
            () => input.setSelectionRange(start, end));
    }

    async saveNegocio(negocio) {
        api.put(`/negocios/${negocio.id}`, negocio)
            .then(res => {
                if (res.status === 204) {
                    this.setState({
                        Edicao: false,
                        submitted: true
                    })
                    this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Negocio atualizado', life: 3000 });
                }
            })
    }

    setTabView(index) {
        // this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Conta gravada', life: 3000 });        
        // this.setState({ activeIndex: index });
    }


    render() {

        const NegocioDialogFooter = (
            <React.Fragment>
                <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={this.saveNegocio} />
            </React.Fragment>
        );
        return (
            <div className="content-section implementation">
                <Toast ref={(el) => this.toast = el} />
                <h5>Negócio</h5>
                <Panel header="Dados do negócio">
                    <div>
                        <b>Cliente: </b>{this.state.Negocio.nome}
                    </div>
                </Panel>
                <Panel>
                    <div className="p-fluid">
                        {/* <TabView className="tabview-custom" activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({ activeIndex: e.index })}> */}
                        <TabView className="tabview-custom" >
                            <TabPanel header="Detalhes">
                                <div className="p-fluid p-formgrid p-grid">
                                    <div className="p-field p-col-12 p-md-6">
                                        <Panel header="Endereço do Negócio" toggleable>
                                            <NegocioEndereco idnegocio={parseInt(this.props.match.params.id)} idstatus={this.state.Negocio.idstatus} />
                                        </Panel>
                                    </div>
                                    <div className="p-field p-col-12 p-md-6">
                                        <Panel header="Conta de energia" toggleable>
                                            <div className="p-fluid p-formgrid p-grid">
                                                <div className="p-field p-col-6">
                                                    <div className="p-mb-2">Classificação</div>
                                                    <div className="p-field-radiobutton">
                                                        <RadioButton
                                                            inputId="monofasico"
                                                            name="classificacao"
                                                            value="M"
                                                            onChange={(e) => this.onClassificacaoChange(e)}
                                                            checked={this.state.Negocio.classificacao === 'M'}
                                                            disabled={this.state.Negocio.idstatus > 2} />
                                                        <label htmlFor="monofasico">Monofásico</label>
                                                    </div>
                                                    <div className="p-field-radiobutton">
                                                        <RadioButton
                                                            inputId="bifasico"
                                                            name="classificacao"
                                                            value="B"
                                                            onChange={(e) => this.onClassificacaoChange(e)}
                                                            checked={this.state.Negocio.classificacao === 'B'}
                                                            disabled={this.state.Negocio.idstatus > 2} />
                                                        <label htmlFor="bifasico">Bifásico</label>
                                                    </div>
                                                    <div className="p-field-radiobutton">
                                                        <RadioButton
                                                            inputId="trifasico"
                                                            name="classificacao"
                                                            value="T"
                                                            onChange={(e) => this.onClassificacaoChange(e)}
                                                            checked={this.state.Negocio.classificacao === 'T'}
                                                            disabled={this.state.Negocio.idstatus > 2} />
                                                        <label htmlFor="trifasico">Trifásico</label>
                                                    </div>
                                                </div>

                                                <div className="p-field p-col-6" >
                                                    <div className="p-mb-2">Tipo de Instalação</div>
                                                    <div className="p-field-radiobutton">
                                                        <RadioButton
                                                            inputId="residencial"
                                                            name="tipoinstalacao"
                                                            value={1}
                                                            onChange={(e) => this.onTipoInstalacaoChange(e)}
                                                            checked={this.state.Negocio.idtipoinstalacao === 1}
                                                            disabled={this.state.Negocio.idstatus > 2} />
                                                        <label htmlFor="residencial">Residencial</label>
                                                    </div>
                                                    <div className="p-field-radiobutton">
                                                        <RadioButton
                                                            inputId="comercial"
                                                            name="tipoinstalacao"
                                                            value={2}
                                                            onChange={(e) => this.onTipoInstalacaoChange(e)}
                                                            checked={this.state.Negocio.idtipoinstalacao === 2}
                                                            disabled={this.state.Negocio.idstatus > 2} />
                                                        <label htmlFor="comercial">Comercial</label>
                                                    </div>
                                                    <div className="p-field-radiobutton">
                                                        <RadioButton
                                                            inputId="rural"
                                                            name="tipoinstalacao"
                                                            value={3}
                                                            onChange={(e) => this.onTipoInstalacaoChange(e)}
                                                            checked={this.state.Negocio.idtipoinstalacao === 3}
                                                            disabled={this.state.Negocio.idstatus > 2} />
                                                        <label htmlFor="rural">Rural</label>
                                                    </div>
                                                    <div className="p-field-radiobutton">
                                                        <RadioButton 
                                                            inputId="industrial" 
                                                            name="tipoinstalacao" 
                                                            value={4} 
                                                            onChange={(e) => this.onTipoInstalacaoChange(e)} 
                                                            checked={this.state.Negocio.idtipoinstalacao === 4}
                                                            disabled={this.state.Negocio.idstatus > 2} />
                                                        <label htmlFor="industrial">Industrial</label>
                                                    </div>
                                                </div>

                                            </div>
                                            {/* <ContaUnica idnegocio={parseInt(this.props.match.params.id)} setTab={this.setTabView} classificacao={this.state.Negocio.classificacao} /> */}
                                            <ContaUnica 
                                                idnegocio={parseInt(this.props.match.params.id)} 
                                                classificacao={this.state.Negocio.classificacao} 
                                                idstatus={this.state.Negocio.idstatus} />
                                        </Panel>
                                    </div>
                                </div>
                            </TabPanel>
                            {/* <TabPanel header="Contas de energia">
                                <ContaUnica idnegocio={this.state.Negocio.id} setTab={this.setTabView} classificacao={this.state.Negocio.classificacao} />
                            </TabPanel> */}
                            <TabPanel header="Dimensionamento" /*leftIcon="pi pi-search" rightIcon="pi pi-cog"*/>
                                <Dimensionamento 
                                    idnegocio={this.state.Negocio.id} 
                                    history={this.props.history}
                                    idstatus={this.state.Negocio.idstatus} />
                            </TabPanel>
                            <TabPanel header="Anexos" /*leftIcon="pi pi-search" rightIcon="pi pi-cog"*/>
                                <Anexos idNegocio={this.state.Negocio.id} history={this.props.history} />
                            </TabPanel>
                        </TabView>
                    </div>
                </Panel>

                <Dialog visible={this.state.Edicao} style={{ width: '550px' }} header="Alterar" modal className="p-fluid" footer={NegocioDialogFooter} onHide={this.hideDialog}>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-12">
                            <label htmlFor="idtipoinstalacao">Tipo de Instalação</label>
                            <Dropdown inputId="idtipoinstalacao" optionValue="id" optionLabel="tipo" value={this.state.Negocio.idtipoinstalacao} options={this.state.TipoInstalacao} onChange={(e) => this.onTipoInstalacaoChange(e)} placeholder="Selecione o tipo de instalação" />
                        </div>

                        <div className="p-field p-col-12">
                            <div className="p-mb-2">Classificação</div>
                            <div className="p-field-radiobutton">
                                <RadioButton inputId="monofasico" name="classificacao" value="M" onChange={(e) => this.onClassificacaoChange(e)} checked={this.state.Negocio.classificacao === 'M'} />
                                <label htmlFor="monofasico">Monofásico</label>
                            </div>
                            <div className="p-field-radiobutton">
                                <RadioButton inputId="bifasico" name="classificacao" value="B" onChange={(e) => this.onClassificacaoChange(e)} checked={this.state.Negocio.classificacao === 'B'} />
                                <label htmlFor="bifasico">Bifásico</label>
                            </div>
                            <div className="p-field-radiobutton">
                                <RadioButton inputId="trifasico" name="classificacao" value="T" onChange={(e) => this.onClassificacaoChange(e)} checked={this.state.Negocio.classificacao === 'T'} />
                                <label htmlFor="trifasico">Trifásico</label>
                            </div>
                        </div>

                        {/* <div className="p-field p-col-12">
                            <label htmlFor="descricao">Descrição</label>
                            <InputTextarea rows={5} cols={30} value={this.state.Negocio.descricao} onChange={(e) => this.onInputChange(e, 'descricao')} autoResize placeholder="Descrição do negócio" />
                        </div> */}

                    </div>
                </Dialog>

            </div>
        )
    }
}               