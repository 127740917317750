import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';
import api from '../../service/api';
import { Skeleton } from 'primereact/skeleton';

export const GraficoNegociosStatus = (props) => {

    const lightOptions = {
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        }
    };

    const [negociostatus, setNegocioStatus] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        api.get(`/dashboard/negociostatus/${props.Ini}/${props.Fim}`)
            .then(res => {
                setNegocioStatus(res.data);
                setLoading(false);
            });
    }, [props.Ini, props.Fim]);

    return (
        <>
            {!loading && (
                <Chart height='140' type="doughnut" data={negociostatus} options={lightOptions} style={{ width: '100%' }} />
            )}
            {loading && (
                <Skeleton height='21rem'/>
            )}

        </>
    );
}

// export default GraficoNegociosStatus;