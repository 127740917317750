import React, { Component } from 'react';
import classNames from 'classnames';
import { DataTable } from '../../components/datatable/DataTable';
import { Column } from '../../components/column/Column';
import { Toast } from '../../components/toast/Toast';
import { Button } from '../../components/button/Button';
import { Toolbar } from '../../components/toolbar/Toolbar';
import { Dialog } from '../../components/dialog/Dialog';
import { InputText } from '../../components/inputtext/InputText';
import { Dropdown } from '../../components/dropdown/Dropdown';
import api from '../../service/api';
import { Panel } from '../../components/panel/Panel';
import { Link } from 'react-router-dom';
import { InputTextarea } from '../../components/inputtextarea/InputTextarea'
import { InputMask } from '../../components/inputmask/InputMask';
import { RadioButton } from '../../components/radiobutton/RadioButton';
import { format, isThisHour, parseISO } from "date-fns";
import CpfCnpjInput from 'react-cpf-cnpj-input';

export class ClienteEdit extends Component {

    emptyCliente = {
        "id": null,
        "nome": '',
        "cpfcnpj": '',
        "logradouro": '',
        "numero": '',
        "idcidade": null,
        "idestado": null,
        "bairro": '',
        "cep": '',
        "email": '',
        "site": '',
        "telefonefixo": '',
        "telefonecelular": '',
        "complemento": '',
        "tipo": '',
        "fantasiaapelido": '',
        "indicacao": ''
    };

    emptyRepresentante = {
        "id": null,
        "represnome": '',
        "represcpf": '',
        "represrg": '',
        "represprofissao": '',
        "represestadocivil": '',
        "represnacionalidade": '',
    }

    emptyNegocio = {
        "id": null,
        "descricao": '',
        "idcliente": null,
        "nome": '',
        "idtipoinstalacao": null,
        "tipo": '',
        "datacadastro": '',
        "classificacao": 'M',
        "consumomedia": 0.00,
        "dataatualizacao": '',
        "idstatus": 1,
        "idusuario": parseInt(sessionStorage.getItem('idusuario')),
    };

    estadoCivil = [
        { name: 'SOLTEIRO(A)', code: 'S' },
        { name: 'CADASO(A)', code: 'C' },
        { name: 'VIÚVO(A)', code: 'V' },
    ];

    constructor(props) {
        super(props);
        this.CpfCnpjInputRef = React.createRef();

        this.state = {
            Cliente: this.emptyCliente,
            negocio: this.emptyNegocio,
            listaNegocios: [],
            estados: [],
            cidades: [],

            submitted: false,
            negocioDialog: false,
            deleteNegocioDialog: false,
            cloneNegocioDialog: false,
            edicao: false,
            ClienteAux: {},
            documentoValido: '',
            representante: this.emptyRepresentante,
            edicaoRepresentante: false,
            representanteAux: {},
            deleteRepresentanteDialog: false,
            rows: 10,
            first: 1,
        };

        this.leftToolbarNegocio = this.leftToolbarNegocio.bind(this);
        this.openNovoNegocio = this.openNovoNegocio.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.saveNegocio = this.saveNegocio.bind(this);
        this.saveCliente = this.saveCliente.bind(this);
        this.confirmDeleteNegocio = this.confirmDeleteNegocio.bind(this);
        this.onTipoJuridicoChange = this.onTipoJuridicoChange.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onInputChangeNegocio = this.onInputChangeNegocio.bind(this);
        this.consultaCEP = this.consultaCEP.bind(this);
        this.editCliente = this.editCliente.bind(this);
        this.cancelEditCliente = this.cancelEditCliente.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.deleteNegocio = this.deleteNegocio.bind(this);
        this.hideDeleteNegocioDialog = this.hideDeleteNegocioDialog.bind(this);
        this.confirmCloneNegocio = this.confirmCloneNegocio.bind(this);
        this.hideCloneNegocioDialog = this.hideCloneNegocioDialog.bind(this);
        this.cloneNegocio = this.cloneNegocio.bind(this);
        this.validarDocumento = this.validarDocumento.bind(this);
        this.editRepresentante = this.editRepresentante.bind(this);
        this.saveRepresentante = this.saveRepresentante.bind(this);
        this.cancelEditRepresentante = this.cancelEditRepresentante.bind(this);
        this.onEstadoCivilChange = this.onEstadoCivilChange.bind(this);
        this.excluirRepresentante = this.excluirRepresentante.bind(this);
        this.confirmDeleteRepresentante = this.confirmDeleteRepresentante.bind(this);
        this.hideDeleteRepresentanteDialog = this.hideDeleteRepresentanteDialog.bind(this);
        this.onInputMaskChange = this.onInputMaskChange.bind(this);
        this.statusBodyTemplate = this.statusBodyTemplate.bind(this);

    }

    async componentDidMount() {
        const { id } = this.props.match.params;
        // const Cliente = await api.get(`/clientes/${id}`);
        // const listaNegocios = await api.get(`/negocios/cliente/${id}`);
        // const representante = await api.get(`/clientes/representante/${id}`)

        api.get(`/clientes/${id}`)
            .then(res => {
                if (res.status === 200) {
                    this.setState({ Cliente: res.data });
                    api.get('/estados')
                        .then(resEstado => {
                            if (resEstado.status === 200) {
                                this.setState({ estados: resEstado.data });
                                api.get(`/cidades/uf/${res.data.idestado}`)
                                    .then(resCidades => {
                                        this.setState({ cidades: resCidades.data });
                                    });
                            }
                        })
                }
            });

        api.get(`/negocios/cliente/${id}`)
            .then(res => {
                if (res.status === 200) {
                    console.log(res.data);
                    this.setState({ listaNegocios: res.data })
                }
            })

        api.get(`/clientes/representante/${id}`)
            .then(res => {
                if (res.status === 200) {
                    this.setState({ representante: res.data })
                }
            });

        // this.setState({
        //     listaNegocios: listaNegocios.data,
        //     Cliente: Cliente.data,
        //     representante: representante.data,
        // });

        // const estados = await api.get('/estados');
        // api.get(`/cidades/uf/${Cliente.data.idestado}`)
        //     .then(cidades => {
        //         this.setState({
        //             estados: estados.data,
        //             cidades: cidades.data
        //         });
        //     });
    }

    onCidadeChange(e) {
        const val = (e.target && e.target.value) || '';
        let Cliente = { ...this.state.Cliente };
        Cliente.idcidade = val;
        this.setState({ Cliente });
    }

    onEstadoChange(e) {
        let Cliente = { ...this.state.Cliente };
        Cliente.idestado = e.value;
        this.setState({ Cliente });
        api.get(`/cidades/uf/${Cliente.idestado}`)
            .then(res => {
                this.setState({ cidades: res.data });
            });
    }

    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Link to={`/negocios/${rowData['id']}`}><Button icon="pi pi-pencil" className="p-button-rounded p-mr-2" /></Link>
                <Button disabled={rowData['dimensionamentos'] > 0} icon="pi pi-trash" className="p-button-rounded p-button-danger p-mr-2" onClick={() => this.confirmDeleteNegocio(rowData)} />
                <Button tooltip='clonar' icon="pi pi-clone" className="p-button-rounded p-button-warning" onClick={() => this.cloneNegocio(rowData)} />
            </React.Fragment>
            //
        );
    }

    leftToolbarNegocio() {
        return (
            <React.Fragment>
                <Button label="Novo" icon="pi pi-plus" className="p-button p-mr-2" onClick={this.openNovoNegocio} />
            </React.Fragment>
        )
    }

    dateTemplate(rowData) {
        const DataCadastro = parseISO(rowData['datacadastro']);
        const DataAtualizacao = parseISO(rowData['dataatualizacao']);
        ; return format(DataCadastro, "dd/MM/yyyy HH:mm") + ' ' + format(DataAtualizacao, "dd/MM/yyyy HH:mm");
    }

    openNovoNegocio() {
        let Cliente = { ...this.state.Cliente };
        let negocio = this.emptyNegocio;

        negocio.idcliente = Cliente.id;

        this.setState({
            negocio: negocio,
            submitted: false,
            negocioDialog: true
        });

        api.get('/tipoinstalacao')
            .then(res => {
                this.setState({ TipoInstalacao: res.data });
            });
    }

    hideDialog() {
        this.setState({
            submitted: false,
            negocioDialog: false
        });
    }

    async saveNegocio() {
        let state = { submitted: true };
        if (this.state.negocio.idtipoinstalacao) {
            let listaNegocios = [...this.state.listaNegocios];
            let negocio = { ...this.state.negocio };

            negocio.id = 0;
            negocio.idcliente = this.state.Cliente.id;
            negocio.datacadastro = format(new Date(), 'yyyy-MM-dd HH:mm:ss');
            negocio.dataatualizacao = format(new Date(), 'yyyy-MM-dd HH:mm:ss');
            api.post('/negocios', negocio)
                .then(res => {
                    if (res.status === 201) {
                        negocio.id = res.headers.id;
                        listaNegocios.push(negocio);
                        state = {
                            ...state,
                            listaNegocios,
                            negocioDialog: false,
                            negocio: this.emptyNegocio
                        };
                        this.setState(state);
                        this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Negocio inserido', life: 3000 });
                        this.props.history.push(`/negocios/${negocio.id}`);
                    }
                })
                .catch(error => {
                    this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu inserir o novo negócio', life: 3000 });
                    //console.log(error.response.data.description);
                })
        }
        else {
            state = {
                ...state
            };
        }

        this.setState(state);

    }

    async saveCliente() {
        if ((this.state.Cliente.nome.trim()) && (this.state.Cliente.cpfcnpj.trim())
            && (this.state.Cliente.idestado) && (this.state.Cliente.idcidade)) {

            let Cliente = { ...this.state.Cliente };

            Cliente.cpfcnpj = this.CpfCnpjInputRef.current.getValue();

            if (Cliente.cpfcnpj.length === 14) {
                Cliente.tipo = 'F';
            }
            else if (Cliente.cpfcnpj.length === 18) {
                Cliente.tipo = 'J';
            }

            api.put(`/clientes/${this.state.Cliente.id}`, Cliente)
                .then(res => {
                    if (res.status === 204) {
                        this.setState({
                            edicao: false,
                            submitted: true,
                        })
                        this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Cliente atualizado', life: 3000 });
                    }
                })
                .catch(error => {
                    this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu atualizar o cliente', life: 3000 });
                    // console.log(error.response.data.description);
                })
        }
        else {
            this.setState({ submitted: true });
        }
    }

    async excluirRepresentante() {
        api.delete(`/clientes/representante/${this.state.representante.id}`)
            .then(res => {
                if (res.status === 204) {
                    this.setState({
                        representante: this.emptyRepresentante,
                        deleteRepresentanteDialog: false,
                    })
                    this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Representante removido', life: 3000 });
                }
            })
    }

    async saveRepresentante() {
        if ((this.state.representante.represnome.trim()) && (this.state.representante.represcpf.trim())
            && (this.state.representante.represrg) && (this.state.representante.represprofissao)
            && (this.state.representante.represestadocivil) && (this.state.representante.represnacionalidade)) {

            let representante = { ...this.state.representante };

            if (representante.id) {
                api.put(`/clientes/representante/${representante.id}`, representante)
                    .then(res => {
                        if (res.status === 204) {
                            this.setState({
                                edicaoRepresentante: false,
                                submitted: true,
                            })
                            this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Representante atualizado', life: 3000 });
                        }
                    })
                    .catch(error => {
                        this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu atualizar o representante', life: 3000 });
                        // console.log(error.response.data.description);
                    })
            }
            else {
                representante.id = this.state.Cliente.id;
                api.post('/clientes/representante', representante)
                    .then(res => {
                        if (res.status === 201) {
                            this.setState({
                                edicaoRepresentante: false,
                                submitted: true,
                            })
                            this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Representante inserido', life: 3000 });
                        }
                    })
                    .catch(error => {
                        this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu inserir o representante', life: 3000 });
                        // console.log(error.response.data.description);
                    })
            }
        }
        else {
            this.setState({ submitted: true });
        }
    }

    onInputChange(e, name) {
        if (e.target.value !== undefined) {
            const val = (e.target && e.target.value) || '';
            const input = e.target;
            const start = input.selectionStart;
            const end = input.selectionEnd;
            let Cliente = { ...this.state.Cliente };
            Cliente[`${name}`] = val.toUpperCase();
            this.setState({ Cliente },
                () => input.setSelectionRange(start, end));
        }
    }

    onInputMaskChange(e, name) {
        const val = (e.target && e.target.value) || '';
        let Cliente = { ...this.state.Cliente };
        Cliente[`${name}`] = val;

        this.setState({ Cliente });
    }

    onInputRepresentanteChange(e, name) {
        if (e.target.value !== undefined) {
            const val = (e.target && e.target.value) || '';
            let representante = { ...this.state.representante };
            representante[`${name}`] = val.toUpperCase();
            this.setState({ representante });
        }
    }

    onInputChangeNegocio(e, name) {
        const val = (e.target && e.target.value) || '';
        let negocio = { ...this.state.negocio };
        negocio[`${name}`] = val.toUpperCase();
        this.setState({ negocio });
    }

    onTipoInstalacaoChange(e) {
        let negocio = { ...this.state.negocio };
        negocio.idtipoinstalacao = e.value;

        let index = -1;
        for (let i = 0; i < this.state.TipoInstalacao.length; i++) {
            if (this.state.TipoInstalacao[i].id === e.value) {
                index = i;
                break;
            }
        }

        negocio.tipo = this.state.TipoInstalacao[index].tipo;

        this.setState({ negocio });
    }

    onTipoJuridicoChange(e) {
        let Cliente = { ...this.state.Cliente };
        Cliente.tipo = e.value;
        this.setState({ Cliente: Cliente });
    }

    confirmDeleteNegocio(negocio) {
        this.setState({
            negocio: negocio,
            deleteNegocioDialog: true
        });
    }

    confirmDeleteRepresentante() {
        this.setState({ deleteRepresentanteDialog: true });
    }

    confirmCloneNegocio(negocio) {
        this.setState({
            negocio: negocio,
            cloneNegocioDialog: true
        });
    }

    hideCloneNegocioDialog() {
        this.setState({ cloneNegocioDialog: false });
    }

    hideDeleteNegocioDialog() {
        this.setState({ deleteNegocioDialog: false });
    }

    hideDeleteRepresentanteDialog() {
        this.setState({ deleteRepresentanteDialog: false });
    }

    cloneNegocio(negocio) {
        let id = negocio['id'];
        let idCliente = negocio['idcliente'];
        let idUsuario = sessionStorage.getItem('idusuario');
        api.post(`/clonar/negocio/${id}/usuario/${idUsuario}`, negocio)
            .then(res => {
                if (res.status === 201) {
                    api.get(`/negocios/cliente/${idCliente}`)
                        .then(listaNegocios => {
                            this.setState({ listaNegocios: listaNegocios.data })
                        })
                    this.setState({
                        cloneNegocioDialog: false,
                        negocio: this.emptyNegocio,
                    });
                    this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Negócio clonado', life: 3000 });
                }
            })
            .catch(error => {
                this.setState({ cloneNegocioDialog: false });
                this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu clonar o negócio', life: 3000 });
                //console.log(error.response.data.description);
            })
    }

    deleteNegocio() {
        api.delete(`/negocios/${this.state.negocio.id}`)
            .then(res => {
                if (res.status === 204) {
                    let listaNegocios = this.state.listaNegocios.filter(val => val.id !== this.state.negocio.id);
                    this.setState({
                        listaNegocios,
                        deleteNegocioDialog: false,
                        negocio: this.emptyNegocio
                    });
                    this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Negócio excluído', life: 3000 });
                }
            })
            .catch(error => {
                this.setState({ deleteNegocioDialog: false });
                this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu excluir o negócio', life: 3000 });
                //console.log(error.response.data.description);
            })

    }

    consultaCEP() {
        let cep = this.state.Cliente.cep;
        if (cep.trim()) {
            let cepAux = cep.replace('.', '');
            cepAux = cepAux.replace('-', '');
            if (cepAux.length === 8) {
                api.get(`/consultacep/${cepAux}`)
                    .then(res => {
                        if (res.status === 200) {
                            if (typeof res.data.erro === "undefined") {
                                let Cliente = { ... this.state.Cliente };
                                let ibge = res.data.ibge;
                                api.get(`/cidades/ibge/${ibge}`)
                                    .then(cidade => {
                                        Cliente.logradouro = res.data.logradouro.toUpperCase();
                                        Cliente.bairro = res.data.bairro.toUpperCase();
                                        Cliente.cidades = res.data.ibge;
                                        Cliente.idestado = cidade.data[0].idestado;
                                        api.get(`/cidades/uf/${cidade.data[0].idestado}`)
                                            .then(res => {
                                                this.setState({ cidades: res.data });
                                                Cliente.idcidade = cidade.data[0].idcidade;
                                                this.setState({ Cliente: Cliente })
                                            });
                                    })
                            }
                            else {
                                this.toast.show({ severity: 'error', summary: 'Erro', detail: `O CEP ${cep} não foi localizado`, life: 3000 });
                            }
                        }
                    })
                    .catch(error => {
                        this.toast.show({ severity: 'error', summary: 'Erro', detail: `Ocorreu algum problema ao tentar consultar o CEP ${cep}`, life: 3000 });
                        console.log(error.data);
                    })
            }
            else {
                alert(`CEP ${cep} inválido`)
            }
        }
    }

    editCliente() {
        let ClienteAux = { ...this.state.Cliente };
        this.setState({
            edicao: true,
            ClienteAux: ClienteAux,
        });
    }

    editRepresentante() {
        let representanteAux = { ...this.state.representante };
        this.setState({
            edicaoRepresentante: true,
            representanteAux: representanteAux,
        });
    }

    cancelEditCliente() {
        let Cliente = { ...this.state.ClienteAux };
        this.setState({
            edicao: false,
            Cliente: Cliente,
        });
    }

    cancelEditRepresentante() {
        let representante = { ...this.state.representante };
        this.setState({
            edicaoRepresentante: false,
            representante: representante,
        });
    }

    onClassificacaoChange(e) {
        let negocio = { ...this.state.negocio };
        negocio.classificacao = e.value;
        this.setState({ negocio: negocio });
    }

    formatarNumero(rowData, field, casas) {
        return rowData[field].toLocaleString('pt-BR', { style: 'decimal', maximunFractionDigits: casas, minimumFractionDigits: casas });
    }

    validarDocumento(e) {
        console.log(e.isValid(e.value));
    }

    onEstadoCivilChange(e) {
        let representante = { ...this.state.representante };
        representante.represestadocivil = e.value;
        this.setState({ representante });
    }

    statusBodyTemplate(rowData) {
        let estilo;
        let texto;

        estilo = "p-tag p-tag-rounded p-tag-primary";
        texto = 'EL';
        // console.log('status:' + rowData.idstatus);
        if (rowData.idstatus === 1) {
            estilo = "p-tag p-tag-rounded p-tag-primary";
            texto = 'EL';
        }
        else if (rowData.idstatus === 2) {
            estilo = "p-tag p-tag-rounded p-tag-warning";
            texto = 'CL';
        }
        else if (rowData.idstatus === 3) {
            estilo = "p-tag p-tag-rounded p-tag-success";
            texto = 'AC';
        }
        else if (rowData.idstatus === 4) {
            estilo = "p-tag p-tag-rounded p-tag-danger";
            texto = 'PE';
        }
        return <span className={estilo}>{texto}</span>;
    }

    onPageChange = (event) => {
        this.setState({
            rows: event.rows,
            first: event.first
        });
    };

    render() {
        const NegocioDialogFooter = (
            <React.Fragment>
                <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={this.saveNegocio} />
            </React.Fragment>
        );
        const deleteNegocioDialogFooter = (
            <React.Fragment>
                <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteNegocioDialog} />
                <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={this.deleteNegocio} />
            </React.Fragment>
        );

        const deleteRepresentanteDialogFooter = (
            <React.Fragment>
                <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteRepresentanteDialog} />
                <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={this.excluirRepresentante} />
            </React.Fragment>
        );

        const cloneNegocioDialogFooter = (
            <React.Fragment>
                <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={this.hideCloneNegocioDialog} />
                <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={this.cloneNegocio} />
            </React.Fragment>
        );

        return (
            <div>
                <div className="content-section introduction">
                    <h5>Cliente</h5>
                </div>
                <div >
                    <Toast ref={(el) => this.toast = el} />

                    <Panel header={this.state.Cliente.nome} toggleable collapsed={true}>
                        <div className="p-fluid p-formgrid p-grid">

                            <div className="p-field p-col-12" >
                                <label htmlFor="nome">Nome/Razão Social</label>
                                <InputText id="nome" value={this.state.Cliente.nome} onChange={(e) => this.onInputChange(e, 'nome')}
                                    required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.Cliente.nome })}
                                    readOnly={!this.state.edicao} disabled={!this.state.edicao} />
                                {this.state.submitted && !this.state.Cliente.nome && <small className="p-invalid">Nome é requerido.</small>}
                            </div>
                        </div>

                        {/* <div>
                            <div className="p-mb-2">
                                Tipo
                            </div>
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field-radiobutton p-col-3">
                                    <RadioButton inputId="pf" name="tipo" value="F" onChange={(e) => this.onTipoJuridicoChange(e)}
                                        checked={this.state.Cliente.tipo === 'F'} disabled={!this.state.edicao} />
                                    <label htmlFor="pf">Pessoa Física</label>
                                </div>
                                <div className="p-field-radiobutton p-col-3">
                                    <RadioButton inputId="pj" name="tipo" value="J" onChange={(e) => this.onTipoJuridicoChange(e)}
                                        checked={this.state.Cliente.tipo === 'J'} disabled={!this.state.edicao} />
                                    <label htmlFor="pj">Pessoa Jurídica</label>
                                </div>
                            </div>
                        </div> */}

                        {/* <InputMask id="cpfcnpj" value={this.state.Cliente.cpfcnpj} onChange={(e) => this.onInputChange(e, 'cpfcnpj')}
                                    required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.Cliente.cpfcnpj })}
                                    disabled={!this.state.Cliente.tipo || !this.state.edicao} mask={this.state.Cliente.tipo === 'F' ? "999.999.999-99" : "99.999.999/9999-99"}
                                     readonly={!this.state.edicao} />
                                {this.state.submitted && !this.state.Cliente.cpfcnpj && <small className="p-invalid">CPF / CNPJ é requerido.</small>} */}
                        <div>
                            <div className="p-mb-2">
                                CPF/CNPJ
                            </div>
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-12">
                                    {/* <label htmlFor="cpfcnpj">CPF / CNPJ</label> */}
                                    <CpfCnpjInput id="cpfcnpj" value={this.state.Cliente.cpfcnpj}
                                        onChange={(e) => this.onInputMaskChange(e, 'cpfcnpj')}
                                        required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.Cliente.cpfcnpj })}
                                        disabled={!this.state.edicao}
                                        style={{ width: '100%', height: '38px' }}
                                        readOnly={!this.state.edicao}
                                        ref={this.CpfCnpjInputRef} />
                                    {this.state.submitted && !this.state.Cliente.cpfcnpj && <small className="p-invalid">CPF / CNPJ é requerido.</small>}
                                    {/* {this.CpfCnpjInputRef.current && this.CpfCnpjInputRef.current.isValid() === false && <small className="p-invalid">CPF / CNPJ inválido.</small>} */}
                                </div>
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12">
                                <label htmlFor="fantasiaapelido">Apelido/Nome Fantasia</label>
                                <InputText id="fantasiaapelido" value={this.state.Cliente.fantasiaapelido} onChange={(e) => this.onInputChange(e, 'fantasiaapelido')}
                                    readOnly={!this.state.edicao} disabled={!this.state.edicao} />
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-10">
                                <label htmlFor="logradouro">Logradouro</label>
                                <InputText id="logradouro" value={this.state.Cliente.logradouro} onChange={(e) => this.onInputChange(e, 'logradouro')}
                                    readOnly={!this.state.edicao} disabled={!this.state.edicao} />
                            </div>

                            <div className="p-field p-col-2">
                                <label htmlFor="numero">Número</label>
                                <InputText id="numero" value={this.state.Cliente.numero} onChange={(e) => this.onInputChange(e, 'numero')}
                                    readOnly={!this.state.edicao} disabled={!this.state.edicao} />
                            </div>
                        </div>

                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12">
                                <label htmlFor="complemento">Complemento</label>
                                <InputText id="complemento" value={this.state.Cliente.complemento} onChange={(e) => this.onInputChange(e, 'complemento')}
                                    readOnly={!this.state.edicao} disabled={!this.state.edicao} />
                            </div>
                        </div>

                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-2">
                                <label htmlFor="idestado">UF</label>
                                <Dropdown inputId="idestado" optionValue="idestado" optionLabel="uf" value={this.state.Cliente.idestado} options={this.state.estados} onChange={(e) => this.onEstadoChange(e)} placeholder="Selecione a UF"
                                    required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.Cliente.idestado })}
                                    disabled={!this.state.edicao} />
                                {this.state.submitted && !this.state.Cliente.idestado && <small className="p-invalid">UF é requerido.</small>}
                            </div>

                            <div className="p-field p-col-10">
                                <label htmlFor="idcidade">Cidade</label>
                                <Dropdown inputId="idcidade" optionValue="idcidade" optionLabel="cidade" value={this.state.Cliente.idcidade} options={this.state.cidades} onChange={(e) => this.onCidadeChange(e)} placeholder="Selecione a cidade"
                                    disabled={!this.state.Cliente.idestado || !this.state.edicao}
                                    required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.Cliente.idcidade })} />
                                {this.state.submitted && !this.state.Cliente.idcidade && <small className="p-invalid">Cidade é requerido.</small>}
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-8">
                                <label htmlFor="bairro">Bairro</label>
                                <InputText id="bairro" value={this.state.Cliente.bairro} onChange={(e) => this.onInputChange(e, 'bairro')}
                                    readOnly={!this.state.edicao} disabled={!this.state.edicao} />
                            </div>

                            <div className="p-field p-col-4">
                                <label htmlFor="cep">CEP</label>
                                <div className="p-inputgroup">
                                    <InputMask id="cep" value={this.state.Cliente.cep} onChange={(e) => this.onInputMaskChange(e, 'cep')}
                                        mask="99.999-999" readonly={!this.state.edicao} disabled={!this.state.edicao} />
                                    <Button icon="pi pi-search" className="p-button" onClick={this.consultaCEP} disabled={!this.state.edicao} />
                                </div>
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-12">
                                <label htmlFor="email">E-mail</label>
                                <InputText id="email" value={this.state.Cliente.email} onChange={(e) => this.onInputChange(e, 'email')}
                                    readOnly={!this.state.edicao} disabled={!this.state.edicao} />
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="telefonefixo">Tel. Fixo</label>
                                <InputMask id="telefonefixo" value={this.state.Cliente.telefonefixo} onChange={(e) => this.onInputMaskChange(e, 'telefonefixo')}
                                    mask="(99)9999-9999" readonly={!this.state.edicao} disabled={!this.state.edicao} />
                            </div>

                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="telefonecelular">Tel. Celular</label>
                                <InputMask id="telefonecelular" value={this.state.Cliente.telefonecelular} onChange={(e) => this.onInputMaskChange(e, 'telefonecelular')}
                                    mask="(99)99999-9999" readonly={!this.state.edicao} disabled={!this.state.edicao} />
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12">
                                <label htmlFor="indicacao">Indicação</label>
                                <InputText id="indicacao" value={this.state.Cliente.indicacao} onChange={(e) => this.onInputChange(e, 'indicacao')}
                                    readOnly={!this.state.edicao} disabled={!this.state.edicao} />
                            </div>
                        </div>
                        <div>
                            <React.Fragment>
                                {!this.state.edicao && <Button label="Editar" icon="pi pi-pencil" className="p-button p-mr-2" onClick={this.editCliente} />}
                                {this.state.edicao && <Button label="Salvar" icon="pi pi-check" className="p-button-success p-mr-2" onClick={this.saveCliente} />}
                                {this.state.edicao && <Button label="Cancelar" icon="pi pi-times" className="p-button-warning p-mr-2" onClick={this.cancelEditCliente} />}
                            </React.Fragment>
                        </div>
                    </Panel>
                    <div hidden={this.state.Cliente.tipo === "F"}>
                        <Panel header="Representante" toggleable collapsed={true}>
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-12" >
                                    <label htmlFor="represnome">Nome</label>
                                    <InputText id="represnome" value={this.state.representante.represnome} onChange={(e) => this.onInputRepresentanteChange(e, 'represnome')}
                                        required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.representante.represnome })}
                                        readOnly={!this.state.edicaoRepresentante} disabled={!this.state.edicaoRepresentante} />
                                    {this.state.submitted && !this.state.representante.represnome && <small className="p-invalid">Nome é requerido.</small>}
                                </div>
                                <div className="p-field p-col-6" >
                                    <label htmlFor="represcpf">CPF</label>
                                    <InputMask id="represcpf" value={this.state.representante.represcpf} onChange={(e) => this.onInputRepresentanteChange(e, 'represcpf')}
                                        required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.representante.represcpf })}
                                        readOnly={!this.state.edicaoRepresentante} disabled={!this.state.edicaoRepresentante} mask='999.999.999-99' />
                                    {this.state.submitted && !this.state.representante.represcpf && <small className="p-invalid">O CPF é requerido.</small>}
                                </div>
                                <div className="p-field p-col-6" >
                                    <label htmlFor="represrg">RG</label>
                                    <InputText id="represrg" value={this.state.representante.represrg} onChange={(e) => this.onInputRepresentanteChange(e, 'represrg')}
                                        required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.representante.represrg })}
                                        readOnly={!this.state.edicaoRepresentante} disabled={!this.state.edicaoRepresentante} />
                                    {this.state.submitted && !this.state.representante.represrg && <small className="p-invalid">O RG é requerido.</small>}
                                </div>
                                <div className="p-field p-col-4" >
                                    <label htmlFor="represprofissao">Profissão</label>
                                    <InputText id="represprofissao" value={this.state.representante.represprofissao} onChange={(e) => this.onInputRepresentanteChange(e, 'represprofissao')}
                                        required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.representante.represprofissao })}
                                        readOnly={!this.state.edicaoRepresentante} disabled={!this.state.edicaoRepresentante} />
                                    {this.state.submitted && !this.state.representante.represprofissao && <small className="p-invalid">O RG é requerido.</small>}
                                </div>
                                <div className="p-field p-col-4" >
                                    <label htmlFor="represestadocivil">Estado Civil</label>
                                    <Dropdown
                                        value={this.state.representante.represestadocivil}
                                        options={this.estadoCivil}
                                        onChange={(e) => this.onEstadoCivilChange(e)}
                                        optionValue="code"
                                        optionLabel="name"
                                        placeholder="Selecione o estado civil"
                                        required
                                        autoFocus
                                        className={classNames({ 'p-invalid': this.state.submitted && !this.state.representante.represestadocivil })}

                                        readOnly={!this.state.edicaoRepresentante}
                                        disabled={!this.state.edicaoRepresentante} />
                                    {this.state.submitted && !this.state.representante.represestadocivil && <small className="p-invalid">O estado civil é requerido.</small>}
                                </div>
                                <div className="p-field p-col-4" >
                                    <label htmlFor="represnacionalidade">Nacionalidade</label>
                                    <InputText id="represnacionalidade" value={this.state.representante.represnacionalidade} onChange={(e) => this.onInputRepresentanteChange(e, 'represnacionalidade')}
                                        required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.representante.represnacionalidade })}
                                        readOnly={!this.state.edicaoRepresentante} disabled={!this.state.edicaoRepresentante} />
                                    {this.state.submitted && !this.state.representante.represnacionalidade && <small className="p-invalid">A nacionalidade é requerida.</small>}
                                </div>
                            </div>
                            <div>
                                <React.Fragment>
                                    {!this.state.edicaoRepresentante && <Button label="Editar" icon="pi pi-pencil" className="p-button p-mr-2" onClick={this.editRepresentante} />}
                                    {!this.state.edicaoRepresentante && this.state.representante.id && <Button label="Excluir" icon="pi pi-pencil" className="p-button-danger p-mr-2" onClick={this.confirmDeleteRepresentante} />}
                                    {this.state.edicaoRepresentante && <Button label="Salvar" icon="pi pi-check" className="p-button-success p-mr-2" onClick={this.saveRepresentante} />}
                                    {this.state.edicaoRepresentante && <Button label="Cancelar" icon="pi pi-times" className="p-button-warning p-mr-2" onClick={this.cancelEditRepresentante} />}
                                </React.Fragment>
                            </div>
                        </Panel>
                    </div>
                    <div>
                        <Panel header="Negócios">
                            <Toast ref={(el) => this.toast = el} />
                            <Toolbar className="p-mb-4" left={this.leftToolbarNegocio} ></Toolbar>
                            {this.state.listaNegocios.length === 0 && <span>Nenhum negócio cadastrado. <br /></span>}
                            {this.state.listaNegocios.length > 0 &&
                                <DataTable
                                    className="p-datatable-gridlines p-datatable-sm"
                                    ref={(el) => this.dt = el}
                                    value={this.state.listaNegocios}
                                    selection={this.state.selectedNegocios}
                                    onSelectionChange={(e) => this.setState({ selectedNegocios: e.value })}
                                    dataKey="id"
                                    paginator={true} //{this.state.listaNegocios.length >= 5}
                                    rows={10}
                                    rowsPerPageOptions={[5, 10, 25]}
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate="Exibindo {first} até {last} de {totalRecords} Negocios"
                                    globalFilter={this.state.globalFilter}
                                // onPage={event => this.onPageChange(event)}
                                //first={this.state.first}
                                >
                                    <Column field="id" header="Id" sortable style={{ width: '6%' }}></Column>
                                    {/* <Column field="nome" header="Cliente" sortable></Column> */}
                                    <Column field="tipo" header="Tipo Instalação" sortable></Column>
                                    <Column field="consumomedia" header="Consumo Médio" body={(rowData) => this.formatarNumero(rowData, 'consumomedia', 0)} sortable></Column>
                                    <Column field="idstatus" header="Status" body={this.statusBodyTemplate} style={{ width: '50px' }}></Column>
                                    <Column field="dataatualizacao" header="Inclusão / Atualização" style={{ width: '125px' }} sortable body={(rowData) => this.dateTemplate(rowData)} ></Column>
                                    <Column body={this.actionBodyTemplate} style={{ width: '110px' }}></Column>
                                </DataTable>
                            }
                        </Panel>
                    </div>

                    <Dialog visible={this.state.negocioDialog} style={{ width: '550px' }} header="Novo Negocio" modal className="p-fluid" footer={NegocioDialogFooter} onHide={this.hideDialog}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-12">
                                <label htmlFor="idtipoinstalacao">Tipo de Instalação</label>
                                <Dropdown inputId="idtipoinstalacao" optionValue="id" optionLabel="tipo"
                                    value={this.state.negocio.idtipoinstalacao} options={this.state.TipoInstalacao}
                                    onChange={(e) => this.onTipoInstalacaoChange(e)} placeholder="Selecione o tipo de instalação"
                                    required className={classNames({ 'p-invalid': this.state.submitted && !this.state.negocio.idtipoinstalacao })} />
                                {this.state.submitted && !this.state.negocio.idtipoinstalacao && <small className="p-invalid">O tipo de instalação é requerido.</small>}
                            </div>

                            <div className="p-field p-col-12">
                                <div className="p-mb-2">Classificação</div>
                                <div className="p-field-radiobutton">
                                    <RadioButton inputId="monofasico" name="classificacao" value="M" onChange={(e) => this.onClassificacaoChange(e)} checked={this.state.negocio.classificacao === 'M'} />
                                    <label htmlFor="monofasico">Monofásico</label>
                                </div>

                                <div className="p-field-radiobutton">
                                    <RadioButton inputId="bifasico" name="classificacao" value="B" onChange={(e) => this.onClassificacaoChange(e)} checked={this.state.negocio.classificacao === 'B'} />
                                    <label htmlFor="bifasico">Bifásico</label>
                                </div>

                                <div className="p-field-radiobutton">
                                    <RadioButton inputId="trifasico" name="classificacao" value="T" onChange={(e) => this.onClassificacaoChange(e)} checked={this.state.negocio.classificacao === 'T'} />
                                    <label htmlFor="trifasico">Trifásico</label>
                                </div>
                            </div>
                            {/* 
                            <div className="p-field p-col-12">
                                <label htmlFor="descricao">Descrição</label>
                                <InputTextarea
                                    rows={5} cols={30}
                                    value={this.state.negocio.descricao}
                                    onChange={(e) => this.onInputChangeNegocio(e, 'descricao')}
                                    autoResize
                                    placeholder="Descrição do negócio" />
                            </div> */}

                        </div>
                    </Dialog>

                    <Dialog visible={this.state.deleteNegocioDialog} style={{ width: '450px' }} header="Confirma" modal
                        footer={deleteNegocioDialogFooter} onHide={this.hideDeleteNegocioDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {this.state.negocio && <span>Tem certeza que deseja excluir o negócio <b>{this.state.negocio.id}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={this.state.deleteRepresentanteDialog} style={{ width: '450px' }} header="Confirma" modal
                        footer={deleteRepresentanteDialogFooter} onHide={this.hideDeleteRepresentanteDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {this.state.negocio && <span>Tem certeza que deseja excluir o representante <b>{this.state.representante.represnome}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={this.state.cloneNegocioDialog} style={{ width: '450px' }} header="Confirma" modal
                        footer={cloneNegocioDialogFooter} onHide={this.hideCloneNegocioDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {this.state.negocio && <span>Confirma o clone do negócio com consumo médio de <b>{this.state.negocio.consumomedia.toLocaleString('pt-BR', { style: 'decimal', maximunFractionDigits: 2, minimumFractionDigits: 2 })}</b>kWh?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        );
    }
}
