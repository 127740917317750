import React, { Component } from 'react';
import api from '../../../service/api';
import classNames from 'classnames';
import { Button } from '../../../components/button/Button';
import { Dialog } from '../../../components/dialog/Dialog';
import { DataTable } from '../../../components/datatable/DataTable';
import { Column } from '../../../components/column/Column'
import { InputText } from '../../../components/inputtext/InputText';
import { Toast } from '../../../components/toast/Toast';
import { Toolbar } from '../../../components/toolbar/Toolbar';
import { InputNumber } from '../../../components/inputnumber/InputNumber';

export class InclusosProposta extends Component {

    emptyInclusosProposta = {
        "id": null,
        "descricao": '',
        "ordem": null,
    }

    constructor(props) {
        super(props);

        this.state = {
            inclusosProposta: this.emptyInclusosProposta,
            listaInclusosProposta: [],
            submitted: false,
            edicao: false,
            editDialog: false,
            deleteDialog: false,
            rows: 10,
            first: 1,
        }

        this.originalRows = {};

        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.openNew = this.openNew.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.save = this.save.bind(this);
        this.edit = this.edit.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.hideDeleteDialog = this.hideDeleteDialog.bind(this);
        this.delete = this.delete.bind(this);
        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.rightToolbarTemplate = this.rightToolbarTemplate.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
    }

    async componentDidMount() {
        const listaInclusosProposta = await api.get(`/inclusosproposta`);
        this.setState({
            listaInclusosProposta: listaInclusosProposta.data
        });
    }

    hideDeleteDialog() {
        this.setState({ deleteDialog: false });
    }

    confirmDelete(inclusosProposta) {
        this.setState({
            inclusosProposta,
            deleteDialog: true
        });
    }

    delete() {
        api.delete(`/inclusosproposta/${this.state.inclusosProposta.id}`)
            .then(res => {
                if (res.status === 204) {
                    let listaInclusosProposta = this.state.listaInclusosProposta.filter(val => val.id !== this.state.inclusosProposta.id);
                    this.setState({
                        listaInclusosProposta,
                        deleteDialog: false,
                        inclusosProposta: this.emptyInclusosProposta
                    });
                    this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Item excluído', life: 3000 });
                }
            })
            .catch(error => {
                this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu excluir o item', life: 3000 });
                // console.log(error.response.data.description);
            })
    }

    edit(inclusosProposta) {
        this.setState({
            inclusosProposta: { ...inclusosProposta },
            editDialog: true
        });
    }

    openNew() {
        this.setState({
            inclusosProposta: this.emptyInclusosProposta,
            submitted: false,
            editDialog: true
        });
    }

    hideDialog() {
        this.setState({
            submitted: false,
            editDialog: false
        });
    }

    async save() {
        let state = { submitted: true };

        if (this.state.inclusosProposta.descricao.trim() && this.state.inclusosProposta.ordem) {
            let listaInclusosProposta = [...this.state.listaInclusosProposta];
            let inclusosProposta = { ...this.state.inclusosProposta };
            if (this.state.inclusosProposta.id) {
                const index = this.findIndexById(this.state.inclusosProposta.id);
                api.put(`/inclusosproposta/${this.state.inclusosProposta.id}`, inclusosProposta)
                    .then(res => {
                        if (res.status === 204) {
                            listaInclusosProposta[index] = inclusosProposta;
                            state = {
                                submitted: false,
                                listaInclusosProposta,
                                editDialog: false,
                                inclusosProposta: this.emptyInclusosProposta
                            };
                            this.setState(state);
                            this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Item atualizado', life: 3000 });
                        }
                    })
                    .catch(error => {
                        this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu atualizar o item', life: 3000 });
                        // console.log(error.response.data.description);
                    })
            }
            else {
                inclusosProposta.id = 0;
                api.post('/inclusosproposta', inclusosProposta)
                    .then(res => {
                        if (res.status === 201) {
                            inclusosProposta.id = res.headers.id;
                            listaInclusosProposta.push(inclusosProposta);
                            state = {
                                submitted: false,
                                listaInclusosProposta,
                                editDialog: false,
                                inclusosProposta: this.emptyInclusosProposta
                            };
                            this.setState(state);
                            this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Item inserido', life: 3000 });
                        }
                    })
                    .catch(error => {
                        this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu inserir o item', life: 3000 });
                        // console.log(error.response.data.description);
                    })
            }
        }
        else {
            this.setState(state);
        }
    }

    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-rounded p-mr-2" onClick={() => this.edit(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => this.confirmDelete(rowData)} />
            </React.Fragment>
        );
    }

    onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        const input = e.target;
        const start = input.selectionStart;
        const end = input.selectionEnd;
        let inclusosProposta = { ...this.state.inclusosProposta };
        inclusosProposta[`${name}`] = val.toUpperCase();
        this.setState({ inclusosProposta: inclusosProposta },
            () => input.setSelectionRange(start, end));
    }

    onInputNumberChange(e, name) {
        const val = e.value;
        let inclusosProposta = { ...this.state.inclusosProposta };
        inclusosProposta[`${name}`] = val;
        this.setState({ inclusosProposta: inclusosProposta });
    }

    findIndexById(id) {
        let index = -1;
        for (let i = 0; i < this.state.listaInclusosProposta.length; i++) {
            if (this.state.listaInclusosProposta[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    onUnidadeChange(e) {
        let inclusosProposta = { ...this.state.inclusosProposta };
        inclusosProposta.idund = e.value;
        this.setState({ inclusosProposta });
    }

    formatarNumero(rowData, field) {
        return rowData[field].toLocaleString('pt-BR', { style: 'decimal', minimumFractionDigits: 2 });
    }

    formatarCurrency(rowData, field) {
        return rowData[field].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 });
    }

    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="Novo" icon="pi pi-plus" className="p-button p-mr-2" onClick={this.openNew} />
            </React.Fragment>
        )
    }

    rightToolbarTemplate() {

    }

    onPageChange = (event) => {
        this.setState({rows : event.rows, first: event.first});
    };

    render() {
        const header = (
            <div className="table-header">
                <h5 className="p-m-0">Listagem</h5>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Localizar..." />
                </span>
            </div>
        );

        const dialogFooter = (
            <React.Fragment>
                <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={this.save} />
            </React.Fragment>
        );

        const deleteDialogFooter = (
            <React.Fragment>
                <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteDialog} />
                <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={this.delete} />
            </React.Fragment>
        );

        return (
            <div>
                <Toast ref={(el) => this.toast = el} />
                <Toolbar className="p-mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
                <DataTable
                    className="p-datatable-gridlines p-datatable-sm"
                    ref={(el) => this.dt = el}
                    value={this.state.listaInclusosProposta}
                    paginator={this.state.listaInclusosProposta.length > 5}
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Exibindo {first} até {last} de {totalRecords} Parâmetros"
                    globalFilter={this.state.globalFilter}
                    header={header}
                    // first={this.state.first}
                    // onPage={event => this.onPageChange(event)}
                    >
                    <Column field="id" header="Id" style={{ width: '6%' }}></Column>
                    <Column field="descricao" header="Descrição" ></Column>
                    <Column field="ordem" header="Ordem" style={{ width: '10%' }}></Column>
                    <Column body={this.actionBodyTemplate} style={{ width: '15%' }}></Column>
                </DataTable>

                <Dialog visible={this.state.editDialog} style={{ width: '550px' }} header="Item Incluso" modal className="p-fluid" footer={dialogFooter}
                    onHide={this.hideDialog}>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12">
                            <label htmlFor="descricao">Descrição</label>
                            <InputText id="descricao" value={this.state.inclusosProposta.descricao} onChange={(e) => this.onInputChange(e, 'descricao')}
                                required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.inclusosProposta.descricao })} 
                                 />
                            {this.state.submitted && !this.state.inclusosProposta.descricao && <small className="p-invalid">Descrição é requerido.</small>}
                        </div>
                        <div className="p-field p-col-12">
                            <label htmlFor="ordem">Ordem</label>
                            <InputNumber id="ordem" value={this.state.inclusosProposta.ordem} onValueChange={(e) => this.onInputNumberChange(e, 'ordem')}
                                required className={classNames({ 'p-invalid': this.state.submitted && !this.state.inclusosProposta.ordem })}
                                mode="decimal" locale="pt-BR" minFractionDigits={2} />
                            {this.state.submitted && !this.state.inclusosProposta.ordem && <small className="p-invalid">Ordem é requerido.</small>}
                        </div>
                    </div>
                </Dialog>

                <Dialog visible={this.state.deleteDialog} style={{ width: '450px' }} header="Confirme" modal footer={deleteDialogFooter}
                    onHide={this.hideDeleteDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        {this.state.inclusosProposta && <span>Tem certeza que deseja excluir <b>{this.state.inclusosProposta.descricao}</b>?</span>}
                    </div>
                </Dialog>
            </div>
        )
    }
}
