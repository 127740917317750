import React, { Component } from 'react';
import { Route, withRouter } from 'react-router-dom';

import { Clientes } from './view/cliente/cliente'
import { NegocioList } from './view/negocio/negocioList'
import { NegocioEdit } from './view/negocio/negocioForm';
import { Materiais } from './view/negocio/materiais';
import { MaterialACEdit } from './view/negocio/materiaisac';
import { MaterialDCEdit } from './view/negocio/materiaisdc';
import { EstruturaEdit } from './view/negocio/estruturafixacao';
import { ObraEdit } from './view/negocio/obracivil';
import { NegocioDimensionamentoEdit } from './view/negocio/negocioDimensionamentoEdit';
import { ParametroServico } from './view/parametros/components/parametroServico';
import { Servico } from './view/parametros/components/servico';
import { DimensionamentoPS } from './view/negocio/dimensionamentoParametroServico';
import { DimensionamentoServico } from './view/negocio/dimensionamentoServico';
import { Custos } from './view/negocio/custos';
import { Painel } from './view/negocio/painel';
import { ResumoFinanceiro } from './view/negocio/financeiro';
// import Login from './pages/login';
import PrivateRoute from './privateRoute';
import { PropostaConfiguracao } from './view/parametros/pages/propostaConfiguracao';
import { ServicoConfiguracao } from './view/parametros/pages/servicoConfiguracao';
import { Garantias } from './view/parametros/components/garantias';
import { ClienteEdit } from './view/cliente/clienteEdit';
import { ParametroSolarimetrico } from './view/parametros/components/parametroSolarimetrico';
import { Usuario } from './view/usuario/usuario';
import { PerfilUsuario } from './view/usuario/perfilUsuario';
import { ClienteNegocio } from './view/cliente/clienteNegocio';
import { Empresa } from './view/negocio/empresa';
import { TipoMaterial } from './view/negocio/tipoMaterial';
import { Teste } from './view/negocio/teste';
import { SelecionarPropostas } from './view/negocio/selecionarPropostas';
import { FormaPagamento } from './view/parametros/components/formaPagamento';
import { Fornecedor } from './view/fornecedor/fornecedor';
import DashBoard from './view/dashboard/dashboard';
import PageTest2 from './view/dashboard/pagetest2';
import Login from './view/login/login';
import { TrocarSenha } from './view/login/trocarSenha';
import { Switch } from 'react-router-dom';
// import  DashBoard  from './components/Dashboard';

const AppRouter = () => {


    return (
        <>
            <Switch>
                <Route exact path="/login" component={Login} />
                <PrivateRoute exact path="/trocarsenha" component={TrocarSenha} />
                <PrivateRoute exact path="/perfil" component={PerfilUsuario} />
                <PrivateRoute exact path="/pagetest" component={PageTest2} />
                <PrivateRoute exact path="/" component={DashBoard} />
                <PrivateRoute exact path="/clientes" component={Clientes} />
                <PrivateRoute exact path="/clientes/:id" component={ClienteEdit} />
                <PrivateRoute exact path="/negocios" component={NegocioList} />
                <PrivateRoute exact path="/negocios/:id" component={NegocioEdit} />
                <PrivateRoute exact path="/materiais" component={Materiais} />
                <PrivateRoute exact path="/materiaisac" component={MaterialACEdit} />
                <PrivateRoute exact path="/materiaisdc" component={MaterialDCEdit} />
                <PrivateRoute exact path="/estruturas" component={EstruturaEdit} />
                <PrivateRoute exact path="/obras" component={ObraEdit} />
                <PrivateRoute exact path="/dimensionamento/:id" component={NegocioDimensionamentoEdit} />
                <PrivateRoute exact path="/parametrosServico" component={ParametroServico} />
                <PrivateRoute exact path="/servicos" component={Servico} />
                <PrivateRoute exact path="/dpservicos" component={DimensionamentoPS} />
                <PrivateRoute exact path="/dimensionamentoservicos" component={DimensionamentoServico} />
                <PrivateRoute exact path="/custos" component={Custos} />
                <PrivateRoute exact path="/painel" component={Painel} />
                <PrivateRoute exact path="/financeiro" component={ResumoFinanceiro} />
                <PrivateRoute exact path="/configuracaoproposta" component={PropostaConfiguracao} />
                <PrivateRoute exact path="/configuracaoservico" component={ServicoConfiguracao} />
                <PrivateRoute exact path="/garantias" component={Garantias} />
                <PrivateRoute exact path="/parametrosolarimetrico" component={ParametroSolarimetrico} />
                <PrivateRoute exact path="/usuarios" component={Usuario} />
                <PrivateRoute exact path="/clienteNegocio" component={ClienteNegocio} />
                <PrivateRoute exact path="/empresa" component={Empresa} />
                <PrivateRoute exact path="/tipomaterial" component={TipoMaterial} />
                <PrivateRoute exact path="/teste" component={Teste} />
                <PrivateRoute exact path="/comparativo/:id" component={SelecionarPropostas} />
                <PrivateRoute exact path="/formapagamento" component={FormaPagamento} />
                <PrivateRoute exact path="/fornecedor" component={Fornecedor} />
            </Switch>
        </>
    );
}

export default withRouter(AppRouter);
