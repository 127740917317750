import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { DataTable } from '../../components/datatable/DataTable';
import { Column } from '../../components/column/Column';
import { Toast } from '../../components/toast/Toast';
import { Button } from '../../components/button/Button';
import { Dialog } from '../../components/dialog/Dialog';
import api from '../../service/api'
import { InputNumber } from '../../components/inputnumber/InputNumber';


export class DimensionamentoServicoTipo extends Component {

    static defaultProps = {
        idNegocioDimensionamento: 0,
        tipo: '',
        idstatus: 1
    }

    static propTypes = {
        idNegocioDimensionamento: PropTypes.number,
        tipo: PropTypes.string,
        idstatus: PropTypes.number,
    }

    emptyDimensionamentoServico = {
        "id": null,
        "idnegociodimensionamento": null,
        "idservico": null,
        "idparametroservico": null,
        "valorunitario": 0,
        "total": 0,
        "servico": ''
    };

    atualizarTotal = () => {
        this.props.atualizarTotal(this.props.value);
    }

    constructor(props) {
        super(props);

        this.state = {
            dimensionamentoServico: this.emptyDimensionamentoServico,
            listaDimensionamentoServicos: [],
            listaDimensionamentoServicosOld: [],
            listaParametros: [],
            servicosDisponiveis: [],
            servicoDialog: false,
            servicosSelecionados: [],
            submitted: false,
            dimensionamentoServicoOld: this.emptyDimensionamentoServico,
        };

        this.originalRows = {};

        this.novoServico = this.novoServico.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.onRowEditInit = this.onRowEditInit.bind(this);
        this.onRowEditCancel = this.onRowEditCancel.bind(this);
        this.onRowEditSave = this.onRowEditSave.bind(this);
        this.adicionarServico = this.adicionarServico.bind(this);
    }

    async componentDidMount() {
        const id = this.props.idNegocioDimensionamento;
        const tipo = this.props.tipo.toUpperCase();
        const dimensionamentoServicosTipo = await api.get(`/dimensionamentoservicos/dimensionamento/${id}/tipo/${tipo}`);

        this.setState({
            listaDimensionamentoServicos: dimensionamentoServicosTipo.data,
        });
    }

    async componentWillReceiveProps(nextProps) {
        if (nextProps.nonUsedKey !== this.props.nonUsedKey) {
            const dimensionamentoServicos = await api.get(`/dimensionamentoservicos/dimensionamento/${this.props.idNegocioDimensionamento}/tipo/${this.props.tipo}`);
            this.setState({ listaDimensionamentoServicos: dimensionamentoServicos.data });
        }
    }

    async novoServico() {
        const id = this.props.idNegocioDimensionamento;
        const tipo = this.props.tipo.toUpperCase();
        api.get(`/servico/disponiveis/dimensionamento/${id}/tipo/${tipo}`)
            .then(res => {
                if (res.status === 200) {
                    if (res.data.length === 0) {
                        this.toast.show({ severity: 'warn', summary: 'Atenção', detail: 'Não há serviços para inclusão', life: 3000 });
                    } else {
                        this.setState({
                            servicosDisponiveis: res.data,
                            servicoDialog: true,
                        });
                    }
                }
            })
            .catch(error => {
                this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu exibir os servicos disponíveis', life: 3000 });
                //console.log(error.response.data.description);
            })


    }

    adicionarServico() {
        const id = this.props.idNegocioDimensionamento;
        let listaDimensionamentoServicos = [...this.state.listaDimensionamentoServicos];
        let listaServicosSelecionados = [...this.state.servicosSelecionados];
        let servico = this.emptyDimensionamentoServico;

        for (let i = 0; i < listaServicosSelecionados.length; i++) {
            servico.id = 0;
            servico.idnegociodimensionamento = id;
            servico.idservico = listaServicosSelecionados[i].id;
            servico.idparametroservico = !listaServicosSelecionados[i].idparametroservico ? 0 : listaServicosSelecionados[i].idparametroservico;
            servico.servico = listaServicosSelecionados[i].servico;
            api.post(`/dimensionamentoservicos`, servico)
                .then(res => {
                    if (res.status === 201) {
                        servico.id = res.headers.id;
                        listaDimensionamentoServicos.push(servico);
                        this.setState({
                            listaDimensionamentoServicos: listaDimensionamentoServicos,
                            servicoDialog: false
                        });
                        this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Serviço inserido com sucesso', life: 3000 });
                    }

                })
                .catch(error => {
                    this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu incluir o servico', life: 3000 });
                    //console.log(error.response.data.description);
                })
        }


    }

    onRowEditInit(event) {
        this.originalRows = { ...this.state.listaDimensionamentoServicos[event.index] };
    }

    onRowEditCancel(event) {
        let dimensionamentoServicos = { ...this.state.listaDimensionamentoServicos[event.index]};
        dimensionamentoServicos[event.index] = this.originalRows;
        event.rowData = dimensionamentoServicos;   
        console.log(dimensionamentoServicos);
        console.log(this.originalRows);
        this.setState({ listaDimensionamentoServico: dimensionamentoServicos });
        delete this.originalRows;
    }

    async onRowEditSave(event) {
        let dimensionamentoServico = { ...this.state.listaDimensionamentoServicos[event.index] };
        if (!dimensionamentoServico.idparametroservico) {
            dimensionamentoServico.idparametroservico = -1;
        }
        api.put(`/dimensionamentoservicos/${dimensionamentoServico.id}`, dimensionamentoServico)
            .then(res => {
                if (res.status === 204) {
                    api.get(`/dimensionamentoservicos/dimensionamento/${this.props.idNegocioDimensionamento}/tipo/${this.props.tipo}`)
                        .then(dimensionamentoServicos => {
                            this.setState({ listaDimensionamentoServicos: dimensionamentoServicos.data });
                        })
                    this.atualizarTotal();
                    this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Serviço atualizado com sucesso', life: 3000 });

                }
            })
            .catch(error => {
                this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu atualizar o servico', life: 3000 });
                // console.log(error.response.data.description);
            })
    }

    onEditorNumberValueChange(listaName, props, value) {
        let updated = [...props.value];
        updated[props.rowIndex][props.field] = value || 0;
        this.setState({ [`${listaName}`]: updated });
    }

    inputNumberEditor(listaName, props, field) {
        return <InputNumber mode="decimal" locale="pt-BR" minFractionDigits={2} value={props.rowData[field]}
        onValueChange={(e) => this.onEditorNumberValueChange(listaName, props, e.value)} inputStyle={{ width: '100%' }} />;
    }


    hideDialog() {
        this.setState({
            submitted: false,
            servicoDialog: false
        });
    }

    formatarCurrency(rowData, field) {
        return rowData[field].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 });
    }

    editServicoDialog(dimensionamentoServico) {
        this.setState({
            dimensionamentoServico: { ...dimensionamentoServico },
            servicoDialog: true
        });
    }

    render() {
        const header = (
            <div className="table-header">
                <Button 
                    label="Incluir" 
                    icon="pi pi-plus" 
                    className="p-button p-mr-2" 
                    onClick={this.novoServico}
                    disabled={this.props.idstatus > 2}
                    /*onClick={() => this.editGarantiaDialog(rowData)}*/ />
            </div>
        );
        const dimensionamentoServicoDialogFooter = (
            <React.Fragment>
                {/* <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} /> */}
                <Button label="Incluir" icon="pi pi-check" className="p-button-text" onClick={this.adicionarServico} />
            </React.Fragment>
        );

        return (
            <div>
                <Toast ref={(el) => this.toast = el} />
                <DataTable
                    className="p-datatable-gridlines p-datatable-sm editable-cells-table"
                    ref={(el) => this.dt = el}
                    value={this.state.listaDimensionamentoServicos}
                    editMode={this.props.idstatus < 3 ? "row" : ""}
                    dataKey="id"
                    header={header}
                    onRowEditInit={this.onRowEditInit}
                    onRowEditCancel={this.onRowEditCancel}
                    onRowEditSave={this.onRowEditSave}>

                    <Column field="servico" header="Descrição" style={{ width: '50%' }}></Column>
                    <Column
                        className="p-editable-column p-cell-editing"
                        field="valorunitario"
                        header="Valor Unitário"
                        body={(rowData) => this.formatarCurrency(rowData, 'valorunitario')}
                        editor={(props) => this.inputNumberEditor('listaDimensionamentoServicos', props, 'valorunitario')}>
                    </Column>
                    <Column field="total" header="Total" body={(rowData) => this.formatarCurrency(rowData, 'total')}></Column>
                    <Column rowEditor headerStyle={{ width: '7rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                </DataTable>

                <Dialog visible={this.state.servicoDialog} style={{ width: '600px' }} header="Serviços" footer={dimensionamentoServicoDialogFooter} modal onHide={this.hideDialog}>
                    <div className="p-field-checkbox">
                        <DataTable 
                            value={this.state.servicosDisponiveis} 
                            selection={this.state.servicosSelecionados} 
                            onSelectionChange={e => this.setState({ servicosSelecionados: e.value })} 
                            dataKey="id"
                            className="p-datatable-gridlines p-datatable-sm">
                            <Column selectionMode="multiple" ></Column>
                            <Column field="servico" header="Descrição"></Column>
                        </DataTable>
                    </div>
                </Dialog>
            </div>
        );
    }
}
