import React, { Component } from 'react';
import api from '../../service/api';
import { Toast } from '../../components/toast/Toast';
import { Dropdown } from '../../components/dropdown/Dropdown';

export class DimensionamentoConfigGarantia extends Component {

    emptyDimensionamento = {
        "id": 0,
        "idnegocio": 0,
        "eficiencia": 0.00,
        "areautilgeracao": 0.00,
        "quantidademodulos": 0.00,
        "potencia": 0.00,
        "fornecedor": "",
        "potenciainversor": 0.00,
        "idgarantiaservico": null,
    };

    static defaultProps = {
        dimensionamento: this.emptyDimensionamento,
    }

    static propTypes = {
        dimensionamento: {},
    }

    emptyGarantia = {
        "id": 0,
        "descricao": "",
        "garantia": "",
        "identificador": "",
        "tipo": ""
    }

    atualizarDimensionamento = (id) => {
        this.props.atualizar(id);
    }

    constructor(props) {
        super(props);

        this.state = {
            garantia: this.emptyGarantia,
            garantias: [],
        };

    }

    async componentDidMount() {
        const garantias = await api.get('/garantias/tipo/s');
        this.setState({
            garantias: garantias.data
        });
    }

    onGarantiaChange(e) {
        const val = (e.target && e.target.value) || '';
        this.atualizarDimensionamento(val);
    }

    render() {

        return (
            <div>
                <Toast ref={(el) => this.toast = el} />

                    <div>
                        <div>
                            <Dropdown
                                style={{ width: "50%" }}
                                optionValue="id"
                                optionLabel="prazo"
                                value={this.props.dimensionamento.idgarantiaservico}
                                options={this.state.garantias}
                                onChange={(e) => this.onGarantiaChange(e)}
                                placeholder="Selecionar" 
                                disabled={this.props.dimensionamento.idstatus > 2}
                                />
                        </div>
                    </div>
            </div>
        );
    }
}