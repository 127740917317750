import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import api from '../../../service/api';
import { Toast } from '../../../components/toast/Toast';
import { Checkbox } from '../../../components/checkbox/Checkbox';

export class EstruturaSoloGarantias extends Component {

    static defaultProps = {
        idEstrutura: 0
    }

    static propTypes = {
        idEstrutura: PropTypes.number
    }
    emptyEstruturaGarantias = {
        "id": null,
        "idestruturas": null,
        "idgarantia": null,
        "descricao": "",
        "prazo": "",
        "identificador": ""
    }

    constructor(props) {
        super(props);

        this.state = {
            listaGarantiasSelecionadas: [],
            listaGarantias: [],
        }

        this.onCategoryChange = this.onCategoryChange.bind(this);

    }

    async componentDidMount() {
        const listaGarantiasSelecionadas = await api.get(`/estruturasgarantias/estrutura/${this.props.idEstrutura}`);
        const listaGarantias = await api.get(`/garantias/tipo/p`);
        this.setState({
            listaGarantiasSelecionadas: listaGarantiasSelecionadas.data,
            listaGarantias: listaGarantias.data
        });
    }

    onCategoryChange(e) {
        console.log("idEstrutura: " + this.props.idEstrutura)
        let listaGarantiasSelecionadas = [...this.state.listaGarantiasSelecionadas];
        if (e.checked) {
            let newGarantia = this.emptyEstruturaGarantias;
            newGarantia.id = -1;
            newGarantia.idestruturas = this.props.idEstrutura;
            newGarantia.idgarantia = e.value.id;
            newGarantia.descricao = e.value.descricao;
            newGarantia.prazo = e.value.prazo;
            newGarantia.identificador = e.value.identificador;

            console.log(newGarantia)
            api.post('/estruturasgarantias', newGarantia)
                .then(res => {
                    if (res.status === 201) {
                        newGarantia.id = res.headers.id;
                        
                        api.get(`/estruturasgarantias/estrutura/${this.props.idEstrutura}`)
                            .then(ret => {
                                this.setState({ listaGarantiasSelecionadas: ret.data });
                            })

                        this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Garantia inserida', life: 3000 });
                    }
                });
        }
        else {
            for (let i = 0; i < listaGarantiasSelecionadas.length; i++) {
                const garantia = listaGarantiasSelecionadas[i];

                if (garantia.idgarantia === e.value.id) {
                    api.delete(`/estruturasgarantias/${garantia.id}`)
                        .then(res => {
                            if (res.status === 204) {
                                listaGarantiasSelecionadas.splice(i, 1);
                                this.setState({ listaGarantiasSelecionadas: listaGarantiasSelecionadas });
                                this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Garantia removida', life: 3000 });
                            }
                        });
                    break;
                }
            }
        }
    }

    render() {
        return (
            <div>
                <Toast ref={(el) => this.toast = el} />
                {
                    this.state.listaGarantias.map((garantia) => {
                        return (
                            <div key={garantia.id} className="p-field-checkbox">
                                <Checkbox
                                    inputId={garantia.id.toLocaleString()}
                                    name="garantia"
                                    value={garantia}
                                    onChange={this.onCategoryChange}
                                    checked={this.state.listaGarantiasSelecionadas.some((item) => item.idgarantia === garantia.id)} />
                                <label htmlFor={garantia.id}>{garantia.descricao} ({garantia.prazo + ' ' + garantia.identificador})</label>
                            </div>
                        )
                    })
                }
            </div>
        )
    }
}
