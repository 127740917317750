import React, { Component } from 'react';
import api from '../../service/api';
import { Button } from '../../components/button/Button';
import { InputNumber } from '../../components/inputnumber/InputNumber';
import { Toast } from '../../components/toast/Toast';
import { PropTypes } from 'prop-types';
import { InputText } from '../../components/inputtext/InputText';
import { DataTable } from '../../components/datatable/DataTable';
import { Column } from '../../components/column/Column';

export class DimensionamentoConfigPrazos extends Component {

    static defaultProps = {
        idNegocioDimensionamento: 0,
        idstatus: 1,
    }

    static propTypes = {
        idNegocioDimensionamento: PropTypes.number,
        idstatus: PropTypes.number,
    }

    constructor(props) {
        super(props);

        this.state = {
            listaPrazos: [],
        };

        this.editingCellRows = {};
        this.originalRows = {};

        this.onEdit = this.onEdit.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onInputNumberChange = this.onInputNumberChange.bind(this);
        this.formatarCurrency = this.formatarCurrency.bind(this);
        this.exibirPaginacao = this.exibirPaginacao.bind(this);
        this.inputNumberEditor = this.inputNumberEditor.bind(this);
        this.onEditorNumberValueChange = this.onEditorNumberValueChange.bind(this);

        this.onEditorInit = this.onEditorInit.bind(this);
        this.onEditorCancel = this.onEditorCancel.bind(this);
        this.onEditorSubmit = this.onEditorSubmit.bind(this);

        this.onRowEditInit = this.onRowEditInit.bind(this);
        this.onRowEditCancel = this.onRowEditCancel.bind(this);
        this.rowPrazoEditSave = this.rowPrazoEditSave.bind(this);

    }

    async componentDidMount() {
        const id = this.props.idNegocioDimensionamento;
        const prazos = await api.get(`/dimensionamentoprazos/dimensionamento/${id}`);
        this.setState({
            listaPrazos: prazos.data
        });
    }

    onEdit() {
        this.dimensionamentoAtual = { ...this.state.Dimensionamento };
        this.setState({ editDimensionamento: true })
    }

    onSave() {
        api.put('/dimensionamentos', this.state.Dimensionamento)
            .then(res => {
                if (res.status === 201) {
                    this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Dado(s) atualizado(s)', life: 3000 });
                    this.setState({
                        qtdModulos: this.state.Dimensionamento.quantidademodulos,
                        editDimensionamento: false
                    })
                }
            })

    }

    onCancel() {
        this.setState({
            Dimensionamento: this.dimensionamentoAtual,
            editDimensionamento: false
        })

    }

    onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        const input = e.target;
        const start = input.selectionStart;
        const end = input.selectionEnd;
        let dimensionamento = { ...this.state.Dimensionamento };
        dimensionamento[`${name}`] = val.toUpperCase();
        this.setState({ Dimensionamento: dimensionamento },
            () => input.setSelectionRange(start, end));
    }

    onInputNumberChange(e, name) {
        const val = e.value || 0;
        let dimensionamento = { ...this.state.Dimensionamento };
        dimensionamento[`${name}`] = val;
        this.setState({ Dimensionamento: dimensionamento });
    }

    editNumerTemplate(name, FractionDigits) {
        if (this.state.editDimensionamento) {
            return (
                <React.Fragment>
                    <InputNumber inputStyle={{ width: '100%' }} className="p-inputtext-sm" value={this.state.Dimensionamento[name]}
                        onValueChange={(e) => this.onInputNumberChange(e, name)}
                        mode="decimal" locale="pt-BR" minFractionDigits={FractionDigits}
                         />
                </React.Fragment>
            )
        } else {
            return (this.state.Dimensionamento[name].toLocaleString('pt-BR'))
        }
    }

    editTextTemplate(name) {
        if (this.state.editDimensionamento) {
            return (
                <React.Fragment>
                    <InputText style={{ width: '100%' }} className="p-inputtext-sm" value={this.state.Dimensionamento[name]}
                        onChange={(e) => this.onInputChange(e, name)}  />
                </React.Fragment>
            )
        } else {
            return (this.state.Dimensionamento[name])
        }
    }

    footerTemplate() {
        if (this.state.editDimensionamento) {
            return (
                <React.Fragment>
                    <Button type="Button" label="Salvar" icon="pi pi-check" className="p-button-rounded p-button-success p-mr-2" onClick={this.onSave} />
                    <Button type="Button" label="Cancelar" icon="pi pi-times" className="p-button-rounded p-button-warning p-mr-2" onClick={this.onCancel} />
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <Button type="Button" label="Alterar" icon="pi pi-pencil" className="p-button-rounded p-mr-2" onClick={this.onEdit} />
                </React.Fragment>
            )

        }
    }


    formatarCurrency(value) {
        let ret = value || 0;
        return ret.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 });
    }

    onEditorValueChange(productKey, props, value) {
        let updatedProducts = [...props.value];
        updatedProducts[props.rowIndex][props.field] = value;
        this.setState({ [`${productKey}`]: updatedProducts });
    }

    inputTextEditor(productKey, props, field) {
        return <InputText type="text" value={props.rowData[field]} onChange={(e) => this.onEditorValueChange(productKey, props, e.target.value)} />;
    }

    onEditorInit(props) {
        const { rowIndex: index, field, rowData } = props;
        if (!this.editingCellRows[index]) {
            this.editingCellRows[index] = { ...rowData };
        }
        this.editingCellRows[index][field] = this.state.listaDimensionamento[index][field];
    }

    onEditorCancel(props) {
        const { rowIndex: index, field } = props;
        let lista = [...this.state.listaDimensionamento];
        lista[index][field] = this.editingCellRows[index][field];
        delete this.editingCellRows[index][field];

        this.setState({
            listaDimensionamento: lista
        });
    }

    onEditorSubmit(props) {
        const { rowIndex: index, field } = props;
        delete this.editingCellRows[index][field];
        this.onSave();
    }

    exibirPaginacao() {
        let exibir = false;
        if (this.state.listaPrazos.length > 5) { exibir = true }
        return exibir
    }

    novoPrazo() {

    }

    onRowEditInit(event) {
        this.originalRows[event.index] = { ...this.state.listaPrazos[event.index] };
    }

    onRowEditCancel(event) {
        let listaPrazos = [...this.state.listaPrazos];
        listaPrazos[event.index] = this.originalRows[event.index];
        delete this.originalRows[event.index];
        this.setState({ listaPrazos: listaPrazos });
    }

    async rowPrazoEditSave(event) {
        let prazo = { ...this.state.listaPrazos[event.index] };
        api.put(`/dimensionamentoprazos/${prazo.id}`, prazo)
            .then(res => {
                if (res.status === 204) {
                    this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Prazo atualizado com sucesso', life: 3000 });

                }
            })
            .catch(error => {
                this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu atualizar o prazo', life: 3000 });
                // console.log(error.response.data.description);
            })
    }

    inputNumberEditor(listaName, props, field) {
        return <InputNumber value={props.rowData[field]}
        onValueChange={(e) => this.onEditorNumberValueChange(listaName, props, e.value)} inputStyle={{ width: '100%' }} />;
    }

    onEditorNumberValueChange(listaName, props, value) {
        let updated = [...props.value];
        updated[props.rowIndex][props.field] = value || 0;
        this.setState({ [`${listaName}`]: updated });
    }

    render() {
        //const titulo = "";
        /* const headerAccordion = (
            <div className="table-header">
                <span>{titulo}</span>
                <Button label='Teste' />
            </div>

        ); */

        return (
            <div >
                <Toast ref={(el) => this.toast = el} />

                <DataTable
                    className="p-datatable-gridlines p-datatable-sm editable-cells-table"
                    style={{ width: '100%' }}
                    ref={(el) => this.dt = el}
                    value={this.state.listaPrazos}
                    editMode={this.props.idstatus < 3 ? "row" : ""}
                    dataKey="id"
                    //header={headerPrazo}
                    onRowEditInit={this.onRowEditInit}
                    onRowEditCancel={this.onRowEditCancel}
                    onRowEditSave={this.rowPrazoEditSave}
                    paginator={this.exibirPaginacao()}
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Exibindo {first} até {last} de {totalRecords} Parâmetros">
                    <Column field="descricao" header="Descrição" style={{ width: '75%' }}></Column>
                    <Column
                        className="p-editable-column p-cell-editing"
                        field="dias"
                        header="Dias"
                        style={{ width: '8%' }}
                        editor={(props) => this.inputNumberEditor('listaPrazos', props, 'dias')}>
                    </Column>
                    <Column rowEditor headerStyle={{ width: '7rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                </DataTable>
            </div>
        );
    }
}
