import React, { Component } from 'react';
import api from '../../service/api';
import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import { Button } from '../../components/button/Button';
import { Panel } from '../../components/panel/Panel';
import { Dialog } from '../../components/dialog/Dialog';
import { Dropdown } from '../../components/dropdown/Dropdown';
import { DataTable } from '../../components/datatable/DataTable';
import { Column } from '../../components/column/Column'
import { InputText } from '../../components/inputtext/InputText';
import { Toast } from '../../components/toast/Toast';
import { InputNumber } from '../../components/inputnumber/InputNumber';
import { MateriasDCGarantias } from './materiais/materiaisdcGarantias';

export class MaterialDCEdit extends Component {

    static defaultProps = {
        idDimensionamento: 1,
        idstatus: 1
    }

    static propTypes = {
        idDimensionamento: PropTypes.number,
        idstatus: PropTypes.number,
    }

    emptyMaterialDC = {
        "id": null,
        "idnegociodimensionamento": null,
        "descricao": '',
        "quantidade": 0,
        "idund": null,
        "valorunitario": 0,
        "total": 0,
        "unidade": '',
        "modulo": false,
        "idtipomaterial": null,
        "tipo": ''

    }

    atualizarTotal = () => {
        this.props.atualizarTotal(this.props.value);
    }

    constructor(props) {
        super(props);

        this.state = {
            materialDC: this.emptyMaterialDC,
            materiaisDC: [],
            unidades: [],
            tipos: [],
            submitted: false,
            edicao: false,
            materialDCDialog: false,
            deleteMaterialDCDialog: false,
            garantiaDialog: false,
        }

        this.originalRows = {};

        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.openNewMaterialDC = this.openNewMaterialDC.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.saveMaterialDC = this.saveMaterialDC.bind(this);
        this.editMaterialDC = this.editMaterialDC.bind(this);
        this.confirmDeleteMaterialDC = this.confirmDeleteMaterialDC.bind(this);
        this.hideDeleteMaterialDCDialog = this.hideDeleteMaterialDCDialog.bind(this);
        this.deleteMaterialDC = this.deleteMaterialDC.bind(this);
        this.editGarantiaDialog = this.editGarantiaDialog.bind(this);
        this.hideGarantiaDialog = this.hideGarantiaDialog.bind(this);
    }

    async componentDidMount() {
        const materiaisDC = await api.get(`/materiaisdc/dimensionamento/${this.props.idDimensionamento}`);
        const unidades = await api.get('/unidadesmedida');
        const tipos = await api.get('/tipomaterial');
        this.setState({
            materiaisDC: materiaisDC.data,
            unidades: unidades.data,
            tipos: tipos.data
        });
    }

    async componentWillReceiveProps(nextProps) {
        if (nextProps.nonUsedKey !== this.props.nonUsedKey) {
            const materiaisDC = await api.get(`/materiaisdc/dimensionamento/${this.props.idDimensionamento}`);
            this.setState({ materiaisDC: materiaisDC.data });
            this.atualizarTotal();
        }
    }

    hideDeleteMaterialDCDialog() {
        this.setState({ deleteMaterialDCDialog: false });
    }

    hideGarantiaDialog() {
        this.setState({ garantiaDialog: false });
    }

    confirmDeleteMaterialDC(materialDC) {
        if (materialDC.modulo === false) {
            this.setState({
                materialDC,
                deleteMaterialDCDialog: true
            });
        } else {
            this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O Módulo não pode ser excluído', life: 3000 });
        }
    }

    deleteMaterialDC() {
        api.delete(`/materiaisdc/${this.state.materialDC.id}`)
            .then(res => {
                if (res.status === 204) {
                    let materiaisDC = this.state.materiaisDC.filter(val => val.id !== this.state.materialDC.id);
                    this.setState({
                        materiaisDC,
                        deleteMaterialDCDialog: false,
                        materialDC: this.emptyMaterialDC
                    });
                    this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Material excluído', life: 3000 });
                    this.atualizarTotal();
                }
            })
            .catch(error => {
                this.setState({ deleteMaterialDCDialog: false });
                this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu excluir o Material DC', life: 3000 });
                console.log(error.response.data.description);
            })
    }

    editMaterialDC(materialDC) {
        this.setState({
            materialDC: { ...materialDC },
            materialDCDialog: true
        });
    }

    editGarantiaDialog(materialDC) {
        this.setState({
            materialDC: { ...materialDC },
            garantiaDialog: true
        });
    }

    openNewMaterialDC() {
        this.setState({
            materialDC: this.emptyMaterialDC,
            submitted: false,
            materialDCDialog: true
        });
    }

    hideDialog() {
        this.setState({
            submitted: false,
            materialDCDialog: false
        });
    }

    saveMaterialDC() {
        let state = { submitted: true };

        if (this.state.materialDC.descricao.trim() && this.state.materialDC.valorunitario && this.state.materialDC.valorunitario > 0
            && this.state.materialDC.quantidade && this.state.materialDC.quantidade > 0 && this.state.materialDC.idund) {

            let materiaisDC = [...this.state.materiaisDC];
            let materialDC = { ...this.state.materialDC };
            if (this.state.materialDC.id) {
                const index = this.findIndexById(this.state.materialDC.id);
                api.put(`/materiaisdc/${this.state.materialDC.id}`, materialDC)
                    .then(res => {
                        if (res.status === 204) {
                            materiaisDC[index] = materialDC;
                            state = {
                                ...state,
                                materiaisDC: materiaisDC,
                                materialDCDialog: false,
                                materialDC: this.emptyMaterialDC
                            };
                            this.setState(state);
                            this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Material DC atualizado', life: 3000 });
                            this.atualizarTotal();
                        }
                    })
                    .catch(error => {
                        this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu atualizar o Material DC', life: 3000 });
                        // console.log(error.response.data.description);
                    })
            }
            else {
                materialDC.id = 0;
                materialDC.idnegociodimensionamento = this.props.idDimensionamento;
                api.post('/materiaisdc', materialDC)
                    .then(res => {
                        if (res.status === 201) {
                            materialDC.id = res.headers.id;
                            materiaisDC.push(materialDC);
                            state = {
                                ...state,
                                materiaisDC: materiaisDC,
                                materialDCDialog: false,
                                materialDC: this.emptyMaterialDC
                            };
                            this.setState(state);
                            this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Material DC inserido', life: 3000 });
                            this.atualizarTotal();
                        }
                    })
                    .catch(error => {
                        this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu inserir o Material DC', life: 3000 });
                        // console.log(error.response.data.description);
                    })
            }

        }
        else {
            this.setState(state);
        }

    }

    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button p-mr-2"
                    onClick={() => this.editMaterialDC(rowData)}
                    tooltip="Editar"
                    tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} 
                    disabled={this.props.idstatus > 2} />
                <Button
                    icon="pi pi-tags"
                    className="p-button-rounded p-button-warning p-mr-2"
                    onClick={() => this.editGarantiaDialog(rowData)}
                    tooltip="Garantia"
                    tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} 
                    disabled={this.props.idstatus > 2} />
                <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-mr-2"
                    onClick={() => this.confirmDeleteMaterialDC(rowData)}
                    tooltip="Excluir"
                    tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} 
                    disabled={this.props.idstatus > 2} />
            </React.Fragment>
        );
    }

    onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        const input = e.target;
        const start = input.selectionStart;
        const end = input.selectionEnd;
        let materialDC = { ...this.state.materialDC };
        materialDC[`${name}`] = val.toUpperCase();
        this.setState({ materialDC: materialDC },
            () => input.setSelectionRange(start, end));
    }

    onInputNumberChange(e, name) {
        const val = e.value || 0;
        let total = 0;
        let materialDC = { ...this.state.materialDC };
        materialDC[`${name}`] = val;
        if ((materialDC.valorunitario) && (materialDC.quantidade)) {
            total = materialDC.valorunitario * materialDC.quantidade;
        }
        materialDC.total = total;
        this.setState({ materialDC: materialDC });
    }

    findIndexById(id) {
        let index = -1;
        for (let i = 0; i < this.state.materiaisDC.length; i++) {
            if (this.state.materiaisDC[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    findUnidade(id) {
        let index = -1;
        for (let i = 0; i < this.state.unidades.length; i++) {
            if (this.state.unidades[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    findTipo(id) {
        let index = -1;
        for (let i = 0; i < this.state.tipos.length; i++) {
            if (this.state.tipos[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    onTipoChange(e) {
        let materialDC = { ...this.state.materialDC };
        materialDC.idtipomaterial = e.value;
        materialDC.tipo = this.state.tipos[this.findTipo(e.value)].tipo;
        this.setState({ materialDC });
    }

    onUnidadeChange(e) {
        let materialDC = { ...this.state.materialDC };
        materialDC.idund = e.value;
        materialDC.unidade = this.state.unidades[this.findUnidade(e.value)].unidade;
        this.setState({ materialDC });
    }

    formatarNumero(rowData, field) {
        return rowData[field].toLocaleString('pt-BR', { style: 'decimal', minimumFractionDigits: 2 });
    }

    formatarCurrency(rowData, field) {
        return rowData[field].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 });
    }

    render() {
        const header = (
            <div className="table-header" left={this.busca}>
                <Button 
                    label="Novo" 
                    icon="pi pi-plus" 
                    className="p-button p-mr-2" 
                    onClick={this.openNewMaterialDC} 
                    disabled={this.props.idstatus > 2} />
            </div>
        );

        const MaterialDCDialogFooter = (
            <React.Fragment>
                <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={this.saveMaterialDC} />
            </React.Fragment>
        );

        const deleteMaterialDCDialogFooter = (
            <React.Fragment>
                <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteMaterialDCDialog} />
                <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={this.deleteMaterialDC} />
            </React.Fragment>
        );

        return (
            <div>
                <Toast ref={(el) => this.toast = el} />
                <DataTable header={header} value={this.state.materiaisDC} className="p-datatable-sm p-datatable-gridlines">
                    <Column field="id" header="Id" style={{ width: '6%' }} />
                    <Column field="descricao" header="Descrição" style={{ width: '30%' }} />
                    <Column field="quantidade" header="Qtd." body={(rowData) => this.formatarNumero(rowData, 'quantidade')} style={{ width: '8%' }} />
                    <Column field="unidade" header="Und" style={{ width: '8%' }} />
                    <Column field="valorunitario" header="Valor" body={(rowData) => this.formatarCurrency(rowData, 'valorunitario')} style={{ width: '12%' }} />
                    <Column field="total" header="Total" body={(rowData) => this.formatarCurrency(rowData, 'total')} style={{ width: '12%' }} />
                    <Column body={this.actionBodyTemplate} style={{ width: '110px' }} />
                </DataTable>

                <Dialog visible={this.state.materialDCDialog} style={{ width: '550px' }} header="Material DC" modal className="p-fluid" footer={MaterialDCDialogFooter} onHide={this.hideDialog}>
                    <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12">
                            <label htmlFor="idtipomaterial">Tipo</label>
                            <Dropdown 
                              inputId="idtipomaterial" 
                              optionValue="id" 
                              optionLabel="descricao" 
                              value={this.state.materialDC.idtipomaterial} 
                              options={this.state.tipos}
                              onChange={(e) => this.onTipoChange(e)} 
                              placeholder="Tipo"
                              required 
                              autoFocus 
                              className={classNames({ 'p-invalid': this.state.submitted && !this.state.materialDC.idtipomaterial })} />
                            {this.state.submitted && !this.state.materialDC.idtipomaterial && <small className="p-invalid">O tipo é requerido.</small>}
                        </div>
                        <div className="p-field p-col-12">
                            <label htmlFor="descricao">Descrição</label>
                            <InputText id="descricao" value={this.state.materialDC.descricao} onChange={(e) => this.onInputChange(e, 'descricao')}
                                required className={classNames({ 'p-invalid': this.state.submitted && !this.state.materialDC.descricao })}
                                 />
                            {this.state.submitted && !this.state.materialDC.descricao && <small className="p-invalid">Descrição é requerido.</small>}
                        </div>
                        <div className="p-field p-col-12">
                            <label htmlFor="quantidade">Quantidade</label>
                            <InputNumber disabled={this.state.materialDC.modulo} id="quantidade" value={this.state.materialDC.quantidade} onValueChange={(e) => this.onInputNumberChange(e, 'quantidade')}
                                required className={classNames({ 'p-invalid': this.state.submitted && !this.state.materialDC.quantidade })}
                                mode="decimal" locale="pt-BR" minFractionDigits={2}  />
                            {this.state.submitted && !this.state.materialDC.quantidade && <small className="p-invalid">Quantidade é requerido.</small>}
                        </div>
                        <div className="p-field p-col-12">
                            <label htmlFor="idund">Unidade</label>
                            <Dropdown inputId="idund" optionValue="id" optionLabel="unidade" value={this.state.materialDC.idund} options={this.state.unidades}
                                onChange={(e) => this.onUnidadeChange(e)} placeholder="Unidade"
                                required className={classNames({ 'p-invalid': this.state.submitted && !this.state.materialDC.idund })} />
                            {this.state.submitted && !this.state.materialDC.idund && <small className="p-invalid">A unidade de medida é requerido.</small>}
                        </div>
                        <div className="p-field p-col-12">
                            <label htmlFor="valorunitario">Valor Unitário</label>
                            <InputNumber id="valorunitario" value={this.state.materialDC.valorunitario} onValueChange={(e) => this.onInputNumberChange(e, 'valorunitario')}
                                required className={classNames({ 'p-invalid': this.state.submitted && !this.state.materialDC.valorunitario })}
                                mode="decimal" locale="pt-BR" minFractionDigits={2}  />
                            {this.state.submitted && !this.state.materialDC.valorunitario && <small className="p-invalid">Valor unitário é requerido.</small>}
                        </div>
                        <div className="p-field p-col-12">
                            <label htmlFor="total">Total</label>
                            <InputNumber inputStyle={{ color: 'blue' }} mode="currency" currency="BRL" locale="pt-BR" id="total" value={this.state.materialDC.total} minFractionDigits={2} disabled />
                        </div>
                    </div>
                </Dialog>

                <Dialog visible={this.state.deleteMaterialDCDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteMaterialDCDialogFooter}
                    onHide={this.hideDeleteMaterialDCDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        {this.state.materiaisDC && <span>Tem certeza que deseja excluir este item?</span>}
                    </div>
                </Dialog>

                <Dialog visible={this.state.garantiaDialog} style={{ width: '600px' }} header="Garantia" modal onHide={this.hideGarantiaDialog}>
                    <Panel header={this.state.materialDC.descricao}>
                        <MateriasDCGarantias idMaterialDC={this.state.materialDC.id} />
                    </Panel>
                </Dialog>
            </div>
        )
    }
}
