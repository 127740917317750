import React from 'react';
import { useState, useEffect, useRef } from 'react';
import api from '../../service/api';
import classNames from 'classnames';

import { Toast } from '../../components/toast/Toast';
import { Toolbar } from '../../components/toolbar/Toolbar';
import { Dialog } from '../../components/dialog/Dialog';
import { InputText } from '../../components/inputtext/InputText';
import { Button } from '../../components/button/Button';
import { InputMask } from '../../components/inputmask/InputMask';

import validator from 'validator';
import { TrocarSenha } from '../login/trocarSenha';

export const PerfilUsuario = (props) => {

    const emptyUsuario = {
        "id": null,
        "nome": '',
        "usuario": '',
        "tel1": '',
        "tel2": '',
        "email": ''
    };

    const emptyTrocarSenha = {
        "id": null,
        "senhaatual": '',
        "senha": '',
        "confirmacaosenha": '',
    }

    const [trocarSenha, setTrocarSenha] = useState(emptyTrocarSenha);
    const [usuario, setUsuario] = useState(emptyUsuario);
    const [submitted, setSubmitted] = useState(false);
    const [emailInvalido, setemailInvalido] = useState(false);
    const [dialogTrocarSenha, setDialogTrocarSenha] = useState(false);
    const toast = useRef(null);

    useEffect(() => {
        api.get(`/usuarios/${sessionStorage.getItem('idusuario')}`)
            .then((res) => {
                if (res.status === 200) {
                    setUsuario(res.data);
                }
            });
    }, []);

    const onTrocarSenhaInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        const input = e.target;
        const start = input.selectionStart;
        const end = input.selectionEnd;
        let _trocarSenha = { ...trocarSenha };
        _trocarSenha[`${name}`] = val;
        setTrocarSenha(_trocarSenha);
        input.setSelectionRange(start, end);
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        const input = e.target;
        const start = input.selectionStart;
        const end = input.selectionEnd;
        let _usuario = { ...usuario };
        _usuario[`${name}`] = val.toUpperCase();
        setUsuario(_usuario);
        input.setSelectionRange(start, end);
    }

    const onInputEmailChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        const input = e.target;
        const start = input.selectionStart;
        const end = input.selectionEnd;
        let _usuario = { ...usuario };
        _usuario[`${name}`] = val.toUpperCase();
        setUsuario(_usuario);
        input.setSelectionRange(start, end);
        if (validator.isEmail(val)) {
            setemailInvalido(false);
        }
        else {
            setemailInvalido(val.length > 0 ? true : false);
        }
    }

    const onInputMaskChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _usuario = { ...usuario };
        _usuario[`${name}`] = val;
        setUsuario(_usuario);
    }

    const save = () => {
        setSubmitted(true);
        if (usuario.nome.trim() && usuario.usuario.trim() && usuario.email && !emailInvalido) {
            api.put(`/usuarios/${usuario.id}`, usuario)
                .then(res => {
                    if (res.status === 204) {
                        setSubmitted(false);
                        toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Usuario atualizado', life: 3000 });
                    }
                })
                .catch(error => {
                    toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu atualizar o Usuario', life: 3000 });
                    // console.log(error.response.data.description);
                })
        }
    }

    const saveTrocarSenha = () => {
        setSubmitted(true);
        let _trocarSenha = { ...trocarSenha };
        _trocarSenha.id = parseInt(sessionStorage.getItem('idusuario'));
        if (_trocarSenha.senhaatual.trim() && _trocarSenha.senha.trim() && _trocarSenha.confirmacaosenha.trim()) {
            if (_trocarSenha.senha === _trocarSenha.confirmacaosenha) {
                api.put(`/usuarios/trocarsenha/${_trocarSenha.id}`, _trocarSenha)
                    .then(res => {
                        if (res.status === 204) {
                            setSubmitted(false);
                            setDialogTrocarSenha(false);
                            toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Usuario atualizado', life: 3000 });
                        }
                    })
                    .catch(error => {
                            toast.current.show({ severity: 'error', summary: 'Erro', detail: error.message, life: 3000 });
                            console.log(error.description);
                    })
            }
            else {
                toast.current.show({ severity: 'error', summary: 'Erro', detail: 'As senhas informadas são diferentes.', life: 3000 });
            }
        }
    }

    const openDialogTrocarSenha = () => {
        setTrocarSenha(emptyTrocarSenha);
        setDialogTrocarSenha(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setDialogTrocarSenha(false);
    }

    const trocarSenhaDialogFooter = (
        <React.Fragment>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={saveTrocarSenha} />
        </React.Fragment>
    );

    return (
        <div>
            <Toast ref={toast} />
            <div className="content-section introduction">
                <h5>Usuário</h5>
            </div>
            <div className="p-grid crud-demo">
                <div className="card">
                    {/* <Toolbar className="p-mb-4" ></Toolbar> */}
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12">
                            <label htmlFor="nome">Nome</label>
                            <InputText id="nome" value={usuario.nome} onChange={(e) => onInputChange(e, 'nome')}
                                required autoFocus className={classNames({ 'p-invalid': submitted && !usuario.nome })}
                            />
                            {submitted && !usuario.nome && <small className="p-invalid">Nome é requerido.</small>}
                        </div>
                        <div className="p-field p-col-12">
                            <label htmlFor="usuario">Nome abreviado</label>
                            <InputText id="usuario" value={usuario.usuario} onChange={(e) => onInputChange(e, 'usuario')}
                                required autoFocus className={classNames({ 'p-invalid': submitted && !usuario.usuario })} />
                            {submitted && !usuario.usuario && <small className="p-invalid">Nome abreviado é requerido.</small>}
                        </div>
                        <div className="p-field p-col-12">
                            <label htmlFor="email">E-mail</label>
                            <InputText id="email" value={usuario.email} onChange={(e) => onInputEmailChange(e, 'email')}
                                autoFocus className={classNames({ 'p-invalid': submitted && (!usuario.email || emailInvalido) })} />
                            {submitted && !usuario.email && <small className="p-invalid">O email é requerido.</small>}
                            {emailInvalido && <small className="p-invalid">E-mail inválido.</small>}
                        </div>
                        <div className="p-field p-col-6 p-md-6">
                            <label htmlFor="tel1">Tel. Celular / WhattsApp</label>
                            <InputMask id="tel1" value={usuario.tel1} onChange={(e) => onInputMaskChange(e, 'tel1')} mask="(99)99999-9999" />
                        </div>

                        <div className="p-field p-col-6 p-md-6">
                            <label htmlFor="tel2">Telefone</label>
                            <InputMask id="tel2" value={usuario.tel2} onChange={(e) => onInputMaskChange(e, 'tel2')} mask="(99)9999-9999" />
                        </div>
                    </div>
                    <div>
                        <React.Fragment>
                            <Button className="p-button-warning p-mr-2" style={{ width: '120px' }} label='Trocar senha' onClick={openDialogTrocarSenha} />
                            <Button className="p-button-success p-mr-2" style={{ width: '120px' }} label='Salvar' onClick={save} />
                        </React.Fragment>
                    </div>
                    <Dialog visible={dialogTrocarSenha} style={{ width: '350px' }} header="Trocar senha" modal className="p-fluid"
                        footer={trocarSenhaDialogFooter} onHide={hideDialog}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12">
                                <label htmlFor="senhaatual">Senha Atual</label>
                                <InputText id="senhaatual" type="password" value={trocarSenha.senhaatual} onChange={(e) => onTrocarSenhaInputChange(e, 'senhaatual')}
                                    required autoFocus className={classNames({ 'p-invalid': submitted && !trocarSenha.senhaatual })} />
                                {submitted && !trocarSenha.senhaatual && <small className="p-invalid">A senha atual requerida.</small>}
                            </div>
                            <div className="p-field p-col-12">
                                <label htmlFor="novasenha">Nova senha</label>
                                <InputText id="novasenha" type="password" value={trocarSenha.senha} onChange={(e) => onTrocarSenhaInputChange(e, 'senha')}
                                    required autoFocus className={classNames({ 'p-invalid': submitted && !trocarSenha.senha })} />
                                {submitted && !trocarSenha.senha && <small className="p-invalid">A nova senha é requerida.</small>}
                            </div>
                            <div className="p-field p-col-12">
                                <label htmlFor="confimacaosenha">Confirme a nova senha</label>
                                <InputText id="confimacaosenha" type="password" value={trocarSenha.confirmacaosenha} onChange={(e) => onTrocarSenhaInputChange(e, 'confirmacaosenha')}
                                    required autoFocus className={classNames({ 'p-invalid': submitted && !trocarSenha.confirmacaosenha })} />
                                {submitted && !trocarSenha.confirmacaosenha && <small className="p-invalid">A confirmação da senha é requerida.</small>}
                            </div>
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    )
}