import React, { Component } from 'react';
import api from '../../service/api';
import { PropTypes } from 'prop-types';
import { Panel } from '../../components/panel/Panel';
import { Button } from '../../components/button/Button';
import { Dialog } from '../../components/dialog/Dialog';
import { NegocioDimensionamento } from './negocioDimensionamento';
import { ParametroPerdaEdit } from './parametrosPerdaEdit';



export class Dimensionamento extends Component {

    emptyParamPerda = {
        "id": null,
        "idnegocio": null,
        "idparametro": null,
        "parametro": null,
        "valor": null
    }

    emptyParamSolPleno = {
        "id": null,
        "jan": 0.00,
        "fev": 0.00,
        "mar": 0.00,
        "abr": 0.00,
        "mai": 0.00,
        "jun": 0.00,
        "jul": 0.00,
        "ago": 0.00,
        "set": 0.00,
        "out": 0.00,
        "nov": 0.00,
        "dez": 0.00,
        "media": 0.00
    }

    emptyparamDimensionamento = {
        "consumomedia": 0,
        "mediavalortarifa": 0,
        "consumominimo": 0,
        "dimensionamentodesejado": 0
    }

    emptyEndereco = {
        "id": -1,
        "logradouro": "",
        "numero": "",
        "complemento": "",
        "idcidade": 0,
        "idestado": 0,
        "bairro": "",
        "cep": "",
        "latitude": 0.0,
        "longitude": 0.0,
        "cidade": "",
        "uf": "",
        "utilizarcoordenadas": false
    }


    static defaultProps = {
        idnegocio: 1,
        idstatus: 1,
    }

    static propTypes = {
        idnegocio: PropTypes.number,
        idstatus: PropTypes.number
    }

    constructor(props) {
        super(props);

        this.state = {
            // paramPerda: [],
            paramPerdaTotal: { valorperda: 0 },
            paramSolPleno: this.emptyParamSolPleno,
            paramDimensionamento: this.emptyparamDimensionamento,
            paramGeracaoMes: this.emptyParamSolPleno,
            paramGeracaoMesPerda: this.emptyParamSolPleno,
            ParamPerdaDialog: false,
            endereco: this.emptyEndereco
        };

        this.editParamPerda = this.editParamPerda.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.ongetPerdaTotal = this.ongetPerdaTotal.bind(this);
        this.getSolPlenoComPerda = this.getSolPlenoComPerda.bind(this);
        this.getDimensionamentoDesejado = this.getDimensionamentoDesejado.bind(this);
        this.round_abnt = this.round_abnt.bind(this);
    }

    async componentDidMount() {
        api.get(`/solpleno/negocio/${this.props.idnegocio}`)
            .then(res => {
                if (res.status === 200){
                    this.setState({paramSolPleno: res.data[0]})
                }
            });
        api.get(`/parametrossolarimetricos/total/negocio/${this.props.idnegocio}`)    
            .then(res => {
                if (res.status === 200){
                    this.setState({paramPerdaTotal: res.data[0]})
                }
            });        
        api.get(`/contas/resumo/negocio/${this.props.idnegocio}`)
            .then(res => {
                if (res.status === 200){
                    this.setState({paramDimensionamento: res.data[0]})
                }
            });                
        api.get(`/negociosendereco/${this.props.idnegocio}`)   
            .then(res => {
                if (res.status === 200){
                    this.setState({endereco: res.data[0]})
                }
            });                        

        // const SolPleno = await api.get(`/solpleno/negocio/${this.props.idnegocio}`);
        // const PerdaTotal = await api.get(`/parametrossolarimetricos/total/negocio/${this.props.idnegocio}`);
        // const Dimensionamento = await api.get(`/contas/resumo/negocio/${this.props.idnegocio}`);
        // const endereco = await api.get(`/negocioendereco/${this.props.idnegocio}`);
        // this.setState({ 
        //     paramSolPleno: SolPleno.data[0], 
        //     paramPerdaTotal: PerdaTotal.data[0], 
        //     paramDimensionamento: Dimensionamento.data[0], 
        //     endereco: endereco.data[0]
        // });
    }

    async ongetPerdaTotal() {
        const PerdaTotal = await api.get(`/parametrossolarimetricos/total/negocio/${this.props.idnegocio}`);
        this.setState({ paramPerdaTotal: PerdaTotal.data[0] });
    }

    calcularPotencia() {
        if (this.state.paramDimensionamento.dimensionamentodesejado!== null && this.state.paramDimensionamento.dimensionamentodesejado > 0) {
            let MediaSolPleno = this.state.paramSolPleno.media;
            let Perda = this.state.paramPerdaTotal.valorperda;
            let Dimensionamento = this.state.paramDimensionamento.dimensionamentodesejado;
            Dimensionamento = Dimensionamento / 30.4 / this.round_abnt(((100 - Perda) / 100 * MediaSolPleno), 2);
            return Dimensionamento.toLocaleString('pt-BR', {maximumFractionDigits:2, minimumFractionDigits:2})
;        }

    }

    getSolPlenoComPerda() {
        let valor = (100 - this.state.paramPerdaTotal.valorperda) / 100 * this.state.paramSolPleno.media;
        return valor.toLocaleString('pt-BR', {maximumFractionDigits:2});
    }

    editParamPerda() {
        this.setState({ ParamPerdaDialog: true });
    }

    hideDialog() {
        this.setState({ ParamPerdaDialog: false });
    }

    round_abnt(nValor, nDecimais) {

        var nRetorno = nValor;
        var spl = nValor.toString().split(".");
        var cDecimais = spl[1];
        var nSubsequente = nDecimais;

        if (nDecimais < 1) {
            return parseInt(nRetorno);
        }

        if (!cDecimais || cDecimais.length <= nDecimais) {
            return parseFloat(nRetorno);
        }

        //Se a casa decimal SUBSEQUENTE for DIFERENTE de 5
        if (cDecimais.substr(nSubsequente, 1) >= '5' || cDecimais.substr(nSubsequente, 1) < '5') {
            nRetorno = nRetorno.toFixed(nDecimais); //Math.round((nRetorno + 0.00001) * 100) / 100; //ARREDONDA
        }
        //Se a casa decimal SUBSEQUENTE for IGUAL a 5
        else if (cDecimais.substr(nSubsequente, 1) == '5') {

            //Se a casa decimal que será CONSERVADA, for IMPAR
            if ((cDecimais.substr(nDecimais - 1, 1) % 2) != 0) {
                nRetorno = nRetorno.toFixed(2); //Math.round((nRetorno + 0.00001) * 100) / 100; //ARREDONDA
            }
            //Se a casa decimal que será CONSERVADA, for PAR
            else
                //Se APÓS a casa decimal SUBSEQUENTE, houver ALGUM algarismo MAIOR que ZERO
                if (cDecimais.substr(nSubsequente + 1, 1) > 0) {
                    nRetorno = nRetorno.toFixed(2); //Math.round((nRetorno + 0.00001) * 100) / 100; //ARREDONDA
                }
                //Se APÓS a casa decimal SUBSEQUENTE, não houver NENHUM outro algarismo ou TODOS forem iguais a ZERO
                else {
                    //TRUNCA (Esse é o único momento em que o "arredondamento ABNT" se diferencia do "arredondamento normal")
                    nRetorno = this.Truncate(nValor, nDecimais);
                }
        }
        return parseFloat(nRetorno);
    }

    Truncate(nValor, nDecimais) {

        var nRetorno = nValor;
        var spl = nValor.toString().split(".");
        var cDecimais = spl[1];

        if (nDecimais < 1) {
            return parseInt(nRetorno);
        }

        if (cDecimais.length <= nDecimais) {
            return nRetorno;
        }

        //Pega a parte inteira do número e concatena com a substring sem alterar, pois é PAR e vai manter!
        nRetorno = parseInt(nValor.toString()) + '.' + cDecimais.substr(0, nDecimais);
        nRetorno = parseFloat(nRetorno);

        return nRetorno;
    }    

    getDimensionamentoDesejado() {
        if ((typeof this.state.paramDimensionamento.dimensionamentodesejado === "undefined") || (this.state.paramDimensionamento.dimensionamentodesejado === null)) {
            return '0.00';
        }
        else if (this.state.paramDimensionamento.dimensionamentodesejado >= 0){
            return this.round_abnt(this.state.paramDimensionamento.dimensionamentodesejado,2).toLocaleString('pt-BR');
        }
    }

    getIrradiacaoSolar(){


        if ((typeof this.state.paramSolPleno.media === "undefined") || (this.state.paramSolPleno.media === null)) {
            return '0.00  -  (C/Perda: 0.00)';
        }
        else if (this.state.paramSolPleno.media >= 0){
            let irradiacao = this.state.paramSolPleno.media.toLocaleString('pt-BR', {maximumFractionDigits:2});
            let perda = this.getSolPlenoComPerda();
            return irradiacao + ' - (C/Perda: ' + perda + ')'
        }        
    }

    render() {
        return (
            <div>
                <Panel header="Dados solarimétricos" toggleable collapsed={true}>
                    <table className="tabela-simples" >
                        <tbody>
                            <tr>
                                <td><b>Cidade</b></td>
                                <td>{this.state.endereco.cidade + '-' + this.state.endereco.uf}</td>
                            </tr>
                            <tr>
                                <td><b>Irradiação solar diária(média anual)</b></td>
                                <td>
                                    {this.getIrradiacaoSolar()}
                                </td>
                            </tr>
                            <tr>
                                <td><b>Consumo para dimensionamento kWh</b></td>
                                <td>
                                    {this.getDimensionamentoDesejado()}
                                </td>
                            </tr>
                            <tr>
                                <td><b>Percentual de perda %</b></td>
                                <td>
                                    {this.state.paramPerdaTotal.valorperda.toLocaleString('pt-BR')}
                                </td>
                            </tr>
                            <tr>
                                <td><b>Potencia desejada kWp</b></td>
                                <td>{this.calcularPotencia()}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="p-d-inline-flex p-mt-5">
                        <Button 
                            type="Button" 
                            label="Alterar" 
                            icon="pi pi-pencil" 
                            className="p-button p-mr-2" 
                            onClick={this.editParamPerda}
                            disabled={this.props.idstatus > 2} />
                    </div>
                </Panel>

                <Panel header="Módulo Fotovoltaico" toggleable>
                    <NegocioDimensionamento 
                        idnegocio={this.props.idnegocio} 
                        history={this.props.history}
                        idstatus={this.props.idstatus} />
                </Panel>

                <Dialog visible={this.state.ParamPerdaDialog} style={{ width: '550px' }} header="Alterar Parâmetros" modal className="p-fluid" onHide={this.hideDialog}>
                    <ParametroPerdaEdit idnegocio={this.props.idnegocio} refreshPerdaTotal={this.ongetPerdaTotal} />
                </Dialog>
            </div>

        )
    }

}