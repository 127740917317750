import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import api from '../../service/api';
import { Panel } from '../../components/panel/Panel';

export class ResumoFinanceiro extends Component {

    static defaultProps = {
        idNegocioDimensionamento: 2,
        totalProposta: 0.00,
        total: 0.00,
        totalObracivil: 0.00,
        totalEstrutura: 0.00,
        totalFrete: 0.00,
        totalServico: 0.00
    }

    static propTypes = {
        idNegocioDimensionamento: PropTypes.number,
        totalMateriaisAC: PropTypes.number,
        totalMateriaisDC: PropTypes.number,
        totalObracivil: PropTypes.number,
        totalEstrutura: PropTypes.number,
        totalFrete: PropTypes.number,
        totalServico: PropTypes.number,
    }

    constructor(props) {
        super(props);

        this.state = {
            custos: null,
            idnegociodimensionamento: null,
            totalservico: 0,
            totalMateriaisAC: 0,
            totalMateriaisDC: 0,
            totalFrete: 0,
            totalObracivil: 0,
            totalEstrutura: 0
        };

        this.originalRows = {};
    }

    async componentDidMount() {
        const servicos = await api.get(`/dimensionamentoservicos/total/${this.props.idNegocioDimensionamento}`);
        const materiaisDC = await api.get(`/materiaisdc/total/${this.props.idNegocioDimensionamento}`);
        const materiaisAC = await api.get(`/materiaisac/total/${this.props.idNegocioDimensionamento}`);
        const obracivil = await api.get(`/obracivil/total/${this.props.idNegocioDimensionamento}`);
        const frete = await api.get(`/fretes/total/${this.props.idNegocioDimensionamento}`);
        const estrutura = await api.get(`/estruturas/total/${this.props.idNegocioDimensionamento}`);
        /* const custoTotalProposta = (I18*H11)+I18; */
        this.setState({
            totalServico: servicos.data.total,
            totalMateriaisDC: materiaisDC.data.total,
            totalMateriaisAC: materiaisAC.data.total,
            totalObracivil: obracivil.data.total,
            totalFrete: frete.data.total,
            totalEstrutura: estrutura.data.total,
            //custos: custosTotais
        });

    }

    render() {
        return (
            <div className="p-field p-col-12 p-md-6">
                <Panel header="Custos Totais" >
                    <table className="table table-bordered" >
                        <tbody>
                            <tr>
                                <td><b>Materiais DC</b></td>
                                <td>{this.state.totalMateriaisDC}</td>
                            </tr>
                            <tr>
                                <td><b>Materiais AC</b></td>
                                <td>{this.state.totalMateriaisAC}</td>
                            </tr>
                            <tr>
                                <td><b>Obras Adicionais</b></td>
                                <td>{this.state.totalObracivil}</td>
                            </tr>
                            <tr>
                                <td><b>Estruturas de Fixação</b></td>
                                <td>{this.state.totalEstrutura}</td>
                            </tr>
                            <tr>
                                <td><b>Serviços</b></td>
                                <td>{this.state.totalServico}</td>
                            </tr>
                            <tr>
                                <td><b>Total de Serviços e Materiais</b></td>
                                <td>{this.state.custos}</td>
                            </tr>
                        </tbody>
                    </table>
                </Panel>
            </div>
        );
    }
}
