import React, { Component } from 'react';
import api from '../../service/api';
import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import { Button } from '../../components/button/Button';
import { Dialog } from '../../components/dialog/Dialog';
import { DataTable } from '../../components/datatable/DataTable';
import { Column } from '../../components/column/Column'
import { Toast } from '../../components/toast/Toast';
import { FileUpload } from '../../components/fileupload/FileUpload'
import { th } from 'date-fns/locale';
import { format, parseISO } from "date-fns";

export class Anexos extends Component {

    static defaultProps = {
        idNegocio: 1
    }

    static propTypes = {
        idNegocio: PropTypes.number
    }

    emptyAnexo = {
        "id": null,
        "idnegocio": null,
        "nome": '',
        "datahora": '',
    }


    constructor(props) {
        super(props);

        this.state = {
            anexo: this.emptyAnexo,
            listaAnexo: [],
            deleteDialog: false,
        }

        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.onUpload = this.onUpload.bind(this);
        this.downloadAnexo = this.downloadAnexo.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.delete = this.delete.bind(this);
        this.hideDeleteDialog = this.hideDeleteDialog.bind(this);
        this.atualizarLista = this.atualizarLista.bind(this);
        this.onBeforeUpload = this.onBeforeUpload.bind(this);
    }

    async atualizarLista(){
        const listaAnexo = await api.get(`/anexo/negocio/${this.props.idNegocio}`);
        this.setState({ listaAnexo: listaAnexo.data });
    }

    async componentDidMount() {
        this.atualizarLista();
    }

    onUpload() {
        this.atualizarLista();
        this.toast.show({ severity: 'success', summary: 'Successo', detail: 'Anexo inserido' });
    }

    onBeforeUpload(event){
        const token = sessionStorage.getItem("rotatoken");
        event.xhr.setRequestHeader("Authorization", "Bearer " + token);
    }

    downloadAnexo(nome, id) {
        api.get(`/anexo/${id}`, { responseType: 'blob' })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', nome);
                document.body.appendChild(link);
                link.click();
            });
    }    

    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button
                    icon="pi pi-download"
                    className="p-button-rounded p-button-warning p-mr-2"
                    onClick={() => this.downloadAnexo(rowData.nome, rowData.id)}
                    tooltip="Download"
                    tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} />
                <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger"
                    onClick={() => this.confirmDelete(rowData)}
                    tooltip="Excluir"
                    tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} />
            </React.Fragment>
        );
    }

    hideDeleteDialog() {
        this.setState({ deleteDialog: false });
    }   
    
    confirmDelete(anexo) {
        this.setState({
            anexo,
            deleteDialog: true
        });
    }    

    delete() {
        api.delete(`/anexo/${this.state.anexo.id}`)
            .then(res => {
                if (res.status === 204) {
                    let listaAnexo = this.state.listaAnexo.filter(val => val.id !== this.state.anexo.id);
                    this.setState({
                        listaAnexo,
                        deleteDialog: false,
                        anexo: this.emptyAnexo
                    });
                    this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Anexo excluído', life: 3000 });
                }
            })
            .catch(error => {
                this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu excluir o anexo', life: 3000 });
                //console.log(error.response.data.description);
            })
    }  
    
    dateTemplate(rowData) {
        const Data = parseISO(rowData['datahora']);
        ; return format(Data, "dd/MM/yyyy HH:mm:ss");
    }

    render() {
        const header = (
            <div className="table-header">
                {/* <Button label="Novo" icon="pi pi-plus" className="p-button p-mr-2" style={{ width: '90px' }} /> */}
                <h5>Listagem de arquivos</h5>
            </div>
        );

        const deleteAnexoDialogFooter = (
            <React.Fragment>
                <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteDialog} />
                <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={this.delete} />
            </React.Fragment>
        );
        const url = api.defaults.baseURL + '/anexo/negocio/' + this.props.idNegocio;
        return (

            <div>
                <Toast ref={(el) => this.toast = el} />

                <div className="card">
                    {/* <h5>Advanced</h5> */}
                    <FileUpload 
                        name="demo[]"
                        url={url}
                        multiple
                        onUpload={this.onUpload}
                        onBeforeSend={this.onBeforeUpload}
                        chooseLabel='Selecionar'
                        uploadLabel='Enviar'
                        cancelLabel='Cancelar'
                        maxFileSize={3000000}
                        emptyTemplate={<p className="p-m-0">Arraste e solte arquivos aqui para enviar.</p>} />
                </div>

                <DataTable header={header} value={this.state.listaAnexo} className="p-datatable-sm p-datatable-gridlines">
                    <Column field="id" header="Id" style={{ width: '5%' }} />
                    <Column field="nome" header="Arquivo"  />
                    <Column field="datahora" header="Data Hora" style={{ width: '15%' }} body={(rowData) => this.dateTemplate(rowData)} />
                    <Column body={this.actionBodyTemplate} style={{ width: '15%' }} ></Column>
                </DataTable>



                <Dialog visible={this.state.deleteDialog} style={{ width: '450px' }} header="Confirme" modal footer={deleteAnexoDialogFooter}
                    onHide={this.hideDeleteDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        {this.state.anexo && <span>Tem certeza que deseja excluir o anexo <b>{this.state.anexo.nome}</b>?</span>}
                    </div>
                </Dialog>
            </div>
        )
    }
}
