import React, { Component } from 'react';
import api from '../../../service/api';
import classNames from 'classnames';
import { Button } from '../../../components/button/Button';
import { Dialog } from '../../../components/dialog/Dialog';
import { DataTable } from '../../../components/datatable/DataTable';
import { Column } from '../../../components/column/Column'
import { InputText } from '../../../components/inputtext/InputText';
import { Toast } from '../../../components/toast/Toast';
import { Toolbar } from '../../../components/toolbar/Toolbar';

export class FormaPagamento extends Component {

    emptyFormaPagamento = {
        "id": null,
        "descricao": ''
    }

    constructor(props) {
        super(props);

        this.state = {
            formaPagamento: this.emptyFormaPagamento,
            listaFormaPagamento: [],
            submitted: false,
            edicao: false,
            formaPagamentoDialog: false,
            deleteFormaPagamentoDialog: false,
            selectedFormaPagamento: null,
            globalFilter: null,
            rows: 10,
            first: 1,
        }

        this.originalRows = {};

        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.openNewFormaPagamento = this.openNewFormaPagamento.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.saveFormaPagamento = this.saveFormaPagamento.bind(this);
        this.editFormaPagamento = this.editFormaPagamento.bind(this);
        this.confirmDeleteFormaPagamento = this.confirmDeleteFormaPagamento.bind(this);
        this.hideDeleteFormaPagamentoDialog = this.hideDeleteFormaPagamentoDialog.bind(this);
        this.deleteFormaPagamento = this.deleteFormaPagamento.bind(this);
        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.rightToolbarTemplate = this.rightToolbarTemplate.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
    }

    async componentDidMount() {
        const listaFormaPagamento = await api.get(`/formapagamento`);
        this.setState({
            listaFormaPagamento: listaFormaPagamento.data
        });
    }

    hideDeleteFormaPagamentoDialog() {
        this.setState({ deleteFormaPagamentoDialog: false });
    }

    confirmDeleteFormaPagamento(formaPagamento) {
        this.setState({
            formaPagamento,
            deleteFormaPagamentoDialog: true
        });
    }

    deleteFormaPagamento() {
        api.delete(`/formapagamento/${this.state.formaPagamento.id}`)
            .then(res => {
                if (res.status === 204) {
                    let listaFormaPagamento = this.state.listaFormaPagamento.filter(val => val.id !== this.state.formaPagamento.id);
                    this.setState({
                        listaFormaPagamento,
                        deleteFormaPagamentoDialog: false,
                        formaPagamento: this.emptyFormaPagamento
                    });
                    this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Forma de pagamento excluída', life: 3000 });
                }
            })
            .catch(error => {
                this.setState({ deleteFormaPagamentoDialog: false })
                this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu excluir a Forma de pagamento', life: 3000 });
                // console.log(error.response.data.description);
            })
    }

    editFormaPagamento(formaPagamento) {
        this.setState({
            formaPagamento: { ...formaPagamento },
            formaPagamentoDialog: true
        });
    }

    openNewFormaPagamento() {
        this.setState({
            formaPagamento: this.emptyFormaPagamento,
            submitted: false,
            formaPagamentoDialog: true
        });
    }

    hideDialog() {
        this.setState({
            submitted: false,
            formaPagamentoDialog: false
        });
    }

    async saveFormaPagamento() {
        let state = { submitted: true };

        if (this.state.formaPagamento.descricao.trim()) {

            let listaFormaPagamento = [...this.state.listaFormaPagamento];
            let formaPagamento = { ...this.state.formaPagamento };
            if (this.state.formaPagamento.id) {
                const index = this.findIndexById(this.state.formaPagamento.id);
                api.put(`/formapagamento/${formaPagamento.id}`, formaPagamento)
                    .then(res => {
                        if (res.status === 204) {
                            listaFormaPagamento[index] = formaPagamento;
                            state = {
                                ...state,
                                listaFormaPagamento,
                                formaPagamentoDialog: false,
                                formaPagamento: this.emptyFormaPagamento
                            };
                            this.setState(state);
                            this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Forma de pagamento atualizada', life: 3000 });
                        }
                    })
                    .catch(error => {
                        this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu atualizar a Forma de pagamento', life: 3000 });
                        // console.log(error.response.data.description);
                    })
            }
            else {
                formaPagamento.id = 0;
                api.post('/formapagamento', formaPagamento)
                    .then(res => {
                        if (res.status === 201) {
                            formaPagamento.id = res.headers.id;
                            listaFormaPagamento.push(formaPagamento);
                            state = {
                                ...state,
                                listaFormaPagamento,
                                formaPagamentoDialog: false,
                                formaPagamento: this.emptyFormaPagamento
                            };
                            this.setState(state);
                            this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Forma de pagamento inserida', life: 3000 });
                        }
                    })
                    .catch(error => {
                        this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu inserir a Forma de pagamento', life: 3000 });
                        // console.log(error.response.data.description);
                    })
            }
        }
        else {
            this.setState(state);
        }
    }

    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-rounded p-mr-2" onClick={() => this.editFormaPagamento(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => this.confirmDeleteFormaPagamento(rowData)} />
            </React.Fragment>
        );
    }

    onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        const input = e.target;
        const start = input.selectionStart;
        const end = input.selectionEnd;
        let formaPagamento = { ...this.state.formaPagamento };
        formaPagamento[`${name}`] = val.toUpperCase();
        this.setState({ formaPagamento: formaPagamento },
            () => input.setSelectionRange(start, end));
    }

    findIndexById(id) {
        let index = -1;
        for (let i = 0; i < this.state.listaFormaPagamento.length; i++) {
            if (this.state.listaFormaPagamento[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    formatarNumero(rowData, field) {
        return rowData[field].toLocaleString('pt-BR', { style: 'decimal', minimumFractionDigits: 2 });
    }

    formatarCurrency(rowData, field) {
        return rowData[field].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 });
    }

    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="Novo" icon="pi pi-plus" className="p-button p-mr-2" onClick={this.openNewFormaPagamento} />
            </React.Fragment>
        )
    }

    rightToolbarTemplate() {

    }

    onPageChange = (event) => {
        this.setState({
            rows : event.rows, 
            first : event.first
        });
    }

    render() {
        const header = (
            <div className="table-header">
                <h5 className="p-m-0">Listagem</h5>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Localizar..." />
                </span>
            </div>
        );

        const parametroDialogFooter = (
            <React.Fragment>
                <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={this.saveFormaPagamento} />
            </React.Fragment>
        );

        const deleteFormaPagamentoDialogFooter = (
            <React.Fragment>
                <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteFormaPagamentoDialog} />
                <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={this.deleteFormaPagamento} />
            </React.Fragment>
        );

        return (
            <div>
                <div className="content-section introduction">
                    <h5>Formas de Pagamento</h5>
                </div>

                <div className="p-grid crud-demo">
                    <Toast ref={(el) => this.toast = el} />

                    <div className="card">

                        <Toolbar className="p-mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>

                        <DataTable
                            className="p-datatable-gridlines p-datatable-sm"
                            ref={(el) => this.dt = el}
                            value={this.state.listaFormaPagamento}
                            selection={this.state.selectedFormaPagamento}
                            onSelectionChange={(e) => this.setState({ selectedFormaPagamento: e.value })}
                            dataKey="id"
                            paginator
                            rows={10}
                            rowsPerPageOptions={[5, 10, 25]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Exibindo {first} até {last} de {totalRecords} Usuários"
                            globalFilter={this.state.globalFilter}                            
                            header={header}
                            // first={this.state.first}
                            // onPage={event => this.onPageChange(event)}
                            >
                            <Column field="id" header="Id" style={{ width: '10%' }} sortable></Column>
                            <Column field="descricao" header="Descrição" sortable></Column>
                            <Column body={this.actionBodyTemplate} style={{ width: '15%' }}></Column>
                        </DataTable>

                        <Dialog visible={this.state.formaPagamentoDialog} style={{ width: '550px' }} header="Forma de Pagamento" modal className="p-fluid" footer={parametroDialogFooter}
                            onHide={this.hideDialog}>
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-12">
                                    <label htmlFor="descricao">Descrição</label>
                                    <InputText id="descricao" value={this.state.formaPagamento.descricao} onChange={(e) => this.onInputChange(e, 'descricao')}
                                        required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.formaPagamento.descricao })}
                                         />
                                    {this.state.submitted && !this.state.formaPagamento.descricao && <small className="p-invalid">Descrição é requerido.</small>}
                                </div>
                            </div>
                        </Dialog>

                        <Dialog visible={this.state.deleteFormaPagamentoDialog} style={{ width: '450px' }} header="Confirmar" modal footer={deleteFormaPagamentoDialogFooter}
                            onHide={this.hideDeleteFormaPagamentoDialog}>
                            <div className="confirmation-content">
                                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                                {this.state.formaPagamento && <span>Tem certeza que deseja excluir <b>{this.state.formaPagamento.descricao}</b>?</span>}
                            </div>
                        </Dialog>
                    </div>
                </div>
            </div>
        )
    }
}
