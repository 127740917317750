import React, { Component } from 'react';
/*
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { DataTable } from '../../components/datatable/DataTable';
import { Column } from '../../components/column/Column';
import { Toast } from '../../components/toast/Toast';
import { Button } from '../../components/button/Button';
import { Rating } from '../../components/rating/Rating';
import { Toolbar } from '../../components/toolbar/Toolbar';
import { Dialog } from '../../components/dialog/Dialog';
import { InputText } from '../../components/inputtext/InputText';
import { Dropdown } from '../../components/dropdown/Dropdown';
import api from '../../service/api';
import Productservice from '../../showcase/service/ProductService';
import { InputMask } from '../../components/inputmask/InputMask';
import { RadioButton } from '../../components/radiobutton/RadioButton';
*/

export class ClienteNegocio extends Component {

        render() {

        return (
            <div>
                <div className="content-section introduction">
                    <h1>NEGÓCIOS do Cliente</h1>
                </div>

            </div>
        );
    }
}
