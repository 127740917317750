import React, { useState, useEffect } from 'react';
import api from '../../service/api';
import { Skeleton } from 'primereact/skeleton';

export const CardsTipoCliente = (props) => {

    const emptyTipoCliente = {
        "F": 0,
        "J": 0
    };

    const [tipoCliente, setTipoCliente] = useState(emptyTipoCliente);
    const [negocioTipoCliente, setNegocioTipoCliente] = useState(emptyTipoCliente);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        api.get(`/dashboard/tipocliente/${props.Ini}/${props.Fim}`)
            .then(res => setTipoCliente(res.data));
        api.get(`/dashboard/negociotipocliente/${props.Ini}/${props.Fim}`)
            .then(res => {
                setNegocioTipoCliente(res.data);
                setLoading(false);
            });

    }, [props.Ini, props.Fim]);

    return (
        <>
            {!loading && (
                <div className="p-col-6">
                    <div className="p-col-12 p-md-4 p-md-6 p-lg-8 p-xl-12 ">
                        <div className="highlight-box">
                            <div className="initials" style={{ backgroundColor: '#007be5', color: '#00448f' }}><span>PF</span></div>
                            <div className="highlight-details " style={{ backgroundColor: '#edf0f5' }}>
                                <i className="pi pi-user"></i>
                                <span>Clientes</span>
                                <span className="count">{tipoCliente.F}</span>
                            </div>

                            <div className="highlight-details " style={{ backgroundColor: '#edf0f5' }}>
                                <i className="pi pi-money-bill"></i>
                                <span>Negócios</span>
                                <span className="count">{negocioTipoCliente.F}</span>
                            </div>

                        </div>
                    </div>

                    <div className="p-col-12 p-md-4 p-md-6 p-lg-8 p-xl-12">
                        <div className="highlight-box">
                            <div className="initials" style={{ backgroundColor: '#ef6262', color: '#a83d3b' }}><span>PJ</span></div>
                            <div className="highlight-details " style={{ backgroundColor: '#edf0f5' }}>
                                <i className="pi pi pi-user"></i>
                                <span>Clientes</span>
                                <span className="count">{tipoCliente.J}</span>
                            </div>

                            <div className="highlight-details " style={{ backgroundColor: '#edf0f5' }}>
                                <i className="pi pi-money-bill"></i>
                                <span>Negócios</span>
                                <span className="count">{negocioTipoCliente.J}</span>
                            </div>

                        </div>
                    </div>
                </div>
            )}
            {loading && (
                <>
                    <div className="p-col-6">
                        <div className="p-col-12 p-md-4 p-md-6 p-lg-8 p-xl-12 ">
                            <Skeleton height="10rem"></Skeleton>
                        </div>
                        <div className="p-col-12 p-md-4 p-md-6 p-lg-8 p-xl-12 ">
                            <Skeleton height="10rem"></Skeleton>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}