import React, { Component } from 'react';
import api from '../../service/api';
import { DataTable } from '../../components/datatable/DataTable';
import { Column } from '../../components/column/Column';
import { Toast } from '../../components/toast/Toast';
import { Toolbar } from '../../components/toolbar/Toolbar';
import { Dialog } from '../../components/dialog/Dialog';
import { InputText } from '../../components/inputtext/InputText';
import classNames from 'classnames';
import { Button } from '../../components/button/Button';
import { InputMask } from '../../components/inputmask/InputMask';
import validator from 'validator';
import { Checkbox } from '../../components/checkbox/Checkbox';

export class Usuario extends Component {

    emptyUsuario = {
        "id": null,
        "nome": '',
        "usuario": '',
        "tel1": '',
        "tel2": '',
        "email": '',
        "ativo": false,
    };

    constructor(props) {
        super(props);

        this.state = {
            listaUsuarios: [],
            usuario: this.emptyUsuario,
            submitted: false,
            usuarioDialog: false,
            deleteDialog: false,
            EmailInvalido: false,
            rows: 10,
            first: 1,
        };

        this.originalRows = {};

        this.openNew = this.openNew.bind(this);
        this.edit = this.edit.bind(this);
        this.save = this.save.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.hideDeleteUsuarioDialog = this.hideDeleteUsuarioDialog.bind(this);
        this.delete = this.delete.bind(this);
        this.findIndexById = this.findIndexById.bind(this);
        this.onInputChange = this.onInputChange.bind(this);

        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.exibirPaginacao = this.exibirPaginacao.bind(this);
        this.onInputMaskChange = this.onInputMaskChange.bind(this);
        this.resetSenha = this.resetSenha.bind(this);
        this.onAtivo = this.onAtivo.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
    }

    /******************************************************************************************************** */

    async componentDidMount() {
        const listaUsuarios = await api.get(`/usuarios`);
        this.setState({ listaUsuarios: listaUsuarios.data });
    }

    /******************************************************************************************************** */

    openNew() {
        this.setState({
            usuario: this.emptyUsuario,
            submitted: false,
            usuarioDialog: true
        })
    }

    edit(usuario) {
        this.setState({
            usuario: { ...usuario },
            usuarioDialog: true
        });
    }

    save() {
        let state = { submitted: true };

        if (this.state.usuario.nome.trim() && this.state.usuario.usuario.trim()
            && this.state.usuario.email && !this.state.EmailInvalido) {

            let listaUsuarios = [...this.state.listaUsuarios];
            let usuario = { ...this.state.usuario };
            if (this.state.usuario.id) {
                const index = this.findIndexById(this.state.usuario.id);
                api.put(`/usuarios/${usuario.id}`, usuario)
                    .then(res => {
                        if (res.status === 204) {
                            listaUsuarios[index] = usuario;
                            state = {
                                ...state,
                                listaUsuarios: listaUsuarios,
                                usuarioDialog: false,
                                usuario: this.emptyUsuario
                            };
                            this.setState(state);
                            this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Usuario atualizado', life: 3000 });
                        }
                    })
                    .catch(error => {
                        this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu atualizar o Usuario', life: 3000 });
                        console.log(error.response.data.description);
                    })
            }
            else {
                usuario.id = 0;
                api.post('/usuarios', usuario)
                    .then(res => {
                        if (res.status === 201) {
                            usuario.id = res.headers.id;
                            listaUsuarios.push(usuario);
                            state = {
                                ...state,
                                listaUsuarios: listaUsuarios,
                                usuarioDialog: false,
                                usuario: this.emptyUsuario
                            };
                            this.setState(state);
                            this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Usuario inserido', life: 3000 });
                        }
                    })
                    .catch(error => {
                        this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu inserir o Usuario', life: 3000 });
                        // console.log(error.response.data.description);
                    })
            }
        }
        else {
            this.setState(state);
        }
    }

    hideDialog() {
        this.setState({
            submitted: false,
            usuarioDialog: false
        });
    }

    confirmDelete(usuario) {
        this.setState({
            usuario,
            deleteDialog: true
        });
    }

    hideDeleteUsuarioDialog() {
        this.setState({ deleteDialog: false });
    }

    delete() {
        api.delete(`/usuarios/${this.state.usuario.id}`)
            .then(res => {
                if (res.status === 204) {
                    let listaUsuarios = this.state.listaUsuarios.filter(val => val.id !== this.state.usuario.id);
                    this.setState({
                        listaUsuarios,
                        deleteDialog: false,
                        usuario: this.emptyUsuario
                    });
                    this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Usuário excluído', life: 3000 });
                }
                else {
                    console.log(res);
                }
            })
            .catch(error => {
                this.setState({ deleteDialog: false });
                this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu excluir o usuário', life: 3000 });
                // console.log(error.response.data.description);
            })
    }

    findIndexById(id) {
        let index = -1;
        for (let i = 0; i < this.state.listaUsuarios.length; i++) {
            if (this.state.listaUsuarios[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        const input = e.target;
        const start = input.selectionStart;
        const end = input.selectionEnd;
        let usuario = { ...this.state.usuario };
        usuario[`${name}`] = val.toUpperCase();
        this.setState({ usuario: usuario },
            () => input.setSelectionRange(start, end));
    }

    onInputEmailChange(e, name) {
        const val = (e.target && e.target.value) || '';
        const input = e.target;
        const start = input.selectionStart;
        const end = input.selectionEnd;
        let usuario = { ...this.state.usuario };
        usuario[`${name}`] = val.toUpperCase();
        this.setState({ usuario: usuario },
            () => input.setSelectionRange(start, end));
        if (validator.isEmail(val)) {
            //this.setState.EmailInvalido(false);
            this.setState({EmailInvalido : false});
        }
        else {
            if (val.length > 0) {
                // this.setState.EmailInvalido(true);
                this.setState({EmailInvalido : true});
            }
            else {
                // this.setState.EmailInvalido(false);
                this.setState({EmailInvalido : false});
            }

        }
    }

    onInputMaskChange(e, name) {
        const val = (e.target && e.target.value) || '';
        let usuario = { ...this.state.usuario };
        usuario[`${name}`] = val;
        this.setState({ usuario: usuario });
    }

    onAtivo(e){
        let _usuario = { ...this.state.usuario };
        _usuario.ativo = e;
        this.setState({ usuario: _usuario });    
    }

    /******************************************************************************************************* */

    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="Novo" icon="pi pi-plus" className="p-button p-mr-2" onClick={this.openNew} />
            </React.Fragment>
        )
    }

    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button p-mr-2"
                    onClick={() => this.edit(rowData)}
                    tooltip="Alterar"
                    tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} />
                <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger"
                    onClick={() => this.confirmDelete(rowData)}
                    tooltip="Excluir"
                    tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} />
            </React.Fragment>
        );
    }

    exibirPaginacao() {
        let exibir = false;
        if (this.state.listaUsuarios.length > 5) { exibir = true }
        return exibir
    }

    resetSenha() {
        api.put(`/usuarios/resetsenha/${this.state.usuario.id}`, this.state.usuario)
            .then(res => {
                if (res.status === 204) {
                    let state = {
                        ...state,
                        usuarioDialog: false,
                        usuario: this.emptyUsuario
                    };
                    this.setState(state);
                    this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Senha redefinida', life: 3000 });
                }
            })
            .catch(error => {
                this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu redefinir a senha do usuário', life: 3000 });
                // console.log(error.response.data.description);
            })
    }

    ativoBodyTemplate(rowData) {
        let estilo;
        let texto = 'ATIVO';
        if (rowData.ativo) {
            estilo = "p-tag p-tag-success";
        }
        else {
            estilo = "p-tag p-tag-warning";
            texto = 'INATIVO';
        }
        return <span className={estilo}>{texto}</span>;
    }
    onPageChange  = (event) => {
        this.setState({
            rows : event.rows,
            first : event.first
        });
    }   

    /******************************************************************************************************* */

    render() {
        const header = (
            <div className="table-header">
                <h5 className="p-m-0">Listagem</h5>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Localizar..." />
                </span>
            </div>
        );
        const usuarioDialogFooter = (
            <React.Fragment>
                {this.state.usuario.id && <Button label="Redefinir senha" icon="pi pi-key" className="p-button-text" onClick={this.resetSenha} />}
                <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={this.save} />
            </React.Fragment>
        );
        const deleteUsuarioDialogFooter = (
            <React.Fragment>
                <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteUsuarioDialog} />
                <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={this.delete} />
            </React.Fragment>
        );
        /** ---------------------------------------------------------------------------------------------------------------------- */
        return (
            <div>
                <div className="content-section introduction">
                    <h5>Usuários</h5>
                </div>

                <div className="p-grid crud-demo">
                    <Toast ref={(el) => this.toast = el} />

                    <div className="card">
                        <Toolbar className="p-mb-4" left={this.leftToolbarTemplate} ></Toolbar>

                        <DataTable
                            className="p-datatable-gridlines p-datatable-sm"
                            ref={(el) => this.dt = el}
                            value={this.state.listaUsuarios}
                            selection={this.state.selectedUsuarios}
                            onSelectionChange={(e) => this.setState({ selectedUsuarios: e.value })}
                            dataKey="id"
                            paginator={this.exibirPaginacao()}
                            rows={10}
                            rowsPerPageOptions={[5, 10, 25]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Exibindo {first} até {last} de {totalRecords} Usuários"
                            globalFilter={this.state.globalFilter}
                            header={header}
                            // onPage={event => this.onPageChange(event)}
                            // first={this.state.first}
                            >
                            <Column field="nome" header="Nome" ></Column>
                            <Column field="tel1" header="Tel./WhattsApp" ></Column>
                            <Column field="email" header="E-mail" ></Column>
                            <Column field="ativo" header="Status" body={this.ativoBodyTemplate} style={{ width: '60px' }}></Column>
                            <Column body={this.actionBodyTemplate} style={{ width: '80px' }}></Column>
                        </DataTable>

                        <Dialog visible={this.state.usuarioDialog} style={{ width: '550px' }} header="Usuário" modal className="p-fluid"
                            footer={usuarioDialogFooter} onHide={this.hideDialog}>
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-12">
                                    <label htmlFor="nome">Nome</label>
                                    <InputText id="nome" value={this.state.usuario.nome} onChange={(e) => this.onInputChange(e, 'nome')}
                                        required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.usuario.nome })}
                                    />
                                    {this.state.submitted && !this.state.usuario.nome && <small className="p-invalid">Nome é requerido.</small>}
                                </div>
                                <div className="p-field p-col-12">
                                    <label htmlFor="usuario">Nome abreviado</label>
                                    <InputText id="usuario" value={this.state.usuario.usuario} onChange={(e) => this.onInputChange(e, 'usuario')}
                                        required className={classNames({ 'p-invalid': this.state.submitted && !this.state.usuario.usuario })}
                                    />
                                    {this.state.submitted && !this.state.usuario.usuario && <small className="p-invalid">Nome abreviado é requerido.</small>}
                                </div>
                                <div className="p-field p-col-12">
                                    <label htmlFor="email">E-mail</label>
                                    <InputText id="email" value={this.state.usuario.email} onChange={(e) => this.onInputEmailChange(e, 'email')}
                                      className={classNames({ 'p-invalid': this.state.submitted && (!this.state.usuario.email || this.state.EmailInvalido) })}
                                    />
                                    {this.state.submitted && !this.state.usuario.email && <small className="p-invalid">O email é requerido.</small>}
                                    {this.state.EmailInvalido && <small className="p-invalid">E-mail inválido.</small>}
                                </div>
                                <div className="p-field p-col-6 p-md-6">
                                    <label htmlFor="tel1">Tel. Celular / WhattsApp</label>
                                    <InputMask id="tel1" value={this.state.usuario.tel1} onChange={(e) => this.onInputMaskChange(e, 'tel1')}
                                        mask="(99)99999-9999" />
                                </div>

                                <div className="p-field p-col-6 p-md-6">
                                    <label htmlFor="tel2">Telefone</label>
                                    <InputMask id="tel2" value={this.state.usuario.tel2} onChange={(e) => this.onInputMaskChange(e, 'tel2')}
                                        mask="(99)9999-9999" />
                                </div>
                                <div className="p-field-checkbox p-ml-2">
                                    <Checkbox inputId="binary" checked={this.state.usuario.ativo} onChange={e => this.onAtivo(e.checked)} />
                                    <label htmlFor="binary">{this.state.usuario.ativo ? 'Ativo' : 'Inativo'}</label>
                                </div>
                            </div>
                        </Dialog>

                        <Dialog visible={this.state.deleteDialog} style={{ width: '450px' }} header="Confirm" modal
                            footer={deleteUsuarioDialogFooter} onHide={this.hideDeleteUsuarioDialog}>
                            <div className="confirmation-content">
                                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                                {this.state.usuario && <span>Tem certeza que deseja excluir <b>{this.state.usuario.nome}</b>?</span>}
                            </div>
                        </Dialog>
                    </div>
                </div>
            </div>
        )
    }
}