import React, { Component } from 'react';
import classNames from 'classnames';
import { DataTable } from '../../components/datatable/DataTable';
import { Column } from '../../components/column/Column';
import { Toast } from '../../components/toast/Toast';
import { Button } from '../../components/button/Button';
import { Toolbar } from '../../components/toolbar/Toolbar';
import { Dialog } from '../../components/dialog/Dialog';
import { InputText } from '../../components/inputtext/InputText';
import { Dropdown } from '../../components/dropdown/Dropdown';
import api from '../../service/api';
import { Panel } from '../../components/panel/Panel';
import { Link } from 'react-router-dom';
import { InputTextarea } from '../../components/inputtextarea/InputTextarea'
import { InputMask } from '../../components/inputmask/InputMask';
import { RadioButton } from '../../components/radiobutton/RadioButton';
import { format, isThisHour, parseISO } from "date-fns";

export class Teste extends Component {

    emptyCliente = {
        "id": null,
        "nome": '',
        "cpfcnpj": '',
        "logradouro": '',
        "numero": '',
        "idcidade": null,
        "idestado": null,
        "bairro": '',
        "cep": '',
        "email": '',
        "site": '',
        "telefonefixo": '',
        "telefonecelular": ''
    };

    emptyNegocio = {
        "id": null,
        "descricao": '',
        "idcliente": null,
        "nome": '',
        "idtipoinstalacao": null,
        "tipo": '',
        "datacadastro": '',
        "classificaccao": ''
    };

    constructor(props) {
        super(props);

        this.state = {
            Cliente: this.emptyCliente,
            negocio: this.emptyNegocio,
            listaNegocios: [],
            estados: [],
            cidades: [],

            submitted: false,
            negocioDialog: false,
            deleteNegocioDialog: false,
            edicao: false,
            ClienteAux: {},
        };

        this.leftToolbarNegocio = this.leftToolbarNegocio.bind(this);
        this.openNovoNegocio = this.openNovoNegocio.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.saveNegocio = this.saveNegocio.bind(this);
        this.saveCliente = this.saveCliente.bind(this);
        this.confirmDeleteNegocio = this.confirmDeleteNegocio.bind(this);
        this.onTipoJuridicoChange = this.onTipoJuridicoChange.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onInputChangeNegocio = this.onInputChangeNegocio.bind(this);
        this.consultaCEP = this.consultaCEP.bind(this);
        this.editCliente = this.editCliente.bind(this);
        this.cancelEditCliente = this.cancelEditCliente.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.deleteNegocio = this.deleteNegocio.bind(this);
        this.hideDeleteNegocioDialog = this.hideDeleteNegocioDialog.bind(this);

    }

    async componentDidMount() {
        const { id } = this.props.match.params;
        const Cliente = await api.get(`/clientes/${id}`);
        const listaNegocios = await api.get(`/negocios/cliente/${id}`);

        this.setState({
            listaNegocios: listaNegocios.data,
            Cliente: Cliente.data,
        });

        const estados = await api.get('/estados');
        api.get(`/cidades/uf/${Cliente.data.idestado}`)
            .then(cidades => {
                this.setState({
                    estados: estados.data,
                    cidades: cidades.data
                });
            });
    }

    onCidadeChange(e) {
        const val = (e.target && e.target.value) || '';
        let Cliente = { ...this.state.Cliente };
        Cliente.idcidade = val;
        this.setState({ Cliente });
    }

    onEstadoChange(e) {
        let Cliente = { ...this.state.Cliente };
        Cliente.idestado = e.value;
        this.setState({ Cliente });
        api.get(`/cidades/uf/${Cliente.idestado}`)
            .then(res => {
                this.setState({ cidades: res.data });
            });
    }

    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Link to={`/negocios/${rowData['id']}`}><Button icon="pi pi-pencil" className="p-button-rounded p-mr-2" /></Link>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => this.confirmDeleteNegocio(rowData)} />
            </React.Fragment>
        );
    }

    leftToolbarNegocio() {
        return (
            <React.Fragment>
                <Button label="Novo" icon="pi pi-plus" className="p-button p-mr-2" onClick={this.openNovoNegocio} />
            </React.Fragment>
        )
    }

    dateTemplate(rowData) {
        const Data = parseISO(rowData['datacadastro']);
        ; return format(Data, "dd/MM/yyyy");
    }

    openNovoNegocio() {
        let Cliente = { ...this.state.Cliente };
        let negocio = this.emptyNegocio;

        negocio.idcliente = Cliente.id;

        this.setState({
            negocio: negocio,
            submitted: false,
            NegocioDialog: true
        });

        api.get('/clientes')
            .then(res => {
                this.setState({ Clientes: res.data });
            })

        api.get('/tipoinstalacao')
            .then(res => {
                this.setState({ TipoInstalacao: res.data });
            })

    }

    hideDialog() {
        this.setState({
            submitted: false,
            NegocioDialog: false
        });
    }

    async saveNegocio() {
        let state = { submitted: true };
        let listaNegocios = [...this.state.listaNegocios];
        let negocio = { ...this.state.negocio };

        negocio.id = 0;
        negocio.idcliente = this.state.Cliente.id;
        negocio.datacadastro = format(new Date(), 'yyyy-MM-dd');
        console.table(negocio);
        api.post('/negocios', negocio)
            .then(res => {
                if (res.status === 200) {
                    negocio.id = res.data.id;
                    listaNegocios.push(negocio);
                    state = {
                        ...state,
                        listaNegocios,
                        negocioDialog: false,
                        negocio: this.emptyNegocio
                    };
                    this.setState(state);
                    this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Negocio inserido', life: 3000 });
                    this.props.history.push(`/negocios/${negocio.id}`);
                }
            })
            .catch(error => {
                this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu inserir o novo negócio', life: 3000 });
                console.log(error.response.data.description);
            })
    }

    async saveCliente() {
        if ((this.state.Cliente.nome.trim()) && (this.state.Cliente.cpfcnpj.trim())
            && (this.state.Cliente.idestado) && (this.state.Cliente.idcidade)) {
            let Cliente = { ...this.state.Cliente };
            api.put('/clientes', Cliente)
                .then(res => {
                    if (res.status === 200) {
                        this.setState({
                            edicao: false,
                            submitted: true,
                        })
                        this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Cliente atualizado', life: 3000 });
                    }
                })
                .catch(error => {
                    this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu atualizar o cliente', life: 3000 });
                    console.log(error.response.data.description);
                })
        }
        else {
            this.setState({ submitted: true });
        }
    }

    onInputChange(e, name) {
        if (e.target.value !== undefined) {
            const val = (e.target && e.target.value) || '';
            let Cliente = { ...this.state.Cliente };
            Cliente[`${name}`] = val.toUpperCase();
            this.setState({ Cliente });
        }

    }

    onInputChangeNegocio(e, name) {
        const val = (e.target && e.target.value) || '';
        let negocio = { ...this.state.negocio };
        negocio[`${name}`] = val.toUpperCase();
        this.setState({ negocio });
    }

    onTipoInstalacaoChange(e) {
        let negocio = { ...this.state.negocio };
        negocio.idtipoinstalacao = e.value;

        let index = -1;
        for (let i = 0; i < this.state.TipoInstalacao.length; i++) {
            if (this.state.TipoInstalacao[i].id === e.value) {
                index = i;
                break;
            }
        }

        negocio.tipo = this.state.TipoInstalacao[index].tipo;

        this.setState({ negocio });
    }

    onTipoJuridicoChange(e) {
        let Cliente = { ...this.state.Cliente };
        Cliente.tipo = e.value;
        this.setState({ Cliente: Cliente });
    }

    confirmDeleteNegocio(negocio) {
        this.setState({
            negocio: negocio,
            deleteNegocioDialog: true
        });
    }

    hideDeleteNegocioDialog() {
        this.setState({ deleteNegocioDialog: false });
    }

    hideDeleteNegociosDialog() {
        this.setState({ deleteNegociosDialog: false });
    }

    deleteNegocio() {
        api.delete(`/negocios/${this.state.negocio.id}`)
            .then(res => {
                if (res.status === 200) {
                    let listaNegocios = this.state.listaNegocios.filter(val => val.id !== this.state.negocio.id);
                    this.setState({
                        listaNegocios,
                        deleteNegocioDialog: false,
                        negocio: this.emptyNegocio
                    });
                    this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Negócio excluído', life: 3000 });
                }
            })
            .catch(error => {
                this.setState({ deleteNegocioDialog: false });
                this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu excluir o negócio', life: 3000 });
                console.log(error.response.data.description);
            })

    }

    consultaCEP() {
        let cep = this.state.Cliente.cep;
        if (cep.trim()) {
            let cepAux = cep.replace('.', '');
            cepAux = cepAux.replace('-', '');
            if (cepAux.length === 8) {
                api.get(`/consultacep/${cepAux}`)
                    .then(res => {
                        if (res.status === 200) {
                            if (typeof res.data.erro === "undefined") {
                                let Cliente = { ... this.state.Cliente };
                                let ibge = res.data.ibge;
                                api.get(`/cidades/ibge/${ibge}`)
                                    .then(cidade => {
                                        api.get(`/cidades/uf/${cidade.data.idestado}`)
                                            .then(res => {
                                                this.setState({ cidades: res.data });
                                            });
                                        Cliente.idcidade = cidade.data.idcidade;
                                        Cliente.idestado = cidade.data.idestado;
                                    })
                                Cliente.logradouro = res.data.logradouro.toUpperCase();
                                Cliente.bairro = res.data.bairro.toUpperCase();
                                Cliente.cidades = res.data.ibge;
                                this.setState({ Cliente: Cliente })
                            }
                            else {
                                this.toast.show({ severity: 'error', summary: 'Erro', detail: `O CEP ${cep} não foi localizado`, life: 3000 });
                            }
                        }
                    })
                    .catch(error => {
                        this.toast.show({ severity: 'error', summary: 'Erro', detail: `Ocorreu algum problema ao tentar consultar o CEP ${cep}`, life: 3000 });
                        console.log(error.data);
                    })
            }
            else {
                alert(`CEP ${cep} inválido`)
            }
        }
    }

    editCliente() {
        let ClienteAux = { ...this.state.Cliente };
        this.setState({
            edicao: true,
            ClienteAux: ClienteAux,
        });
    }

    cancelEditCliente() {
        let Cliente = { ...this.state.ClienteAux };
        this.setState({
            edicao: false,
            Cliente: Cliente,
        });
    }

    onClassificacaoChange(e) {
        let negocio = { ...this.state.Negocio };
        negocio.classificacao = e.value;
        this.setState({ negocio: negocio });
    }

    render() {
        const NegocioDialogFooter = (
            <React.Fragment>
                <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={this.saveNegocio} />
            </React.Fragment>
        );
        const deleteNegocioDialogFooter = (
            <React.Fragment>
                <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteNegocioDialog} />
                <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={this.deleteNegocio} />
            </React.Fragment>
        );

        return (
            <div>
                <div className="content-section introduction">
                    <h5>Cliente</h5>
                </div>
                <div className="p-grid crud-demo">
                    <Toast ref={(el) => this.toast = el} />

                    <Panel header={this.state.Cliente.nome} toggleable collapsed={true}>
                        <div className="p-fluid p-formgrid p-grid">

                            <div className="p-field p-col-12" >
                                <label htmlFor="nome">Nome</label>
                                <InputText id="nome" value={this.state.Cliente.nome} onChange={(e) => this.onInputChange(e, 'nome')}
                                    required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.Cliente.nome })}
                                     readOnly={!this.state.edicao} disabled={!this.state.edicao} />
                                {this.state.submitted && !this.state.Cliente.nome && <small className="p-invalid">Nome é requerido.</small>}
                            </div>
                        </div>

                        <div>
                            <div className="p-mb-2">
                                Tipo
                            </div>
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field-radiobutton p-col-3">
                                    <RadioButton inputId="pf" name="tipo" value="F" onChange={(e) => this.onTipoJuridicoChange(e)}
                                        checked={this.state.Cliente.tipo === 'F'} disabled={!this.state.edicao} />
                                    <label htmlFor="pf">Pessoa Física</label>
                                </div>
                                <div className="p-field-radiobutton p-col-3">
                                    <RadioButton inputId="pj" name="tipo" value="J" onChange={(e) => this.onTipoJuridicoChange(e)}
                                        checked={this.state.Cliente.tipo === 'J'} disabled={!this.state.edicao} />
                                    <label htmlFor="pj">Pessoa Jurídica</label>
                                </div>
                            </div>
                        </div>

                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12">
                                <label htmlFor="cpfcnpj">CPF / CNPJ</label>
                                <InputMask id="cpfcnpj" value={this.state.Cliente.cpfcnpj} onChange={(e) => this.onInputChange(e, 'cpfcnpj')}
                                    required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.Cliente.cpfcnpj })}
                                    disabled={!this.state.Cliente.tipo || !this.state.edicao} mask={this.state.Cliente.tipo === 'F' ? "999.999.999-99" : "99.999.999/9999-99"}
                                     readonly={!this.state.edicao} />
                                {this.state.submitted && !this.state.Cliente.cpfcnpj && <small className="p-invalid">CPF / CNPJ é requerido.</small>}
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-10">
                                <label htmlFor="logradouro">Logradouro</label>
                                <InputText id="logradouro" value={this.state.Cliente.logradouro} onChange={(e) => this.onInputChange(e, 'logradouro')}
                                     readOnly={!this.state.edicao} disabled={!this.state.edicao} />
                            </div>

                            <div className="p-field p-col-2">
                                <label htmlFor="numero">Número</label>
                                <InputText id="numero" value={this.state.Cliente.numero} onChange={(e) => this.onInputChange(e, 'numero')}
                                     readOnly={!this.state.edicao} disabled={!this.state.edicao} />
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-2">
                                <label htmlFor="idestado">UF</label>
                                <Dropdown inputId="idestado" optionValue="idestado" optionLabel="uf" value={this.state.Cliente.idestado} options={this.state.estados} onChange={(e) => this.onEstadoChange(e)} placeholder="Selecione a UF"
                                    required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.Cliente.idestado })}
                                    disabled={!this.state.edicao} />
                                {this.state.submitted && !this.state.Cliente.idestado && <small className="p-invalid">UF é requerido.</small>}
                            </div>

                            <div className="p-field p-col-10">
                                <label htmlFor="idcidade">Cidade</label>
                                <Dropdown inputId="idcidade" optionValue="idcidade" optionLabel="cidade" value={this.state.Cliente.idcidade} options={this.state.cidades} onChange={(e) => this.onCidadeChange(e)} placeholder="Selecione a cidade"
                                    disabled={!this.state.Cliente.idestado || !this.state.edicao}
                                    required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.Cliente.idcidade })} />
                                {this.state.submitted && !this.state.Cliente.idcidade && <small className="p-invalid">Cidade é requerido.</small>}
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-8">
                                <label htmlFor="bairro">Bairro</label>
                                <InputText id="bairro" value={this.state.Cliente.bairro} onChange={(e) => this.onInputChange(e, 'bairro')}
                                     readOnly={!this.state.edicao} disabled={!this.state.edicao} />
                            </div>

                            <div className="p-field p-col-4">
                                <label htmlFor="cep">CEP</label>
                                <div className="p-inputgroup">
                                    <InputMask id="cep" value={this.state.Cliente.cep} onChange={(e) => this.onInputChange(e, 'cep')}
                                        mask="99.999-999"  readonly={!this.state.edicao} disabled={!this.state.edicao} />
                                    <Button icon="pi pi-search" className="p-button" onClick={this.consultaCEP} disabled={!this.state.edicao} />
                                </div>
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-12">
                                <label htmlFor="email">E-mail</label>
                                <InputText id="email" value={this.state.Cliente.email} onChange={(e) => this.onInputChange(e, 'email')}
                                     readOnly={!this.state.edicao} disabled={!this.state.edicao} />
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="telefonefixo">Tel. Fixo</label>
                                <InputMask id="telefonefixo" value={this.state.Cliente.telefonefixo} onChange={(e) => this.onInputChange(e, 'telefonefixo')}
                                    mask="(99)9999-9999"  readonly={!this.state.edicao} disabled={!this.state.edicao} />
                            </div>

                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="telefonecelular">Tel. Celular</label>
                                <InputMask id="telefonecelular" value={this.state.Cliente.telefonecelular} onChange={(e) => this.onInputChange(e, 'telefonecelular')}
                                    mask="(99)99999-9999"  readonly={!this.state.edicao} disabled={!this.state.edicao} />
                            </div>
                        </div>
                        <div>
                            <React.Fragment>
                                {!this.state.edicao && <Button label="Editar" icon="pi pi-pencil" className="p-button p-mr-2" onClick={this.editCliente} />}
                                {this.state.edicao && <Button label="Salvar" icon="pi pi-check" className="p-button-success p-mr-2" onClick={this.saveCliente} />}
                                {this.state.edicao && <Button label="Cancelar" icon="pi pi-times" className="p-button-warning p-mr-2" onClick={this.cancelEditCliente} />}
                            </React.Fragment>
                        </div>
                    </Panel>

                    <div>
                        <Panel header="Negócios">
                            <Toast ref={(el) => this.toast = el} />
                            <Toolbar className="p-mb-4" left={this.leftToolbarNegocio} ></Toolbar>
                            {this.state.listaNegocios.length == 0 && <span>Nenhum negócio cadastrado. <br /></span>}
                            {!this.state.listaNegocios.length == 0 &&
                                <DataTable
                                    className="p-datatable-gridlines p-datatable-sm"
                                    ref={(el) => this.dt = el}
                                    value={this.state.listaNegocios}
                                    selection={this.state.selectedNegocios}
                                    onSelectionChange={(e) => this.setState({ selectedNegocios: e.value })}
                                    dataKey="id"
                                    paginator={this.state.listaNegocios.length >= 5}
                                    rows={10}
                                    rowsPerPageOptions={[5, 10, 25]}
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate="Exibindo {first} até {last} de {totalRecords} Negocios"
                                    globalFilter={this.state.globalFilter}>

                                    <Column field="id" header="Id" sortable style={{ width: '6%' }}></Column>
                                    {/* <Column field="nome" header="Cliente" sortable></Column> */}
                                    <Column field="tipo" header="Tipo Instalação" sortable></Column>
                                    <Column field="datacadastro" header="Data de Cadastro" sortable body={(rowData) => this.dateTemplate(rowData)} ></Column>
                                    <Column body={this.actionBodyTemplate} style={{ width: '15%' }}></Column>
                                </DataTable>
                            }
                        </Panel>
                    </div>

                    <Dialog visible={this.state.NegocioDialog} style={{ width: '550px' }} header="Novo Negocio" modal className="p-fluid" footer={NegocioDialogFooter} onHide={this.hideDialog}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-12">
                                <label htmlFor="idtipoinstalacao">Tipo de Instalação</label>
                                <Dropdown inputId="idtipoinstalacao" optionValue="id" optionLabel="tipo" value={this.state.negocio.idtipoinstalacao} options={this.state.TipoInstalacao} onChange={(e) => this.onTipoInstalacaoChange(e)} placeholder="Selecione o tipo de instalação" />
                            </div>

                            <div className="p-field p-col-12">
                                <div className="p-mb-2">Classificação</div>
                                <div className="p-field-radiobutton">
                                    <RadioButton inputId="monofasico" name="classificacao" value="M" onChange={(e) => this.onClassificacaoChange(e)} checked={this.state.negocio.classificacao === 'M'} />
                                    <label htmlFor="monofasico">Monofásico</label>
                                </div>
                                <div className="p-field-radiobutton">
                                    <RadioButton inputId="tiposervico" name="classificacao" value="T" onChange={(e) => this.onClassificacaoChange(e)} checked={this.state.negocio.classificacao === 'T'} />
                                    <label htmlFor="tiposervico">Trifásico</label>
                                </div>
                            </div>

                            <div className="p-field p-col-12">
                                <label htmlFor="descricao">Descrição</label>
                                <InputTextarea
                                    rows={5} cols={30}
                                    value={this.state.negocio.descricao}
                                    onChange={(e) => this.onInputChangeNegocio(e, 'descricao')}
                                    autoResize
                                    placeholder="Descrição do negócio" />
                            </div>

                        </div>
                    </Dialog>

                    <Dialog visible={this.state.deleteNegocioDialog} style={{ width: '450px' }} header="Confirm" modal
                        footer={deleteNegocioDialogFooter} onHide={this.hideDeleteNegocioDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {this.state.negocio && <span>Tem certeza que deseja excluir <b>{this.state.negocio.name}</b>?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        );
    }
}
