import React, { Component } from "react";
import api from "../../service/api";
import { PropTypes } from 'prop-types';
import { Button } from '../../components/button/Button';
import { Toast } from '../../components/toast/Toast';
import { MaterialDCEdit } from "./materiaisdc";
import { MaterialACEdit } from "./materiaisac";
import { EstruturaEdit } from "./estruturafixacao";
import { ObraEdit } from "./obracivil";
//import { FreteEdit } from "./frete";
import { TabPanel, TabView } from "../../components/tabview/TabView";

export class Materiais extends Component {

    static defaultProps = {
        idDimensionamento: 1,
        idstatus: 1
    }

    static propTypes = {
        idDimensionamento: PropTypes.number,
        idstatus: PropTypes.number
    }

    constructor(props) {
        super(props);

        this.state = {
            totalMateriaisAC: 0.00,
            totalMateriaisDC: 0.00,
            totalFrete: 0.00,
            totalObracivil: 0.00,
            totalEstrutura: 0.00
        };

        this.calculatTotalMaterialAC = this.calculatTotalMaterialAC.bind(this);
        this.calculatTotalMaterialDC = this.calculatTotalMaterialDC.bind(this);
        this.calculatTotalEstruturas = this.calculatTotalEstruturas.bind(this);
        this.calculatTotalObras = this.calculatTotalObras.bind(this);
        this.calculatTotalFretes = this.calculatTotalFretes.bind(this);
        this.formatarCurrency = this.formatarCurrency.bind(this);
    }

    async componentDidMount() {
        const materiaisDC = await api.get(`/materiaisdc/total/${this.props.idDimensionamento}`);
        const materiaisAC = await api.get(`/materiaisac/total/${this.props.idDimensionamento}`);
        const obracivil = await api.get(`/obracivil/total/${this.props.idDimensionamento}`);
        const frete = await api.get(`/fretes/total/${this.props.idDimensionamento}`);
        const estrutura = await api.get(`/estruturas/total/${this.props.idDimensionamento}`);

        this.setState({
            totalMateriaisDC: materiaisDC.data[0].total,
            totalMateriaisAC: materiaisAC.data[0].total,
            totalObracivil: obracivil.data[0].total,
            totalFrete: frete.data[0].total,
            totalEstrutura: estrutura.data[0].total,
        });

        
    }

    formatarCurrency(value) {
        let ret = value || 0;
        return ret.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 });
    }

    async calculatTotalMaterialAC() {
        api.get(`/materiaisac/total/${this.props.idDimensionamento}`)
            .then(res => {
                this.setState({ totalMateriaisAC: res.data[0].total })
            })
    }

    async calculatTotalMaterialDC() {
        api.get(`/materiaisdc/total/${this.props.idDimensionamento}`)
            .then(res => {
                this.setState({ totalMateriaisDC: res.data[0].total })
            })
    }

    async calculatTotalEstruturas() {
        api.get(`/estruturas/total/${this.props.idDimensionamento}`)
            .then(res => {
                this.setState({ totalEstrutura: res.data[0].total })
            })
    }

    async calculatTotalObras() {
        api.get(`/obracivil/total/${this.props.idDimensionamento}`)
            .then(res => {
                this.setState({ totalObracivil: res.data[0].total })
            })
    }

    async calculatTotalFretes() {
        api.get(`/fretes/total/${this.props.idDimensionamento}`)
            .then(res => {
                this.setState({ totalFrete: res.data[0].total })
            })
    }

    render() {
        const titulo = "";
        const header = (
            <div className="table-header">
                <span>{titulo}</span>
                <Button label='Teste' />
            </div>

        );
        return (
            <div>
                <Toast ref={(el) => this.toast = el} />

                <TabView>
                    <TabPanel header={`Sist. DC | ${this.formatarCurrency(this.state.totalMateriaisDC)}`}>
                        <MaterialDCEdit 
                            idDimensionamento={this.props.idDimensionamento} 
                            idstatus={this.props.idstatus}
                            atualizarTotal={this.calculatTotalMaterialDC} 
                            nonUsedKey={this.props.nonUsedKey} />
                    </TabPanel>
                    <TabPanel header={`Sist. AC | ${this.formatarCurrency(this.state.totalMateriaisAC)}`}>
                        <MaterialACEdit 
                            idDimensionamento={this.props.idDimensionamento} 
                            idstatus={this.props.idstatus}
                            atualizarTotal={this.calculatTotalMaterialAC} />
                    </TabPanel>
                    <TabPanel header={`Est. Fix. Solo | ${this.formatarCurrency(this.state.totalEstrutura)}`}>
                        <EstruturaEdit 
                            idDimensionamento={this.props.idDimensionamento} 
                            idstatus={this.props.idstatus}
                            atualizarTotal={this.calculatTotalEstruturas} />
                    </TabPanel>
                    <TabPanel header={`Obras Adicionais | ${this.formatarCurrency(this.state.totalObracivil)}`}>
                        <ObraEdit 
                            idDimensionamento={this.props.idDimensionamento} 
                            idstatus={this.props.idstatus}
                            atualizarTotal={this.calculatTotalObras} />
                    </TabPanel>
                </TabView>


                {/* <Accordion multiple activeIndex={[0]}>
                    <AccordionTab header={titulo,
                        this.titulo = "Materiais Sistema DC | " + this.formatarCurrency(this.state.totalMateriaisDC)}>
                        <MaterialDCEdit idDimensionamento={this.props.idDimensionamento} atualizarTotal={this.calculatTotalMaterialDC} />
                    </AccordionTab>

                    <AccordionTab header={header,
                        this.titulo = "Materiais Sistema AC | " + this.formatarCurrency(this.state.totalMateriaisAC)}>
                        <MaterialACEdit idDimensionamento={this.props.idDimensionamento} atualizarTotal={this.calculatTotalMaterialAC} />
                    </AccordionTab>

                    <AccordionTab header={header,
                        this.titulo = "Estrutura de Fixação em Solo | " + this.formatarCurrency(this.state.totalEstrutura)}>
                        <EstruturaEdit idDimensionamento={this.props.idDimensionamento} atualizarTotal={this.calculatTotalEstruturas} />
                    </AccordionTab>

                    <AccordionTab header={header,
                        this.titulo = "Obra Civil | " + this.formatarCurrency(this.state.totalObracivil)}>
                        <ObraEdit idDimensionamento={this.props.idDimensionamento} atualizarTotal={this.calculatTotalObras} />
                    </AccordionTab>

                    <AccordionTab header={header,
                        this.titulo = "Frete | " + this.formatarCurrency(this.state.totalFrete)}>
                        <FreteEdit idDimensionamento={this.props.idDimensionamento} atualizarTotal={this.calculatTotalFretes} />
                    </AccordionTab>
                </Accordion> */}
            </div>
        );
    };
}
