import React from 'react';
import { Panel } from '../../../components/panel/Panel';
import { InclusosProposta } from '../components/inclusosProposta';
import { NaoInclusosProposta } from '../components/naoInclusosProposta';

export const PropostaConfiguracao = () => {
    return (
        <div>
            <div className="content-section introduction">
                <h5>Proposta Configuração</h5>
            </div>
            <div className="p-grid crud-demo">
                <div className="card">
                    <Panel header="Itens inclusos">
                        <InclusosProposta />
                    </Panel>
                    <Panel className="p-mt-4" header="Itens não inclusos">
                        <NaoInclusosProposta />
                    </Panel>
                </div>
            </div>
        </div>
    )
}  