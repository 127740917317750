import React, { Component } from 'react';
import classNames from 'classnames';
import api from '../../service/api';
import { PropTypes } from 'prop-types';
import { DataTable } from '../../components/datatable/DataTable';
import { Column } from '../../components/column/Column';
import { Toast } from '../../components/toast/Toast';
import { Button } from '../../components/button/Button';
import { Toolbar } from '../../components/toolbar/Toolbar';
import { Dialog } from '../../components/dialog/Dialog';
import { InputText } from '../../components/inputtext/InputText';
import { InputNumber } from '../../components/inputnumber/InputNumber';
import { Link, Redirect } from 'react-router-dom';
import { format, isThisHour, parseISO } from "date-fns";
import { Dropdown } from '../../components/dropdown/Dropdown';

export class NegocioDimensionamento extends Component {

    emptyDimensionamento = {
        "id": null,
        "idnegocio": null,
        "eficiencia": 0.00,
        "areautilgeracao": 0.00,
        "quantidademodulos": 0.00,
        "potencia": 0.00,
        "fornecedor": "",
        "potenciainversor": 0.00,
        "potenciapico": 0.00,
        "validade": 0,
        "largura": 0,
        "altura": 0,
        "dataatualizacao": '',
        "datacadastro": '',
		"obsformapagamento": '',
        "idfornecedor": null,
        "nomefantasia": '',
        "idstatus":null,
        "status":'',
        "obsformapagamento": '',
        "procuracaocpf": '',
        "procuracaovalidade": null,
    };

    emptymodulo = {
        "id": null,
        "idnegociodimensionamento": null,
        "descricao": '',
        "quantidade": 0,
        "idund": null,
        "valorunitario": 0,
        "total": 0,
        "unidade": '',
        "modulo": false,
        "idtipomaterial": ''
    }

    static defaultProps = {
        idnegocio: 1,
        idstatus: 1,
    }

    static propTypes = {
        idnegocio: PropTypes.number,
        idstatus: PropTypes.number
    }

    constructor(props) {
        super(props);

        this.state = {
            Dimensionamentos: null,
            DimensionamentoDialog: false,
            deleteDimensionamentoDialog: false,
            deleteDimensionamentosDialog: false,
            Dimensionamento: this.emptyDimensionamento,
            selectedDimensionamentos: [],
            submitted: false,
            globalFilter: null,
            Clientes: [],
            TipoInstalacao: [],
            Edicao: false,
            modulo: this.emptymodulo,
            cloneDimensionamentoDialog: false,
            comparar: false,
            listaFornecedor: [],
        };

        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.rightToolbarTemplate = this.rightToolbarTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.statusBodyTemplate = this.statusBodyTemplate.bind(this);

        this.openNew = this.openNew.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.saveDimensionamento = this.saveDimensionamento.bind(this);
        this.editDimensionamento = this.editDimensionamento.bind(this);
        this.confirmDeleteDimensionamento = this.confirmDeleteDimensionamento.bind(this);
        this.deleteDimensionamento = this.deleteDimensionamento.bind(this);
        this.hideDeleteDimensionamentoDialog = this.hideDeleteDimensionamentoDialog.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onInputChangeMaterialDC = this.onInputChangeMaterialDC.bind(this);
        this.onInputNumberChangeMaterialDC = this.onInputNumberChangeMaterialDC.bind(this);
        this.calcularPotenciaPico = this.calcularPotenciaPico.bind(this);
        this.confirmCloneDimensionamento = this.confirmCloneDimensionamento.bind(this);
        this.hideCloneDimensionamentoDialog = this.hideCloneDimensionamentoDialog.bind(this);
        this.cloneDimensionamento = this.cloneDimensionamento.bind(this);
        this.formatarNumero = this.formatarNumero.bind(this);
        this.selecionarDimensionamento = this.selecionarDimensionamento.bind(this);
        this.compararPropostas = this.compararPropostas.bind(this);
        this.dateTemplate = this.dateTemplate.bind(this);
        this.onFornecedorChange = this.onFornecedorChange.bind(this);
    }

    async componentDidMount() {
        const response = await api.get(`/dimensionamentos/negocio/${this.props.idnegocio}`);
        const fornecedores = await api.get('/fornecedores');
        this.setState({ 
            Dimensionamentos: response.data, 
            listaFornecedor: fornecedores.data
        });
    }

    dateTemplate(rowData) {
        const DataCadastro = parseISO(rowData['datacadastro']);
        const Data = parseISO(rowData['dataatualizacao']);
        return format(DataCadastro, "dd/MM/yyyy HH:mm") + ' ' + format(Data, "dd/MM/yyyy HH:mm");
    }

    round_abnt(nValor, nDecimais) {

        var nRetorno = nValor;
        var spl = nValor.toString().split(".");
        var cDecimais = spl[1];
        var nSubsequente = nDecimais;

        if (nDecimais < 1) {
            return parseInt(nRetorno);
        }

        if (!cDecimais || cDecimais.length <= nDecimais) {
            return parseFloat(nRetorno);
        }

        //Se a casa decimal SUBSEQUENTE for DIFERENTE de 5
        if (cDecimais.substr(nSubsequente, 1) >= '5' || cDecimais.substr(nSubsequente, 1) < '5') {
            nRetorno = nRetorno.toFixed(2); //Math.round((nRetorno + 0.00001) * 100) / 100; //ARREDONDA
        }
        //Se a casa decimal SUBSEQUENTE for IGUAL a 5
        else if (cDecimais.substr(nSubsequente, 1) == '5') {

            //Se a casa decimal que será CONSERVADA, for IMPAR
            if ((cDecimais.substr(nDecimais - 1, 1) % 2) != 0) {
                nRetorno = nRetorno.toFixed(2); //Math.round((nRetorno + 0.00001) * 100) / 100; //ARREDONDA
            }
            //Se a casa decimal que será CONSERVADA, for PAR
            else
                //Se APÓS a casa decimal SUBSEQUENTE, houver ALGUM algarismo MAIOR que ZERO
                if (cDecimais.substr(nSubsequente + 1, 1) > 0) {
                    nRetorno = nRetorno.toFixed(2); //Math.round((nRetorno + 0.00001) * 100) / 100; //ARREDONDA
                }
                //Se APÓS a casa decimal SUBSEQUENTE, não houver NENHUM outro algarismo ou TODOS forem iguais a ZERO
                else {
                    //TRUNCA (Esse é o único momento em que o "arredondamento ABNT" se diferencia do "arredondamento normal")
                    nRetorno = this.Truncate(nValor, nDecimais);
                }
        }
        return parseFloat(nRetorno);
    }

    Truncate(nValor, nDecimais) {

        var nRetorno = nValor;
        var spl = nValor.toString().split(".");
        var cDecimais = spl[1];

        if (nDecimais < 1) {
            return parseInt(nRetorno);
        }

        if (cDecimais.length <= nDecimais) {
            return nRetorno;
        }

        //Pega a parte inteira do número e concatena com a substring sem alterar, pois é PAR e vai manter!
        nRetorno = parseInt(nValor.toString()) + '.' + cDecimais.substr(0, nDecimais);
        nRetorno = parseFloat(nRetorno);

        return nRetorno;
    }

    openNew() {
        api.get(`/contas/negocio/${this.props.idnegocio}/top/1`)
            .then(res => {
                let ok = false;
                if (res.status === 200) {
                    //if (typeof res.data.id !== "undefined") {
                    if (res.data.length > 0){    
                        ok = res.data[0].consumomedia > 0 ? true : false;
                    }
                    else {
                        ok = false;
                    }
                }
                if (ok) {
                    this.setState({
                        Dimensionamento: this.emptyDimensionamento,
                        submitted: false,
                        DimensionamentoDialog: true
                    });
                }
                else {
                    this.toast.show({ severity: 'warn', summary: 'Atenção', detail: `Antes de prosseguir, informe os dados da conta de energia`, life: 3000 });
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    hideDialog() {
        this.setState({
            submitted: false,
            DimensionamentoDialog: false
        });
    }

    hideDeleteDimensionamentoDialog() {
        this.setState({ deleteDimensionamentoDialog: false });
    }

    hideCloneDimensionamentoDialog() {
        this.setState({ cloneDimensionamentoDialog: false });
    }

    async saveDimensionamento() {
        let state = { submitted: true };

        if (this.state.Dimensionamento.idfornecedor &&
            this.state.Dimensionamento.potencia && this.state.Dimensionamento.potencia > 0 &&
            this.state.Dimensionamento.quantidademodulos && this.state.Dimensionamento.quantidademodulos > 0 &&
            this.state.Dimensionamento.eficiencia && this.state.Dimensionamento.eficiencia > 0 &&
            this.state.Dimensionamento.areautilgeracao && this.state.Dimensionamento.areautilgeracao > 0 &&
            this.state.Dimensionamento.validade && this.state.Dimensionamento.validade > 0 &&
            this.state.Dimensionamento.largura && this.state.Dimensionamento.largura > 0 &&
            this.state.Dimensionamento.altura && this.state.Dimensionamento.altura > 0) {

            let Dimensionamentos = [...this.state.Dimensionamentos];
            let Dimensionamento = { ...this.state.Dimensionamento };

            Dimensionamento.datacadastro = format(new Date(), 'yyyy-MM-dd HH:mm:ss');
            Dimensionamento.dataatualizacao = format(new Date(), 'yyyy-MM-dd HH:mm:ss');

            if (this.state.Dimensionamento.id) {
                const index = this.findIndexById(this.state.Dimensionamento.id);
                api.put(`/dimensionamentos/${Dimensionamento.id}`, Dimensionamento)
                    .then(res => {
                        if (res.status === 204) {
                            Dimensionamentos[index] = Dimensionamento;
                            state = {
                                ...state,
                                Dimensionamentos,
                                DimensionamentoDialog: false,
                                Dimensionamento: this.emptyDimensionamento
                            };
                            this.setState(state);
                            this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Dimensionamento atualizado', life: 3000 });
                        }
                    })
                    .catch(error => {
                        this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu atualizar o dimensionamento', life: 3000 });
                        //console.log(error.response.data.description);
                    })

            }
            else {
                Dimensionamento.id = 0;
                Dimensionamento.idnegocio = this.props.idnegocio;
                api.post('/dimensionamentos', Dimensionamento)
                    .then(res => {
                        if (res.status === 201) {
                            Dimensionamento.id = parseInt(res.headers.id);
                            Dimensionamentos.push(Dimensionamento);

                            let novoModulo = { ...this.state.modulo };
                            novoModulo.id = 0;
                            novoModulo.idnegociodimensionamento = Dimensionamento.id;
                            novoModulo.quantidade = Dimensionamento.quantidademodulos;
                            novoModulo.idund = 1;
                            novoModulo.total = novoModulo.quantidade * novoModulo.valorunitario;
                            novoModulo.unidade = 'UND';
                            novoModulo.modulo = true;
                            novoModulo.idtipomaterial = 1;
                            api.post('/materiaisdc', novoModulo)
                                .then(res1 => {
                                    if (res1.status === 201) {
                                        state = {
                                            ...state,
                                            Dimensionamentos,
                                            DimensionamentoDialog: false,
                                            Dimensionamento: this.emptyDimensionamento,
                                            modulo: novoModulo
                                        };
                                        this.setState(state);
                                        this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Dimensionamento inserido', life: 3000 });
                                        this.props.history.push(`/dimensionamento/${Dimensionamento.id}`);
                                    }
                                })
                        }
                    })
                    .catch(error => {
                        this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu inserir o dimensionamento', life: 3000 });
                        //console.log(error.response.data.description);
                    })
            }
        }
        else {
            this.setState(state);
        }
    }

    onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        const input = e.target;
        const start = input.selectionStart;
        const end = input.selectionEnd;
        let Dimensionamento = { ...this.state.Dimensionamento };
        Dimensionamento[`${name}`] = val.toUpperCase();
        this.setState({ Dimensionamento },
            () => input.setSelectionRange(start, end));
    }

    onInputChangeMaterialDC(e, name) {
        const val = (e.target && e.target.value) || '';
        let modulo = { ...this.state.modulo };
        modulo[`${name}`] = val.toUpperCase();
        this.setState({ modulo });
    }

    onInputNumberChange(e, name) {
        const val = e.value; // || 0;
        let Dimensionamento = { ...this.state.Dimensionamento };
        Dimensionamento[`${name}`] = val;
        if ((Dimensionamento.quantidademodulos) && (Dimensionamento.potencia)) {
            Dimensionamento.potenciapico = Dimensionamento.quantidademodulos * Dimensionamento.potencia / 1000
        }
        if ((Dimensionamento.largura) && (Dimensionamento.altura)) {
            if (Dimensionamento.largura === 0 || Dimensionamento.altura === 0) {
                Dimensionamento.areautilgeracao = 0;
            }
            else {
                Dimensionamento.areautilgeracao = this.round_abnt((Dimensionamento.largura * Dimensionamento.altura) / 1000000, 2);
            }
        }
        this.setState({ Dimensionamento });
    }

    onInputNumberChangeMaterialDC(e, name) {
        const val = e.value; // || 0;
        let modulo = { ...this.state.modulo };
        modulo[`${name}`] = val;
        this.setState({ modulo });
    }

    editDimensionamento(Dimensionamento) {
        this.setState({
            Dimensionamento: { ...Dimensionamento },
            Edicao: true
        });
    }


    confirmDeleteDimensionamento(Dimensionamento) {
        this.setState({
            Dimensionamento,
            deleteDimensionamentoDialog: true
        });
    }

    confirmCloneDimensionamento(Dimensionamento) {
        this.setState({
            Dimensionamento,
            cloneDimensionamentoDialog: true
        });
    }

    cloneDimensionamento(dimensionamento) {
        let id = dimensionamento['id'];
        let idNegocio = dimensionamento['idnegocio'];
        api.post(`/clonar/dimensionamento/${id}`, dimensionamento)
            .then(res => {
                if (res.status === 201) {
                    api.get(`/dimensionamentos/negocio/${idNegocio}`)
                        .then(dim => {
                            this.setState({ Dimensionamentos: dim.data })
                        })
                    this.setState({
                        cloneDimensionamentoDialog: false,
                        Dimensionamento: this.emptyDimensionamento
                    });
                    this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Dimensionamento clonado', life: 3000 });
                }
            })
            .catch(error => {
                this.setState({ cloneDimensionamentoDialog: false });
                this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu clonar o Dimensionamento', life: 3000 });
                // console.log(error.response.data.description);
            })
    }

    deleteDimensionamento() {
        api.delete(`/dimensionamentos/${this.state.Dimensionamento.id}`)
            .then(res => {
                if (res.status === 204) {
                    let Dimensionamentos = this.state.Dimensionamentos.filter(val => val.id !== this.state.Dimensionamento.id);
                    this.setState({
                        Dimensionamentos,
                        deleteDimensionamentoDialog: false,
                        Dimensionamento: this.emptyDimensionamento
                    });
                    this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Dimensionamento excluído', life: 3000 });
                }
            })
            .catch(error => {
                this.setState({ deleteDimensionamentoDialog: false });
                this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu excluir o Dimensionamento', life: 3000 });
                // console.log(error.response.data.description);
            })

    }

    findIndexById(id) {
        let index = -1;
        for (let i = 0; i < this.state.Dimensionamentos.length; i++) {
            if (this.state.Dimensionamentos[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                {/* <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => this.editDimensionamento(rowData)} /> */}
                <Link to={`/dimensionamento/${rowData['id']}`}>
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded p-mr-2"
                        tooltip="Alterar"
                        tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} />
                </Link>
                <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-mr-2"
                    onClick={() => this.confirmDeleteDimensionamento(rowData)}
                    tooltip="Excluir"
                    tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} 
                    disabled={rowData.idstatus > 2} 
                    />
                <Button
                    icon="pi pi-clone"
                    className="p-button-rounded p-button-warning"
                    onClick={() => this.cloneDimensionamento(rowData)}
                    tooltip='Clonar'
                    tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} />
            </React.Fragment>
        );
    }

    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button 
                    label="Novo" 
                    icon="pi pi-plus" 
                    className="p-button p-mr-2" 
                    onClick={this.openNew}
                    disabled={this.props.idstatus > 2} />
            </React.Fragment>
        )
    }

    compararPropostas() {
        this.setState({ comparar: true });
    }

    rightToolbarTemplate() {
        return (
            <React.Fragment>
                <Button
                    label="Comparar"
                    icon="pi pi-copy"
                    className="p-button-help"
                    tooltip="Comparar propostas"
                    tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
                    disabled={this.state.selectedDimensionamentos && this.state.selectedDimensionamentos.length === 0}
                    onClick={this.compararPropostas} />
            </React.Fragment>
        )
    }

    calcularPotenciaPico() {
        let potencia = this.state.Dimensionamento.potencia !== null ? this.state.Dimensionamento.potencia : 0;
        let qtdModulos = this.state.Dimensionamento.quantidademodulos !== null ? this.state.Dimensionamento.quantidademodulos : 0;
        return potencia * qtdModulos / 1000;
    }

    formatarNumero(rowData, field) {
        return rowData[field].toLocaleString('pt-BR', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 });
    }

    selecionarDimensionamento(dimensionamento) {
        this.setState({ selectedDimensionamentos: dimensionamento });
    }

    onFornecedorChange(e) {
        let Dimensionamento = { ...this.state.Dimensionamento };
        Dimensionamento.idfornecedor = e.value;
        // materialDC.unidade = this.state.unidades[this.findUnidade(e.value)].unidade;
        this.setState({ Dimensionamento });
    }    

    statusBodyTemplate(rowData) {
        let estilo;
        let texto;            
        
        estilo = "p-tag p-tag-rounded p-tag-primary";
        texto = 'EL';  
        console.log('status:' + rowData.idstatus);
        if (rowData.idstatus === 1) {
            estilo = "p-tag p-tag-rounded p-tag-primary";
            texto = 'EL';            
        }
        else if (rowData.idstatus === 2) {
            estilo = "p-tag p-tag-rounded p-tag-warning";
            texto = 'CL';
        }
        else if (rowData.idstatus === 3) {
            estilo = "p-tag p-tag-rounded p-tag-success";
            texto = 'AC';
        }
        else if (rowData.idstatus === 4) {
            estilo = "p-tag p-tag-rounded p-tag-danger";
            texto = 'PE';
        }                
        return <span className={estilo}>{texto}</span>;
    }    

    render() {
        if (this.state.Edicao) {
            return <Redirect push to={`/dimensionamento/${this.state.Dimensionamento.id}`} />;
        }
        else if (this.state.comparar) {
            return <Redirect push to={`/comparativo/${this.props.idnegocio}`} />;
        }
        else {
            const DimensionamentoDialogFooter = (
                <React.Fragment>
                    <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} tabIndex={6} />
                    <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={this.saveDimensionamento} tabIndex={7} />
                </React.Fragment>
            );
            const deleteDimensionamentoDialogFooter = (
                <React.Fragment>
                    <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteDimensionamentoDialog} />
                    <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={this.deleteDimensionamento} />
                </React.Fragment>
            );

            const cloneDimensionamentoDialogFooter = (
                <React.Fragment>
                    <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={this.hideCloneDimensionamentoDialog} />
                    <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={this.cloneDimensionamento} />
                </React.Fragment>
            );

            return (
                <div>
                    {sessionStorage.setItem('dimensionamentos', JSON.stringify(this.state.selectedDimensionamentos))}
                    <Toast ref={(el) => this.toast = el} />
                    <div>
                        <Toolbar className="p-mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
                        {/* <DataTable className="p-datatable-gridlines p-datatable-sm" ref={(el) => this.dt = el} value={this.state.Dimensionamentos} selection={this.state.selectedDimensionamentos} onSelectionChange={(e) => this.setState({ selectedDimensionamentos: e.value })} */}
                        <DataTable className="p-datatable-gridlines p-datatable-sm" ref={(el) => this.dt = el} value={this.state.Dimensionamentos} selection={this.state.selectedDimensionamentos} onSelectionChange={(e) => this.selecionarDimensionamento(e.value)}
                            dataKey="id">
                            <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
                            <Column field="nomefantasia" header="Fornecedor" ></Column>
                            <Column field="fornecedor" header="Descrição" ></Column>                            
                            <Column field="quantidademodulos" header="Módulos" style={{ width: '7%' }}></Column>
                            <Column field="potencia" header="Potência Módulo" style={{ width: '9%' }}></Column>
                            {/* <Column field="potenciainversor" header="Potência Inversor"  style={{ width: '9%' }}></Column> */}
                            {/* <Column field="areautilgeracao" header="Área útil" body={(rowData) => this.formatarNumero(rowData, 'areautilgeracao')} style={{ width: '5%' }}></Column>
                            <Column field="eficiencia" header="Eficiência" body={(rowData) => this.formatarNumero(rowData, 'eficiencia')} style={{ width: '9%' }}></Column> */}
                            <Column field="potenciapico" header="Potencia Utilizada" body={(rowData) => this.formatarNumero(rowData, 'potenciapico')} style={{ width: '9%' }}></Column>
                            <Column field="idstatus" header="Status" body={this.statusBodyTemplate} style={{ width: '50px' }}></Column>
                            <Column field="dataatualizacao" header="Inclusão / Atualização" style={{ width: '125px' }} sortable body={(rowData) => this.dateTemplate(rowData)} ></Column>
                            <Column body={this.actionBodyTemplate} style={{ width: '110px' }}></Column>
                        </DataTable>
                    </div>

                    <Dialog visible={this.state.DimensionamentoDialog} style={{ width: '550px' }} header="Novo Dimensionamento" modal className="p-fluid" footer={DimensionamentoDialogFooter} onHide={this.hideDialog}>
                        <div className="p-fluid p-formgrid p-grid">
                            
                            <div className="p-field p-col-12 p-md-12">
                                <label htmlFor="idfornecedor">Fornecedor</label>
                                <Dropdown 
                                  inputId="idfornecedor" 
                                  optionValue="id" 
                                  optionLabel="nomefantasia" 
                                  value={this.state.Dimensionamento.idfornecedor} 
                                  options={this.state.listaFornecedor} 
                                  onChange={(e) => this.onFornecedorChange(e)} 
                                  placeholder="Selecione o fornecedor" 
                                  required 
                                  autoFocus 
                                  className={classNames({ 'p-invalid': this.state.submitted && !this.state.Dimensionamento.idfornecedor })} 
                                  tabIndex={1} />
                                {this.state.submitted && !this.state.Dimensionamento.idfornecedor && <small className="p-invalid">O Fornecedor é requerido.</small>}
                            </div>

                            <div className="p-field p-col-12 p-md-12">
                                <label htmlFor="fornecedor">Descrição</label>
                                <InputText id="fornecedor" value={this.state.Dimensionamento.fornecedor} onChange={(e) => this.onInputChange(e, 'fornecedor')}
                                  tabIndex={2}  />
                            </div>

                            <div className="p-field p-col-12 p-md-12">
                                <label htmlFor="descricao">Descrição do Módulo</label>
                                <InputText id="descricao" value={this.state.modulo.descricao} onChange={(e) => this.onInputChangeMaterialDC(e, 'descricao')}
                                    required className={classNames({ 'p-invalid': this.state.submitted && !this.state.modulo.descricao })}
                                    tabIndex={3}  />
                                {this.state.submitted && !this.state.modulo.descricao && <small className="p-invalid">A Descrição do módulo é requerida.</small>}
                            </div>
                            <div className="p-field p-col-12 p-md-12">
                                <label htmlFor="valorunitario">Valor do Módulo</label>
                                <InputNumber id="valorunitario" value={this.state.modulo.valorunitario} onValueChange={(e) => this.onInputNumberChangeMaterialDC(e, 'valorunitario')}
                                    required className={classNames({ 'p-invalid': this.state.submitted && !this.state.modulo.valorunitario })}
                                    tabIndex={4} mode="decimal" locale="pt-BR" minFractionDigits={2}  />
                                {this.state.submitted && !this.state.modulo.valorunitario && <small className="p-invalid">O Valor unitário é requerido.</small>}
                            </div>
                            <div className="p-field p-col-12 p-md-12">
                                <label htmlFor="quantidademodulos">Quantidade. Módulos</label>
                                <InputNumber id="quantidademodulos" value={this.state.Dimensionamento.quantidademodulos} onValueChange={(e) => this.onInputNumberChange(e, 'quantidademodulos')}
                                    required className={classNames({ 'p-invalid': this.state.submitted && !this.state.Dimensionamento.quantidademodulos })}
                                    tabIndex={5}  />
                                {this.state.submitted && !this.state.Dimensionamento.quantidademodulos && <small className="p-invalid">A quantidade de módulos é requerida.</small>}
                            </div>
                            <div className="p-field p-col-12 p-md-12">
                                <label htmlFor="potencia">Potência Módulo(W)</label>
                                <InputNumber id="potencia" value={this.state.Dimensionamento.potencia} onValueChange={(e) => this.onInputNumberChange(e, 'potencia')}
                                    required className={classNames({ 'p-invalid': this.state.submitted && !this.state.Dimensionamento.potencia })}
                                    mode="decimal" locale="pt-BR" minFractionDigits={2} tabIndex={6}  />
                                {this.state.submitted && !this.state.Dimensionamento.potencia && <small className="p-invalid">A potência do módulo é requerida.</small>}
                            </div>

                            <div className="p-field p-col-12 p-md-4">
                                <label htmlFor="largura">Largura (mm)</label>
                                <InputNumber id="largura" value={this.state.Dimensionamento.largura} onChange={(e) => this.onInputNumberChange(e, 'largura')}
                                    required className={classNames({ 'p-invalid': this.state.submitted && !this.state.Dimensionamento.largura })}
                                    tabIndex={7}  />
                                {this.state.submitted && !this.state.Dimensionamento.largura && <small className="p-invalid">A largura é requerida.</small>}
                            </div>

                            <div className="p-field p-col-12 p-md-4">
                                <label htmlFor="altura">Altura (mm)</label>
                                <InputNumber id="altura" value={this.state.Dimensionamento.altura} onValueChange={(e) => this.onInputNumberChange(e, 'altura')}
                                    required className={classNames({ 'p-invalid': this.state.submitted && !this.state.Dimensionamento.altura })}
                                    tabIndex={8}  />
                                {this.state.submitted && !this.state.Dimensionamento.altura && <small className="p-invalid">A altura é requerida.</small>}
                            </div>

                            <div className="p-field p-col-12 p-md-4">
                                <label htmlFor="areautilgeracao">Área útil (m2)</label>
                                <InputNumber id="areautilgeracao" value={this.state.Dimensionamento.areautilgeracao}
                                    inputStyle={{ color: 'blue' }} disabled mode="decimal" locale="pt-BR" minFractionDigits={2} tabIndex={9} />
                                {this.state.submitted && !this.state.Dimensionamento.areautilgeracao && <small className="p-invalid">A área útil de geração é requerida.</small>}
                            </div>


                            <div className="p-field p-col-12 p-md-12">
                                <label htmlFor="eficiencia">Eficiência (%)</label>
                                <InputNumber id="eficiencia" value={this.state.Dimensionamento.eficiencia} onValueChange={(e) => this.onInputNumberChange(e, 'eficiencia')}
                                    required className={classNames({ 'p-invalid': this.state.submitted && !this.state.Dimensionamento.eficiencia })}
                                    mode="decimal" locale="pt-BR" minFractionDigits={2} tabIndex={10}  />
                                {this.state.submitted && !this.state.Dimensionamento.eficiencia && <small className="p-invalid">A eficiência é requerida.</small>}
                            </div>

                            <div className="p-field p-col-12 p-md-12">
                                <label htmlFor="validade">Validade</label>
                                <InputNumber id="validade" value={this.state.Dimensionamento.validade} onChange={(e) => this.onInputNumberChange(e, 'validade')}
                                    required className={classNames({ 'p-invalid': this.state.submitted && !this.state.Dimensionamento.validade })}
                                    tabIndex={11}  />
                                {this.state.submitted && !this.state.Dimensionamento.validade && <small className="p-invalid">A validade é requerida.</small>}
                            </div>

                            {/* <div className="p-field p-col-12 p-md-12">
                                <label htmlFor="potenciainversor">Potência Inversor(kWp)</label>
                                <InputNumber id="potenciainversor" value={this.state.Dimensionamento.potenciainversor} onValueChange={(e) => this.onInputNumberChange(e, 'potenciainversor')}
                                    required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.Dimensionamento.potenciainversor })}
                                    mode="decimal" locale="pt-BR" minFractionDigits={2} tabIndex={10}  />
                                {this.state.submitted && !this.state.Dimensionamento.potenciainversor && <small className="p-invalid">A potência do inversor é requerida.</small>}
                            </div>                             */}

                            <div className="p-field p-col-12 p-md-12">
                                <label htmlFor="potenciapico">Potência Utilizada (kWp)</label>
                                <InputNumber id="potenciapico" value={this.state.Dimensionamento.potenciapico}
                                    inputStyle={{ color: 'blue' }} disabled mode="decimal" locale="pt-BR" minFractionDigits={2} tabIndex={12} />
                            </div>

                        </div>
                    </Dialog>


                    <Dialog visible={this.state.deleteDimensionamentoDialog} style={{ width: '450px' }} header="Confirma" modal footer={deleteDimensionamentoDialogFooter} onHide={this.hideDeleteDimensionamentoDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {this.state.Dimensionamento && <span>Tem certeza que deseja excluir o dimensionamento <b>{this.state.Dimensionamento.fornecedor}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={this.state.cloneDimensionamentoDialog} style={{ width: '450px' }}
                        header="Confirma" modal footer={cloneDimensionamentoDialogFooter} onHide={this.hideCloneDimensionamentoDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {this.state.Dimensionamento && <span>Confirma o clone do dimensionamento <b>{this.state.Dimensionamento.fornecedor}</b>?</span>}
                        </div>
                    </Dialog>
                </div>
            )
        }
    }
}