import React, { Component } from 'react';
import classNames from 'classnames';
import api from '../../service/api';
import { consultaCEP } from '../../service/cepService';
import { Button } from '../../components/button/Button';
import { InputNumber } from '../../components/inputnumber/InputNumber';
import { Toast } from '../../components/toast/Toast';
import { Toolbar } from '../../components/toolbar/Toolbar';
import { InputText } from '../../components/inputtext/InputText';
import { Dialog } from '../../components/dialog/Dialog';
import { DataTable } from '../../components/datatable/DataTable';
import { Column } from '../../components/column/Column'
import { Dropdown } from '../../components/dropdown/Dropdown';
import { RadioButton } from '../../components/radiobutton/RadioButton';
import { InputMask } from '../../components/inputmask/InputMask';
import LoadingScreen from 'react-loading-screen';
import { PropTypes } from 'prop-types';

export class DimensionamentoContratos extends Component {

    static defaultProps = {
        idNegocioDimensionamento: 0,
        idstatus: 1
    }

    static propTypes = {
        idNegocioDimensionamento: PropTypes.number,
        idstatus: PropTypes.number,
    }

    emptyContrato = {
        "id": 0,
        "idnegociodimensionamento": null,
        "contrato": '',
        "logradouro": '',
        "bairro": '',
        "idcidade": null,
        "tipo": '',
        "principal": false,
        "idestado": null,
        "cidade": '',
        "uf": '',
        "cep": '',
        "porcentagem": 0,

    }

    constructor(props) {
        super(props);

        this.state = {
            contrato: this.emptyContrato,
            listaContratos: [],
            submitted: false,
            edicao: false,
            editDialog: false,
            deleteDialog: false,
            estados: [],
            cidades: [],
            loading: false,
        }

        this.originalRows = {};

        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.unidConsumidoraBodyTemplate = this.unidConsumidoraBodyTemplate.bind(this);
        this.openNew = this.openNew.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.save = this.save.bind(this);
        this.edit = this.edit.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.hideDeleteDialog = this.hideDeleteDialog.bind(this);
        this.delete = this.delete.bind(this);
        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.rightToolbarTemplate = this.rightToolbarTemplate.bind(this);
        this.exibirPaginacao = this.exibirPaginacao.bind(this);
        this.onUnidConsumidoraChange = this.onUnidConsumidoraChange.bind(this);
        this.openRateio = this.openRateio.bind(this);
        this.validarPorcentagem = this.validarPorcentagem.bind(this);
        this.validarTotalPorcentagem = this.validarTotalPorcentagem.bind(this);
        this.consultaCEP = this.consultaCEP.bind(this);
        this.porcentagemCompleta = this.porcentagemCompleta.bind(this);
        this.onInputMaskChange = this.onInputMaskChange.bind(this);
    }

    async componentDidMount() {
        await api.get('/estados')
            .then(res => {
                this.setState({ estados: res.data });
            })
        const listaContratos = await api.get(`/contratos/dimensionamento/${this.props.idNegocioDimensionamento}`);
        this.setState({ listaContratos: listaContratos.data });
    }

    hideDeleteDialog() {
        this.setState({ deleteDialog: false });
    }

    confirmDelete(contrato) {
        this.setState({
            contrato,
            deleteDialog: true
        });
    }

    delete() {
        api.delete(`/contratos/${this.state.contrato.id}`)
            .then(res => {
                if (res.status === 204) {
                    let listaContratos = this.state.listaContratos.filter(val => val.id !== this.state.contrato.id);
                    this.setState({
                        listaContratos,
                        deleteDialog: false,
                        contrato: this.emptyContrato
                    });
                    this.toast.show({ severity: 'success', summary: 'Successo', detail: 'Contrato excluído', life: 3000 });
                }
            })
            .catch(error => {
                this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu excluir o contrato', life: 3000 });
                // console.log(error.response.data.description);
            })
    }

    edit(contrato) {
        if (contrato.idestado) {
            api.get(`/cidades/uf/${contrato.idestado}`)
                .then(res => {
                    this.setState({ cidades: res.data });
                });
        }
        this.setState({
            contrato: { ...contrato },
            editDialog: true
        });
    }

    openNew() {
        let _contrato = this.emptyContrato;
        if (this.state.listaContratos.length > 0) {
            _contrato.tipo = 'c';
        }
        else {
            _contrato.tipo = 'g';
        }
        this.setState({
            contrato: _contrato,
            submitted: false,
            editDialog: true
        });
    }

    hideDialog() {
        this.setState({
            submitted: false,
            editDialog: false
        });
    }

    validarPorcentagem() {
        let retorno = true;
        if (this.state.contrato.porcentagem === null) {
            retorno = false;
        }
        else if (this.state.contrato.tipo === 'c' && this.state.contrato.porcentagem <= 0) {
            retorno = false;
        };
        return retorno;
    }

    validarTotalPorcentagem() {
        let total = 0;
        let id = this.state.contrato.id;
        for (let i = 0; i < this.state.listaContratos.length; i++) {
            if (this.state.listaContratos[i].id !== id) {
                total = total + this.state.listaContratos[i].porcentagem;
            }
        }
        total = total + this.state.contrato.porcentagem;
        return total > 100 ? false : true;
    }

    porcentagemCompleta() {
        let total = 0;
        for (let i = 0; i < this.state.listaContratos.length; i++) {
            total = total + this.state.listaContratos[i].porcentagem;
        }
        return total === 100 ? true : false;
    }

    async save() {
        let state = { submitted: true }
        if ((this.state.contrato.contrato.trim()) && (this.state.contrato.logradouro.trim())
            && (this.state.contrato.bairro.trim()) && (this.state.contrato.idcidade)
            && (this.state.contrato.idestado) && (this.validarPorcentagem())
            && (this.validarTotalPorcentagem()) && (this.state.contrato.tipo.trim())) {
            let listaContratos = [...this.state.listaContratos];
            let contrato = { ...this.state.contrato };
            if (this.state.contrato.id) {
                const index = this.findIndexById(this.state.contrato.id);
                api.put(`/contratos/${contrato.id}`, contrato)
                    .then(res => {
                        if (res.status === 204) {
                            listaContratos[index] = contrato;
                            state = {
                                submitted: false,
                                listaContratos,
                                editDialog: false,
                                contrato: this.emptyContrato
                            };
                            this.setState(state);
                            this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Contrato atualizado', life: 3000 });
                        }
                    })
                    .catch(error => {
                        this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu atualizar o contrato', life: 3000 });
                        //console.log(error.response.data.description);
                    })
            }
            else {
                contrato.id = 0;
                contrato.idnegociodimensionamento = this.props.idNegocioDimensionamento;
                api.post('/contratos', contrato)
                    .then(res => {
                        if (res.status === 201) {
                            contrato.id = res.headers.id;
                            listaContratos.push(contrato);
                            state = {
                                submitted: false,
                                listaContratos,
                                editDialog: false,
                                contrato: this.emptyContrato
                            };
                            this.setState(state);
                            this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Contrato inserido', life: 3000 });
                        }
                    })
                    .catch(error => {
                        this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu inserir o contrato', life: 3000 });
                        console.log(error.response.data.description);
                    })
            }
        }
        else {
            this.setState(state);
        }
    }

    consultaCEP() {

        consultaCEP(this.state.contrato.cep)
            .then(ret => {
                if (ret) {
                    let contrato = { ...this.state.contrato };
                    contrato.logradouro = ret.logradouro;
                    contrato.bairro = ret.bairro;
                    contrato.idcidade = ret.idCidade;
                    contrato.cidade = ret.cidade;
                    contrato.idestado = ret.idEstado;
                    contrato.uf = ret.uf;
                    contrato.ibge = ret.ibge;
                    api.get(`/cidades/uf/${contrato.idestado}`)
                        .then(res => {
                            this.setState({
                                cidades: res.data,
                                contrato: contrato
                            });
                        });
                }
            })
            .catch(error => {
                this.toast.show({ severity: 'error', summary: 'Erro', detail: `O CEP ${this.state.contrato.cep} não foi localizado`, life: 3000 });
                console.log(error);
            })


        // let cep = this.state.contrato.cep;
        // if (cep.trim()) {
        //     let cepAux = cep.replace('.', '');
        //     cepAux = cepAux.replace('-', '');
        //     if (cepAux.length === 8) {
        //         api.get(`/consultacep/${cepAux}`)
        //             .then(res => {
        //                 if (res.status === 200) {
        //                     if (typeof res.data.erro === "undefined") {
        //                         let contrato = { ...this.state.contrato };
        //                         let ibge = res.data.ibge;
        //                         api.get(`/cidades/ibge/${ibge}`)
        //                             .then(cidade => {
        //                                 api.get(`/cidades/uf/${cidade.data.idestado}`)
        //                                     .then(res => {
        //                                         this.setState({ cidades: res.data });
        //                                     });
        //                                 contrato.idcidade = cidade.data.idcidade;
        //                                 contrato.cidade = cidade.data.cidade.toUpperCase();
        //                                 contrato.idestado = cidade.data.idestado;
        //                                 contrato.uf = cidade.data.uf.toUpperCase()
        //                             })
        //                         contrato.logradouro = res.data.logradouro.toUpperCase();
        //                         contrato.bairro = res.data.bairro.toUpperCase();
        //                         this.setState({ contrato: contrato })
        //                     }
        //                     else {
        //                         this.toast.show({ severity: 'error', summary: 'Erro', detail: `O CEP ${cep} não foi localizado`, life: 3000 });
        //                     }
        //                 }
        //             })
        //             .catch(error => {
        //                 this.toast.show({ severity: 'error', summary: 'Erro', detail: `Ocorreu algum problema ao tentar consultar o CEP ${cep}`, life: 3000 });
        //                 console.log(error.data);
        //             })
        //     }
        //     else {
        //         alert(`CEP ${cep} inválido`)
        //     }
        // }
    }

    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-mr-2"
                    onClick={() => this.edit(rowData)}
                    disabled={this.props.idstatus > 3} />
                <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger"
                    onClick={() => this.confirmDelete(rowData)}
                    disabled={this.props.idstatus > 3} />
            </React.Fragment>
        );
    }

    unidConsumidoraBodyTemplate(rowData) {
        let estilo;
        let texto = 'GERADORA';
        if (rowData.tipo.toLowerCase() === 'g') {
            estilo = "p-tag p-tag-success";
        }
        else {
            estilo = "p-tag p-tag-warning";
            texto = 'COMPENSAÇÃO';
        }
        return <span className={estilo}>{texto}</span>;
    }

    onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        const input = e.target;
        const start = input.selectionStart;
        const end = input.selectionEnd;
        let contrato = { ...this.state.contrato };
        contrato[`${name}`] = val.toUpperCase();
        this.setState({ contrato: contrato },
            () => input.setSelectionRange(start, end));
    }

    onInputMaskChange(e, name) {
        const val = (e.target && e.target.value) || '';
        let contrato = { ...this.state.contrato };
        contrato[`${name}`] = val;
        this.setState({ contrato: contrato });
    }

    onInputNumberChange(e, name) {
        const val = e.value;
        let contrato = { ...this.state.contrato };
        contrato[`${name}`] = val;
        this.setState({ contrato: contrato });
    }

    findIndexById(id) {
        let index = -1;
        for (let i = 0; i < this.state.listaContratos.length; i++) {
            if (this.state.listaContratos[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    onUnidadeChange(e) {
        let contrato = { ...this.state.contrato };
        contrato.idund = e.value;
        this.setState({ contrato });
    }

    formatarNumero(rowData, field) {
        return rowData[field].toLocaleString('pt-BR', { style: 'decimal', minimumFractionDigits: 2 });
    }

    formatarCurrency(rowData, field) {
        return rowData[field].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 });
    }

    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button
                    label="Novo"
                    icon="pi pi-plus"
                    className="p-button p-mr-2"
                    onClick={this.openNew}
                    disabled={this.props.idstatus > 3} />
            </React.Fragment>
        )
    }

    rightToolbarTemplate() {
        return (
            <React.Fragment>
                <Button
                    label="Imprimir"
                    icon="pi pi-print"
                    className="p-button p-mr-2"
                    onClick={this.openRateio}
                    disabled={!this.porcentagemCompleta()} />
            </React.Fragment>
        )
    }

    exibirPaginacao() {
        let exibir = false;
        if (this.state.listaContratos.length > 5) { exibir = true }
        return exibir
    }

    onEstadoChange(e) {
        let contrato = { ...this.state.contrato };
        contrato.idestado = e.value;
        contrato.uf = this.state.estados.filter(val => val.idestado === contrato.idestado)[0].uf;
        this.setState({ contrato });
        api.get(`/cidades/uf/${contrato.idestado}`)
            .then(res => {
                this.setState({ cidades: res.data });
            });
    }

    onCidadeChange(e) {
        const val = (e.target && e.target.value) || '';
        let contrato = { ...this.state.contrato };
        contrato.idcidade = val;
        contrato.cidade = this.state.cidades.filter(val => val.idcidade === contrato.idcidade)[0].cidade;
        this.setState({ contrato });
    }

    onUnidConsumidoraChange(e) {
        let contrato = { ...this.state.contrato };
        contrato.tipo = e.value;
        this.setState({ contrato });
    }

    formatarNumero(rowData, field) {
        return rowData[field].toLocaleString('pt-BR', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 });
    }

    async openRateio() {
        this.setState({ loading: true })
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        api.get(`/docaux/rateio/${this.props.idNegocioDimensionamento}`, { responseType: 'blob' })
            .then(response => {
                const file = new Blob([response.data], { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(file);

                a.href = fileURL;
                a.download = `ListaRateio-${this.props.idNegocioDimensionamento}.pdf`;
                a.click();
                window.URL.revokeObjectURL(fileURL);
                this.setState({ loading: false });
                //window.open(fileURL);
            })
            .catch(error => {
                this.setState({ loading: false });
                console.log(error);
            });
    }

    render() {
        const header = (
            <div className="table-header">
                <h5 className="p-m-0">Listagem</h5>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Localizar..." />
                </span>
            </div>
        );

        const dialogFooter = (
            <React.Fragment>
                <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={this.save} />
            </React.Fragment>
        );

        const deleteDialogFooter = (
            <React.Fragment>
                <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteDialog} />
                <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={this.delete} />
            </React.Fragment>
        );

        return (
            <div>
                <LoadingScreen
                    loading={this.state.loading}
                    bgColor='#f1f1f1'
                    spinnerColor='#9ee5f8'
                    textColor='#676767'
                    logoSrc='assets/rota/RotaSolarLogo.png'
                    text='Aguarde...'
                >
                    <div className=" datatable-crud-demo">
                        <Toast ref={(el) => this.toast = el} />
                        <Toolbar className="p-mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
                        <DataTable
                            className="p-datatable-gridlines p-datatable-sm"
                            ref={(el) => this.dt = el}
                            value={this.state.listaContratos}
                            paginator={this.exibirPaginacao()}
                            rows={10}
                            rowsPerPageOptions={[5, 10, 25]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Exibindo {first} até {last} de {totalRecords} Parâmetros"
                            globalFilter={this.state.globalFilter}
                            header={header}>
                            <Column field="contrato" header="Contrato" ></Column>
                            <Column field="logradouro" header="Logradouro" ></Column>
                            <Column field="bairro" header="Bairro" ></Column>
                            <Column field="cidade" header="Cidade" ></Column>
                            <Column field="uf" header="UF" style={{ width: '30px' }}></Column>
                            <Column field="cep" header="CEP" style={{ width: '80px' }}></Column>
                            <Column field="tipo" header="Und. Cons." body={this.unidConsumidoraBodyTemplate} style={{ width: '100px' }}></Column>
                            <Column field="porcentagem" header="%" body={(rowData) => this.formatarNumero(rowData, 'porcentagem')} style={{ width: '60px' }}></Column>
                            <Column body={this.actionBodyTemplate} style={{ width: '90px' }}></Column>
                        </DataTable>

                        <Dialog visible={this.state.editDialog} style={{ width: '550px' }} header="Lista de Rateio" modal className="p-fluid" footer={dialogFooter}
                            onHide={this.hideDialog}>
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-8">
                                    <label htmlFor="contrato">Contrato</label>
                                    <InputText id="contrato" value={this.state.contrato.contrato} onChange={(e) => this.onInputChange(e, 'contrato')}
                                        required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.contrato.contrato })}
                                    />
                                    {this.state.submitted && !this.state.contrato.contrato && <small className="p-invalid">O contrato é requerido.</small>}
                                </div>
                                <div className="p-field p-col-4">
                                    <label htmlFor="porcentagem">Porcentagem</label>
                                    <InputNumber id="porcentagem" value={this.state.contrato.porcentagem} onValueChange={(e) => this.onInputNumberChange(e, 'porcentagem')}
                                        mode="decimal" locale="pt-BR" minFractionDigits={2}
                                        required autoFocus
                                        className={classNames({ 'p-invalid': (this.state.submitted && !this.validarPorcentagem()) || (this.state.submitted && !this.validarTotalPorcentagem()) })}
                                    />
                                    {this.state.submitted && !this.validarPorcentagem() && <small className="p-invalid">A porcentagem é requerida.</small>}
                                    {this.state.submitted && !this.validarTotalPorcentagem() && <small className="p-invalid">A soma do percentual ultrapassa 100%.</small>}
                                </div>
                                <div className="p-field p-col-12">
                                    <label htmlFor="logradouro">Logradouro</label>
                                    <InputText id="logradouro" value={this.state.contrato.logradouro} onChange={(e) => this.onInputChange(e, 'logradouro')}
                                        required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.contrato.logradouro })}
                                    />
                                    {this.state.submitted && !this.state.contrato.logradouro && <small className="p-invalid">O logradouro é requerido.</small>}
                                </div>

                                <div className="p-field p-col-2">
                                    <label htmlFor="idestado">UF</label>
                                    <Dropdown inputId="idestado" optionValue="idestado" optionLabel="uf"
                                        value={this.state.contrato.idestado} options={this.state.estados}
                                        onChange={(e) => this.onEstadoChange(e)} placeholder="Selecione a UF"
                                        required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.contrato.idestado })} />
                                    {this.state.submitted && !this.state.contrato.idestado && <small className="p-invalid">UF é requerido.</small>}
                                </div>

                                <div className="p-field p-col-10">
                                    <label htmlFor="idcidade">Cidade</label>
                                    <Dropdown inputId="idcidade" optionValue="idcidade" optionLabel="cidade" value={this.state.contrato.idcidade} options={this.state.cidades} onChange={(e) => this.onCidadeChange(e)} placeholder="Selecione a cidade"
                                        disabled={!this.state.contrato.idestado}
                                        required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.contrato.idcidade })} />
                                    {this.state.submitted && !this.state.contrato.idcidade && <small className="p-invalid">Cidade é requerido.</small>}
                                </div>

                                <div className="p-field p-col-8">
                                    <label htmlFor="bairro">Bairro</label>
                                    <InputText id="bairro" value={this.state.contrato.bairro} onChange={(e) => this.onInputChange(e, 'bairro')}
                                        required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.contrato.bairro })}
                                    />
                                    {this.state.submitted && !this.state.contrato.bairro && <small className="p-invalid">O bairro é requerido.</small>}
                                </div>
                                <div className="p-field p-col-4">
                                    <label htmlFor="cep">CEP</label>
                                    <div className="p-inputgroup">
                                        <InputMask id="cep" value={this.state.contrato.cep} onChange={(e) => this.onInputMaskChange(e, 'cep')}
                                            mask="99.999-999"
                                            required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.contrato.cep })}
                                        />
                                        <Button icon="pi pi-search" className="p-button" onClick={this.consultaCEP} />
                                    </div>
                                    {this.state.submitted && !this.state.contrato.cep && <small className="p-invalid">O cep é requerido.</small>}
                                </div>

                                <div className="p-field p-col-6">
                                    <div className="p-mb-2">Unidade Consumidora</div>
                                    <div className="p-field-radiobutton">
                                        <RadioButton inputId="geradora" name="unidconsumidora" value="g"
                                            onChange={(e) => this.onUnidConsumidoraChange(e)}
                                            checked={this.state.contrato.tipo === 'g'} />
                                        <label htmlFor="geradora">Geradora</label>
                                    </div>
                                    <div className="p-field-radiobutton">
                                        <RadioButton inputId="compensacao" name="unidconsumidora" value="c"
                                            onChange={(e) => this.onUnidConsumidoraChange(e)}
                                            checked={this.state.contrato.tipo === 'c'} />
                                        <label htmlFor="compensacao">Compensação</label>
                                    </div>
                                </div>
                            </div>
                        </Dialog>

                        <Dialog visible={this.state.deleteDialog} style={{ width: '450px' }} header="Confirme" modal footer={deleteDialogFooter}
                            onHide={this.hideDeleteDialog}>
                            <div className="confirmation-content">
                                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                                {this.state.contrato && <span>Tem certeza que deseja excluir <b>{this.state.contrato.contrato}</b>?</span>}
                            </div>
                        </Dialog>
                    </div>
                </LoadingScreen>
            </div>
        )
    }
}
