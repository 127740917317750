import React, { useState, useContext } from 'react';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { UserContext } from './context';
import { useHistory } from "react-router-dom";

export const AppProfile = () => {

    const [expanded, setExpanded] = useState(false);
    const context = useContext(UserContext);
    const history = useHistory();

    const onClick = (event) => {
        setExpanded(prevState => !prevState);
        event.preventDefault();
    }
    const logout = () => {
        sessionStorage.removeItem('rotatoken');
        sessionStorage.removeItem('idusuario');
        sessionStorage.removeItem('usuario');
        context.alterLogin(false);
        window.location.reload();
    }

    const perfil = () => {
        history.push('/perfil');
    }

    return (
        <div className="layout-profile">
            <div>
                <img src="assets/layout/images/avatar_2.png" alt="Profile" />
            </div>
            <button className="p-link layout-profile-link" onClick={onClick}>
                <span className="username">{sessionStorage.getItem("usuario")}</span>
                <i className="pi pi-fw pi-cog" />
            </button>
            <CSSTransition classNames="p-toggleable-content" timeout={{ enter: 1000, exit: 450 }} in={expanded} unmountOnExit>
                <ul className={classNames({ 'layout-profile-expanded': expanded })}>
                    <li><button type="button" className="p-link" onClick={perfil}><i className="pi pi-fw pi-user" /><span>Conta</span></button></li>
                    {/* <li><button type="button" className="p-link"><i className="pi pi-fw pi-inbox" /><span>Notifications</span><span className="menuitem-badge">2</span></button></li> */}
                    <li><button type="button" className="p-link" onClick={logout}><i className="pi pi-fw pi-power-off" /><span>Logout</span></button></li>
                </ul>
            </CSSTransition>
        </div>
    );

}
