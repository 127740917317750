import React, { Component } from 'react';
import api from '../../../service/api';
import classNames from 'classnames';
import { Button } from '../../../components/button/Button';
import { Dialog } from '../../../components/dialog/Dialog';
import { DataTable } from '../../../components/datatable/DataTable';
import { Column } from '../../../components/column/Column'
import { InputText } from '../../../components/inputtext/InputText';
import { Toast } from '../../../components/toast/Toast';
import { Toolbar } from '../../../components/toolbar/Toolbar';
import { InputNumber } from '../../../components/inputnumber/InputNumber';

export class Prazos extends Component {

    emptyPrazos = {
        "id": null,
        "descricao": '',
        "dias": 0,
    }

    constructor(props) {
        super(props);

        this.state = {
            prazo: this.emptyPrazos,
            listaPrazos: [],
            submitted: false,
            edicao: false,
            editDialog: false,
            deleteDialog: false,
            rows: 10,
            first: 1,
        }

        this.originalRows = {};

        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.openNew = this.openNew.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.save = this.save.bind(this);
        this.edit = this.edit.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.hideDeleteDialog = this.hideDeleteDialog.bind(this);
        this.delete = this.delete.bind(this);
        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.rightToolbarTemplate = this.rightToolbarTemplate.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
    }

    async componentDidMount() {
        const listaPrazos = await api.get(`/prazos`);
        this.setState({
            listaPrazos: listaPrazos.data
        });
    }

    hideDeleteDialog() {
        this.setState({ deleteDialog: false });
    }

    confirmDelete(prazo) {
        this.setState({
            prazo,
            deleteDialog: true
        });
    }

    delete() {
        api.delete(`/prazos/${this.state.prazo.id}`)
            .then(res => {
                if (res.status === 204) {
                    let listaPrazos = this.state.listaPrazos.filter(val => val.id !== this.state.prazo.id);
                    this.setState({
                        listaPrazos,
                        deleteDialog: false,
                        prazo: this.emptyPrazos
                    });
                    this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Prazo excluído', life: 3000 });
                }
            })
            .catch(error => {
                this.setState({deleteDialog: false})
                this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu excluir o Prazo', life: 3000 });
                // console.log(error.response.data.description);
            })
    }

    edit(prazo) {
        this.setState({
            prazo: { ...prazo },
            editDialog: true
        });
    }

    openNew() {
        this.setState({
            prazo: this.emptyPrazos,
            submitted: false,
            editDialog: true
        });
    }

    hideDialog() {
        this.setState({
            submitted: false,
            editDialog: false
        });
    }

    async save() {
        let state = { submitted: true };

        if (this.state.prazo.descricao.trim() && this.state.prazo.dias !== null && this.state.prazo.dias > 0) {
            let listaPrazos = [...this.state.listaPrazos];
            let prazo = { ...this.state.prazo };
            if (this.state.prazo.id) {
                const index = this.findIndexById(this.state.prazo.id);
                api.put(`/prazos/${this.state.prazo.id}`, prazo)
                    .then(res => {
                        if (res.status === 204) {
                            listaPrazos[index] = prazo;
                            state = {
                                ...state,
                                listaPrazos,
                                editDialog: false,
                                prazo: this.emptyPrazos
                            };
                            this.setState(state);
                            this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Prazo atualizado', life: 3000 });
                        }
                    })
                    .catch(error => {
                        this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu atualizar o Prazo', life: 3000 });
                        // console.log(error.response.data.description);
                    })
            }
            else {
                prazo.id = 0;
                prazo.idnegociodimensionamento = this.props.idDimensionamento;
                api.post('/prazos', prazo)
                    .then(res => {
                        if (res.status === 201) {
                            prazo.id = res.headers.id;
                            listaPrazos.push(prazo);
                            state = {
                                ...state,
                                listaPrazos,
                                editDialog: false,
                                prazo: this.emptyPrazos
                            };
                            this.setState(state);
                            this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Prazo inserido', life: 3000 });
                        }
                    })
                    .catch(error => {
                        this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu inserir o Prazo', life: 3000 });
                        // console.log(error.response.data.description);
                    })
            }
        }
        else {
            this.setState(state);
        }
    }

    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-rounded p-mr-2" onClick={() => this.edit(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => this.confirmDelete(rowData)} />
            </React.Fragment>
        );
    }

    onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        const input = e.target;
        const start = input.selectionStart;
        const end = input.selectionEnd;
        let prazo = { ...this.state.prazo };
        prazo[`${name}`] = val.toUpperCase();
        this.setState({ prazo: prazo },
            () => input.setSelectionRange(start, end));
    }

    onInputNumberChange(e, name) {
        const val = e.value;
        let total = 0;
        let prazo = { ...this.state.prazo };
        prazo[`${name}`] = val;
        if ((prazo.valorunitario) && (prazo.quantidade)) {
            total = prazo.valorunitario * prazo.quantidade;
        }
        prazo.total = total;
        this.setState({ prazo: prazo });
    }

    findIndexById(id) {
        let index = -1;
        for (let i = 0; i < this.state.listaPrazos.length; i++) {
            if (this.state.listaPrazos[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    onUnidadeChange(e) {
        let prazo = { ...this.state.prazo };
        prazo.idund = e.value;
        this.setState({ prazo });
    }

    formatarNumero(rowData, field) {
        return rowData[field].toLocaleString('pt-BR', { style: 'decimal', minimumFractionDigits: 2 });
    }

    formatarCurrency(rowData, field) {
        return rowData[field].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 });
    }

    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="Novo" icon="pi pi-plus" className="p-button p-mr-2" onClick={this.openNew} />
            </React.Fragment>
        )
    }

    rightToolbarTemplate() {

    }

    onPageChange = (event) => {
        this.setState({rows : event.rows, first : event.first});
    }

    render() {
        const header = (
            <div className="table-header">
                <h5 className="p-m-0">Listagem</h5>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Localizar..." />
                </span>
            </div>
        );

        const dialogFooter = (
            <React.Fragment>
                <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={this.save} />
            </React.Fragment>
        );

        const deleteDialogFooter = (
            <React.Fragment>
                <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteDialog} />
                <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={this.delete} />
            </React.Fragment>
        );

        return (
            <div>
                <Toast ref={(el) => this.toast = el} />

                <Toolbar className="p-mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>

                <DataTable
                    className="p-datatable-gridlines p-datatable-sm"
                    ref={(el) => this.dt = el}
                    value={this.state.listaPrazos}
                    paginator={this.state.listaPrazos.length > 5}
                    rows={10}
                    // first={this.state.first}
                    rowsPerPageOptions={[5, 10, 25]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Exibindo {first} até {last} de {totalRecords} Parâmetros"
                    globalFilter={this.state.globalFilter}
                    header={header}
                    // onPage={event => this.onPageChange(event)}
                    >
                    <Column field="id" header="Id" style={{ width: '6%' }}></Column>
                    <Column field="descricao" header="Descrição" ></Column>
                    <Column field="dias" header="Dias" style={{ width: '10%' }}></Column>
                    <Column body={this.actionBodyTemplate} style={{ width: '15%' }}></Column>
                </DataTable>

                <Dialog visible={this.state.editDialog} style={{ width: '550px' }} header="Prazos" modal className="p-fluid" footer={dialogFooter}
                    onHide={this.hideDialog}>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12">
                            <label htmlFor="descricao">Descrição</label>
                            <InputText id="descricao" value={this.state.prazo.descricao} onChange={(e) => this.onInputChange(e, 'descricao')}
                                required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.prazo.descricao })} 
                                 />
                            {this.state.submitted && !this.state.prazo.descricao && <small className="p-invalid">Descrição é requerido.</small>}
                        </div>
                        <div className="p-field p-col-12">
                            <label htmlFor="dias">Qtd. Dias</label>
                            <InputNumber id="dias" value={this.state.prazo.dias} onValueChange={(e) => this.onInputNumberChange(e, 'dias')}
                                required className={classNames({ 'p-invalid': this.state.submitted && (this.state.prazo.dias === null || this.state.prazo.dias < 0) })} 
                                 />
                            {this.state.submitted && (this.state.prazo.dias === null || this.state.prazo.dias < 0) && <small className="p-invalid">Qtd. Dias é requerido e deve ser maior que zero.</small>}
                        </div>
                    </div>
                </Dialog>

                <Dialog visible={this.state.deleteDialog} style={{ width: '450px' }} header="Confirme" modal footer={deleteDialogFooter}
                    onHide={this.hideDeleteDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        {this.state.prazo && <span>Tem certeza que deseja excluir <b>{this.state.prazo.descricao}</b>?</span>}
                    </div>
                </Dialog>
            </div>
        )
    }
}
