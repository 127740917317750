import React, { useState, useEffect } from 'react';
import { Panel } from 'primereact/panel';
import { Fieldset } from 'primereact/fieldset';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Chart } from 'primereact/chart';
import { ProgressBar } from 'primereact/progressbar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FullCalendar } from 'primereact/fullcalendar';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { ProductService } from '../../service/ProductService';
import { Knob } from 'primereact/knob';
import { EventService } from '../../service/EventService';

const dropdownCities = [
    { name: 'New York', code: 'NY' },
    { name: 'Rome', code: 'RM' },
    { name: 'London', code: 'LDN' },
    { name: 'Istanbul', code: 'IST' },
    { name: 'Paris', code: 'PRS' }
];

const options = {
    plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
    defaultDate: '2023-01-01',
    header: {
        left: 'prev,next',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay'
    },
    editable: true
};

const lineData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
        {
            label: 'First Dataset',
            data: [65, 59, 80, 81, 56, 55, 40],
            fill: false,
            backgroundColor: '#2f4860',
            borderColor: '#2f4860'
        },
        {
            label: 'Second Dataset',
            data: [28, 48, 40, 19, 86, 27, 90],
            fill: false,
            backgroundColor: '#00bb7e',
            borderColor: '#00bb7e'
        }
    ]
};

const chartData = {
    labels: ['A', 'B', 'C', 'D', 'E'],
    datasets: [
        {
            data: [300, 50, 100, 200, 230],
            backgroundColor: [
                "#FF6384",
                "#36A2EB",
                "#FFCE56",
                "#FF6384",
                "#36A2EB"
            ],
            hoverBackgroundColor: [
                "#FF6384",
                "#36A2EB",
                "#FFCE56",
                "#FF6384",
                "#36A2EB"
            ]
        }]
};

const chartData2 = {
    labels: ['Fornecedor', 'Empresa'],
    datasets: [
        {
            data: [250, 50],
            backgroundColor: [
                "#FF6384",
                "#36A2EB",
                "#FFCE56",
                "#FF6384",
                "#36A2EB"
            ],
            hoverBackgroundColor: [
                "#FF6384",
                "#36A2EB",
                "#FFCE56",
                "#FF6384",
                "#36A2EB"
            ]
        }]
};

const chartData3 = {
    labels: ['Inversor', 'Microinversor'],
    datasets: [
        {
            data: [25, 50],
            backgroundColor: [
                "#FF6384",
                "#36A2EB",
                "#FFCE56",
                "#FF6384",
                "#36A2EB"
            ],
            hoverBackgroundColor: [
                "#FF6384",
                "#36A2EB",
                "#FFCE56",
                "#FF6384",
                "#36A2EB"
            ]
        }]
};

const PageTest2 = () => {

    const [tasksCheckbox, setTasksCheckbox] = useState([]);
    const [dropdownCity, setDropdownCity] = useState(null);
    const [events, setEvents] = useState(null);
    const [products, setProducts] = useState(null);

    useEffect(() => {
        const productService = new ProductService();
        const eventService = new EventService();
        productService.getProductsSmall().then(data => setProducts(data));
        eventService.getEvents().then(data => setEvents(data));
    }, []);

    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    };

    const onTaskCheckboxChange = (e) => {
        let selectedTasks = [...tasksCheckbox];
        if (e.checked)
            selectedTasks.push(e.value);
        else
            selectedTasks.splice(selectedTasks.indexOf(e.value), 1);

        setTasksCheckbox(selectedTasks);
    };

    // const getLightTheme = () => {
    //     let basicOptions = {
    //         maintainAspectRatio: false,
    //         aspectRatio: .8,
    //         plugins: {
    //             legend: {
    //                 labels: {
    //                     color: '#495057'
    //                 }
    //             }
    //         },
    //         scales: {
    //             x: {
    //                 ticks: {
    //                     color: '#495057'
    //                 },
    //                 grid: {
    //                     color: '#ebedef'
    //                 }
    //             },
    //             y: {
    //                 ticks: {
    //                     color: '#495057'
    //                 },
    //                 grid: {
    //                     color: '#ebedef'
    //                 }
    //             }
    //         }
    //     };

    //     let horizontalOptions = {
    //         indexAxis: 'y',
    //         maintainAspectRatio: true,
    //         aspectRatio: .8,
    //         plugins: {
    //             title: {
    //                 display: true,
    //                 text: 'My Title',
    //                 font: {
    //                     size: 16
    //                 }
    //             },                
    //             legend: {
    //                 labels: {
    //                     color: '#495057',
    //                     position: 'bottom'
    //                 }
    //             },

    //         },
    //         scales: {
    //             x: {
    //                 ticks: {
    //                     color: '#495057'
    //                 },
    //                 grid: {
    //                     color: '#ebedef'
    //                 }
    //             },
    //             y: {
    //                 ticks: {
    //                     color: '#495057'
    //                 },
    //                 grid: {
    //                     color: '#ebedef'
    //                 }
    //             }
    //         }
    //     };

    //     let stackedOptions = {
    //         maintainAspectRatio: false,
    //         aspectRatio: .8,
    //         plugins: {
    //             tooltips: {
    //                 mode: 'index',
    //                 intersect: false
    //             },
    //             legend: {
    //                 labels: {
    //                     color: '#495057'
    //                 }
    //             }
    //         },
    //         scales: {
    //             x: {
    //                 stacked: true,
    //                 ticks: {
    //                     color: '#495057'
    //                 },
    //                 grid: {
    //                     color: '#ebedef'
    //                 }
    //             },
    //             y: {
    //                 stacked: true,
    //                 ticks: {
    //                     color: '#495057'
    //                 },
    //                 grid: {
    //                     color: '#ebedef'
    //                 }
    //             }
    //         }
    //     };

    //     let multiAxisOptions = {
    //         maintainAspectRatio: false,
    //         aspectRatio: .8,
    //         plugins: {
    //             legend: {
    //                 labels: {
    //                     color: '#495057'
    //                 }
    //             },
    //             tooltips: {
    //                 mode: 'index',
    //                 intersect: true
    //             }
    //         },
    //         scales: {
    //             x: {
    //                 ticks: {
    //                     color: '#495057'
    //                 },
    //                 grid: {
    //                     color: '#ebedef'
    //                 }
    //             },
    //             y: {
    //                 type: 'linear',
    //                 display: true,
    //                 position: 'left',
    //                 ticks: {
    //                     min: 0,
    //                     max: 100,
    //                     color: '#495057'
    //                 },
    //                 grid: {
    //                     color: '#ebedef'
    //                 }
    //             },
    //             y1: {
    //                 type: 'linear',
    //                 display: true,
    //                 position: 'right',
    //                 grid: {
    //                     drawOnChartArea: false,
    //                     color: '#ebedef'
    //                 },
    //                 ticks: {
    //                     min: 0,
    //                     max: 100,
    //                     color: '#495057'
    //                 }
    //             }
    //         }
    //     };

    //     return {
    //         basicOptions,
    //         horizontalOptions,
    //         stackedOptions,
    //         multiAxisOptions
    //     }
    // }

    // const { basicOptions, horizontalOptions, multiAxisOptions, stackedOptions } = getLightTheme();
    // const plugin = {
    //     id: 'custom_canvas_background_color',
    //     beforeDraw: (chart) => {
    //       const ctx = chart.canvas.getContext('2d');
    //       ctx.save();
    //       ctx.globalCompositeOperation = 'destination-over';
    //       ctx.fillStyle = 'lightGreen';
    //       ctx.fillRect(0, 0, chart.width, chart.height);
    //       ctx.restore();
    //     }
    //   };

    const horizontalOptions = {
        indexAxis: 'y',
        maintainAspectRatio: false,
        aspectRatio: .8,
        plugins: {
            title: {
                display: true,
                text: 'Custom Chart Title'
            }
        }
        // backgroundColor:'#FFFFFF',
        // plugins: [plugin]
        // plugins: {
        //     title: {
        //         display: true,
        //         text: 'título',
        //         font: {
        //             size: 16
        //         }
        //     },
        //     legend: {
        //         labels: {
        //             color: '#495057',
        //             position: 'bottom'
        //         }
        //     },

        // },
    }

    const basicData = {
        labels: ['Fornecedor 1', 'Fornecedor 2'],
        datasets: [
            {
                label: 'Fornecedores',
                backgroundColor: '#42A5F5',
                data: [65, 65]
            }

        ]
    };

    const basicData2 = {
        labels: ['Inversor', 'Micro Inversor'],
        datasets: [
            {
                label: 'Inversosres',
                backgroundColor: '#42A5F5',
                data: [65, 65]
            }

        ]
    };


    return (
        <div className="p-grid p-fluid dashboard">




            <div className="p-col-12 p-lg-12" >
                <Card style={{ height: '100%', backgroundColor: '#edf0f5' }} >
                    <div className='p-text-center p-mt-0' style={{ BackgroundColor: '#ffffff' }}>
                        <Panel>
                            <h3 className='p-mb-0'> Clientes e Negócios</h3>
                        </Panel>
                    </div>
                    <div className='p-grid p-fluid p-mt-4'>
                        <div className="p-col-4 p-grid p-fluid" >
                            <div className="p-col-12 p-lg-12" >
                                <Fieldset legend="Pessoa Física">
                                    <div className="p-col-12 p-lg-12 p-grid p-fluid" >
                                        <div className="p-col-12 p-lg-12 p-text-center" >
                                            <div className='p-grid p-fluid p-align-center vertical-container p-mt-0 p-mb-0 p-p-0'>

                                                <div className="p-col-12 p-lg-12 p-text-center p-mt-0 p-mb-0" >
                                                    <img src="assets/layout/images/man.png" alt="Profile" />
                                                </div>
                                                {/* <div className="p-col-6 p-lg-6 p-text-right" >
                                    <img src="assets/layout/images/line-chart.png" alt="Profile" />
                                </div>
                                <div className="p-col-6 p-lg-6 p-text-left p-mt-3 " >
                                    <h1> Geração</h1>
                                </div> */}
                                            </div>
                                        </div>
                                        <div className="p-col-12 p-lg-12" >
                                            <div className="card summary" style={{ backgroundColor: '#edf0f5' }}>
                                                <span className="title">Clientes</span>
                                                <span className="detail">(Total)</span>
                                                <span className="count purchases">82</span>
                                            </div>
                                        </div>
                                        <div className="p-col-12 p-lg-12">
                                            <div className="card summary" style={{ backgroundColor: '#edf0f5' }}>
                                                <span className="title">Negócios</span>
                                                <span className="detail">(Total)</span>
                                                <span className="count purchases">321</span>
                                            </div>
                                        </div>
                                    </div>
                                </Fieldset>
                            </div>

                            <div className="p-col-12 p-lg-12" >
                                <Fieldset legend="Pessoa Jurídica">
                                    <div className="p-col-12 p-lg-12 p-grid p-fluid" >
                                        <div className="p-col-12 p-lg-12 p-text-center" >
                                            <div className='p-grid p-fluid p-align-center vertical-container'>

                                                <div className="p-col-12 p-lg-12 p-text-center" >
                                                    <img src="assets/layout/images/suitcase.png" alt="Profile" />
                                                </div>
                                                {/* <div className="p-col-6 p-lg-6 p-text-right" >
                                    <img src="assets/layout/images/line-chart.png" alt="Profile" />
                                </div>
                                <div className="p-col-6 p-lg-6 p-text-left p-mt-3 " >
                                    <h1> Geração</h1>
                                </div> */}
                                            </div>
                                        </div>
                                        <div className="p-col-12 p-lg-12" >
                                            <div className="card summary" style={{ backgroundColor: '#edf0f5' }}>
                                                <span className="title">Clientes</span>
                                                <span className="detail">(Total)</span>
                                                <span className="count revenue">76</span>
                                            </div>
                                        </div>
                                        <div className="p-col-12 p-lg-12">
                                            <div className="card summary" style={{ backgroundColor: '#edf0f5' }}>
                                                <span className="title">Negócios</span>
                                                <span className="detail">(Total)</span>
                                                <span className="count revenue">123</span>
                                            </div>
                                        </div>
                                    </div>
                                </Fieldset>
                            </div>


                        </div>

                        <div className="p-col-8 p-grid vertical-container" >

                            <Chart type="doughnut" data={chartData} options={horizontalOptions} width='100%' />

                            {/* // p-align-stretch vertical-container p-mt-4" style={{ width: '75%' }} > */}

                            {/* <div className="p-col-12 p-grid p-fluid "> */}
                            {/* <div className="p-col-12 p-grid p-align-stretch vertical-container"> */}
                            {/* <div className="p-col-12 p-align-stretch">
                                    <div className="card">
                                        <Chart type="bar" data={chartData} options={horizontalOptions} />
                                    </div>
                                </div> */}



                            <div className="p-col-12 p-lg-12 p-col-align-end">
                                <Fieldset legend="Dimensionamento">
                                    <div className="p-col-12 p-lg-12 p-grid p-fluid" >
                                        <div className="p-col-12 p-lg-12 p-text-center" >
                                            <div className='p-grid p-fluid p-align-center vertical-container'>
                                                <div className="p-col-12 p-lg-12 p-text-center" >
                                                </div>

                                            </div>
                                        </div>
                                        <div className="p-col-6 p-lg-6" >
                                            <div className="card summary" style={{ backgroundColor: '#edf0f5' }}>
                                                <span className="title">Total</span>
                                                <span className="detail"></span>
                                                <span className="count visitors">648</span>
                                            </div>
                                        </div>
                                        <div className="p-col-6 p-lg-6">

                                            <div className="p-d-flex p-text-center p-mb-3">
                                                <h4 className="activity p-m-0 p-text-center">Conversão</h4>
                                            </div>
                                            <ProgressBar value={50} showValue={true} />

                                        </div>
                                    </div>
                                </Fieldset>
                            </div>
                            {/* </div> */}
                        </div>
                    </div>
                </Card>
            </div>


            <div className="p-col-12 p-lg-12" >
                <Card style={{ height: '100%', backgroundColor: '#edf0f5' }} >
                    <div className='p-text-center p-mt-0 p-mb-4' style={{ BackgroundColor: '#ffffff' }}>
                        <Panel>
                            <h3 className='p-mb-0'> Financeiro </h3>
                        </Panel>
                    </div>
                    <div className='p-grid p-fluid '>

                        <div className="p-col-6 p-grid vertical-container" >

                            <div className="p-col-12 p-lg-12 p-fluid">
                                <Panel header="Tipo de Instalação">
                                    <Chart type="bar" data={chartData} options={horizontalOptions} width='100%' height='390px' />
                                </Panel>
                            </div>
                            {/* <Chart type="bar" data={chartData} options={horizontalOptions} width='100%' /> */}

                            {/* // p-align-stretch vertical-container p-mt-4" style={{ width: '75%' }} > */}

                            {/* <div className="p-col-12 p-grid p-fluid "> */}
                            {/* <div className="p-col-12 p-grid p-align-stretch vertical-container"> */}
                            {/* <div className="p-col-12 p-align-stretch">
        <div className="card">
            <Chart type="bar" data={chartData} options={horizontalOptions} />
        </div>
    </div> */}


                            {/* </div> */}
                        </div>

                        <div className="p-col-3 p-grid p-fluid" >

                            <div className="p-col-12 p-lg-12"  >
                                <div className="card summary">
                                    <span className="title">Receita Bruta</span>
                                    <span className="detail"></span>
                                    <span className="count visitors">R$ 1.543.879,21</span>
                                </div>
                            </div>



                            <div className="p-col-12 p-lg-12" >
                                <Panel>
                                    <div className='p-text-center'>
                                        <h5> Margem de Contribuição (%)</h5>
                                        <Knob
                                            value={75} size={200} >
                                        </Knob>
                                    </div>
                                    <div className="p-col-12 p-lg-12"  >
                                        <div className="card summary">
                                            <span className="title">Total</span>
                                            <span className="detail"></span>
                                            <span className="count visitors">R$ 1.543.879,21</span>
                                        </div>
                                    </div>


                                </Panel>
                            </div>




                        </div>

                        <div className="p-col-3 p-grid p-fluid" >
                            <div className="p-col-12 p-lg-12" >
                                <Panel>

                                    <div className="p-col-12 p-lg-12 p-grid p-fluid" >
                                        <div className="p-col-12 p-lg-12 p-text-center" >
                                            <div className='p-grid p-fluid p-align-center vertical-container'>

                                                <div className="p-col-12 p-lg-12 p-text-center" >
                                                    <img src="assets/layout/images/profits.png" alt="Profile" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p-col-12 p-lg-12" >                                            

                                            <div className='p-text-center'>

                                                <Knob
                                                    value={75} size={200} >
                                                </Knob>
                                                <h5> Rentabilidade (%)</h5>
                                            </div>
                                        </div>

                                    </div>
                                </Panel>
                                <div className="p-col-12 p-lg-12"  >
                                    <div className="card summary">
                                        <span className="title">Receita Bruta</span>
                                        <span className="detail"></span>
                                        <span className="count visitors">R$ 1.543.879,21</span>
                                    </div>
                                </div>
                            </div>

                        </div>




                    </div>
                </Card>
            </div>




            <div className="p-col-12 p-lg-12 p-fluid">
                <Card style={{ height: '100%', backgroundColor: '#edf0f5' }}>
                    <div className='p-text-center' style={{ BackgroundColor: '#ffffff' }}>
                        <Panel>
                            <h3 className='p-mb-0'> Usinas</h3>
                        </Panel>
                    </div>
                    <div className="p-col-12 p-lg-12 p-grid p-fluid">
                        <div className="p-col-6 p-lg-6 p-fluid">
                            <Fieldset legend="Geração">
                                <div className="p-col-12 p-lg-12 p-grid p-fluid" >
                                    <div className="p-col-12 p-lg-12 p-text-center" >
                                        <div className='p-grid p-fluid p-align-center vertical-container'>

                                            <div className="p-col-12 p-lg-12 p-text-center" >
                                                <img src="assets/layout/images/solar-energy.png" alt="Profile" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-col-6 p-lg-6" >
                                        <div className="card summary" style={{ backgroundColor: '#edf0f5' }}>
                                            <span className="title">Média</span>
                                            <span className="detail">(em Kwp)</span>
                                            <span className="count visitors">1200</span>
                                        </div>
                                    </div>
                                    <div className="p-col-6 p-lg-6">
                                        <div className="card summary" style={{ backgroundColor: '#edf0f5' }}>
                                            <span className="title">Total</span>
                                            <span className="detail">(em Kh/p)</span>
                                            <span className="count visitors">4356,21</span>
                                        </div>
                                    </div>
                                </div>
                            </Fieldset>
                        </div>


                        <div className="p-col-6 p-lg-6 p-fluid">
                            <Fieldset legend="Potência">
                                <div className="p-col-12 p-lg-12 p-grid p-fluid" >
                                    <div className="p-col-12 p-lg-12 p-text-center" >
                                        <div className='p-grid p-fluid p-align-center vertical-container'>

                                            <div className="p-col-12 p-lg-12 p-text-center" >
                                                <img src="assets/layout/images/renewable-energy.png" alt="Profile" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-col-6 p-lg-6" >
                                        <div className="card summary" style={{ backgroundColor: '#edf0f5' }}>
                                            <span className="title">Média</span>
                                            <span className="detail">(em Kwp)</span>
                                            <span className="count purchases">1200</span>
                                        </div>
                                    </div>
                                    <div className="p-col-6 p-lg-6">
                                        <div className="card summary" style={{ backgroundColor: '#edf0f5' }}>
                                            <span className="title">Total</span>
                                            <span className="detail">(em Kh/p)</span>
                                            <span className="count purchases">4356,21</span>
                                        </div>
                                    </div>
                                </div>
                            </Fieldset>
                        </div>
                    </div>

                    <div className="p-col-12 p-lg-12 p-grid p-fluid">

                        <div className="p-col-3 p-lg-3 p-fluid">
                            <Panel header="Fornecedores">
                                <Chart type="doughnut" data={chartData2} options={horizontalOptions} width='100%' height='400' />
                            </Panel>
                        </div>
                        <div className="p-col-3 p-lg-3 p-fluid">
                            <Panel header="Inversores">
                                <Chart type="doughnut" data={chartData3} options={horizontalOptions} width='100%' height='400' />
                            </Panel>
                        </div>

                        <div className="p-col-6 p-lg-6 p-fluid">
                            <Panel header="Tipo de Instalação">
                                <Chart type="bar" data={chartData} options={horizontalOptions} width='100%' height='400' />
                            </Panel>
                        </div>


                    </div>


                </Card>
            </div>



        </div>
    );
}

export default PageTest2;