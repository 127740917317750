import axios from 'axios';

const token = sessionStorage.getItem("rotatoken");

const api = axios.create({baseURL : 'https://www.sistemaimperium.com.br/rotadmvc',
// const api = axios.create({baseURL : 'http://localhost:8081',
  headers: {"Authorization" : `Bearer ${token}`}
});

api.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      if (error.response) {
        if (error.response.status === 401) {
          // Do something, call refreshToken() request for example;
          // return a request      
          
          if (token){
            sessionStorage.removeItem("rotatoken");
          }
          window.location.reload();
          return api;
        }
        else{
        // if (error.response.status === ANOTHER_STATUS_CODE) {
          // Do something 
          return Promise.reject(error.response.data);
        }
      }
  
      return Promise.reject(error);
    }
  );

export default api;
