import React, { Component } from 'react';
import api from '../../service/api';
import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import { Button } from '../../components/button/Button';
import { Dialog } from '../../components/dialog/Dialog';
import { Dropdown } from '../../components/dropdown/Dropdown';
import { DataTable } from '../../components/datatable/DataTable';
import { Column } from '../../components/column/Column'
import { InputText } from '../../components/inputtext/InputText';
import { Toast } from '../../components/toast/Toast';
import { InputNumber } from '../../components/inputnumber/InputNumber';

export class MaterialACEdit extends Component {

    static defaultProps = {
        idDimensionamento: 1,
        idstatus: 1
    }

    static propTypes = {
        idDimensionamento: PropTypes.number,
        idstatus: PropTypes.number
    }

    emptyMaterialAC = {
        "id": null,
        "idnegociodimensionamento": null,
        "descricao": '',
        "quantidade": 0,
        "idund": null,
        "valorunitario": 0,
        "total": 0,
        "unidade": ''
    }

    atualizarTotal = () => {
        this.props.atualizarTotal(this.props.value);
    }

    constructor(props) {
        super(props);

        this.state = {
            materialAC: this.emptyMaterialAC,
            materiaisAC: [],
            unidades: [],
            submitted: false,
            edicao: false,
            materialACDialog: false,
            deleteMaterialACDialog: false
        }

        this.originalRows = {};

        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.openNewMaterialAC = this.openNewMaterialAC.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.saveMaterialAC = this.saveMaterialAC.bind(this);
        this.editMaterialAC = this.editMaterialAC.bind(this);
        this.deleteMaterialAC = this.deleteMaterialAC.bind(this);
        this.hideDeleteMaterialACDialog = this.hideDeleteMaterialACDialog.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onInputNumberChange = this.onInputNumberChange.bind(this);
    }

    async componentDidMount() {
        const materiaisAC = await api.get(`/materiaisac/dimensionamento/${this.props.idDimensionamento}`);
        const unidades = await api.get('/unidadesmedida');
        this.setState({
            materiaisAC: materiaisAC.data,
            unidades: unidades.data
        });
    }

    hideDeleteMaterialACDialog() {
        this.setState({ deleteMaterialACDialog: false });
    }

    editMaterialAC(materialAC) {
        this.setState({
            materialAC: { ...materialAC },
            materialACDialog: true
        });
    }

    openNewMaterialAC() {
        this.setState({
            materialAC: this.emptyMaterialAC,
            submitted: false,
            materialACDialog: true
        });
    }

    hideDialog() {
        this.setState({
            submitted: false,
            materialACDialog: false
        });
    }

    confirmDeleteMaterialAC(materialAC) {
        this.setState({
            materialAC,
            deleteMaterialACDialog: true
        });
    }

    deleteMaterialAC() {
        api.delete(`/materiaisac/${this.state.materialAC.id}`)
            .then(res => {
                if (res.status === 204) {
                    let materiaisAC = this.state.materiaisAC.filter(val => val.id !== this.state.materialAC.id);
                    this.setState({
                        materiaisAC,
                        deleteMaterialACDialog: false,
                        materialAC: this.emptyMaterialAC
                    });
                    this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Material excluído', life: 3000 });
                    this.atualizarTotal();
                }
            })
            .catch(error => {
                this.setState({ deleteMaterialACDialog: false });
                this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu excluir o Material AC', life: 3000 });
                // console.log(error.response.data.description);
            })
    }

    async saveMaterialAC() {
        let state = { submitted: true };
        if (this.state.materialAC.descricao.trim() && this.state.materialAC.valorunitario && this.state.materialAC.valorunitario > 0
            && this.state.materialAC.quantidade && this.state.materialAC.quantidade > 0 && this.state.materialAC.idund) {

            let materiaisAC = [...this.state.materiaisAC];
            let materialAC = { ...this.state.materialAC };
            if (this.state.materialAC.id) {
                const index = this.findIndexById(this.state.materialAC.id);
                api.put(`/materiaisac/${this.state.materialAC.id}`, materialAC)
                    .then(res => {
                        if (res.status === 204) {
                            materiaisAC[index] = materialAC;
                            state = {
                                ...state,
                                materiaisAC,
                                materialACDialog: false,
                                materialAC: this.emptyMaterialAC
                            };
                            this.setState(state);
                            this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Material AC atualizado', life: 3000 });
                            this.atualizarTotal();
                        }
                    })
                    .catch(error => {
                        this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu atualizar o Material AC', life: 3000 });
                        // console.log(error.response.data.description);
                    })
            }
            else {
                materialAC.id = 0;
                materialAC.idnegociodimensionamento = this.props.idDimensionamento;
                api.post('/materiaisac', materialAC)
                    .then(res => {
                        if (res.status === 201) {
                            materialAC.id = res.headers.id;
                            materiaisAC.push(materialAC);
                            state = {
                                ...state,
                                materiaisAC,
                                materialACDialog: false,
                                materialAC: this.emptyMaterialAC
                            };
                            this.setState(state);
                            this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Material AC inserido', life: 3000 });
                            this.atualizarTotal();
                        }
                    })
                    .catch(error => {
                        this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu inserir o Material AC', life: 3000 });
                        // console.log(error.response.data.description);
                    })
            }
        }
        else {
            this.setState(state)
        }
    }

    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button p-mr-2"
                    onClick={() => this.editMaterialAC(rowData)}
                    tooltip="Alterar"
                    tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} 
                    disabled={this.props.idstatus > 2} />
                <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger"
                    onClick={() => this.confirmDeleteMaterialAC(rowData)}
                    tooltip="Excluir"
                    tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} 
                    disabled={this.props.idstatus > 2} />
            </React.Fragment>
        );
    }

    onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        const input = e.target;
        const start = input.selectionStart;
        const end = input.selectionEnd;
        let materialAC = { ...this.state.materialAC };
        materialAC[`${name}`] = val.toUpperCase();
        this.setState({ materialAC: materialAC },
            () => input.setSelectionRange(start, end));
    }

    onInputNumberChange(e, name) {
        const val = e.value || 0;
        let total = 0;
        let materialAC = { ...this.state.materialAC };
        materialAC[`${name}`] = val;
        if ((materialAC.valorunitario) && (materialAC.quantidade)) {
            total = materialAC.valorunitario * materialAC.quantidade;
        }
        materialAC.total = total;
        console.log(materialAC.total);
        this.setState({ materialAC: materialAC });
    }

    findIndexById(id) {
        let index = -1;
        for (let i = 0; i < this.state.materiaisAC.length; i++) {
            if (this.state.materiaisAC[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    findUnidade(id) {
        let index = -1;
        for (let i = 0; i < this.state.unidades.length; i++) {
            if (this.state.unidades[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    onUnidadeChange(e) {
        let materialAC = { ...this.state.materialAC };
        materialAC.idund = e.value;
        materialAC.unidade = this.state.unidades[this.findUnidade(e.value)].unidade;
        this.setState({ materialAC : materialAC });
    }

    formatarNumero(rowData, field) {
        return rowData[field].toLocaleString('pt-BR', { style: 'decimal', minimumFractionDigits: 2 });
    }

    formatarCurrency(rowData, field) {
        return rowData[field].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 });
    }

    render() {
        const header = (
            <div className="table-header" left={this.busca}>
                <Button 
                    label="Novo" 
                    icon="pi pi-plus" 
                    className="p-button p-mr-2" 
                    onClick={this.openNewMaterialAC} 
                    disabled={this.props.idstatus > 2} />
            </div>
        );
        const MaterialACDialogFooter = (
            <React.Fragment>
                <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={this.saveMaterialAC} />
            </React.Fragment>
        );
        const deleteMaterialACDialogFooter = (
            <React.Fragment>
                <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteMaterialACDialog} />
                <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={this.deleteMaterialAC} />
            </React.Fragment>
        );
        return (

            <div>
                <Toast ref={(el) => this.toast = el} />
                <DataTable header={header} value={this.state.materiaisAC} className="p-datatable-sm p-datatable-gridlines">
                    <Column field="id" header="Id" style={{ width: '6%' }} />
                    <Column className="p-editable-column p-cell-editing" field="descricao" header="Descrição" style={{ width: '30%' }} />
                    <Column field="quantidade" header="Qtd." body={(rowData) => this.formatarNumero(rowData, 'quantidade')} style={{ width: '8%' }} />
                    <Column field="unidade" header="Und" style={{ width: '8%' }} />
                    <Column field="valorunitario" header="Valor" body={(rowData) => this.formatarCurrency(rowData, 'valorunitario')} style={{ width: '12%' }} />
                    <Column field="total" header="Total" body={(rowData) => this.formatarCurrency(rowData, 'total')} style={{ width: '12%' }} />
                    <Column body={this.actionBodyTemplate} style={{ width: '14%' }} ></Column>
                </DataTable>

                <Dialog visible={this.state.materialACDialog} style={{ width: '550px' }} header="Material AC" modal className="p-fluid" footer={MaterialACDialogFooter} onHide={this.hideDialog}>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12">
                            <label htmlFor="descricao">Descrição</label>
                            <InputText id="descricao" value={this.state.materialAC.descricao} onChange={(e) => this.onInputChange(e, 'descricao')}
                                required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.materialAC.descricao })} 
                                 />
                            {this.state.submitted && !this.state.materialAC.descricao && <small className="p-invalid">Descrição é requerido.</small>}
                        </div>
                        <div className="p-field p-col-12">
                            <label htmlFor="quantidade">Quantidade</label>
                            <InputNumber id="quantidade" value={this.state.materialAC.quantidade} onValueChange={(e) => this.onInputNumberChange(e, 'quantidade')}
                                required className={classNames({ 'p-invalid': this.state.submitted && !this.state.materialAC.quantidade })}
                                mode="decimal" locale="pt-BR" minFractionDigits={2}  />
                            {this.state.submitted && !this.state.materialAC.quantidade && <small className="p-invalid">Quantidade é requerido.</small>}
                        </div>
                        <div className="p-field p-col-12">
                            <label htmlFor="idund">Unidade</label>
                            <Dropdown inputId="idund" optionValue="id" optionLabel="unidade" value={this.state.materialAC.idund} options={this.state.unidades} onChange={(e) => this.onUnidadeChange(e)} placeholder="Unidade"
                                required className={classNames({ 'p-invalid': this.state.submitted && !this.state.materialAC.idund })} />
                            {this.state.submitted && !this.state.materialAC.idund && <small className="p-invalid">A unidade de medida é requerido.</small>}
                        </div>
                        <div className="p-field p-col-12">
                            <label htmlFor="valorunitario">Valor Unitário</label>
                            <InputNumber id="valorunitario" value={this.state.materialAC.valorunitario} onValueChange={(e) => this.onInputNumberChange(e, 'valorunitario')}
                                required className={classNames({ 'p-invalid': this.state.submitted && !this.state.materialAC.valorunitario })}
                                mode="decimal" locale="pt-BR" minFractionDigits={2} />
                            {this.state.submitted && !this.state.materialAC.valorunitario && <small className="p-invalid">Valor unitário é requerido.</small>}
                        </div>
                        <div className="p-field p-col-12">
                            <label htmlFor="total">Total</label>
                            <InputNumber inputStyle={{ color: 'blue' }} mode="currency" currency="BRL" locale="pt-BR" id="total" value={this.state.materialAC.total} minFractionDigits={2} disabled />
                        </div>
                    </div>
                </Dialog>

                <Dialog visible={this.state.deleteMaterialACDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteMaterialACDialogFooter}
                    onHide={this.hideDeleteMaterialACDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        {this.state.materialAC && <span>Tem certeza que deseja excluir <b>{this.state.materialAC.descricao}</b>?</span>}
                    </div>
                </Dialog>
            </div>
        )
    }
}
