import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Toast } from '../../components/toast/Toast';
import { Button } from '../../components/button/Button';
import { InputText } from '../../components/inputtext/InputText';
import api from '../../service/api'
import { PropTypes } from 'prop-types';
import { InputNumber } from '../../components/inputnumber/InputNumber';
import IntlCurrencyInput from "react-intl-currency-input"

const contaUnica = (props) => {

    let emptyConta = {
        'id': null,
        'contrato': '',
        'idnegocio': null,
        'valortarifa': 0.00,
        'consumominimo': 0.00,
        'consumojan': 0.00,
        'consumofev': 0.00,
        'consumomar': 0.00,
        'consumoabr': 0.00,
        'consumomai': 0.00,
        'consumojun': 0.00,
        'consumojul': 0.00,
        'consumoago': 0.00,
        'consumoset': 0.00,
        'consumoout': 0.00,
        'consumonov': 0.00,
        'consumodez': 0.00,
        'consumomedia': 0.00,
        'ajuste': 0.00
    };

    contaUnica.propTypes = {
        idnegocio: PropTypes.number,
        classificacao: PropTypes.string,
        idstatus: PropTypes.number,
    }

    const currencyConfig = {
        locale: "pt-BR",
        formats: {
          number: {
            BRL: {
              style: "currency",
              currency: "BRL",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            },
          },
        },
      };

    const { idnegocio = -1, classificacao = "M", idstatus = 1 } = props;

    const [Conta, setConta] = useState(emptyConta);
    const [ContaAux, setContaAux] = useState(emptyConta);
    const [submitted, setSubmitted] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);

    const handleChange = (event, value, maskedValue) => {
        event.preventDefault();
        // console.log(value); // value without mask (ex: 1234.56)
        // console.log(maskedValue); // masked value (ex: R$1234,56)

        let _Conta = { ...Conta };
        _Conta.consumojan = value;
        _Conta.consumomedia = getMediaConsumo1(_Conta);
        setConta(_Conta);

      };

  
    useEffect(() => {

        api.get(`/contas/negocio/${idnegocio}/top/1`)
            .then(res => {
                if (res.status === 200) {
                    // if (typeof res.data[0].id !== "undefined") {
                    if (res.data.length > 0){
                        setConta(res.data[0]);
                        setContaAux(res.data[0]);
                    }
                    else {
                        let _Conta = { ...Conta };
                        let _ContaAux = { ...ContaAux };
                        let consumo = 0;
                        if (classificacao === "M") {
                            consumo = 30;
                        } else if (classificacao === "B") {
                            consumo = 50;
                        } else if (classificacao === "T") {
                            consumo = 100;
                        }
                        _Conta.consumominimo = consumo;
                        _ContaAux.consumominimo = consumo;
                        setConta(_Conta);
                        setContaAux(_ContaAux);
                    }
                }
            })
            .catch(error => {
                console.error(error.data);
            })
    },[idnegocio, classificacao, idstatus]);

    const getMediaConsumo = () => {
        let fator = 0;
        let total = 0;
        //let ajustePercentual = Conta.ajuste / 100;
        fator = Conta.consumojan > 0 ? fator + 1 : fator;
        fator = Conta.consumofev > 0 ? fator + 1 : fator;
        fator = Conta.consumomar > 0 ? fator + 1 : fator;
        fator = Conta.consumoabr > 0 ? fator + 1 : fator;
        fator = Conta.consumomai > 0 ? fator + 1 : fator;
        fator = Conta.consumojun > 0 ? fator + 1 : fator;
        fator = Conta.consumojul > 0 ? fator + 1 : fator;
        fator = Conta.consumoago > 0 ? fator + 1 : fator;
        fator = Conta.consumoset > 0 ? fator + 1 : fator;
        fator = Conta.consumoout > 0 ? fator + 1 : fator;
        fator = Conta.consumonov > 0 ? fator + 1 : fator;
        fator = Conta.consumodez > 0 ? fator + 1 : fator;

        total = Conta.consumojan + total;
        total = Conta.consumofev + total;
        total = Conta.consumomar + total;
        total = Conta.consumoabr + total;
        total = Conta.consumomai + total;
        total = Conta.consumojun + total;
        total = Conta.consumojul + total;
        total = Conta.consumoago + total;
        total = Conta.consumoset + total;
        total = Conta.consumoout + total;
        total = Conta.consumonov + total;
        total = Conta.consumodez + total;

        let media = fator > 0 ? total / fator : 0;
        media = media - Conta.consumominimo + Conta.ajuste;
        return media;
        // return media.toFixed();
        // media = media * ajustePercentual + media
        // return media - Conta.consumominimo;
    }

    const formatarNumero = (rowData, field) => {
        return rowData[field].toLocaleString('pt-BR', { style: 'decimal', minimumFractionDigits: 2 });
    }

    const formatarCurrency = (rowData, field) => {
        return rowData[field].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 });
    }

    const validarConta = () => {
        return (
            Conta.valortarifa !== null && Conta.valortarifa > 0 &&
            Conta.consumominimo !== null &&
            getMediaConsumo() > 0 &&
            Conta.contrato.trim() !== '' &&
            Conta.ajuste !== null &&
            Conta.consumojan !== null && Conta.consumojan >= 0 &&
            Conta.consumofev !== null && Conta.consumofev >= 0 &&
            Conta.consumomar !== null && Conta.consumomar >= 0 &&
            Conta.consumoabr !== null && Conta.consumoabr >= 0 &&
            Conta.consumomai !== null && Conta.consumomai >= 0 &&
            Conta.consumojun !== null && Conta.consumojun >= 0 &&
            Conta.consumojul !== null && Conta.consumojul >= 0 &&
            Conta.consumoago !== null && Conta.consumoago >= 0 &&
            Conta.consumoset !== null && Conta.consumoset >= 0 &&
            Conta.consumoout !== null && Conta.consumoout >= 0 &&
            Conta.consumonov !== null && Conta.consumonov >= 0 &&
            Conta.consumodez !== null && Conta.consumodez >= 0
        )
    }

    const saveConta = () => {
        if (validarConta() === true) {
            let _Conta = { ...Conta };
            _Conta.consumomedia = getMediaConsumo();
            console.log(_Conta);
            if (_Conta.id) {
                api.put(`/contas/${_Conta.id}`, _Conta)
                    .then(res => {
                        if (res.status === 204) {
                            setSubmitted(false);
                            setContaAux(_Conta);
                            toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Conta atualizada', life: 3000 });
                        }
                    })
                    .catch(error => {
                        toast.current.show({ severity: 'error', summary: 'Erro', detail: `O sistema não conseguiu atualizar a conta ${_Conta.contrato}`, life: 3000 });
                        // console.error('Error: ' + error.data);
                    })
            }
            else {
                _Conta.id = 0;
                _Conta.idnegocio = idnegocio;
                api.post('/contas', _Conta)
                    .then(res => {
                        if (res.status === 201) {
                            _Conta.id = res.headers.id;
                            setConta(_Conta);
                            setSubmitted(false);
                            setContaAux(_Conta);
                        }
                    })
                    .catch(error => {
                        toast.current.show({ severity: 'error', summary: 'Erro', detail: `O sistema não conseguiu inserir a conta ${_Conta.contrato}`, life: 3000 });
                        //console.error(error.data);
                    })
            }
        }
        else {
            setSubmitted(true);
        }
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        const input = e.target;
        const start = input.selectionStart;
        const end = input.selectionEnd;
        let _Conta = { ...Conta };
        _Conta[`${name}`] = val;
        setConta(_Conta);
        input.setSelectionRange(start, end);
    }

    const getMediaConsumo1 = (Conta) => {
        let fator = 0;
        let total = 0;
        fator = Conta.consumojan > 0 ? fator + 1 : fator;
        fator = Conta.consumofev > 0 ? fator + 1 : fator;
        fator = Conta.consumomar > 0 ? fator + 1 : fator;
        fator = Conta.consumoabr > 0 ? fator + 1 : fator;
        fator = Conta.consumomai > 0 ? fator + 1 : fator;
        fator = Conta.consumojun > 0 ? fator + 1 : fator;
        fator = Conta.consumojul > 0 ? fator + 1 : fator;
        fator = Conta.consumoago > 0 ? fator + 1 : fator;
        fator = Conta.consumoset > 0 ? fator + 1 : fator;
        fator = Conta.consumoout > 0 ? fator + 1 : fator;
        fator = Conta.consumonov > 0 ? fator + 1 : fator;
        fator = Conta.consumodez > 0 ? fator + 1 : fator;

        total = Conta.consumojan + total;
        total = Conta.consumofev + total;
        total = Conta.consumomar + total;
        total = Conta.consumoabr + total;
        total = Conta.consumomai + total;
        total = Conta.consumojun + total;
        total = Conta.consumojul + total;
        total = Conta.consumoago + total;
        total = Conta.consumoset + total;
        total = Conta.consumoout + total;
        total = Conta.consumonov + total;
        total = Conta.consumodez + total;

        let media = fator > 0 ? total / fator : 0;
        media = media - Conta.consumominimo + Conta.ajuste;
        // return media.toFixed();
        return media;
    }

    const onInputNumberChange = (e, name) => {
        const val = e.value; // || 0;
        let _Conta = { ...Conta };
        _Conta[`${name}`] = val;
        _Conta.consumomedia = getMediaConsumo1(_Conta);
        setConta(_Conta);
    }

    const onFocus = (event) => {
        event.target.select();
    }

    const getFooter = () => {
        if (JSON.stringify(Conta) !== JSON.stringify(ContaAux)) {
            return (
                <React.Fragment>
                    <Button label="Salvar" icon="pi pi-check" className="p-button p-mr-3"
                        onClick={saveConta} tabIndex={16}
                        badge=" " badgeClassName="p-badge-danger"
                        style={{ width: '100px' }} disabled={idstatus > 2} />
                </React.Fragment>
            )
        }
        else {
            return (
                <React.Fragment>
                    <Button label="Salvar" icon="pi pi-check" className="p-button p-mr-3"
                        onClick={saveConta} tabIndex={16}
                        style={{ width: '100px' }} disabled={idstatus > 2} />
                </React.Fragment>
            )
        }
    }

    const Mensagem = 'Consumo é reqerido e não pode ser negativo';

    return (
        <div>
            <Toast ref={toast} />
            <div className="p-fluid p-formgrid p-grid border border-secondary rounder"  >

                <div className="p-field p-col-12 p-md-4">
                    <label htmlFor="contrato">Contrato</label>
                    <InputText id="contrato" value={Conta.contrato} onChange={(e) => onInputChange(e, 'contrato')}
                        required autoFocus className={classNames({ 'p-invalid': submitted && !Conta.contrato })}
                        tabIndex={1} disabled={idstatus > 2} />
                    {submitted && !Conta.contrato && <small className="p-invalid">Contrato é requerido.</small>}
                </div>
                <div className="p-field p-col-12 p-md-4">
                    <label htmlFor="valortarifa">Tarifa kWh</label>
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">R$</span>
                        <InputNumber id="valortarifa" value={Conta.valortarifa} onValueChange={(e) => onInputNumberChange(e, 'valortarifa')}
                            required autoFocus className={classNames({ 'p-invalid': submitted && (!Conta.valortarifa || Conta.valortarifa <= 0) })}
                            mode="decimal" locale="pt-BR" minFractionDigits={2}
                            //mode="currency" currency="BRL" locale="pt-BR"
                            tabIndex={2} disabled={idstatus > 2} />
                    </div>
                    {submitted && (!Conta.valortarifa || Conta.valortarifa <= 0) && <small className="p-invalid">O valor da taifa é requerido e deve ser maior que zero.</small>}
                </div>
                <div className="p-field p-col-12 p-md-4">
                    <label htmlFor="consumominimo">Consumo Mín.</label>
                    <InputNumber id="consumominimo" value={Conta.consumominimo} onValueChange={(e) => onInputNumberChange(e, 'consumominimo')}
                        required autoFocus className={classNames({ 'p-invalid': submitted && (Conta.consumominimo === null || Conta.consumominimo < 0) })}
                        mode="decimal" locale="pt-BR" minFractionDigits={2} 
                        tabIndex={3} disabled />
                    {submitted && (Conta.consumominimo === null || Conta.consumominimo < 0) && <small className="p-invalid">{Mensagem}</small>}
                </div>
            </div>

            <div className="p-col-12">
                <table className="tabela-div-horizontais" >
                    <tbody>
                        <tr>
                            <td>
                                Jan
                            </td>
                            <td>
                                <div className="p-inputgroup">
                                    <InputNumber id="consumojan" value={Conta.consumojan} onValueChange={(e) => onInputNumberChange(e, 'consumojan')}
                                        required autoFocus className={classNames({ 'p-invalid': submitted && (Conta.consumojan === null || Conta.consumojan < 0) })}
                                        mode="decimal" locale="pt-BR" minFractionDigits={2} tabIndex={4} disabled={idstatus > 2}
                                    />
                                    <span className="p-inputgroup-addon">kWh</span>
                                </div>

                                {/* <IntlCurrencyInput className="input" value={Conta.consumojan} currency="BRL" config={currencyConfig}
                                    onChange={handleChange} /> */}

                                {submitted && (Conta.consumojan === null || Conta.consumojan < 0) && <small className="p-invalid">{Mensagem}</small>}
                            </td>
                            <td>
                                Jul
                            </td>
                            <td>
                                <div className="p-inputgroup">
                                    <InputNumber id="consumojul" value={Conta.consumojul} onValueChange={(e) => onInputNumberChange(e, 'consumojul')}
                                        required autoFocus className={classNames({ 'p-invalid': submitted && (Conta.consumojul === null || Conta.consumojul < 0) })}
                                        mode="decimal" locale="pt-BR" minFractionDigits={2} tabIndex={10} disabled={idstatus > 2} />
                                    <span className="p-inputgroup-addon">kWh</span>
                                </div>
                                {submitted && (Conta.consumojul === null || Conta.consumojul < 0) && <small className="p-invalid">{Mensagem}</small>}

                            </td>
                        </tr>

                        <tr>
                            <td>
                                Fev
                            </td>
                            <td>
                                <div className="p-inputgroup">
                                    <InputNumber id="consumofev" value={Conta.consumofev} onValueChange={(e) => onInputNumberChange(e, 'consumofev')}
                                        required autoFocus className={classNames({ 'p-invalid': submitted && (Conta.consumofev === null || Conta.consumofev < 0) })}
                                        mode="decimal" locale="pt-BR" minFractionDigits={2} tabIndex={5} disabled={idstatus > 2} />
                                    <span className="p-inputgroup-addon">kWh</span>
                                </div>
                                {submitted && (Conta.consumofev === null || Conta.consumofev < 0) && <small className="p-invalid">{Mensagem}</small>}
                            </td>
                            <td>
                                Ago
                            </td>
                            <td>
                                <div className="p-inputgroup">
                                    <InputNumber id="consumoago" value={Conta.consumoago} onValueChange={(e) => onInputNumberChange(e, 'consumoago')}
                                        required autoFocus className={classNames({ 'p-invalid': submitted && (Conta.consumoago === null || Conta.consumoago < 0) })}
                                        mode="decimal" locale="pt-BR" minFractionDigits={2} tabIndex={11} disabled={idstatus > 2} />
                                    <span className="p-inputgroup-addon">kWh</span>
                                </div>
                                {submitted && (Conta.consumoago === null || Conta.consumoago < 0) && <small className="p-invalid">{Mensagem} </small>}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Mar
                            </td>
                            <td>
                                <div className="p-inputgroup">
                                    <InputNumber id="consumomar" value={Conta.consumomar} onValueChange={(e) => onInputNumberChange(e, 'consumomar')}
                                        required autoFocus className={classNames({ 'p-invalid': submitted && (Conta.consumomar === null || Conta.consumomar < 0) })}
                                        mode="decimal" locale="pt-BR" minFractionDigits={2} tabIndex={6} disabled={idstatus > 2} />
                                    <span className="p-inputgroup-addon">kWh</span>
                                </div>
                                {submitted && (Conta.consumomar === null || Conta.consumomar < 0) && <small className="p-invalid">{Mensagem} </small>}
                            </td>
                            <td>
                                Set
                            </td>
                            <td>
                                <div className="p-inputgroup">
                                    <InputNumber id="consumoset" value={Conta.consumoset} ononValueChangeChange={(e) => onInputNumberChange(e, 'consumoset')}
                                        required autoFocus className={classNames({ 'p-invalid': submitted && (Conta.consumoset === null || Conta.consumoset < 0) })}
                                        mode="decimal" locale="pt-BR" minFractionDigits={2} tabIndex={12} disabled={idstatus > 2} />
                                    <span className="p-inputgroup-addon">kWh</span>
                                </div>
                                {submitted && (Conta.consumoset === null || Conta.consumoset < 0) && <small className="p-invalid"> {Mensagem}</small>}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Abr
                            </td>
                            <td>
                                <div className="p-inputgroup">
                                    <InputNumber id="consumoabr" value={Conta.consumoabr} onValueChange={(e) => onInputNumberChange(e, 'consumoabr')}
                                        required autoFocus className={classNames({ 'p-invalid': submitted && (Conta.consumoabr === null || Conta.consumoabr < 0) })}
                                        mode="decimal" locale="pt-BR" minFractionDigits={2} tabIndex={7} disabled={idstatus > 2} />
                                    <span className="p-inputgroup-addon">kWh</span>
                                </div>
                                {submitted && (Conta.consumoabr === null || Conta.consumoabr < 0) && <small className="p-invalid">{Mensagem}</small>}
                            </td>
                            <td>
                                Out
                            </td>
                            <td>
                                <div className="p-inputgroup">
                                    <InputNumber id="consumoout" value={Conta.consumoout} onValueChange={(e) => onInputNumberChange(e, 'consumoout')}
                                        required autoFocus className={classNames({ 'p-invalid': submitted && (Conta.consumoout === null || Conta.consumoout < 0) })}
                                        mode="decimal" locale="pt-BR" minFractionDigits={2} tabIndex={13} disabled={idstatus > 2} />
                                    <span className="p-inputgroup-addon">kWh</span>
                                </div>
                                {submitted && (Conta.consumoout === null || Conta.consumoout < 0) && <small className="p-invalid">{Mensagem}</small>}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Mai
                            </td>
                            <td>
                                <div className="p-inputgroup">
                                    <InputNumber id="consumomai" value={Conta.consumomai} onValueChange={(e) => onInputNumberChange(e, 'consumomai')}
                                        required autoFocus className={classNames({ 'p-invalid': submitted && (Conta.consumomai === null || Conta.consumomai < 0) })}
                                        mode="decimal" locale="pt-BR" minFractionDigits={2} tabIndex={8} disabled={idstatus > 2} />
                                    <span className="p-inputgroup-addon">kWh</span>
                                </div>
                                {submitted && (Conta.consumomai === null || Conta.consumomai < 0) && <small className="p-invalid">{Mensagem}</small>}
                            </td>
                            <td>
                                Nov
                            </td>
                            <td>
                                <div className="p-inputgroup">
                                    <InputNumber id="consumonov" value={Conta.consumonov} onValueChange={(e) => onInputNumberChange(e, 'consumonov')}
                                        required autoFocus className={classNames({ 'p-invalid': submitted && (Conta.consumonov === null || Conta.consumonov < 0) })}
                                        mode="decimal" locale="pt-BR" minFractionDigits={2} tabIndex={14} disabled={idstatus > 2} />
                                    <span className="p-inputgroup-addon">kWh</span>
                                </div>
                                {submitted && (Conta.consumonov === null || Conta.consumonov < 0) && <small className="p-invalid">{Mensagem}</small>}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Jun
                            </td>
                            <td>
                                <div className="p-inputgroup">
                                    <InputNumber id="consumojun" value={Conta.consumojun} onValueChange={(e) => onInputNumberChange(e, 'consumojun')}
                                        required autoFocus className={classNames({ 'p-invalid': submitted && (Conta.consumojun === null || Conta.consumojun < 0) })}
                                        mode="decimal" locale="pt-BR" minFractionDigits={2} tabIndex={9} disabled={idstatus > 2} />
                                    <span className="p-inputgroup-addon">kWh</span>
                                </div>
                                {submitted && (Conta.consumojun === null || Conta.consumojun < 0) && <small className="p-invalid">{Mensagem}</small>}
                            </td>
                            <td>
                                Dez
                            </td>
                            <td>
                                <div className="p-inputgroup">
                                    <InputNumber id="consumodez" value={Conta.consumodez} onValueChange={(e) => onInputNumberChange(e, 'consumodez')}
                                        required className={classNames({ 'p-invalid': submitted && (Conta.consumodez === null || Conta.consumodez < 0) })}
                                        mode="decimal" locale="pt-BR" minFractionDigits={2} tabIndex={14} disabled={idstatus > 2} />
                                    <span className="p-inputgroup-addon">kWh</span>
                                </div>
                                {submitted && (Conta.consumodez === null || Conta.consumodez < 0) && <small className="p-invalid">{Mensagem}</small>}
                            </td>
                        </tr>

                        <tr style={{ backgroundColor: '#e9ecef' }}>
                            <td>
                                Ajuste
                            </td>
                            <td>
                                <div className="p-inputgroup">
                                    <InputNumber id="ajuste" value={Conta.ajuste} onValueChange={(e) => onInputNumberChange(e, 'ajuste')}
                                        required autoFocus className={classNames({ 'p-invalid': submitted && Conta.ajuste === null })}
                                        mode="decimal" locale="pt-BR" minFractionDigits={2} tabIndex={15} disabled={idstatus > 2} />
                                    <span className="p-inputgroup-addon">kWh</span>
                                </div>
                                {submitted && Conta.ajuste === null && <small className="p-invalid">Requerido</small>}

                            </td>
                            <td>
                                Con. Méd.
                            </td>
                            <td>
                                <InputNumber inputStyle={{ color: 'blue' }} id="consumomedia" value={Conta.consumomedia}
                                    className={classNames({ 'p-invalid': submitted && Conta.consumomedia === 0 })}
                                    disabled mode="decimal" locale="pt-BR" maxFractionDigits={2} suffix=" kWh" />
                                {submitted && Conta.consumomedia === 0 && <small className="p-invalid">O consumo médio deve ser maior que zero.</small>}
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
            <div className="p-grid" style={{ width: '100%' }}>
                <div className="p-col-2 p-mb-3 p-ml-2 p-mt-5">
                    {getFooter()}
                </div>
            </div>


        </div>

    );

}
export default contaUnica;