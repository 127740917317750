import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';
import api from '../../service/api';
import { Skeleton } from 'primereact/skeleton';

export const GraficoTipoInstalacao = (props) => {

    const lightOptions = {
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        }
    };

    const [tipoInstalacao, setTipoInstalacao] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        api.get(`/dashboard/tipoinstalacao/${props.Ini}/${props.Fim}`)
            .then(res => {
                setTipoInstalacao(res.data);
                setLoading(false);
            });
    }, [props.Ini, props.Fim]);    

    return(
        <>
          {!loading && (
            <Chart type="bar" height='185' data={tipoInstalacao} options={lightOptions} style={{ position: 'relative', width: '100%' }} />
          )}
          {loading && (
              <Skeleton height='23rem' />
          )}
            
        </>
    );
}