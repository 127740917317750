import React, { useState, useEffect, useRef } from 'react';
import { Panel } from '../../components/panel/Panel';
import { Button } from '../../components/button/Button';
import { OverlayPanel } from '../../components/overlaypanel/OverlayPanel';
import { Calendar } from '../../components/calendar/Calendar';
import { RadioButton } from '../../components/radiobutton/RadioButton';
import { format, parseISO } from 'date-fns'
import { GraficoNegociosStatus } from './graficoNegociosStatus';
import { GraficoFornecedores } from './graficoFornecedores';
import { GraficoTipoMaterial } from './graficoTipoMaterial';
import { GraficoTipoInstalacao } from './graficoTipoInstalacao';
import { CardsCadastros } from './cardsCadastros';
import { CardsTipoCliente } from './cardsTipoCliente';
import { CardsFinanceiros } from './cardsFinanceiros';
import { CardsGeracaoPotencia } from './cardsGeracaoPotencia';
import { Toast } from '../../components/toast/Toast';

const Dashboard = () => {

    const date = new Date();

    const periodoInicial = {
        "dataIni": sessionStorage.getItem('dataini') ? sessionStorage.getItem('dataini') : format(new Date(date.getFullYear(), 0, 1), 'yyyy-MM-dd'),
        "dataFim": sessionStorage.getItem('datafim') ? sessionStorage.getItem('datafim') : format(new Date(date.getFullYear(), 11, 31), 'yyyy-MM-dd'),
        "dashFiltro": sessionStorage.getItem('periodo') ? sessionStorage.getItem('periodo') : 'Ano'
    };

    const [periodo, setPeriodo] = useState(periodoInicial);
    const [periodoAux, setPeriodoAux] = useState(periodoInicial)
    const op = useRef(null);
    const toast = useRef(null);
    const dtIni = useRef(null);
    const dtFim = useRef(null);

    const convertData = (data) => {
        let dt = parseISO(data);
        // return format(dt, 'dd-MM-yyyy')
        return format(dt, 'yyyy-MM-dd')
    }

    const atualizarPeriodo = (e) => {

        let periodo = { ...periodo };
        periodo.dashFiltro = e.value
        switch (e.value) {
            case 'Ano':
                periodo.dataIni = format(new Date(date.getFullYear(), 0, 1), 'yyyy-MM-dd');
                periodo.dataFim = format(new Date(date.getFullYear(), 11, 31), 'yyyy-MM-dd');
                break;
            case 'Mes':
                periodo.dataIni = format(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd');
                periodo.dataFim = format(new Date(date.getFullYear(), date.getMonth() + 1, 0), 'yyyy-MM-dd');
                break;
            case 'Personalizado':
                periodo.dataIni = format(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd');
                periodo.dataFim = format(new Date(date.getFullYear(), date.getMonth() + 1, 0), 'yyyy-MM-dd');
                break;
        }
        setPeriodoAux(periodo);
    }

    const onDataChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        if (val) {
            let _periodoAux = { ...periodoAux };
            _periodoAux[`${name}`] = format(val, 'yyyy-MM-dd');
            setPeriodoAux(_periodoAux);
        }
    }

    const filtrarClick = (e) => {
        if (periodoAux.dataIni > periodoAux.dataFim) {
            toast.current.show({severity:'error', summary: 'Período inválido', detail:'A data inicial não pode ser maior que a data final.', life: 3000});
        }
        else{
            setPeriodo(periodoAux);
            op.current.hide();
        }
    }

    return (
        <>
            <Toast ref={toast}/>
            <div className="p-grid p-fluid dashboard">
                <div className="p-col-12 p-lg-12" >
                    <div className='p-text-center p-mt-0' style={{ BackgroundColor: '#ffffff' }}>
                        <Panel>
                            <div className="p-grid p-fluid">
                                <div className="p-col-11 p-lg-11">
                                    <h3 className='p-ml-6 p-mb-0'>Clientes e Negócios</h3>
                                </div>
                                <div className="p-text-right p-col-1 p-lg-1">
                                    <Button type="button" icon="pi pi-filter" label='' onClick={(e) => op.current.toggle(e)} aria-haspopup aria-controls="overlay_panel"
                                        className="select-product-button p-0" />
                                    <OverlayPanel
                                        ref={op}
                                        // showCloseIcon
                                        id="overlay_panel"
                                        style={{ width: '450px' }}
                                        className="overlaypanel-demo">
                                        <div className="p-text-left p-grid p-fluid">
                                            <div className="p-col-6">
                                                <div className="p-field-radiobutton">
                                                    <RadioButton inputId="rbAno" name="rbAno" value="Ano" onChange={(e) => atualizarPeriodo(e)} checked={periodoAux.dashFiltro === 'Ano'} />
                                                    <label htmlFor="rbAno">Este ano</label>
                                                </div>
                                            </div>
                                            <div className="p-col-6">

                                            </div>
                                            <div className="p-col-6">
                                                <div className="p-field-radiobutton">
                                                    <RadioButton inputId="rbMes" name="rbMes" value="Mes" onChange={(e) => atualizarPeriodo(e)} checked={periodoAux.dashFiltro === 'Mes'} />
                                                    <label htmlFor="rbMes">Este mês</label>
                                                </div>

                                            </div>
                                            <div className="p-col-6">

                                            </div>
                                            <div className="p-col-4">
                                                <div className="p-field-radiobutton">
                                                    <RadioButton inputId="rbPersonalizado" name="rbPersonalizado" value="Personalizado" onChange={(e) => atualizarPeriodo(e)} checked={periodoAux.dashFiltro === 'Personalizado'} />
                                                    <label htmlFor="rbPersonalizado">Personalizado</label>
                                                </div>
                                            </div>
                                            <div className="p-col-4">
                                                <Calendar
                                                    disabled={periodoAux.dashFiltro !== 'Personalizado'}
                                                    showIcon
                                                    value={parseISO(periodoAux.dataIni)}
                                                    locale="pt"
                                                    dateFormat="dd/mm/yy"
                                                    ref={dtIni}
                                                    onChange={(e) => onDataChange(e, 'dataIni')}>
                                                </Calendar>
                                            </div>
                                            <div className="p-col-4">
                                                <Calendar
                                                    disabled={periodoAux.dashFiltro !== 'Personalizado'}
                                                    showIcon
                                                    value={parseISO(periodoAux.dataFim)}
                                                    locale="pt" dateFormat="dd/mm/yy"
                                                    ref={dtFim}
                                                    onChange={(e) => onDataChange(e, 'dataFim')}>
                                                </Calendar>
                                            </div>
                                            <div className='p-col-4'>
                                                <Button label='Filtrar' onClick={(e) => filtrarClick(e)} aria-haspopup aria-controls="overlay_panel"></Button>
                                            </div>
                                        </div>
                                    </OverlayPanel>
                                </div>
                            </div>
                        </Panel>
                    </div>
                </div>
                <CardsCadastros Ini={convertData(periodo.dataIni)} Fim={convertData(periodo.dataFim)} />
                <div className="p-col-12">
                    <Panel header="Cadastros">
                        <div className="p-grid p-justify-center">
                            <CardsTipoCliente Ini={convertData(periodo.dataIni)} Fim={convertData(periodo.dataFim)} />
                            <div className="p-col-6">
                                <div className="p-grid p-justify-center" >
                                    <div className="p-col-12">
                                        <GraficoNegociosStatus Ini={convertData(periodo.dataIni)} Fim={convertData(periodo.dataFim)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Panel>
                </div>
                <div className="p-col-12 p-lg-12" >
                    <div className='p-text-center p-mt-0' style={{ BackgroundColor: '#ffffff' }}>
                        <Panel>
                            <h3 className='p-mb-0'>Financeiro</h3>
                        </Panel>
                    </div>
                </div>
                <CardsFinanceiros Ini={convertData(periodo.dataIni)} Fim={convertData(periodo.dataFim)} />
                <div className="p-col-12 p-lg-12" >
                    <div className='p-text-center p-mt-0' style={{ BackgroundColor: '#ffffff' }}>
                        <Panel>
                            <h3 className='p-mb-0'>Usinas</h3>
                        </Panel>
                    </div>
                </div>
                <CardsGeracaoPotencia Ini={convertData(periodo.dataIni)} Fim={convertData(periodo.dataFim)} />
                <div className="p-col-3">
                    <Panel header="Fornecedores">
                        <GraficoFornecedores Ini={convertData(periodo.dataIni)} Fim={convertData(periodo.dataFim)} />
                    </Panel>
                </div>

                <div className="p-col-3">
                    <Panel header="Tipo de Material">
                        <GraficoTipoMaterial Ini={convertData(periodo.dataIni)} Fim={convertData(periodo.dataFim)} />
                    </Panel>
                </div>
                <div className="p-col-6">
                    <Panel header="Tipo de Instalação">
                        <GraficoTipoInstalacao Ini={convertData(periodo.dataIni)} Fim={convertData(periodo.dataFim)} />
                    </Panel>
                </div>
            </div>
        </>
    );
}

export default Dashboard;