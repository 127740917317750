import React, { Component } from 'react';
import api from '../../service/api';
import { NegocioDimensionamentoResultado } from './negocioDimensionamentoResultado';
import { Materiais } from './materiais';
import { TabPanel, TabView } from '../../components/tabview/TabView';
import { Button } from '../../components/button/Button';
import { InputNumber } from '../../components/inputnumber/InputNumber';
import { Toast } from '../../components/toast/Toast';
import { DimensionamentoPS } from './dimensionamentoParametroServico';
import { Custos } from './custos';
import { PropTypes } from 'prop-types';
import { Panel } from '../../components/panel/Panel';
import { Toolbar } from '../../components/toolbar/Toolbar';
import { InputText } from '../../components/inputtext/InputText';
import { DimensionamentoConfigIncluso } from '../negocio/dimensionamentoConfigIncluso';
import { DimensionamentoConfigNaoIncluso } from '../negocio/dimensionamentoConfigNaoIncluso';
import { DimensionamentoConfigPrazos } from '../negocio/dimensionamentoConfigPrazo';
import { DimensionamentoConfigGarantia } from '../negocio/dimensionamentoConfigGarantia';
import { AnexosDimensionamento } from './anexosDimensionamento';
import LoadingScreen from 'react-loading-screen';
import { InputTextarea } from '../../components/inputtextarea/InputTextarea';
import { SplitButton } from '../../components/splitbutton/SplitButton';
import { Dropdown } from '../../components/dropdown/Dropdown';
import { DimensionamentoContratos } from './dimensionamentoContratos';
import { InputMask } from '../../components/inputmask/InputMask';
import { format, isThisHour, parseISO } from "date-fns";
import { Calendar } from '../../components/calendar/Calendar';
import { Dialog } from '../../components/dialog/Dialog';
// import ptBR from 'date-fns/locale/pt-BR';
// import { locale, addLocale, localeOption, localeOptions } from 'primereact/api';

export class NegocioDimensionamentoEdit extends Component {

    static defaultProps = {
        idNegocioDimensionamento: 2,
        totalServico: 0.00,
    }

    static propTypes = {
        idNegocioDimensionamento: PropTypes.number,
        totalServico: PropTypes.number,
    }

    emptyDimensionamento = {
        "id": 0,
        "idnegocio": 0,
        "eficiencia": 0.00,
        "areautilgeracao": 0.00,
        "quantidademodulos": 0.00,
        "potencia": 0.00,
        "fornecedor": "",
        "potenciainversor": 0.00,
        "idgarantiaservico": null,
        "validade": 0,
        "obsformapagamento": '',
        "idfornecedor": null,
        "nomefantasia": '',
        "procuracaonome": '',
        "procuracaocpf": '',
        "procuracaovalidade": null,
        "idstatus": 1,
        "status": '',
    };

    emptyCliente = {
        "id": 0,
        "nome": "",
        "cpfcnpj": "",
        "logradouro": "",
        "numero": "",
        "complemento": "",
        "idcidade": 0,
        "idestado": 0,
        "bairro": "",
        "cep": "",
        "email": "",
        "site": "",
        "telefonefixo": "",
        "telefonecelular": ""
    }

    emptyStatus = {
        "id": 0,
        "idnegociodimensionamento": null,
        "idstatus": 2,
    }

    dimensionamentoAtual = this.emptyDimensionamento;

    constructor(props) {
        super(props);

        this.items = [
            {
                label: 'Com valores',
                icon: 'pi pi-money-bill',
                command: () => {
                    this.openProposta('S');
                }
            },
            {
                label: 'Resumida',
                icon: 'pi pi-file',
                command: () => {
                    this.openPropostaResumida();
                }
            }

        ]

        this.btnStatusProposta = [
            {
                label: 'Aceita',
                icon: 'pi pi-thumbs-up',
                command: async () => {
                    if (!this.state.Dimensionamento.obsformapagamento) {
                        this.toast.show({ severity: 'warn', summary: 'Atenção', detail: 'A forma de pagamento não foi informada.', life: 3000 });
                    }
                    else {
                        if (!await this.existeMaterialDC()) {
                            return;
                        }
                        if (await this.ValidarGarantias()) {
                            this.setState({
                                newIdstatus: 3,
                                newStatus: "PROPOSTA ACEITA",
                                dialogStatusEnviado: true
                            });
                        }
                    }

                }
            },
            {
                label: 'Perdida',
                icon: 'pi pi-thumbs-down',
                command: () => {
                    this.setState({
                        newIdstatus: 4,
                        newStatus: "PERDIDO",
                        dialogStatusEnviado: true
                    });
                }
            }
        ]


        // this.br = {
        //     firstDayOfWeek: 1,
        //     dayNames: ["domingo", "segunda", "terça", "quarta", "quinta", "sexta", "sábado"],
        //     dayNamesShort: ["dom", "seg", "ter", "qua", "qui", "sex", "sáb"],
        //     dayNamesMin: ["D", "S", "T", "Q", "Q", "S", "S"],
        //     monthNames: ["janero", "fevereiro", "março", "abril", "maio", "junho", "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"],
        //     monthNamesShort: ["jan", "fev", "mar", "abr", "mai", "jun", "jul", "ago", "set", "out", "nov", "dez"],
        //     today: 'Hoje',
        //     clear: 'Limpar',
        //     dateFormat: 'dd/mm/yy',
        //     weekHeader: 'Sm'
        // };



        this.state = {
            Dimensionamento: this.emptyDimensionamento,
            DimensionamentoEdit: this.emptyDimensionamento,
            nonUsedKey: Date.now(),
            editDimensionamento: false,
            totalServico: 0.00,
            cliente: this.emptyCliente,
            qtdModulos: 0,
            listaDimensionamento: [],
            listaPrazos: [],
            loading: true,
            editObsFormaPagamento: false,
            listaFornecedor: [],
            procuracaoDialog: false,
            dialogStatusEnviado: false,
            newIdstatus: 2,
            newStatus: "ENVIADO AO CLIENTE",
        };

        this.columns = [
            { field: 'fornecedor', header: 'Fornecedor' },
            { field: 'quantidademodulos', header: 'Módulos' },
            { field: 'potencia', header: 'Potência Módulo' },
            { field: 'areautilgeracao', header: 'Área Útil Geração' },
            { field: 'eficiencia', header: 'Eficiência' },
            { field: 'potenciainversor', header: 'Potência Inversor' },
        ];

        this.editingCellRows = {};
        this.originalRows = {};

        this.refresh = this.refresh.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onInputNumberChange = this.onInputNumberChange.bind(this);
        this.formatarCurrency = this.formatarCurrency.bind(this);
        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.rightToolbarTemplate = this.rightToolbarTemplate.bind(this);
        this.openProposta = this.openProposta.bind(this);
        this.openPropostaResumida = this.openPropostaResumida.bind(this);
        this.getDimensionamentoParametroServico = this.getDimensionamentoParametroServico.bind(this);
        this.calculatTotalServicos = this.calculatTotalServicos.bind(this);
        this.exibirPaginacao = this.exibirPaginacao.bind(this);
        this.inputNumberEditor = this.inputNumberEditor.bind(this);
        this.onEditorNumberValueChange = this.onEditorNumberValueChange.bind(this);
        this.onDataChange = this.onDataChange.bind(this);

        this.onEditorInit = this.onEditorInit.bind(this);
        this.onEditorCancel = this.onEditorCancel.bind(this);
        this.onEditorSubmit = this.onEditorSubmit.bind(this);

        this.onRowEditInit = this.onRowEditInit.bind(this);
        this.onRowEditCancel = this.onRowEditCancel.bind(this);
        this.rowPrazoEditSave = this.rowPrazoEditSave.bind(this);

        this.atualizarGarantia = this.atualizarGarantia.bind(this);

        this.footerTemplateObsFormaPagamento = this.footerTemplateObsFormaPagamento.bind(this);
        this.onEditObsFormaPagamento = this.onEditObsFormaPagamento.bind(this);
        this.onCancelObsFormaPagamento = this.onCancelObsFormaPagamento.bind(this);
        this.onSaveObsFormaPagamento = this.onSaveObsFormaPagamento.bind(this);

        this.procuracaoHideDialog = this.procuracaoHideDialog.bind(this);
        this.procuracaoEdit = this.procuracaoEdit.bind(this);
        this.footerTemplateProcuracao = this.footerTemplateProcuracao.bind(this);
        this.leftToolbarProcuracao = this.leftToolbarProcuracao.bind(this);
        this.rightToolbarProcuracao = this.rightToolbarProcuracao.bind(this);
        this.openProcuracao = this.openProcuracao.bind(this);
        this.hideDialogStatusEnviado = this.hideDialogStatusEnviado.bind(this);
        this.atualizarStatus = this.atualizarStatus.bind(this);
        this.statusBodyTemplate = this.statusBodyTemplate.bind(this);
        this.onInputMaskChange = this.onInputMaskChange.bind(this);

        this.ValidarGarantias = this.ValidarGarantias.bind(this);
        this.setStatusCliente = this.setStatusCliente.bind(this);
        this.existeMaterialDC = this.existeMaterialDC.bind(this);

    }

    async componentDidMount() {
        const { id } = this.props.match.params;
        const custoServicos = await api.get(`/dimensionamentoservicos/total/${id}`);
        const dime = await api.get(`/dimensionamentos/${id}`);
        const cli = await api.get(`clientes/negocios/${dime.data[0].idnegocio}`)
        const listaPrazos = await api.get(`/prazos`);
        const listaFornecedor = await api.get('/fornecedores');
        let lista = [];
        lista.push(dime.data[0]);

        this.setState({
            totalServico: custoServicos.data[0].total,
            Dimensionamento: dime.data[0],
            cliente: cli.data[0],
            qtdModulos: dime.data[0].quantidademodulos,
            listaDimensionamento: lista,
            listaPrazos: listaPrazos.data,
            listaFornecedor: listaFornecedor.data,
            loading: false,
        });
    }

    onEdit() {
        this.dimensionamentoAtual = { ...this.state.Dimensionamento };
        this.setState({ editDimensionamento: true })
    }

    onEditObsFormaPagamento() {
        this.dimensionamentoAtual = { ...this.state.Dimensionamento };
        this.setState({ editObsFormaPagamento: true })
    }

    atualizarGarantia(idGarantia) {
        let Dimensionamento = { ...this.state.Dimensionamento };
        Dimensionamento.idgarantiaservico = idGarantia;
        api.put(`/dimensionamentos/${Dimensionamento.id}`, Dimensionamento)
            .then(res => {
                if (res.status === 204) {
                    this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Dado(s) atualizado(s)', life: 3000 });
                    this.setState({
                        Dimensionamento: Dimensionamento,
                    })
                }
            })
    }

    onSave() {
        let Dimensionamento = { ...this.state.Dimensionamento };
        if (Dimensionamento.procuracaovalidade) {
            Dimensionamento.procuracaovalidade = parseISO(Dimensionamento.procuracaovalidade);
        }
        api.put(`/dimensionamentos/${Dimensionamento.id}`, Dimensionamento)
            .then(res => {
                if (res.status === 204) {
                    this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Dado(s) atualizado(s)', life: 3000 });
                    this.setState({
                        DimensionamentoEdit: Dimensionamento,
                        qtdModulos: this.state.Dimensionamento.quantidademodulos,
                        editDimensionamento: false,
                        nonUsedKey: Date.now(),
                        procuracaoDialog: false,
                    })
                }
            })

    }

    onSaveObsFormaPagamento() {
        api.put(`/dimensionamentos/${this.state.Dimensionamento.id}`, this.state.Dimensionamento)
            .then(res => {
                if (res.status === 204) {
                    this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Dado(s) atualizado(s)', life: 3000 });
                    this.setState({
                        editObsFormaPagamento: false
                    })
                }
            })

    }


    onCancel() {
        this.setState({
            Dimensionamento: this.dimensionamentoAtual,
            editDimensionamento: false
        })

    }

    onCancelObsFormaPagamento() {
        this.setState({
            Dimensionamento: this.dimensionamentoAtual,
            editObsFormaPagamento: false
        })

    }

    exibirResultado(IdNegocio) {
        if (IdNegocio > 0) {
            return (
                <React.Fragment>
                    <NegocioDimensionamentoResultado
                        idnegocio={this.state.Dimensionamento.idnegocio}
                        eficiencia={this.state.Dimensionamento.eficiencia}
                        areautilgeracao={this.state.Dimensionamento.areautilgeracao}
                        potencia={this.state.Dimensionamento.potencia}
                        quantidademodulos={this.state.Dimensionamento.quantidademodulos} />
                </React.Fragment>
            );
        }

    }

    exibirMateriais(id) {
        if (id > 0) {
            return (
                <React.Fragment>
                    <Materiais
                        idDimensionamento={this.state.Dimensionamento.id}
                        idstatus={this.state.Dimensionamento.idstatus}
                        nonUsedKey={this.state.nonUsedKey} />
                </React.Fragment>
            );
        }
    }

    onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        const input = e.target;
        const start = input.selectionStart;
        const end = input.selectionEnd;
        let dimensionamento = { ...this.state.Dimensionamento };
        dimensionamento[`${name}`] = val.toUpperCase();
        this.setState({ Dimensionamento: dimensionamento },
            () => input.setSelectionRange(start, end)
        );
    }

    onInputMaskChange(e, name) {
        const val = (e.target && e.target.value) || '';
        let dimensionamento = { ...this.state.Dimensionamento };
        dimensionamento[`${name}`] = val.toUpperCase();
        this.setState({ Dimensionamento: dimensionamento });
    }

    onDataChange(e, name) {
        const val = (e.target && e.target.value) || '';
        console.log('Data: ' + val);
        console.log('Data Convertida:' + format(val, 'yyyy-MM-dd'))
        console.log('Data Convertida1:' + parseISO(format(val, 'yyyy-MM-dd')));
        if (val) {
            let dimensionamento = { ...this.state.Dimensionamento };
            dimensionamento[`${name}`] = format(val, 'yyyy-MM-dd');
            this.setState({ Dimensionamento: dimensionamento });
        }
    }

    onInputNumberChange(e, name) {
        const val = e.value || 0;
        let dimensionamento = { ...this.state.Dimensionamento };
        dimensionamento[`${name}`] = val;
        this.setState({ Dimensionamento: dimensionamento });
    }

    editNumerTemplate(name, FractionDigits) {
        if (this.state.editDimensionamento) {
            return (
                <React.Fragment>
                    <InputNumber inputStyle={{ width: '100%' }} className="p-inputtext-sm" value={this.state.Dimensionamento[name]}
                        onValueChange={(e) => this.onInputNumberChange(e, name)}
                        mode="decimal" locale="pt-BR" minFractionDigits={FractionDigits}
                    />
                </React.Fragment>
            )
        } else {
            // return (this.state.Dimensionamento[name].toLocaleString('pt-BR'));
            return (this.state.Dimensionamento[name].toLocaleString('pt-BR', { style: 'decimal', minimumFractionDigits: FractionDigits }))
        }
    }

    editTextTemplate(name) {
        if (this.state.editDimensionamento) {
            return (
                <React.Fragment>
                    <InputText style={{ width: '100%' }} className="p-inputtext-sm" value={this.state.Dimensionamento[name]}
                        onChange={(e) => this.onInputChange(e, name)} />
                </React.Fragment>
            )
        } else {
            return (this.state.Dimensionamento[name])
        }
    }

    formatData(value, formato) {
        if (!value) {
            return ''
        }
        else {
            if (!formato) {
                formato = "yyyy-MM-dd";
            }
            return format(parseISO(value), formato);
            // let Data = parseISO(value);
            // return format(Data, "dd/MM/yyyy");
        }
    }

    editDataTemplate(name) {
        if (this.state.editDimensionamento) {
            return (
                <React.Fragment>
                    <Calendar id="buttonbar" value={this.formatData(this.state.Dimensionamento.procuracaovalidade)}
                        dateFormat="dd/mm/yy" onChange={(e) => this.onDataChange(e, name)} touchUI />
                </React.Fragment>
            )
        } else {

            return (
                this.formatData(this.state.Dimensionamento[name])
            )
        }
    }

    findFornecedor(id) {
        let index = -1;
        for (let i = 0; i < this.state.listaFornecedor.length; i++) {
            if (this.state.listaFornecedor[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    onFornecedorChange(e) {
        let Dimensionamento = { ...this.state.Dimensionamento };
        Dimensionamento.idfornecedor = e.value;
        Dimensionamento.nomefantasia = this.state.listaFornecedor[this.findFornecedor(e.value)].nomefantasia;
        this.setState({ Dimensionamento });
    }

    editFornecedor(name) {
        if (this.state.editDimensionamento) {
            return (
                <React.Fragment>

                    <Dropdown
                        inputId="idfornecedor"
                        optionValue="id"
                        optionLabel="nomefantasia"
                        value={this.state.Dimensionamento.idfornecedor}
                        options={this.state.listaFornecedor}
                        onChange={(e) => this.onFornecedorChange(e)}
                        placeholder="Selecione o fornecedor"
                        required
                        autoFocus
                        tabIndex={1}
                        style={{ width: '100%', height: '33px' }} />
                </React.Fragment>
            )
        } else {
            return (this.state.Dimensionamento[name])
        }
    }

    footerTemplate() {
        if (this.state.editDimensionamento) {
            return (
                <React.Fragment>
                    <Button type="Button" label="Salvar" icon="pi pi-check" className="p-button-rounded p-button-success p-mr-2" onClick={this.onSave} />
                    <Button type="Button" label="Cancelar" icon="pi pi-times" className="p-button-rounded p-button-warning p-mr-2" onClick={this.onCancel} />
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <Button type="Button" label="Alterar" icon="pi pi-pencil" className="p-button-rounded p-mr-2" onClick={this.onEdit} />
                </React.Fragment>
            )

        }
    }

    footerTemplate1() {
        if (this.state.editDimensionamento) {
            return (
                <React.Fragment>
                    <Button
                        type="Button"
                        tooltip="Gravar"
                        tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
                        icon="pi pi-check"
                        className="p-button-rounded p-button-success p-mr-2"
                        onClick={this.onSave}>
                    </Button>
                    <Button
                        type="Button"
                        tooltip="Cancelar"
                        tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
                        icon="pi pi-times"
                        className="p-button-rounded p-button-warning p-mr-2"
                        onClick={this.onCancel}>
                    </Button>
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <Button
                        type="Button"
                        tooltip="Alterar"
                        tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
                        icon="pi pi-pencil"
                        className="p-button-rounded p-mr-2"
                        onClick={this.onEdit}
                        disabled={this.state.Dimensionamento.idstatus && this.state.Dimensionamento.idstatus > 2}
                    >
                    </Button>
                </React.Fragment>
            )

        }
    }

    footerTemplateProcuracao() {
        return (
            <React.Fragment>
                <Button
                    type="Button"
                    tooltip="Alterar"
                    tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-mr-2"
                    onClick={this.procuracaoEdit}
                    disabled={this.state.Dimensionamento.idstatus && this.state.Dimensionamento.idstatus > 2}>
                </Button>
            </React.Fragment>
        )
    }

    refresh() {
        api.get(`/dimensionamentoservicos/total/${this.state.Dimensionamento.id}`)
            .then(res => {
                this.setState({
                    totalServico: res.data.total,
                    nonUsedKey: Date.now()
                })
            })
    }

    formatarCurrency(value) {
        let ret = value || 0;
        return ret.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 });
    }

    leftToolbarTemplate() {
        return (
            <b>Cliente: {this.state.cliente.nome}</b>
        )
    }

    procuracaoEdit() {
        let DimensionamentoEdit = { ...this.state.Dimensionamento }
        this.setState({
            DimensionamentoEdit: DimensionamentoEdit,
            procuracaoDialog: true
        });
    }

    async openProcuracao() {
        this.setState({ loading: true })
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        api.get(`/docaux/procuracao/${this.state.Dimensionamento.id}`, { responseType: 'blob' })
            .then(response => {
                const file = new Blob([response.data], { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(file);

                a.href = fileURL;
                a.download = `Procuracao-${this.state.Dimensionamento.id}.pdf`;
                a.click();
                window.URL.revokeObjectURL(fileURL);
                this.setState({ loading: false });
                //window.open(fileURL);
            })
            .catch(error => {
                this.setState({ loading: false });
                console.log(error);
            });
    }

    leftToolbarProcuracao() {
        return (
            <React.Fragment>
                <Button
                    label="Editar"
                    icon="pi pi-pencil"
                    className="p-button p-mr-2"
                    onClick={this.procuracaoEdit}
                    disabled={this.state.Dimensionamento.idstatus > 3} />
            </React.Fragment>
        )
    }

    rightToolbarProcuracao() {
        return (
            <React.Fragment>
                <Button
                    label="Imprimir"
                    icon="pi pi-print"
                    className="p-button p-mr-2"
                    onClick={this.openProcuracao}
                    disabled={this.state.Dimensionamento.idstatus > 3 || !this.state.Dimensionamento.procuracaonome || !this.state.Dimensionamento.procuracaonome.trim()} />
            </React.Fragment>
        )
    }

    async existeMaterialDC() {
        let res = await api.get(`/materiaisdc/dimensionamento/${this.state.Dimensionamento.id}`);
        if (res.data.length > 0) {
            return true;
        }
        else {
            this.toast.show({ severity: 'warn', summary: 'Atenção', detail: 'Para prosseguir é necessário informar materiais no Sist. DC', life: 4000 });
            return false;
        }
    }

    async ValidarGarantias() {
        let semGarantiaDC = await api.get(`/materiaisdc/semgarantia/${this.state.Dimensionamento.id}`);
        let estruturas = await api.get(`/estruturas/dimensionamento/${this.state.Dimensionamento.id}`);

        if (semGarantiaDC.data.length > 0) {
            this.toast.show({ severity: 'warn', summary: 'Atenção', detail: 'Para prosseguir é necessário informar a garantia em todos os materiais do Sist. DC', life: 4000 });
            return false;
        }
        else if (estruturas.data.length > 0) {
            let semGarantiaEstSolo = await api.get(`/estruturas/semgarantia/${this.state.Dimensionamento.id}`);
            if (semGarantiaEstSolo.data.length > 0) {
                this.toast.show({ severity: 'warn', summary: 'Atenção', detail: 'Para prosseguir é necessário informar a garantia em todos os materiais da Est. Fix. Solo', life: 4000 });
                return false;
            }
            else{
                return true;
            }
        }
        else if (!this.state.Dimensionamento.idgarantiaservico) {
            this.toast.show({ severity: 'warn', summary: 'Atenção', detail: 'Para prosseguir é necessário informar a garantia do serviço.', life: 4000 });
            return false;
        }
        else {
            return true;
        }
    }

    setStatusCliente() {
        if (this.state.Dimensionamento.idstatus === 1) {
            this.setState({
                newIdstatus: 2,
                newStatus: "ENVIADO AO CLIENTE",
                dialogStatusEnviado: true
            });
        }
    }

    async openProposta(ExibirValores) {
        if (!await this.existeMaterialDC()) {
            return;
        }
        if (await this.ValidarGarantias()) {
            this.setStatusCliente();
            this.setState({ loading: true })
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";

            api.get(`/proposta/${this.state.Dimensionamento.id}/valores/${ExibirValores}`, { responseType: 'blob' })
                .then(response => {
                    const file = new Blob([response.data], { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);

                    a.href = fileURL;
                    a.download = `proposta-${this.state.Dimensionamento.id}.pdf`;
                    a.click();
                    window.URL.revokeObjectURL(fileURL);
                    this.setState({ loading: false });
                    //window.open(fileURL);
                })
                .catch(error => {
                    this.setState({ loading: false });
                    console.log(error);
                });
        }
    }

    async openPropostaResumida() {
        if (!await this.existeMaterialDC()) {
            return;
        }
        if (await this.ValidarGarantias()) {
            this.setStatusCliente();
            this.setState({ loading: true })
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";

            api.get(`/proposta/resumida/${this.state.Dimensionamento.id}`, { responseType: 'blob' })
                .then(response => {
                    const file = new Blob([response.data], { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);

                    a.href = fileURL;
                    a.download = `propostaresumida-${this.state.Dimensionamento.id}.pdf`;
                    a.click();
                    window.URL.revokeObjectURL(fileURL);
                    this.setState({ loading: false });
                    //window.open(fileURL);
                })
                .catch(error => {
                    this.setState({ loading: false });
                    console.log(error);
                });
        }
    }

    rightToolbarTemplate() {
        return (
            <React.Fragment>
                <SplitButton label="Status" icon="pi pi-play" model={this.btnStatusProposta} disabled={this.state.Dimensionamento.idstatus !== 2} />
                <span>&nbsp;</span>
                <SplitButton label="Imprimir" icon="pi pi-print" onClick={() => { this.openProposta('N') }} model={this.items} />

                {/* <Button label="Imprimir" icon="pi pi-print" className="p-button p-mr-2" onClick={this.openProposta} /> */}
                {/* <Button label="E-mail" icon="pi pi-envelope" className="p-button p-mr-2"  /> */}
            </React.Fragment>
        )
    }

    getDimensionamentoParametroServico() {
        return (
            <React.Fragment>
                <DimensionamentoPS
                    idNegocioDimensionamento={this.state.Dimensionamento.id}
                    refresh={this.refresh}
                    qtdModulos={this.state.qtdModulos}
                    atualizarTotal={this.calculatTotalServicos} />
            </React.Fragment>
        )
    }

    onEditorValueChange(productKey, props, value) {
        let updatedProducts = [...props.value];
        updatedProducts[props.rowIndex][props.field] = value;
        this.setState({ [`${productKey}`]: updatedProducts });
    }

    inputTextEditor(productKey, props, field) {
        return <InputText type="text" value={props.rowData[field]} onChange={(e) => this.onEditorValueChange(productKey, props, e.target.value)} />;
    }

    onEditorInit(props) {
        const { rowIndex: index, field, rowData } = props;
        if (!this.editingCellRows[index]) {
            this.editingCellRows[index] = { ...rowData };
        }
        this.editingCellRows[index][field] = this.state.listaDimensionamento[index][field];
    }

    onEditorCancel(props) {
        const { rowIndex: index, field } = props;
        let lista = [...this.state.listaDimensionamento];
        lista[index][field] = this.editingCellRows[index][field];
        delete this.editingCellRows[index][field];

        this.setState({
            listaDimensionamento: lista
        });
    }

    onEditorSubmit(props) {
        const { rowIndex: index, field } = props;
        delete this.editingCellRows[index][field];
        this.onSave();
    }

    async calculatTotalServicos() {
        api.get(`/dimensionamentoservicos/total/${this.state.Dimensionamento.id}`)
            .then(res => {
                this.setState({ totalServico: res.data[0].total })
            })
    }

    exibirPaginacao() {
        let exibir = false;
        if (this.state.listaPrazos.length > 5) { exibir = true }
        return exibir
    }

    novoPrazo() {

    }

    onRowEditInit(event) {
        this.originalRows[event.index] = { ...this.state.listaPrazos[event.index] };
    }

    onRowEditCancel(event) {
        let listaPrazos = [...this.state.listaPrazos];
        listaPrazos[event.index] = this.originalRows[event.index];
        delete this.originalRows[event.index];
        this.setState({ listaPrazos: listaPrazos });
    }

    async rowPrazoEditSave(event) {
        alert("O prazo não está atualizado no banco!")
    }

    inputNumberEditor(listaName, props, field) {
        return <InputNumber mode="decimal" locale="pt-BR" minFractionDigits={2} value={props.rowData[field]}
            onValueChange={(e) => this.onEditorNumberValueChange(listaName, props, e.value)} inputStyle={{ width: '100%' }} />;
    }

    onEditorNumberValueChange(listaName, props, value) {
        let updated = [...props.value];
        updated[props.rowIndex][props.field] = value || 0;
        this.setState({ [`${listaName}`]: updated });
    }

    footerTemplateObsFormaPagamento() {
        if (this.state.editObsFormaPagamento) {
            return (
                <React.Fragment>
                    <Button type="Button" label="Salvar" icon="pi pi-check" className="p-button-success p-mr-2" onClick={this.onSaveObsFormaPagamento} />
                    <Button type="Button" label="Cancelar" icon="pi pi-times" className="p-button-warning p-mr-2" onClick={this.onCancelObsFormaPagamento} />
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <Button
                        type="Button"
                        label="Alterar"
                        icon="pi pi-pencil"
                        className="p-button p-mr-2"
                        onClick={this.onEditObsFormaPagamento}
                        disabled={this.state.Dimensionamento.idstatus > 2} />
                </React.Fragment>
            )

        }
    }

    procuracaoHideDialog() {
        let Dimensionamento = { ...this.state.DimensionamentoEdit }
        this.setState({
            Dimensionamento: Dimensionamento,
            procuracaoDialog: false
        });
    }

    hideDialogStatusEnviado() {
        this.setState({ dialogStatusEnviado: false });
    }

    atualizarStatus() {
        let newStatus = this.emptyStatus;
        newStatus.idstatus = this.state.newIdstatus;
        newStatus.idnegociodimensionamento = this.state.Dimensionamento.id
        api.post('/dimensionamentostatus', newStatus)
            .then(res => {
                if (res.status === 201) {
                    let Dimensionamento = { ...this.state.Dimensionamento };
                    Dimensionamento.idstatus = this.state.newIdstatus;
                    Dimensionamento.status = this.state.newStatus;
                    this.setState({
                        Dimensionamento: Dimensionamento,
                        dialogStatusEnviado: false,
                    });
                    this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Status atualizado', life: 3000 });
                }
            })
            .catch(error => {
                this.setState({ dialogStatusEnviado: false });
                this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu atualizar o status', life: 3000 });
                // console.log(error.response.data.description);
            })
    }

    statusBodyTemplate() {
        let estilo;
        let texto;

        estilo = "p-tag p-tag-rounded p-tag-primary";
        texto = 'EL';
        if ((this.state.Dimensionamento.idstatus) && (this.state.Dimensionamento.idstatus === 1)) {
            estilo = "p-tag p-tag-rounded p-tag-primary";
            texto = 'EL';
        }
        else if ((this.state.Dimensionamento.idstatus) && (this.state.Dimensionamento.idstatus === 2)) {
            estilo = "p-tag p-tag-rounded p-tag-warning";
            texto = 'CL';
        }
        else if ((this.state.Dimensionamento.idstatus) && (this.state.Dimensionamento.idstatus === 3)) {
            estilo = "p-tag p-tag-rounded p-tag-success";
            texto = 'AC';
        }
        else if ((this.state.Dimensionamento.idstatus) && (this.state.Dimensionamento.idstatus === 4)) {
            estilo = "p-tag p-tag-rounded p-tag-danger";
            texto = 'PE';
        }
        return <span className={estilo}>{texto}</span>;
    }

    render() {

        const procuracaoDialogFooter = (
            <React.Fragment>
                <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={this.procuracaoHideDialog} />
                <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={this.onSave} />
            </React.Fragment>
        );

        const dialogFooterStatusEnviado = (
            <React.Fragment>
                <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={this.hideDialogStatusEnviado} />
                <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={this.atualizarStatus} />
            </React.Fragment>
        );

        return (

            <div>
                <LoadingScreen
                    loading={this.state.loading}
                    bgColor='#f1f1f1'
                    spinnerColor='#9ee5f8'
                    textColor='#676767'
                    logoSrc='assets/rota/RotaSolarLogo.png'
                    text='Aguarde...'
                >
                    <div className="content-section implementation">
                        <Toast ref={(el) => this.toast = el} />
                        <h5>Dimensionamento {this.statusBodyTemplate()}</h5>
                        <Panel header="Dados do dimensionamento" >
                            <div>
                                <Toolbar className="p-mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate} />
                                <div className="table-responsive">
                                    <table style={{ width: '100%' }} className="table table-bordered table-light table-sm" >
                                        <thead>
                                            <tr>
                                                <th scope="col">Fornecedor</th>
                                                <th scope="col">Descrição</th>
                                                <th scope="col">Validade</th>
                                                <th scope="col">Módulos</th>
                                                <th scope="col">Potência Módulo</th>
                                                {/* <th scope="col">Potência Inversor</th> */}
                                                <th scope="col">Área útil geração</th>
                                                <th scope="col">Eficiência</th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style={{ width: '20%' }} className="p-text-center">{this.editFornecedor("nomefantasia")}</td>
                                                <td style={{ width: '35%' }} className="p-text-center">{this.editTextTemplate("fornecedor")}</td>
                                                <td style={{ width: '5%' }} className="p-text-center">{this.editNumerTemplate("validade", 0)}</td>
                                                <td style={{ width: '10%' }} className="p-text-center">{this.editNumerTemplate("quantidademodulos", 0)}</td>
                                                <td style={{ width: '10%' }} className="p-text-center">{this.editNumerTemplate("potencia", 2)}</td>
                                                {/* <td style={{ width: '10%' }} className="p-text-center">{this.editNumerTemplate("potenciainversor", 2)}</td> */}
                                                <td style={{ width: '10%' }} className="p-text-center">{this.editNumerTemplate("areautilgeracao", 2)}</td>
                                                <td style={{ width: '10%' }} className="p-text-center">{this.editNumerTemplate("eficiencia", 2)}</td>
                                                <td style={{ width: '10%' }} className="p-text-center">{this.footerTemplate1()}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div>
                                {this.exibirResultado(this.state.Dimensionamento.idnegocio)}
                            </div>
                        </Panel>

                        <Panel>
                            <div className="p-formgrid p-grid">
                                <div className="p-col-12 p-md-12">
                                    <TabView>
                                        <TabPanel header="Materiais">
                                            {this.exibirMateriais(this.state.Dimensionamento.id)}
                                        </TabPanel>
                                        <TabPanel header="Financeiro">
                                            <Custos
                                                idNegocioDimensionamento={this.state.Dimensionamento.id}
                                                qtdModulos={this.state.Dimensionamento.quantidademodulos}
                                                potencia={this.state.Dimensionamento.potencia}
                                                idstatus={this.state.Dimensionamento.idstatus} >
                                            </Custos>
                                            <div className="p-formgrid p-grid">
                                                <div className="p-fluid p-formgrid p-grid" style={{ width: '100%' }}>
                                                    <div className="p-field p-col-12 p-md-12">
                                                        <Panel header="Obs. forma de pagamento">
                                                            <InputTextarea rows={6}
                                                                value={this.state.Dimensionamento.obsformapagamento}
                                                                onChange={(e) => this.onInputChange(e, 'obsformapagamento')}
                                                                autoResize disabled={!this.state.editObsFormaPagamento} />
                                                            <div className="p-d-inline-flex p-mt-3">
                                                                {this.footerTemplateObsFormaPagamento()}
                                                            </div>
                                                        </Panel>
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPanel>

                                        <TabPanel header="Configurações">
                                            <TabView>
                                                <TabPanel header="Prazos">
                                                    <DimensionamentoConfigPrazos
                                                        idNegocioDimensionamento={this.state.Dimensionamento.id}
                                                        idstatus={this.state.Dimensionamento.idstatus}>
                                                    </DimensionamentoConfigPrazos>
                                                </TabPanel>

                                                <TabPanel header="Garantias">
                                                    <DimensionamentoConfigGarantia
                                                        dimensionamento={this.state.Dimensionamento}
                                                        atualizar={this.atualizarGarantia}>
                                                    </DimensionamentoConfigGarantia>
                                                </TabPanel>

                                                <TabPanel header="Lista de Rateio">
                                                    <DimensionamentoContratos
                                                        idNegocioDimensionamento={this.state.Dimensionamento.id}
                                                        idstatus={this.state.Dimensionamento.idstatus}>
                                                    </DimensionamentoContratos>
                                                </TabPanel>

                                                <TabPanel header="Procuração">
                                                    <Toolbar className="p-mb-4" left={this.leftToolbarProcuracao} right={this.rightToolbarProcuracao}></Toolbar>
                                                    <div className="table-responsive">
                                                        <table style={{ width: '100%' }} className="table table-bordered table-light table-sm" >
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" className="p-text-left" >Nome Responsável</th>
                                                                    <th scope="col" className="p-text-left" >CPF</th>
                                                                    <th scope="col" className="p-text-left" >Validade</th>
                                                                    <th scope="col"></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td style={{ width: '60%' }} className="p-text-center">{this.state.Dimensionamento.procuracaonome}</td>
                                                                    <td style={{ width: '20%' }} className="p-text-center">{this.state.Dimensionamento.procuracaocpf}</td>
                                                                    <td style={{ width: '20%' }} className="p-text-center">{this.formatData(this.state.Dimensionamento.procuracaovalidade, "dd/MM/yyyy")}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </TabPanel>

                                                <TabPanel header="Itens Inclusos">
                                                    <DimensionamentoConfigIncluso
                                                        idNegocioDimensionamento={this.state.Dimensionamento.id}
                                                        idstatus={this.state.Dimensionamento.idstatus}>
                                                    </DimensionamentoConfigIncluso>
                                                </TabPanel>

                                                <TabPanel header="Itens Não Inclusos">
                                                    <DimensionamentoConfigNaoIncluso
                                                        idNegocioDimensionamento={this.state.Dimensionamento.id}
                                                        idstatus={this.state.Dimensionamento.idstatus}>
                                                    </DimensionamentoConfigNaoIncluso>
                                                </TabPanel>
                                            </TabView>
                                        </TabPanel>
                                        <TabPanel header="Anexos">
                                            <AnexosDimensionamento
                                                idDimensionamento={this.state.Dimensionamento.id}
                                                idstatus={this.state.Dimensionamento.idstatus} />
                                        </TabPanel>
                                    </TabView>
                                </div>
                            </div>
                        </Panel>
                    </div>

                    <Dialog visible={this.state.procuracaoDialog} style={{ width: '600px' }} header="Procuração" modal className="p-fluid" footer={procuracaoDialogFooter} onHide={this.procuracaoHideDialog}>

                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12">
                                <label htmlFor="procuracaonome">Nome Responsável</label>
                                <InputText id="procuracaonome" value={this.state.Dimensionamento.procuracaonome} onChange={(e) => this.onInputChange(e, 'procuracaonome')}
                                />
                            </div>
                            <div className="p-field p-col-12">
                                <label htmlFor="procuracaocpf">CPF</label>
                                <InputMask id="procuracaocpf"
                                    mask="999.999.999-99"
                                    value={this.state.Dimensionamento.procuracaocpf}
                                    onChange={(e) => this.onInputMaskChange(e, 'procuracaocpf')}
                                />
                            </div>
                            <div className="p-field p-col-12">
                                <label htmlFor="procuracaovalidade">Validade</label>
                                <Calendar
                                    id="dataprocuracaovalidade"
                                    locale="pt"
                                    dateFormat="dd/mm/yy"
                                    value={parseISO(this.state.Dimensionamento.procuracaovalidade)}
                                    onChange={(e) => this.onDataChange(e, 'procuracaovalidade')} inline showWeek />
                            </div>
                        </div>
                    </Dialog>
                    <Dialog
                        visible={this.state.dialogStatusEnviado}
                        style={{ width: '450px' }}
                        header="Confirma"
                        modal
                        footer={dialogFooterStatusEnviado}
                        onHide={this.hideDialogStatusEnviado}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {this.state.Dimensionamento && <span>Deseja alterar o Status da proposta para <b>{this.state.newStatus}</b>?</span>}
                        </div>
                    </Dialog>

                </LoadingScreen>
            </div>
        );
    }

}
