import React, { Component } from 'react';
import api from '../../service/api';
import { PropTypes } from 'prop-types';
import { DataTable } from '../../components/datatable/DataTable';
import { Column } from '../../components/column/Column';
import { InputNumber } from '../../components/inputnumber/InputNumber';

export class ParametroPerdaEdit extends Component {

    static defaultProps = {
        idnegocio: 1
    }

    static propTypes = {
        idnegocio: PropTypes.number
    }

    getPerdaTotal = () => {
        this.props.refreshPerdaTotal(this.props.value);
    }

    constructor(props) {
        super(props);

        this.state = {
            paramPerda: [],

        };

        this.originalRows = {};

        this.onRowEditInit = this.onRowEditInit.bind(this);
        this.onRowEditCancel = this.onRowEditCancel.bind(this);
        this.onRowEditSave = this.onRowEditSave.bind(this);
    }

    async componentDidMount() {
        const Perda = await api.get(`/parametrossolarimetricos/negocio/${this.props.idnegocio}`);
        this.setState({ paramPerda: Perda.data });

    }

    onRowEditInit(event) {
        this.originalRows[event.index] = { ...this.state.paramPerda[event.index] };
    }

    onRowEditCancel(event) {
        let Params = [...this.state.paramPerda];
        Params[event.index] = this.originalRows[event.index];
        delete this.originalRows[event.index];

        this.setState({ paramPerda: Params });
    }

    async onRowEditSave(event) {
        let Param = { ...this.state.paramPerda[event.index] };
        await api.put(`/parametrossolarimetricos/${Param.id}`, Param);
        this.getPerdaTotal();
    }

    onEditorValueChange(paramKey, props, value) {
        let updatedParams = [...props.value];
        updatedParams[props.rowIndex][props.field] = value || 0;
        this.setState({ [`${paramKey}`]: updatedParams });
    }

    inputNumberEditor(productKey, props, field) {
        return <InputNumber mode="decimal" locale="pt-BR" minFractionDigits={2} value={props.rowData[field]} 
        onValueChange={(e) => this.onEditorValueChange(productKey, props, e.value)} />;
    }

    paramValueEditor(productKey, props) {
        return this.inputNumberEditor(productKey, props, 'valor');
    }

    render() {

        return (
            <div>
                <DataTable dataKey="id" value={this.state.paramPerda} editMode="row" className="editable-cells-table p-datatable-sm p-datatable-gridlines"
                    onRowEditInit={this.onRowEditInit} onRowEditCancel={this.onRowEditCancel} onRowEditSave={this.onRowEditSave} >
                    <Column field="parametro" header="Nome"></Column>
                    <Column field="valor" header="Valor" editor={(props) => this.paramValueEditor('paramPerda', props)}> </Column>
                    <Column rowEditor headerStyle={{ width: '7rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                </DataTable>
            </div>
        )
    }
}   