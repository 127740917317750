import React, { Component } from 'react';
import api from '../../../service/api';
import classNames from 'classnames';
import { Button } from '../../../components/button/Button';
import { Dialog } from '../../../components/dialog/Dialog';
import { DataTable } from '../../../components/datatable/DataTable';
import { Column } from '../../../components/column/Column'
import { InputText } from '../../../components/inputtext/InputText';
import { Toast } from '../../../components/toast/Toast';
import { Toolbar } from '../../../components/toolbar/Toolbar';
import { InputNumber } from '../../../components/inputnumber/InputNumber';

export class ParametroServico extends Component {

    emptyParametro = {
        "id": null,
        "descricao": '',
        "valorpadrao": 0,
        "unidade": ''
    }

    constructor(props) {
        super(props);

        this.state = {
            parametro: this.emptyParametro,
            listaParametros: [],
            submitted: false,
            edicao: false,
            parametroDialog: false,
            deleteParametroDialog: false,
            rows: 10,
            first: 1,
        }

        this.originalRows = {};

        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.openNewParametro = this.openNewParametro.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.saveParametro = this.saveParametro.bind(this);
        this.editParametro = this.editParametro.bind(this);
        this.confirmDeleteParametro = this.confirmDeleteParametro.bind(this);
        this.hideDeleteParametroDialog = this.hideDeleteParametroDialog.bind(this);
        this.deleteParametro = this.deleteParametro.bind(this);
        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.rightToolbarTemplate = this.rightToolbarTemplate.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
    }

    async componentDidMount() {
        const listaParametros = await api.get(`/parametrosservicos`);
        this.setState({
            listaParametros: listaParametros.data
        });
    }

    hideDeleteParametroDialog() {
        this.setState({ deleteParametroDialog: false });
    }

    confirmDeleteParametro(parametro) {
        this.setState({
            parametro,
            deleteParametroDialog: true
        });
    }

    deleteParametro() {
        api.delete(`/parametrosservicos/${this.state.parametro.id}`)
            .then(res => {
                if (res.status === 204) {
                    let listaParametros = this.state.listaParametros.filter(val => val.id !== this.state.parametro.id);
                    this.setState({
                        listaParametros,
                        deleteParametroDialog: false,
                        parametro: this.emptyParametro
                    });
                    this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Parametro excluído', life: 3000 });
                }
            })
            .catch(error => {
                this.setState({deleteParametroDialog: false})
                this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu excluir o Parâmetro', life: 3000 });
                // console.log(error.response.data.description);
            })
    }

    editParametro(parametro) {
        this.setState({
            parametro: { ...parametro },
            parametroDialog: true
        });
    }

    openNewParametro() {
        this.setState({
            parametro: this.emptyParametro,
            submitted: false,
            parametroDialog: true
        });
    }

    hideDialog() {
        this.setState({
            submitted: false,
            parametroDialog: false
        });
    }

    async saveParametro() {
        let state = { submitted: true };

        if (this.state.parametro.descricao.trim() && this.state.parametro.valorpadrao >= 0 && this.state.parametro.unidade.trim()) {

            let listaParametros = [...this.state.listaParametros];
            let parametro = { ...this.state.parametro };
            if (this.state.parametro.id) {
                const index = this.findIndexById(this.state.parametro.id);
                api.put(`/parametrosservicos/${this.state.parametro.id}`, parametro)
                    .then(res => {
                        if (res.status === 204) {
                            listaParametros[index] = parametro;
                            state = {
                                ...state,
                                listaParametros,
                                parametroDialog: false,
                                parametro: this.emptyParametro
                            };
                            this.setState(state);
                            this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Parametro atualizado', life: 3000 });
                        }
                    })
                    .catch(error => {
                        this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu atualizar o Parâmetro', life: 3000 });
                        // console.log(error.response.data.description);
                    })
            }
            else {
                parametro.id = 0;
                parametro.idnegociodimensionamento = this.props.idDimensionamento;
                api.post('/parametrosservicos', parametro)
                    .then(res => {
                        if (res.status === 201) {
                            parametro.id = res.headers.id;
                            listaParametros.push(parametro);
                            state = {
                                ...state,
                                listaParametros,
                                parametroDialog: false,
                                parametro: this.emptyParametro
                            };
                            this.setState(state);
                            this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Parâmetro inserido', life: 3000 });
                        }
                    })
                    .catch(error => {
                        this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu inserir o Parâmetro', life: 3000 });
                        // console.log(error.response.data.description);
                    })
            }
        }
        else {
            this.setState(state);
        }
    }

    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-rounded p-mr-2" onClick={() => this.editParametro(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => this.confirmDeleteParametro(rowData)} />
            </React.Fragment>
        );
    }

    onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        const input = e.target;
        const start = input.selectionStart;
        const end = input.selectionEnd;        
        let parametro = { ...this.state.parametro };
        parametro[`${name}`] = val.toUpperCase();
        this.setState({ parametro: parametro },
            () => input.setSelectionRange(start, end));
    }

    onInputNumberChange(e, name) {
        const val = e.value || 0;
        let parametro = { ...this.state.parametro };
        parametro[`${name}`] = val;
        this.setState({ parametro: parametro });        

        // const val = e.value || 0;
        // let total = 0;
        // let parametro = { ...this.state.parametro };
        // parametro[`${name}`] = val;
        // if ((parametro.valorunitario) && (parametro.quantidade)) {
        //     total = parametro.valorunitario * parametro.quantidade;
        // }
        // parametro.total = total;
        // this.setState({ parametro: parametro });
    }

    findIndexById(id) {
        let index = -1;
        for (let i = 0; i < this.state.listaParametros.length; i++) {
            if (this.state.listaParametros[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    onUnidadeChange(e) {
        let parametro = { ...this.state.parametro };
        parametro.idund = e.value;
        this.setState({ parametro });
    }

    formatarNumero(rowData, field) {
        return rowData[field].toLocaleString('pt-BR', { style: 'decimal', minimumFractionDigits: 2 });
    }

    formatarCurrency(rowData, field) {
        return rowData[field].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 });
    }

    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="Novo" icon="pi pi-plus" className="p-button p-mr-2" onClick={this.openNewParametro} />
            </React.Fragment>
        )
    }

    rightToolbarTemplate() {

    }

    onPageChange = (event) => {
        this.setState({rows : event.rows, first : event.first});
    }

    render() {
        const header = (
            <div className="table-header">
                <h5 className="p-m-0">Listagem</h5>
            </div>
        );

        const parametroDialogFooter = (
            <React.Fragment>
                <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={this.saveParametro} />
            </React.Fragment>
        );

        const deleteParametroDialogFooter = (
            <React.Fragment>
                <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteParametroDialog} />
                <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={this.deleteParametro} />
            </React.Fragment>
        );

        return (
            <div>
                <Toast ref={(el) => this.toast = el} />

                <Toolbar className="p-mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>

                <DataTable
                    className="p-datatable-gridlines p-datatable-sm"
                    ref={(el) => this.dt = el}
                    value={this.state.listaParametros}
                    header={header}
                    // first={this.state.first}
                    paginator={this.state.listaParametros.length > 5}
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25]}
                    // onPage={event => this.onPageChange(event)}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Exibindo {first} até {last} de {totalRecords} Parâmetros">
                    <Column field="descricao" header="Descrição" style={{ width: '50%' }} ></Column>
                    <Column field="valorpadrao" header="Valor Padrão" ></Column>
                    <Column field="unidade" header="Unidade" ></Column>
                    <Column body={this.actionBodyTemplate}></Column>
                </DataTable>

                <Dialog visible={this.state.parametroDialog} style={{ width: '550px' }} header="Parâmetro de Serviço" modal className="p-fluid" footer={parametroDialogFooter}
                    onHide={this.hideDialog}>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12">
                            <label htmlFor="descricao">Descrição</label>
                            <InputText id="descricao" value={this.state.parametro.descricao} onChange={(e) => this.onInputChange(e, 'descricao')}
                                required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.parametro.descricao })} 
                                 />
                            {this.state.submitted && !this.state.parametro.descricao && <small className="p-invalid">Descrição é requerido.</small>}
                        </div>
                        <div className="p-field p-col-12">
                            <label htmlFor="valorpadrao">Valor Padrão</label>
                            <InputNumber id="valorpadrao" value={this.state.parametro.valorpadrao} onChange={(e) => this.onInputNumberChange(e, 'valorpadrao')}
                                required className={classNames({ 'p-invalid': this.state.submitted && !this.state.parametro.valorpadrao })}/>
                            {this.state.submitted && !this.state.parametro.valorpadrao && <small className="p-invalid">Valor padrão é requerido.</small>}
                            {this.state.submitted && this.state.parametro.valorpadrao < 0 && <small className="p-invalid">O valor padrão não pode ser negativo.</small>}
                        </div>
                        <div className="p-field p-col-12">
                            <label htmlFor="unidade">Unidade</label>
                            <InputText id="unidade" value={this.state.parametro.unidade} onChange={(e) => this.onInputChange(e, 'unidade')}
                                required className={classNames({ 'p-invalid': this.state.submitted && !this.state.parametro.unidade })} 
                                 />
                            {this.state.submitted && !this.state.parametro.unidade && <small className="p-invalid">Unidade é requerida.</small>}
                        </div>
                    </div>
                </Dialog>

                <Dialog visible={this.state.deleteParametroDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteParametroDialogFooter}
                    onHide={this.hideDeleteParametroDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        {this.state.parametro && <span>Tem certeza que deseja excluir <b>{this.state.parametro.descricao}</b>?</span>}
                    </div>
                </Dialog>
            </div>
        )
    }
}
