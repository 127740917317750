import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Carousel } from '../../components/carousel/Carousel';
import { Button } from '../../components/button/Button';
import api from '../../service/api';
import { PricingTable, PricingSlot, PricingDetail } from 'react-pricing-table';
import { format, isThisHour, parseISO } from "date-fns";
import LoadingScreen from 'react-loading-screen';

export class SelecionarPropostas extends Component {

    emptyNegocio = {
        "id": 0,
        "descricao": "",
        "idcliente": 0,
        "nome": "",
        "idtipoinstalacao": 0,
        "tipo": "",
        "datacadastro": "",
        "classificacao": ""
    }

    emptyDimensionamento = {
        "iddimensionamento": 0,
        "potenciasistema": 0.0,
        "geracaomediamensalestimada": 0,
        "quantidademodulos": 0,
        "areaminimanecessaria": 0,
        "precototal": 0.0,
        "tarifa": 0.0,
        "economiamediamensal": 0.0,
        "valorwp": 0.0,
        "temporetorno": "",
        "garantiaservico": "",
        "dataelaboracao": null,
        "validade": null,
        "percentualatendido": 0,
        "temporetornoanos": "",
        "temporetornomeses": "",
        "fornecedor": "",
        "garantiadcok": false,
        "descricaoinversor": "",
        "garantiainversor": "",
        "sqlinversores": [],
    };

    constructor(props) {
        super(props);

        this.state = {
            Dimensionamentos: null,
            dimensionamento: this.emptyDimensionamento,
            negocio: this.emptyNegocio,
            loading: true
        };

        this.responsiveOptions = [
            {
                breakpoint: '1024px',
                numVisible: 3,
                numScroll: 3
            },
            {
                breakpoint: '600px',
                numVisible: 2,
                numScroll: 2
            },
            {
                breakpoint: '480px',
                numVisible: 1,
                numScroll: 1
            }
        ];

        this.garantia = '';

        this.dimensionamentoTemplate = this.dimensionamentoTemplate.bind(this);
        this.formatarCurrency = this.formatarCurrency.bind(this);
        this.teste = this.teste.bind(this);
        this.getBadge = this.getBadge.bind(this);
        this.dateYear = this.dateYear.bind(this);
        this.getCabecalho = this.getCabecalho.bind(this);
        this.openProposta = this.openProposta.bind(this);
        this.configData = this.configData.bind(this);
    }

    async componentDidMount() {
        const { id } = this.props.match.params;
        const Negocio = await api.get(`/negocios/${id}`);
        const response = await api.get(`/proposta/comparativo/${id}`);

        let teste = JSON.parse(sessionStorage.getItem('dimensionamentos'));
        let propostas = [];
        for (let i = 0; i < response.data.length; i++) {
            for (let j = 0; j < teste.length; j++) {
                if (response.data[i].iddimensionamento === teste[j].id) {
                    propostas.push(response.data[i]);
                }
            }
        }
        this.setState({ Dimensionamentos: propostas, negocio: Negocio.data, loading: false });
    }


    dateYear(value) {
        const Data = parseISO(value);
        return Data.getFullYear().toString()
    }

    formatarCurrency(value) {
        let ret = value || 0.00;
        return ret.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 });
    }

    round_abnt(nValor, nDecimais) {

        var nRetorno = nValor;
        var spl = nValor.toString().split(".");
        var cDecimais = spl[1];
        var nSubsequente = nDecimais;

        if (nDecimais < 1) {
            return parseInt(nRetorno);
        }

        if (!cDecimais || cDecimais.length <= nDecimais) {
            return parseFloat(nRetorno);
        }

        //Se a casa decimal SUBSEQUENTE for DIFERENTE de 5
        if (cDecimais.substr(nSubsequente, 1) >= '5' || cDecimais.substr(nSubsequente, 1) < '5') {
            nRetorno = nRetorno.toFixed(2); //Math.round((nRetorno + 0.00001) * 100) / 100; //ARREDONDA
        }
        //Se a casa decimal SUBSEQUENTE for IGUAL a 5
        else if (cDecimais.substr(nSubsequente, 1) == '5') {

            //Se a casa decimal que será CONSERVADA, for IMPAR
            if ((cDecimais.substr(nDecimais - 1, 1) % 2) != 0) {
                nRetorno = nRetorno.toFixed(2); //Math.round((nRetorno + 0.00001) * 100) / 100; //ARREDONDA
            }
            //Se a casa decimal que será CONSERVADA, for PAR
            else
                //Se APÓS a casa decimal SUBSEQUENTE, houver ALGUM algarismo MAIOR que ZERO
                if (cDecimais.substr(nSubsequente + 1, 1) > 0) {
                    nRetorno = nRetorno.toFixed(2); //Math.round((nRetorno + 0.00001) * 100) / 100; //ARREDONDA
                }
                //Se APÓS a casa decimal SUBSEQUENTE, não houver NENHUM outro algarismo ou TODOS forem iguais a ZERO
                else {
                    //TRUNCA (Esse é o único momento em que o "arredondamento ABNT" se diferencia do "arredondamento normal")
                    nRetorno = this.Truncate(nValor, nDecimais);
                }
        }
        return parseFloat(nRetorno);
    }

    Truncate(nValor, nDecimais) {

        var nRetorno = nValor;
        var spl = nValor.toString().split(".");
        var cDecimais = spl[1];

        if (nDecimais < 1) {
            return parseInt(nRetorno);
        }

        if (cDecimais.length <= nDecimais) {
            return nRetorno;
        }

        //Pega a parte inteira do número e concatena com a substring sem alterar, pois é PAR e vai manter!
        nRetorno = parseInt(nValor.toString()) + '.' + cDecimais.substr(0, nDecimais);
        nRetorno = parseFloat(nRetorno);

        return nRetorno;
    }

    getBadge(texto) {
        return (
            // <div style={{color: 'white', backgroundColor: '#0078d4'}}><b>{texto}</b></div>
            <div className="badges p-text-center">
                <span className="p-tag p-badge-xl p-m-2"> {texto} </span>
            </div>
        )
    }

    configData(data) {
        const _data = parseISO(data);
        return format(_data, "dd/MM/yyyy");
    }

    teste(dimensionamento) {
        const titulo = `Proposta ${dimensionamento.iddimensionamento}/` + this.dateYear(dimensionamento.dataelaboracao);
        return (
            <PricingTable highlightColor='#1976D2'>
                <PricingSlot onClick={() => this.openProposta(dimensionamento)} buttonText='Visualizar' title={titulo} priceText={this.formatarCurrency(dimensionamento.precototal)}>


                    {
                        dimensionamento.sqlinversores && dimensionamento.sqlinversores.map((inversor) => {

                            if (inversor.exibirproposta) {
                                this.garantia = inversor.prazo;
                            }

                            return (
                                <PricingDetail>
                                    <b>{inversor.quantidade > 1 ? inversor.quantidade : ''} {inversor.descricao}</b>
                                    {this.getBadge(`Garantia de ${this.garantia}`)}
                                </PricingDetail>
                            )
                        }
                        )
                    }

                    <PricingDetail> <b><font size="3">{this.formatarCurrency(dimensionamento.valorwp)}</font></b> R$/Wp</PricingDetail>
                    <PricingDetail> <b><font size="3">{this.round_abnt(dimensionamento.potenciasistema, 2)} kWp</font></b> de potência</PricingDetail>
                    <PricingDetail> <b><font size="3">{this.round_abnt(dimensionamento.geracaomediamensalestimada, 0).toLocaleString('pt-BR', { style: 'decimal', maximumFractionDigits: 0, minimumFractionDigits: 0 })} kWh</font></b> de geração por mês</PricingDetail>
                    <PricingDetail> <b><font size="3">{this.round_abnt(dimensionamento.percentualatendido, 0)}%</font></b> de atendimento</PricingDetail>
                    <PricingDetail> <b>Retorno em {dimensionamento.temporetornoanos + dimensionamento.temporetornomeses}</b></PricingDetail>
                    <PricingDetail> Proposta válida até <b>{this.configData(dimensionamento.validade)}</b></PricingDetail>
                </PricingSlot>
            </PricingTable>
        )
    }

    async openProposta(dimensionamento) {
        if (!dimensionamento.garantiadcok) {
            this.toast.show({ severity: 'warn', summary: 'Atenção', detail: 'Para visualizar a proposta e necessário informar a garantia em todos os materiais dc.', life: 4000 });
        }
        else if (!dimensionamento.garantiaservico) {
            this.toast.show({ severity: 'warn', summary: 'Atenção', detail: 'Para visualizar a proposta e necessário informar a garantia do serviço.', life: 4000 });
        }
        else {
            this.setState({ loading: true })
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";

            api.get(`/proposta/${dimensionamento.iddimensionamento}/valores/n`, { responseType: 'blob' })
                .then(response => {
                    const file = new Blob([response.data], { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);

                    a.href = fileURL;
                    a.download = `proposta-${dimensionamento.iddimensionamento}.pdf`;
                    a.click();
                    window.URL.revokeObjectURL(fileURL);
                    this.setState({ loading: false });
                    //window.open(fileURL);
                })
                .catch(error => {
                    this.setState({ loading: false });
                    console.log(error);
                });
        }
    }

    dimensionamentoTemplate(dimensionamento) {
        return (
            <div className="product-item">
                <div className="product-item-content">
                    <div className="p-mb-3">
                    </div>
                    <div>
                        <h4 className="p-mb-1">{dimensionamento.fornecedor}</h4>
                        <h6 className="p-mt-0 p-mb-3">Qtd. Módulos: {dimensionamento.quantidademodulos}</h6>
                        <h5 className="p-mt-0 p-mb-3">Potência Pico: {this.round_abnt(dimensionamento.potenciasistema, 2)} kWp</h5>
                        <h5 className="p-mt-0 p-mb-3">Geração média mensal: {this.round_abnt(dimensionamento.geracaomediamensalestimada, 0)} kWh</h5>
                        <h5 className="p-mt-0 p-mb-3">Percentual atendido: {this.round_abnt(dimensionamento.percentualatendido, 0)}%</h5>
                        <h5 className="p-mt-0 p-mb-3">R$/Wp: {this.formatarCurrency(dimensionamento.valorwp)}</h5>
                        <h5 className="p-mt-0 p-mb-3">Investimento: {this.formatarCurrency(dimensionamento.precototal)}</h5>
                        <h6 className="p-mt-0 p-mb-3">Retorno: {dimensionamento.temporetornoanos + dimensionamento.temporetornomeses}</h6>
                        <div className="car-buttons p-mt-5">
                            <Button icon="pi pi-search" className="p-button p-button-rounded p-mr-2" />
                            <Button icon="pi pi-star" className="p-button-success p-button-rounded p-mr-2" />
                            <Button icon="pi pi-cog" className="p-button-help p-button-rounded" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    getCabecalho() {
        return (
            <div>
                <h5>{this.state.negocio.nome}</h5>
            </div>

        )
    }

    render() {
        return (
            <div>
                <LoadingScreen
                    loading={this.state.loading}
                    bgColor='#f1f1f1'
                    spinnerColor='#9ee5f8'
                    textColor='#676767'
                    logoSrc='assets/rota/RotaSolarLogo.png'
                    text='Aguarde...'
                >
                    <div className="carousel-demo">
                        <div className="card">
                            {/* implementar função para exibir o Carrosel quando houverem mais de 3 cards */}
                            <Carousel
                                value={this.state.Dimensionamentos}
                                numVisible={3}
                                numScroll={1}
                                responsiveOptions={this.responsiveOptions}
                                itemTemplate={this.teste}
                                header={this.getCabecalho()} />

                            {/* <div className="product-item">
                        <div className="product-item-content">
                            <div className="p-mb-3">
                            </div>
                            <div>
                                <h4 className="p-mb-1">{this.state.dimensionamento.fornecedor}</h4>
                                <h6 className="p-mt-0 p-mb-3">${this.state.dimensionamento.quantidademodulos}</h6>
                                <span className={`product-badge status-${this.state.dimensionamento.fornecedor.toLowerCase()}`}>
                                    {this.state.dimensionamento.fornecedor}
                                </span>
                                <div className="car-buttons p-mt-5">
                                    <Button icon="pi pi-search" className="p-button p-button-rounded p-mr-2" />
                                    <Button icon="pi pi-star" className="p-button-success p-button-rounded p-mr-2" />
                                    <Button icon="pi pi-cog" className="p-button-help p-button-rounded" />
                                </div>
                            </div>
                        </div>
                    </div> */}
                        </div>
                    </div>
                </LoadingScreen>
            </div>
        );
    }
}