import React, { Component } from 'react';
import api from '../../service/api';
import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import { Button } from '../../components/button/Button';
import { Dialog } from '../../components/dialog/Dialog';
import { Dropdown } from '../../components/dropdown/Dropdown';
import { DataTable } from '../../components/datatable/DataTable';
import { Column } from '../../components/column/Column'
import { InputText } from '../../components/inputtext/InputText';
import { Toast } from '../../components/toast/Toast';
import { InputNumber } from '../../components/inputnumber/InputNumber';

export class ObraEdit extends Component {

    static defaultProps = {
        idDimensionamento: 1,
        idstatus: 1
    }

    static propTypes = {
        idDimensionamento: PropTypes.number,
        idstatus: PropTypes.number
    }

    emptyObra = {
        "id": null,
        "idnegociodimensionamento": null,
        "descricao": '',
        "quantidade": 0,
        "idund": null,
        "und": "",
        "valorunitario": 0,
        "total": 0,
        "unidade": '' 
    }

    atualizarTotal = () => {
        this.props.atualizarTotal(this.props.value);
    }

    constructor(props) {
        super(props);

        this.state = {
            obra: this.emptyObra,
            listaObras: [],
            unidades: [],
            submitted: false,
            edicao: false,
            obraDialog: false,
            deleteObraDialog: false
        }

        this.originalRows = {};

        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.openNewObra = this.openNewObra.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.saveObra = this.saveObra.bind(this);
        this.editObra = this.editObra.bind(this);
        this.confirmDeleteObra = this.confirmDeleteObra.bind(this);
        this.hideDeleteObraDialog = this.hideDeleteObraDialog.bind(this);
        this.deleteObra = this.deleteObra.bind(this);
    }

    async componentDidMount() {
        const listaObras = await api.get(`/obracivil/dimensionamento/${this.props.idDimensionamento}`);
        const unidades = await api.get('/unidadesmedida');
        this.setState({
            listaObras: listaObras.data,
            unidades: unidades.data
        });
    }

    hideDeleteObraDialog() {
        this.setState({ deleteObraDialog: false });
    }

    confirmDeleteObra(obra) {
        this.setState({
            obra,
            deleteObraDialog: true
        });
    }

    deleteObra() {
        api.delete(`/obracivil/${this.state.obra.id}`)
            .then(res => {
                if (res.status === 204) {
                    let listaObras = this.state.listaObras.filter(val => val.id !== this.state.obra.id);
                    this.setState({
                        listaObras,
                        deleteObraDialog: false,
                        obra: this.emptyObra
                    });
                    this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Obra excluída', life: 3000 });
                    this.atualizarTotal();
                }
            })
    }

    editObra(obra) {
        this.setState({
            obra: { ...obra },
            obraDialog: true
        });
    }

    openNewObra() {
        this.setState({
            obra: this.emptyObra,
            submitted: false,
            obraDialog: true
        });
    }

    hideDialog() {
        this.setState({
            submitted: false,
            obraDialog: false
        });
    }

    async saveObra() {
        let state = { submitted: true };

        if (this.state.obra.descricao.trim() && this.state.obra.valorunitario && this.state.obra.valorunitario > 0
            && this.state.obra.quantidade && this.state.obra.quantidade > 0 && this.state.obra.idund) {

            let listaObras = [...this.state.listaObras];
            let obra = { ...this.state.obra };
            if (this.state.obra.id) {
                const index = this.findIndexById(this.state.obra.id);
                api.put(`/obracivil/${this.state.obra.id}`, obra)
                    .then(res => {
                        if (res.status === 204) {
                            listaObras[index] = obra;
                            state = {
                                ...state,
                                listaObras,
                                obraDialog: false,
                                obra: this.emptyObra
                            };
                            this.setState(state);
                            this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Obra atualizada', life: 3000 });
                            this.atualizarTotal();
                        }
                    })
                    .catch(error => {
                        this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu atualizar a Obra', life: 3000 });
                        // console.log(error.response.data.description);
                    })
            }
            else {
                obra.id = 0;
                obra.idnegociodimensionamento = this.props.idDimensionamento;
                api.post('/obracivil', obra)
                    .then(res => {
                        if (res.status === 201) {
                            obra.id = res.headers.id;
                            listaObras.push(obra);
                            state = {
                                ...state,
                                listaObras,
                                obraDialog: false,
                                obra: this.emptyObra
                            };
                            this.setState(state);
                            this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Obra inserida', life: 3000 });
                            this.atualizarTotal();
                        }
                    })
                    .catch(error => {
                        this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu inserir a Obra', life: 3000 });
                        // console.log(error.response.data.description);
                    })
            }
        }
        else {
            this.setState(state);
        }
    }

    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button p-mr-2"
                    onClick={() => this.editObra(rowData)}
                    tooltip="Alterar"
                    tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} 
                    disabled={this.props.idstatus > 2} />
                <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger"
                    onClick={() => this.confirmDeleteObra(rowData)}
                    tooltip="Excluir"
                    tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} 
                    disabled={this.props.idstatus > 2} />
            </React.Fragment>
        );
    }

    onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        const input = e.target;
        const start = input.selectionStart;
        const end = input.selectionEnd;
        let obra = { ...this.state.obra };
        obra[`${name}`] = val.toUpperCase();
        this.setState({ obra: obra },
            () => input.setSelectionRange(start, end));
    }

    onInputNumberChange(e, name) {
        const val = e.value || 0;
        let total = 0;
        let obra = { ...this.state.obra };
        obra[`${name}`] = val;
        if ((obra.valorunitario) && (obra.quantidade)) {
            total = obra.valorunitario * obra.quantidade;
        }
        obra.total = total;
        this.setState({ obra: obra });
    }

    findIndexById(id) {
        let index = -1;
        for (let i = 0; i < this.state.listaObras.length; i++) {
            if (this.state.listaObras[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    findUnidade(id) {
        let index = -1;
        for (let i = 0; i < this.state.unidades.length; i++) {
            if (this.state.unidades[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    onUnidadeChange(e) {
        let obra = { ...this.state.obra };
        obra.idund = e.value;
        obra.unidade = this.state.unidades[this.findUnidade(e.value)].unidade;
        this.setState({ obra });
    }

    formatarNumero(rowData, field) {
        return rowData[field].toLocaleString('pt-BR', { style: 'decimal', minimumFractionDigits: 2 });
    }

    formatarCurrency(rowData, field) {
        return rowData[field].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 });
    }

    render() {
        const header = (
            <div className="table-header" left={this.busca}>
                <Button 
                    label="Novo" 
                    icon="pi pi-plus" 
                    className="p-button p-mr-2" 
                    onClick={this.openNewObra} 
                    disabled={this.props.idstatus > 2} />
            </div>
        );

        const obraDialogFooter = (
            <React.Fragment>
                <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={this.saveObra} />
            </React.Fragment>
        );

        const deleteObraDialogFooter = (
            <React.Fragment>
                <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteObraDialog} />
                <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={this.deleteObra} />
            </React.Fragment>
        );

        return (
            <div>
                <Toast ref={(el) => this.toast = el} />
                <DataTable header={header} value={this.state.listaObras} className="p-datatable-sm p-datatable-gridlines">
                    <Column field="id" header="Id" style={{ width: '6%' }} />
                    <Column className="p-editable-column p-cell-editing" field="descricao" header="Descrição" style={{ width: '30%' }} />
                    <Column field="quantidade" header="Qtd." body={(rowData) => this.formatarNumero(rowData, 'quantidade')} style={{ width: '8%' }} />
                    <Column field="unidade" header="Und" style={{ width: '8%' }} />
                    <Column field="valorunitario" header="Valor" body={(rowData) => this.formatarCurrency(rowData, 'valorunitario')} style={{ width: '12%' }} />
                    <Column field="total" header="Total" body={(rowData) => this.formatarCurrency(rowData, 'total')} style={{ width: '12%' }} />
                    <Column body={this.actionBodyTemplate} style={{ width: '14%' }} ></Column>
                </DataTable>

                <Dialog visible={this.state.obraDialog} style={{ width: '550px' }} header="Obra Adicional" modal className="p-fluid" footer={obraDialogFooter}
                    onHide={this.hideDialog}>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12">
                            <label htmlFor="descricao">Descrição</label>
                            <InputText id="descricao" value={this.state.obra.descricao} onChange={(e) => this.onInputChange(e, 'descricao')}
                                required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.obra.descricao })} 
                                />
                            {this.state.submitted && !this.state.obra.descricao && <small className="p-invalid">Descrição é requerido.</small>}
                        </div>
                        <div className="p-field p-col-12">
                            <label htmlFor="quantidade">Quantidade</label>
                            <InputNumber id="quantidade" value={this.state.obra.quantidade} onValueChange={(e) => this.onInputNumberChange(e, 'quantidade')}
                                required className={classNames({ 'p-invalid': this.state.submitted && !this.state.obra.quantidade })}
                                mode="decimal" locale="pt-BR" minFractionDigits={2}  />
                            {this.state.submitted && !this.state.obra.quantidade && <small className="p-invalid">Quantidade é requerido.</small>}
                        </div>
                        <div className="p-field p-col-12">
                            <label htmlFor="idund">Unidade</label>
                            <Dropdown inputId="idund" optionValue="id" optionLabel="unidade" value={this.state.obra.idund} options={this.state.unidades}
                                onChange={(e) => this.onUnidadeChange(e)} placeholder="Unidade"
                                required className={classNames({ 'p-invalid': this.state.submitted && !this.state.obra.idund })} />
                            {this.state.submitted && !this.state.obra.idund && <small className="p-invalid">A unidade de medida é requerido.</small>}
                        </div>
                        <div className="p-field p-col-12">
                            <label htmlFor="valorunitario">Valor Unitário</label>
                            <InputNumber id="valorunitario" value={this.state.obra.valorunitario} onValueChange={(e) => this.onInputNumberChange(e, 'valorunitario')}
                                required className={classNames({ 'p-invalid': this.state.submitted && !this.state.obra.valorunitario })}
                                mode="decimal" locale="pt-BR" minFractionDigits={2}  />
                            {this.state.submitted && !this.state.obra.valorunitario && <small className="p-invalid">Valor unitário é requerido.</small>}
                        </div>
                        <div className="p-field p-col-12">
                            <label htmlFor="total">Total</label>
                            <InputNumber inputStyle={{ color: 'blue' }} mode="currency" currency="BRL" locale="pt-BR" id="total" value={this.state.obra.total} minFractionDigits={2} disabled />
                        </div>
                    </div>
                </Dialog>
                <Dialog visible={this.state.deleteObraDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteObraDialogFooter}
                    onHide={this.hideDeleteObraDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        {this.state.obra && <span>Tem certeza que deseja excluir <b>{this.state.obra.descricao}</b>?</span>}
                    </div>
                </Dialog>
            </div>
        )
    }
}               