import React, { Component } from 'react';
import api from '../../service/api';
import classNames from 'classnames';
import { Button } from '../../components/button/Button';
import { Dialog } from '../../components/dialog/Dialog';
import { DataTable } from '../../components/datatable/DataTable';
import { Column } from '../../components/column/Column'
import { InputText } from '../../components/inputtext/InputText';
import { Toast } from '../../components/toast/Toast';
import { Toolbar } from '../../components/toolbar/Toolbar';
import CpfCnpjInput from 'react-cpf-cnpj-input';
import { InputMask } from '../../components/inputmask/InputMask';

export class Fornecedor extends Component {

    emptyFornecedor = {
        "id": null,
        "razaosocial": '',
        "nomefantasia": '',
        "cnpj": '',
        "site": '',
        "email": '',
        "telefones": '',
        "contatos": ''
    }

    constructor(props) {
        super(props);
        this.CnpjInputRef = React.createRef();

        this.state = {
            fornecedor: this.emptyFornecedor,
            listaFornecedor: [],
            submitted: false,
            edicao: false,
            fornecedorDialog: false,
            deleteFornecedorDialog: false,
            selectedFornecedor: null,
            globalFilter: null,
            rows: 10,
            first: 1,
        }

        this.originalRows = {};

        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.openNewFornecedor = this.openNewFornecedor.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.saveFornecedor = this.saveFornecedor.bind(this);
        this.editFornecedor = this.editFornecedor.bind(this);
        this.confirmDeleteFornecedor = this.confirmDeleteFornecedor.bind(this);
        this.hideDeleteFornecedorDialog = this.hideDeleteFornecedorDialog.bind(this);
        this.deleteFornecedor = this.deleteFornecedor.bind(this);
        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.rightToolbarTemplate = this.rightToolbarTemplate.bind(this);
        this.onInputMaskChange = this.onInputMaskChange.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
    }

    async componentDidMount() {
        const listaFornecedor = await api.get(`/fornecedores`);
        this.setState({
            listaFornecedor: listaFornecedor.data
        });
    }

    hideDeleteFornecedorDialog() {
        this.setState({ deleteFornecedorDialog: false });
    }

    confirmDeleteFornecedor(fornecedor) {
        this.setState({
            fornecedor,
            deleteFornecedorDialog: true
        });
    }

    deleteFornecedor() {
        api.delete(`/fornecedores/${this.state.fornecedor.id}`)
            .then(res => {
                if (res.status === 204) {
                    let listaFornecedor = this.state.listaFornecedor.filter(val => val.id !== this.state.fornecedor.id);
                    this.setState({
                        listaFornecedor,
                        deleteFornecedorDialog: false,
                        fornecedor: this.emptyFornecedor
                    });
                    this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Fornecedor excluído', life: 3000 });
                }
            })
            .catch(error => {
                this.setState({ deleteFornecedorDialog: false })
                this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu excluir a Fornecedor', life: 3000 });
                console.log(error.data);
            })
    }

    editFornecedor(fornecedor) {
        this.setState({
            fornecedor: { ...fornecedor },
            fornecedorDialog: true
        });
    }

    openNewFornecedor() {
        this.setState({
            fornecedor: this.emptyFornecedor,
            submitted: false,
            fornecedorDialog: true
        });
    }

    hideDialog() {
        this.setState({
            submitted: false,
            fornecedorDialog: false
        });
    }

    async saveFornecedor() {
        let state = { submitted: true };

        if ((this.state.fornecedor.razaosocial.trim()) && (this.state.fornecedor.nomefantasia.trim())) {

            let listaFornecedor = [...this.state.listaFornecedor];
            let fornecedor = { ...this.state.fornecedor };
            fornecedor.cnpj = this.CnpjInputRef.current.getValue();
            if (this.state.fornecedor.id) {
                const index = this.findIndexById(this.state.fornecedor.id);
                api.put(`/fornecedores/${fornecedor.id}`, fornecedor)
                    .then(res => {
                        if (res.status === 204) {
                            listaFornecedor[index] = fornecedor;
                            state = {
                                ...state,
                                listaFornecedor,
                                fornecedorDialog: false,
                                fornecedor: this.emptyFornecedor
                            };
                            this.setState(state);
                            this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Fornecedor atualizada', life: 3000 });
                        }
                    })
                    .catch(error => {
                        this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu atualizar a Fornecedor', life: 3000 });
                        //console.log(error.response.data.description);
                    })
            }
            else {
                fornecedor.id = 0;
                api.post('/fornecedores', fornecedor)
                    .then(res => {
                        if (res.status === 201) {
                            fornecedor.id = res.headers.id;
                            listaFornecedor.push(fornecedor);
                            state = {
                                ...state,
                                listaFornecedor,
                                fornecedorDialog: false,
                                fornecedor: this.emptyFornecedor
                            };
                            this.setState(state);
                            this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Fornecedor inserido', life: 3000 });
                        }
                    })
                    .catch(error => {
                        this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu inserir a Fornecedor', life: 3000 });
                        //console.log(error.response.data.description);
                    })
            }
        }
        else {
            this.setState(state);
        }
    }

    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-rounded p-mr-2" onClick={() => this.editFornecedor(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => this.confirmDeleteFornecedor(rowData)} />
            </React.Fragment>
        );
    }

    onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        const input = e.target;
        const start = input.selectionStart;
        const end = input.selectionEnd;
        let fornecedor = { ...this.state.fornecedor };
        fornecedor[`${name}`] = val.toUpperCase();
        this.setState({ fornecedor: fornecedor },
            () => input.setSelectionRange(start, end));
    }

    onInputMaskChange(e, name) {
        const val = (e.target && e.target.value) || '';
        let fornecedor = { ...this.state.fornecedor };
        fornecedor[`${name}`] = val.toUpperCase();
        this.setState({ fornecedor: fornecedor });
    }

    findIndexById(id) {
        let index = -1;
        for (let i = 0; i < this.state.listaFornecedor.length; i++) {
            if (this.state.listaFornecedor[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    formatarNumero(rowData, field) {
        return rowData[field].toLocaleString('pt-BR', { style: 'decimal', minimumFractionDigits: 2 });
    }

    formatarCurrency(rowData, field) {
        return rowData[field].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 });
    }

    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="Novo" icon="pi pi-plus" className="p-button p-mr-2" onClick={this.openNewFornecedor} />
            </React.Fragment>
        )
    }

    rightToolbarTemplate() {

    }

    onPageChange = (event) => {
        this.setState({
            rows : event.rows,
            first : event.first
        });
    };        

    render() {
        const header = (
            <div className="table-header">
                <h5 className="p-m-0">Listagem</h5>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Localizar..." />
                </span>
            </div>
        );

        const parametroDialogFooter = (
            <React.Fragment>
                <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={this.saveFornecedor} />
            </React.Fragment>
        );

        const deleteFornecedorDialogFooter = (
            <React.Fragment>
                <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteFornecedorDialog} />
                <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={this.deleteFornecedor} />
            </React.Fragment>
        );

        return (
            <div>
                <div className="content-section introduction">
                    <h5>Fornecedores</h5>
                </div>

                <div className="p-grid crud-demo">
                    <Toast ref={(el) => this.toast = el} />

                    <div className="card">

                        <Toolbar className="p-mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>

                        <DataTable
                            className="p-datatable-gridlines p-datatable-sm"
                            ref={(el) => this.dt = el}
                            value={this.state.listaFornecedor}
                            selection={this.state.selectedFornecedor}
                            onSelectionChange={(e) => this.setState({ selectedFornecedor: e.value })}
                            dataKey="id"
                            paginator
                            rows={10}
                            rowsPerPageOptions={[5, 10, 25]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Exibindo {first} até {last} de {totalRecords} Usuários"
                            globalFilter={this.state.globalFilter}
                            header={header}
                            // onPage={event => this.onPageChange(event)}
                            // first={this.state.first}
                            >
                            <Column field="id" header="Id" style={{ width: '10%' }} sortable></Column>
                            <Column field="razaosocial" header="Razão Social" sortable></Column>
                            <Column field="nomefantasia" header="Nome Fantasia" sortable></Column>
                            <Column body={this.actionBodyTemplate} style={{ width: '13%' }}></Column>
                        </DataTable>

                        <Dialog visible={this.state.fornecedorDialog} style={{ width: '550px' }} header="Fornecedor" modal className="p-fluid" footer={parametroDialogFooter}
                            onHide={this.hideDialog}>
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-12">
                                    <label htmlFor="razaosocial">Razão Social</label>
                                    <InputText id="razaosocial" value={this.state.fornecedor.razaosocial} onChange={(e) => this.onInputChange(e, 'razaosocial')}
                                        required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.fornecedor.razaosocial })}
                                         />
                                    {this.state.submitted && !this.state.fornecedor.razaosocial && <small className="p-invalid">Razão Social é requerida.</small>}
                                </div>
                                <div className="p-field p-col-12">
                                    <label htmlFor="nomefantasia">Nome Fantasia</label>
                                    <InputText id="nomefantasia" value={this.state.fornecedor.nomefantasia} onChange={(e) => this.onInputChange(e, 'nomefantasia')}
                                        required className={classNames({ 'p-invalid': this.state.submitted && !this.state.fornecedor.nomefantasia })}
                                         />
                                    {this.state.submitted && !this.state.fornecedor.nomefantasia && <small className="p-invalid">Nome Fantasia é requerido.</small>}
                                </div>

                                <div className="p-field p-col-12 p-mb-2">CNPJ</div>

                                <div className="p-field p-col-12">
                                    <CpfCnpjInput
                                        id="cnpj"
                                        type="cnpj"
                                        value={this.state.fornecedor.cnpj}
                                        onChange={(e) => this.onInputMaskChange(e, 'cnpj')}
                                        style={{ width: '100%', height: '38px' }}
                                        ref={this.CnpjInputRef} />
                                </div>

                                <div className="p-field p-col-6">
                                    <label htmlFor="site">Site</label>
                                    <InputText id="site" value={this.state.fornecedor.site} onChange={(e) => this.onInputChange(e, 'site')} />
                                </div>
                                <div className="p-field p-col-6">
                                    <label htmlFor="email">E-mail</label>
                                    <InputText id="email" value={this.state.fornecedor.email} onChange={(e) => this.onInputChange(e, 'email')} />
                                </div>
                                <div className="p-field p-col-6">
                                    <label htmlFor="telefones">Telefone</label>
                                    <InputMask id="telefones" value={this.state.fornecedor.telefones} onChange={(e) => this.onInputMaskChange(e, 'telefones')}
                                         mask="(99)9999-9999" />
                                </div>
                                <div className="p-field p-col-6">
                                    <label htmlFor="contatos">Contato</label>
                                    <InputText id="contatos" value={this.state.fornecedor.contatos} onChange={(e) => this.onInputChange(e, 'contatos')} />
                                </div>
                            </div>

                        </Dialog>

                        <Dialog visible={this.state.deleteFornecedorDialog} style={{ width: '450px' }} header="Confirmar" modal footer={deleteFornecedorDialogFooter}
                            onHide={this.hideDeleteFornecedorDialog}>
                            <div className="confirmation-content">
                                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                                {this.state.fornecedor && <span>Tem certeza que deseja excluir <b>{this.state.fornecedor.nomefantasia}</b>?</span>}
                            </div>
                        </Dialog>
                    </div>
                </div>
            </div>
        )
    }
}
