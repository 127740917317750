import React, { Component } from 'react';
import api from '../../../service/api';
import { DataTable } from '../../../components/datatable/DataTable';
import { Column } from '../../../components/column/Column';
import { InputNumber } from '../../../components/inputnumber/InputNumber';
import { Toast } from '../../../components/toast/Toast';
import { Toolbar } from '../../../components/toolbar/Toolbar';
import { Button } from '../../../components/button/Button';
import { Dialog } from '../../../components/dialog/Dialog';
import { InputText } from '../../../components/inputtext/InputText';
import classNames from 'classnames';

export class ParametroSolarimetrico extends Component {

    emptyParametro = {
        "id": null,
        "parametro": '',
        "valor": 0.00,
    }

    constructor(props) {
        super(props);

        this.state = {
            parametro: this.emptyParametro,
            listaParametros: [],
            submitted: false,
            editDialog: false,
            deleteDialog: false,
            rows: 10,
            first: 1,
        };

        this.originalRows = {};

        this.openNew = this.openNew.bind(this);
        this.editParam = this.editParam.bind(this);
        this.saveParam = this.saveParam.bind(this);
        this.findIndexById = this.findIndexById.bind(this);
        this.confirmDeleteParam = this.confirmDeleteParam.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.hideDeleteDialog = this.hideDeleteDialog.bind(this);
        this.deleteParam = this.deleteParam.bind(this);
        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onInputNumberChange = this.onInputNumberChange.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
    }

    async componentDidMount() {
        const parametros = await api.get(`/configparametrossolarimetricos`);
        this.setState({ listaParametros: parametros.data });
    }

    openNew() {
        this.setState({
            parametro: this.emptyParametro,
            submitted: false,
            editDialog: true
        });
    }

    editParam(parametro) {
        this.setState({
            parametro: { ...parametro },
            editDialog: true
        });
    }

    saveParam() {
        let state = { submitted: true };

        if (this.state.parametro.parametro.trim() && this.state.parametro.valor && this.state.parametro.valor > 0) {
            let listaParametros = [...this.state.listaParametros];
            let parametro = { ...this.state.parametro };

            /* EDITAR */
            if (this.state.parametro.id) {
                const index = this.findIndexById(parametro.id);
                api.put(`/configparametrossolarimetricos/${parametro.id}`, parametro)
                    .then(res => {
                        if (res.status === 204) {
                            listaParametros[index] = parametro;
                            state = {
                                ...state,
                                listaParametros: listaParametros,
                                editDialog: false,
                                parametro: this.emptyParametro
                            };
                            this.setState(state);
                            this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Parâmetro atualizado', life: 3000 });

                        }
                    })
                    .catch(error => {
                        this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu atualizar o Parâmetro', life: 3000 });
                        // console.log(error.response.data.description);
                    })
            }
            /* ADICIONAR */
            else {
                parametro.id = 0;
                api.post('/configparametrossolarimetricos', parametro)
                    .then(res => {
                        if (res.status === 201) {
                            parametro.id = res.headers.id;
                            listaParametros.push(parametro);
                            state = {
                                ...state,
                                listaParametros: listaParametros,
                                editDialog: false,
                                parametro: this.emptyParametro
                            };
                            this.setState(state);
                            this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Parâmetro inserido', life: 3000 });
                        }
                    })
                    .catch(error => {
                        this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu inserir o Parâmetro', life: 3000 });
                        // console.log(error.response.data.description);
                    })
            }
        }
        else {
            this.setState(state);
        }
    }

    findIndexById(id) {
        let index = -1;
        for (let i = 0; i < this.state.listaParametros.length; i++) {
            if (this.state.listaParametros[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    confirmDeleteParam(parametro) {
        this.setState({
            parametro,
            deleteDialog: true
        });
    }

    hideDialog() {
        this.setState({
            submitted: false,
            editDialog: false
        });
    }

    hideDeleteDialog() {
        this.setState({ deleteDialog: false });
    }

    deleteParam() {
        api.delete(`/configparametrossolarimetricos/${this.state.parametro.id}`)
            .then(res => {
                if (res.status === 204) {
                    let listaParametros = this.state.listaParametros.filter(val => val.id !== this.state.parametro.id);
                    this.setState({
                        listaParametros,
                        deleteDialog: false,
                        parametro: this.emptyParametro
                    });
                    this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Parâmetro Excluído', life: 3000 });
                }
            });
    }

    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="Novo" icon="pi pi-plus" className="p-button p-mr-2" onClick={this.openNew} />
            </React.Fragment>
        )
    }

    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button p-mr-2"
                    onClick={() => this.editParam(rowData)}
                    tooltip="Alterar"
                    tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} />
                <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger"
                    onClick={() => this.confirmDeleteParam(rowData)}
                    tooltip="Excluir"
                    tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} />
            </React.Fragment>
        );
    }

    onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        const input = e.target;
        const start = input.selectionStart;
        const end = input.selectionEnd;
        let parametro = { ...this.state.parametro };
        parametro[`${name}`] = val.toUpperCase();
        this.setState({ parametro: parametro },
            () => input.setSelectionRange(start, end));
    }

    onInputNumberChange(e, name) {
        const val = e.value || 0;
        let parametro = { ...this.state.parametro };
        parametro[`${name}`] = val;
        this.setState({ parametro: parametro });
    }

    onPageChange = (event) => {
        this.setState({rows : event.rows, first : event.first});
    }


    render() {
        const editDialogFooter = (
            <React.Fragment>
                <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={this.saveParam} />
            </React.Fragment>
        );
        const deleteDialogFooter = (
            <React.Fragment>
                <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteDialog} />
                <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={this.deleteParam} />
            </React.Fragment>
        );
        return (
            <div>
                <div className="p-grid crud-demo">
                    <Toast ref={(el) => this.toast = el} />

                    <div className="card">
                        <Toolbar className="p-mb-4" left={this.leftToolbarTemplate} ></Toolbar>
                        <DataTable
                            dataKey="id"
                            value={this.state.listaParametros} className="p-datatable-sm p-datatable-gridlines"
                            paginator={this.state.listaParametros.length > 5}
                            rows={10}
                            // first={this.state.first}
                            rowsPerPageOptions={[5, 10, 25]}
                            // onPage={event => this.onPageChange(event)}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Exibindo {first} até {last} de {totalRecords} Parâmetros">                            
                            <Column field="parametro" header="Parâmetro"></Column>
                            <Column field="valor" header="Valor" > </Column>
                            <Column body={this.actionBodyTemplate}></Column>
                        </DataTable>

                        <Dialog visible={this.state.editDialog} style={{ width: '550px' }} className="p-fluid" modal
                            header="Parâmetros" footer={editDialogFooter} onHide={this.hideDialog}>
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-12">
                                    <label htmlFor="parametro">Descrição</label>
                                    <InputText
                                        id="parametro" value={this.state.parametro.parametro}
                                        onChange={(e) => this.onInputChange(e, 'parametro')}
                                        required autoFocus
                                        className={classNames({ 'p-invalid': this.state.submitted && !this.state.parametro.parametro })}
                                         />
                                    {this.state.submitted && !this.state.parametro.parametro && <small className="p-invalid">Parâmetro é requerido.</small>}
                                </div>
                                <div className="p-field p-col-12">
                                    <label htmlFor="valor">Valor</label>
                                    <InputNumber
                                        id="valor" value={this.state.parametro.valor}
                                        onValueChange={(e) => this.onInputNumberChange(e, 'valor')}
                                        required
                                        className={classNames({ 'p-invalid': this.state.submitted && !this.state.parametro.valor })}
                                        mode="decimal" locale="pt-BR" minFractionDigits={2}
                                         />
                                    {this.state.submitted && !this.state.parametro.valor && <small className="p-invalid">Valor é requerido.</small>}
                                </div>
                            </div>
                        </Dialog>

                        <Dialog visible={this.state.deleteDialog} style={{ width: '450px' }} modal 
                        header="Confirm" footer={deleteDialogFooter} onHide={this.hideDeleteDialog}>
                            <div className="confirmation-content">
                                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                                {this.state.parametro && <span>Tem certeza que deseja excluir este item?</span>}
                            </div>
                        </Dialog>
                    </div>
                </div>
            </div>
        )
    }
}   