import React, { Component } from 'react';
import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import { DataTable } from '../../components/datatable/DataTable';
import { Column } from '../../components/column/Column';
import { Toast } from '../../components/toast/Toast';
import { Button } from '../../components/button/Button';
import { Dialog } from '../../components/dialog/Dialog';
import { InputText } from '../../components/inputtext/InputText';
import api from '../../service/api'
import { InputNumber } from '../../components/inputnumber/InputNumber';

export class DimensionamentoServico extends Component {

    static defaultProps = {
        idNegocioDimensionamento: 0
    }

    static propTypes = {
        idNegocioDimensionamento: PropTypes.number
    }

    emptyDimensionamentoServico = {
        "id": null,
        "idnegociodimensionamento": null,
        "idservico": null,
        "idparametroservico": null,
        "valorunitario": 0,
        "total": 0,
        "servico": ''
    };

    atualizarTotal = () => {
        this.props.atualizarTotal(this.props.value);
    }

    constructor(props) {
        super(props);

        this.state = {
            dimensionamentoServico: this.emptyDimensionamentoServico,
            listaDimensionamentoServicos: [],
            listaParametros: [],
        };

        this.originalRows = {};

        this.openNewParametroServico = this.openNewParametroServico.bind(this);
        this.hideDialog = this.hideDialog.bind(this);

        this.onRowEditInit = this.onRowEditInit.bind(this);
        this.onRowEditCancel = this.onRowEditCancel.bind(this);
        this.onRowEditSave = this.onRowEditSave.bind(this);
    }

    async componentDidMount() {
        const dimensionamentoServicos = await api.get(`/dimensionamentoservicos/dimensionamento/${this.props.idNegocioDimensionamento}`);
        this.setState({ listaDimensionamentoServicos: dimensionamentoServicos.data });
    }

    async componentWillReceiveProps(nextProps) {
        if (nextProps.nonUsedKey !== this.props.nonUsedKey) {
            const dimensionamentoServicos = await api.get(`/dimensionamentoservicos/dimensionamento/${this.props.idNegocioDimensionamento}`);
            this.setState({ listaDimensionamentoServicos: dimensionamentoServicos.data });
        }
    }

    openNewParametroServico() {
        this.setState({
            dimensionamentoServico: this.emptyDimensionamentoServico,
            submitted: false,
            servicoDialog: true
        });
    }

    onRowEditInit(event) {
        this.originalRows[event.index] = { ...this.state.listaDimensionamentoServicos[event.index] };
    }

    onRowEditCancel(event) {
        let dimensionamentoServicos = [...this.state.listaDimensionamentoServicos];
        dimensionamentoServicos[event.index] = this.originalRows[event.index];
        delete this.originalRows[event.index];
        this.setState({ listaDimensionamentoServico: dimensionamentoServicos });
    }

    async onRowEditSave(event) {
        let dimensionamentoServico = { ...this.state.listaDimensionamentoServicos[event.index] };
        if (!dimensionamentoServico.idparametroservico) {
            dimensionamentoServico.idparametroservico = -1;
        }
        api.put(`/dimensionamentoservicos/${dimensionamentoServico.id}`, dimensionamentoServico)
            .then(res => {
                if (res.status === 204) {
                    api.get(`/dimensionamentoservicos/dimensionamento/${this.props.idNegocioDimensionamento}`)
                        .then(dimensionamentoServicos => {
                            this.setState({ listaDimensionamentoServicos: dimensionamentoServicos.data });
                        })
                    this.atualizarTotal();
                    this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Serviço atualizado com sucesso', life: 3000 });

                }
            })
    }

    onEditorNumberValueChange(listaName, props, value) {
        let updated = [...props.value];
        updated[props.rowIndex][props.field] = value || 0;
        this.setState({ [`${listaName}`]: updated });
    }

    inputNumberEditor(listaName, props, field) {
        return <InputNumber mode="decimal" locale="pt-BR" minFractionDigits={2} value={props.rowData[field]}
        onValueChange={(e) => this.onEditorNumberValueChange(listaName, props, e.value)} inputStyle={{ width: '100%' }} />;
    }

    paramValueEditor(listaName, props) {
        return this.inputNumberEditor(listaName, props, 'valor');
    }

    hideDialog() {
        this.setState({
            submitted: false,
            servicoDialog: false
        });
    }

    formatarCurrency(rowData, field) {
        return rowData[field].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 });
    }

    render() {
    
        const dimensionamentoServicoDialogFooter = (
            <React.Fragment>
                <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={this.saveDimensionamentoServico} />
            </React.Fragment>
        );
        return (
            <div>
                <Toast ref={(el) => this.toast = el} />
                <DataTable
                    className="p-datatable-gridlines p-datatable-sm editable-cells-table"
                    ref={(el) => this.dt = el}
                    value={this.state.listaDimensionamentoServicos}
                    editMode="row"
                    dataKey="id"
                    //header={header}
                    onRowEditInit={this.onRowEditInit}
                    onRowEditCancel={this.onRowEditCancel}
                    onRowEditSave={this.onRowEditSave}>

                    <Column field="servico" header="Descrição" style={{ width: '50%' }}></Column>
                    <Column
                        className="p-editable-column p-cell-editing"
                        field="valorunitario"
                        header="Valor Unitário"
                        body={(rowData) => this.formatarCurrency(rowData, 'valorunitario')}
                        editor={(props) => this.inputNumberEditor('listaDimensionamentoServicos', props, 'valorunitario')}>
                    </Column>
                    <Column field="total" header="Total" body={(rowData) => this.formatarCurrency(rowData, 'total')}></Column>
                    <Column rowEditor headerStyle={{ width: '7rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                </DataTable>

                <Dialog visible={this.state.servicoDialog} style={{ width: '550px' }} header="Dimensionamento Serviços" modal className="p-fluid" footer={dimensionamentoServicoDialogFooter} onHide={this.hideDialog}>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12">
                            <label htmlFor="servico">Serviço</label>
                            <InputText id="servico" value={this.state.dimensionamentoServico.servico} onChange={(e) => this.onInputChange(e, 'servico')}
                                required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.dimensionamentoServico.servico })} />
                            {this.state.submitted && !this.state.dimensionamentoServico.servico && <small className="p-invalid">Serviço é requerido.</small>}
                        </div>
                    </div>
                </Dialog>
            </div>
        );
    }
}
