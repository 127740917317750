import React, { Component } from 'react';
import { DataTable } from '../../components/datatable/DataTable';
import { Column } from '../../components/column/Column';
import { Toast } from '../../components/toast/Toast';
import { Button } from '../../components/button/Button';
import { Rating } from '../../components/rating/Rating';
import { Toolbar } from '../../components/toolbar/Toolbar';
import { Dialog } from '../../components/dialog/Dialog';
import { InputText } from '../../components/inputtext/InputText';
import { InputTextarea } from '../../components/inputtextarea/InputTextarea'
import { Dropdown } from '../../components/dropdown/Dropdown';
import api from '../../service/api'
import { Link } from 'react-router-dom';
import { format, parseISO } from "date-fns";



export class NegocioList extends Component {

    emptyNegocio = {
        "id": null,
        "descricao": '',
        "idcliente": null,
        "nome": '',
        "idtipoinstalacao": null,
        "tipo": '',
        "datacadastro": ''
    };

    constructor(props) {
        super(props);

        this.state = {
            Negocios: null,
            NegocioDialog: false,
            deleteNegocioDialog: false,
            deleteNegociosDialog: false,
            Negocio: this.emptyNegocio,
            selectedNegocios: null,
            submitted: false,
            globalFilter: null,
            Clientes: [],
            TipoInstalacao: [],
            Edicao: false,
        };

        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.rightToolbarTemplate = this.rightToolbarTemplate.bind(this);
        this.imageBodyTemplate = this.imageBodyTemplate.bind(this);
        this.priceBodyTemplate = this.priceBodyTemplate.bind(this);
        this.ratingBodyTemplate = this.ratingBodyTemplate.bind(this);
        this.statusBodyTemplate = this.statusBodyTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);

        this.openNew = this.openNew.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.saveNegocio = this.saveNegocio.bind(this);
        this.editNegocio = this.editNegocio.bind(this);
        this.confirmDeleteNegocio = this.confirmDeleteNegocio.bind(this);
        this.deleteNegocio = this.deleteNegocio.bind(this);
        this.exportCSV = this.exportCSV.bind(this);
        this.confirmDeleteSelected = this.confirmDeleteSelected.bind(this);
        this.deleteSelectedNegocios = this.deleteSelectedNegocios.bind(this);
        this.onCategoryChange = this.onCategoryChange.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onInputNumberChange = this.onInputNumberChange.bind(this);
        this.hideDeleteNegocioDialog = this.hideDeleteNegocioDialog.bind(this);
        this.hideDeleteNegociosDialog = this.hideDeleteNegociosDialog.bind(this);
    }

    async componentDidMount() {
        const response = await api.get('/negocios');
        this.setState({ Negocios: response.data });
    }

    formatCurrency(value) {
        return value.toLocaleString('pt-BR', { style: 'currency', currency: 'USD' });
    }

    dateTemplate(rowData) {
        const Data = parseISO(rowData['datacadastro']);
        ; return format(Data, "dd/MM/yyyy");
        // return rowData['datacadastro'];
    }

    openNew() {
        this.setState({
            Negocio: this.emptyNegocio,
            submitted: false,
            NegocioDialog: true
        });

        api.get('/clientes')
            .then(res => {
                this.setState({ Clientes: res.data });
            })

        api.get('/tipoinstalacao')
            .then(res => {
                this.setState({ TipoInstalacao: res.data });
            })

    }

    hideDialog() {
        this.setState({
            submitted: false,
            NegocioDialog: false
        });
    }

    hideDeleteNegocioDialog() {
        this.setState({ deleteNegocioDialog: false });
    }

    hideDeleteNegociosDialog() {
        this.setState({ deleteNegociosDialog: false });
    }

    async saveNegocio() {
        let state = { submitted: true };

        if (this.state.Negocio.idcliente) {
            let Negocios = [...this.state.Negocios];
            let Negocio = { ...this.state.Negocio };
            if (this.state.Negocio.id) {
                const index = this.findIndexById(this.state.Negocio.id);

                api.put(`/negocios/${Negocio.id}`, Negocio)
                    .then(res => {
                        if (res.status === 204) {
                            Negocios[index] = Negocio;
                            state = {
                                ...state,
                                Negocios,
                                NegocioDialog: false,
                                Negocio: this.emptyNegocio
                            };
                            this.setState(state);
                            this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Negocio atualizado', life: 3000 });
                        }
                    })

            }
            else {
                Negocio.id = 0;
                Negocio.datacadastro = format(new Date(), 'yyyy-MM-dd');
                api.post('/negocios', Negocio)
                    .then(res => {
                        if (res.status === 201) {
                            Negocio.id = res.headers.id;
                            Negocios.push(Negocio);
                            state = {
                                ...state,
                                Negocios,
                                NegocioDialog: false,
                                Negocio: this.emptyNegocio
                            };
                            this.setState(state);
                            this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Negocio inserido', life: 3000 });
                            this.props.history.push(`/negocios/${Negocio.id}`);
                        }
                    })

            }


        }

    }

    editNegocio(Negocio) {
        this.setState({
            Negocio: { ...Negocio },
            Edicao: true
        });
    }

    confirmDeleteNegocio(Negocio) {
        this.setState({
            Negocio,
            deleteNegocioDialog: true
        });
    }

    deleteNegocio() {
        api.delete(`/negocios/${this.state.Negocio.id}`)
            .then(res => {
                if (res.status === 204) {
                    let Negocios = this.state.Negocios.filter(val => val.id !== this.state.Negocio.id);
                    this.setState({
                        Negocios,
                        deleteNegocioDialog: false,
                        Negocio: this.emptyNegocio
                    });
                    this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Negocio excluído', life: 3000 });
                }
            })
            .catch(error => {
                this.setState({ deleteNegocioDialog: false });
                this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu excluir o negócio', life: 3000 });
                // console.log(error.response.data.description);
            })

    }

    findIndexById(id) {
        let index = -1;
        for (let i = 0; i < this.state.Negocios.length; i++) {
            if (this.state.Negocios[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    createId() {
        let id = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return id;
    }

    exportCSV() {
        this.dt.exportCSV();
    }

    confirmDeleteSelected() {
        this.setState({ deleteNegociosDialog: true });
    }

    deleteSelectedNegocios() {
        let Negocios = this.state.Negocios.filter(val => !this.state.selectedNegocios.includes(val));
        this.setState({
            Negocios,
            deleteNegociosDialog: false,
            selectedNegocios: null
        });
        this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Negócio excluído', life: 3000 });
    }

    onCategoryChange(e) {
        let Negocio = { ...this.state.Negocio };
        Negocio['category'] = e.value;
        this.setState({ Negocio });
    }

    onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        const input = e.target;
        const start = input.selectionStart;
        const end = input.selectionEnd;
        let Negocio = { ...this.state.Negocio };
        Negocio[`${name}`] = val.toUpperCase();
        this.setState({ Negocio },
            () => input.setSelectionRange(start, end));
    }

    onClienteChange(e) {
        let Negocio = { ...this.state.Negocio };
        Negocio.idcliente = e.value;

        let index = -1;
        for (let i = 0; i < this.state.Clientes.length; i++) {
            if (this.state.Clientes[i].id === e.value) {
                index = i;
                break;
            }
        }

        Negocio.nome = this.state.Clientes[index].nome;

        this.setState({ Negocio });

    }

    onTipoInstalacaoChange(e) {
        let Negocio = { ...this.state.Negocio };
        Negocio.idtipoinstalacao = e.value;

        let index = -1;
        for (let i = 0; i < this.state.TipoInstalacao.length; i++) {
            if (this.state.TipoInstalacao[i].id === e.value) {
                index = i;
                break;
            }
        }

        Negocio.tipo = this.state.TipoInstalacao[index].tipo;

        this.setState({ Negocio });
    }

    onInputNumberChange(e, name) {
        const val = e.value || 0;
        let Negocio = { ...this.state.Negocio };
        Negocio[`${name}`] = val;

        this.setState({ Negocio });


    }

    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="Novo" icon="pi pi-plus" className="p-button p-mr-2" onClick={this.openNew} />
                {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={this.confirmDeleteSelected} disabled={!this.state.selectedNegocios || !this.state.selectedNegocios.length} /> */}
            </React.Fragment>
        )
    }

    rightToolbarTemplate() {
        return (
            <React.Fragment>
                {/* <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="p-mr-2 p-d-inline-block" /> */}
                <Button label="Exportar" icon="pi pi-upload" className="p-button-help" onClick={this.exportCSV} />
            </React.Fragment>
        )
    }

    imageBodyTemplate(rowData) {
        return <img src={`showcase/demo/images/Negocio/${rowData.image}`} onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={rowData.image} className="Negocio-image" />
    }

    priceBodyTemplate(rowData) {
        return this.formatCurrency(rowData.price);
    }

    ratingBodyTemplate(rowData) {
        return <Rating value={rowData.rating} readonly cancel={false} />;
    }

    statusBodyTemplate(rowData) {
        return <span className={`Negocio-badge status-${rowData.inventoryStatus.toLowerCase()}`}>{rowData.inventoryStatus}</span>;
    }

    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                {/* <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => this.editNegocio(rowData)} /> */}
                <Link to={`/negocios/${rowData['id']}`}><Button icon="pi pi-pencil" className="p-button-rounded p-mr-2" /></Link>
                {/* <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => this.editNegocio(rowData)} /> */}
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => this.confirmDeleteNegocio(rowData)} />
            </React.Fragment>
        );
    }

    render() {
        const header = (
            <div className="table-header">
                <h5 className="p-m-0">Listagem</h5>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Localizar..." />
                </span>
            </div>
        );
        const NegocioDialogFooter = (
            <React.Fragment>
                <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={this.saveNegocio} />
            </React.Fragment>
        );
        const deleteNegocioDialogFooter = (
            <React.Fragment>
                <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteNegocioDialog} />
                <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={this.deleteNegocio} />
            </React.Fragment>
        );
        const deleteNegociosDialogFooter = (
            <React.Fragment>
                <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteNegociosDialog} />
                <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={this.deleteSelectedNegocios} />
            </React.Fragment>
        );

        return (
            <div>
                <div className="content-section introduction">
                    <h5>Negocios</h5>
                </div>

                <div className="p-grid crud-demo">
                    <Toast ref={(el) => this.toast = el} />

                    <div className="card">
                        <Toolbar className="p-mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>

                        <DataTable
                            className="p-datatable-gridlines p-datatable-sm"
                            ref={(el) => this.dt = el}
                            value={this.state.Negocios}
                            selection={this.state.selectedNegocios}
                            onSelectionChange={(e) => this.setState({ selectedNegocios: e.value })}
                            dataKey="id"
                            paginator rows={10}
                            rowsPerPageOptions={[5, 10, 25]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Exibindo {first} até {last} de {totalRecords} Negocios"
                            globalFilter={this.state.globalFilter}
                            header={header}>

                            {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column> */}
                            <Column field="id" header="Id" sortable style={{ width: '6%' }}></Column>
                            <Column field="nome" header="Cliente" sortable></Column>
                            <Column field="tipo" header="Tipo Instalação" sortable></Column>
                            <Column field="datacadastro" header="Data de Cadastro" sortable body={(rowData) => this.dateTemplate(rowData)} ></Column>
                            <Column body={this.actionBodyTemplate} style={{ width: '15%' }}></Column>
                        </DataTable>
                    </div>

                    <Dialog visible={this.state.NegocioDialog} style={{ width: '550px' }} header="Negócio" modal className="p-fluid" footer={NegocioDialogFooter} onHide={this.hideDialog}>
                        <div className="p-fluid p-formgrid p-grid">

                            <div className="p-field p-col-12 p-md-12">
                                <label htmlFor="idcliente">Cliente</label>
                                <Dropdown inputId="idcliente" optionValue="id" optionLabel="nome" value={this.state.Negocio.idcliente} options={this.state.Clientes} onChange={(e) => this.onClienteChange(e)} filter showClear filterBy="nome" placeholder="Selecione o Cliente" />
                            </div>

                            <div className="p-field p-col-12 p-md-12">
                                <label htmlFor="idtipoinstalacao">Tipo de Instalação</label>
                                <Dropdown inputId="idtipoinstalacao" optionValue="id" optionLabel="tipo" value={this.state.Negocio.idtipoinstalacao} options={this.state.TipoInstalacao} onChange={(e) => this.onTipoInstalacaoChange(e)} placeholder="Selecione o tipo de instalação" />
                            </div>

                            <div className="p-field p-col-12">
                                <label htmlFor="descricao">Descrição</label>
                                <InputTextarea rows={5} cols={30} value={this.state.Negocio.descricao} onChange={(e) => this.onInputChange(e, 'descricao')} autoResize placeholder="Descrição do negócio" />
                            </div>

                        </div>
                    </Dialog>


                    <Dialog visible={this.state.deleteNegocioDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteNegocioDialogFooter} onHide={this.hideDeleteNegocioDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {this.state.Negocio && <span>Tem certeza que deseja excluir <b>{this.state.Negocio.name}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={this.state.deleteNegociosDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteNegociosDialogFooter} onHide={this.hideDeleteNegociosDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {this.state.Negocio && <span>Tem certeza que deseja exluir todos os Negocios selecionados?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        );
    }
}
