import React, { Component } from 'react';
import api from '../../../service/api';
import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import { Button } from '../../../components/button/Button';
import { Dialog } from '../../../components/dialog/Dialog';
import { Dropdown } from '../../../components/dropdown/Dropdown';
import { DataTable } from '../../../components/datatable/DataTable';
import { Column } from '../../../components/column/Column'
import { InputText } from '../../../components/inputtext/InputText';
import { Toast } from '../../../components/toast/Toast';
import { Checkbox } from '../../../components/checkbox/Checkbox';
import { Toolbar } from '../../../components/toolbar/Toolbar';
import { RadioButton } from '../../../components/radiobutton/RadioButton';

export class Servico extends Component {

    static defaultProps = {
        idDimensionamento: 1
    }

    static propTypes = {
        idDimensionamento: PropTypes.number
    }

    emptyServico = {
        "id": null,
        "idparametroservico": null,
        "servico": '',
        "descricao": '',
        "incluirpadrao": true,
        "tipo": '',
        "ativo": true,
    }

    constructor(props) {
        super(props);

        this.state = {
            servico: this.emptyServico,
            listaServicos: [],
            listaParametros: [],
            submitted: false,
            edicao: false,
            servicoDialog: false,
            deleteServicoDialog: false,
            rows: 10,
            first: 1,
        }

        this.originalRows = {};

        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.openNewServico = this.openNewServico.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.saveServico = this.saveServico.bind(this);
        this.editServico = this.editServico.bind(this);
        this.confirmDeleteServico = this.confirmDeleteServico.bind(this);
        this.hideDeleteServicoDialog = this.hideDeleteServicoDialog.bind(this);
        this.deleteServico = this.deleteServico.bind(this);
        this.onPadraoChange = this.onPadraoChange.bind(this);
        this.onAtivoChange = this.onAtivoChange.bind(this);
        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
    }

    async componentDidMount() {
        const servicos = await api.get(`/servico`);
        const parametrosServicos = await api.get('/parametrosservicos');
        this.setState({
            listaServicos: servicos.data,
            listaParametros: parametrosServicos.data
        });
    }

    hideDeleteServicoDialog() {
        this.setState({ deleteServicoDialog: false });
    }

    confirmDeleteServico(servico) {
        this.setState({
            servico,
            deleteServicoDialog: true
        });
    }

    deleteServico() {
        api.delete(`/servico/${this.state.servico.id}`)
            .then(res => {
                if (res.status === 204) {
                    let listaServicos = this.state.listaServicos.filter(val => val.id !== this.state.servico.id);
                    this.setState({
                        listaServicos,
                        deleteServicoDialog: false,
                        servico: this.emptyServico
                    });
                    this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Servico excluído', life: 3000 });
                }
            })
            .catch(error => {
                this.setState({ deleteServicoDialog: false })
                this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu excluir o Serviço', life: 3000 });
                // console.log(error.response.data.description);
            })
    }

    editServico(servico) {
        this.setState({
            servico: { ...servico },
            servicoDialog: true
        });
    }

    openNewServico() {
        this.setState({
            servico: this.emptyServico,
            submitted: false,
            servicoDialog: true
        });
    }

    hideDialog() {
        this.setState({
            submitted: false,
            servicoDialog: false
        });
    }

    async saveServico() {
        let state = { submitted: true };

        if (this.state.servico.servico.trim() && this.state.servico.tipo) {
            let listaServicos = [...this.state.listaServicos];
            let servico = { ...this.state.servico };
            if (this.state.servico.id) {
                const index = this.findIndexById(this.state.servico.id);
                api.put(`/servico/${this.state.servico.id}`, servico)
                    .then(res => {
                        if (res.status === 204) {
                            listaServicos[index] = servico;
                            state = {
                                submitted: false,
                                listaServicos,
                                servicoDialog: false,
                                servico: this.emptyServico
                            };
                            this.setState(state);
                            this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Servico atualizado', life: 3000 });
                        }
                    })
                    .catch(error => {
                        this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu atualizar o Serviço', life: 3000 });
                        // console.log(error.response.data.description);
                    })
            }
            else {
                servico.id = 0;
                servico.idnegociodimensionamento = this.props.idDimensionamento;
                console.log(servico);
                api.post('/servico', servico)
                    .then(res => {
                        if (res.status === 201) {
                            servico.id = res.headers.id;
                            listaServicos.push(servico);
                            state = {
                                submitted: false,
                                listaServicos,
                                servicoDialog: false,
                                servico: this.emptyServico
                            };
                            this.setState(state);
                            this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'servico inserido', life: 3000 });
                        }
                    })
                    .catch(error => {
                        this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu inserir o Serviço', life: 3000 });
                        // console.log(error.response.data.description);
                    })
            }
        }
        else {
            this.setState(state);
        }
    }

    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-rounded p-mr-2" onClick={() => this.editServico(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => this.confirmDeleteServico(rowData)} />
            </React.Fragment>
        );
    }

    onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        const input = e.target;
        const start = input.selectionStart;
        const end = input.selectionEnd;
        let servico = { ...this.state.servico };
        servico[`${name}`] = val.toUpperCase();
        this.setState({ servico: servico },
            () => input.setSelectionRange(start, end));
    }

    onInputNumberChange(e, name) {
        const val = e.value || 0;
        let total = 0;
        let servico = { ...this.state.servico };
        servico[`${name}`] = val;
        if ((servico.valorunitario) && (servico.quantidade)) {
            total = servico.valorunitario * servico.quantidade;
        }
        servico.total = total;
        this.setState({ servico: servico });
    }

    findIndexById(id) {
        let index = -1;
        for (let i = 0; i < this.state.listaServicos.length; i++) {
            if (this.state.listaServicos[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    onParametroChange(e) {
        console.log(e.optionValue);
        let servico = { ...this.state.servico };
        servico.idparametroservico = e.value;
        this.setState({ servico });
    }

    onPadraoChange(e) {
        let servico = { ...this.state.servico };
        if (e.checked)
            servico.incluirpadrao = true;
        else
            servico.incluirpadrao = false;
        this.setState({ servico });
    }

    onAtivoChange(e) {
        let servico = { ...this.state.servico };
        if (e.checked)
            servico.ativo = true;
        else
            servico.ativo = false;
        this.setState({ servico });
    }

    formatarNumero(rowData, field) {
        return rowData[field].toLocaleString('pt-BR', { style: 'decimal', minimumFractionDigits: 2 });
    }

    formatarCurrency(rowData, field) {
        return rowData[field].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 });
    }

    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="Novo" icon="pi pi-plus" className="p-button p-mr-2" onClick={this.openNewServico} />
            </React.Fragment>
        )
    }

    tipoBodyTemplate(rowData) {
        let estilo;
        let texto = 'DIRETO';
        if (rowData.tipo.toLowerCase() === 'd') {
            estilo = "p-tag p-tag-success";
        }
        else {
            estilo = "p-tag p-tag-warning";
            texto = 'INDIRETO';
        }
        return <span className={estilo}>{texto}</span>;
    }

    onTipoChange(e) {
        let servico = { ...this.state.servico };
        servico.tipo = e.value;
        this.setState({ servico: servico });
    }
    onPageChange = (event) => {
        this.setState({rows : event.rows, first: event.first});
    }

    render() {

        const header = (
            <div className="table-header" >
                <h5 className="p-m-0">Listagem</h5>
            </div>
        );

        const servicoDialogFooter = (
            <React.Fragment>
                <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={this.saveServico} />
            </React.Fragment>
        );

        const deleteServicoDialogFooter = (
            <React.Fragment>
                <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteServicoDialog} />
                <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={this.deleteServico} />
            </React.Fragment>
        );

        return (
            <div>
                <Toast ref={(el) => this.toast = el} />

                <Toolbar className="p-mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>

                <DataTable
                    className="p-datatable-gridlines p-datatable-sm"
                    ref={(el) => this.dt = el}
                    value={this.state.listaServicos}
                    header={header}
                    paginator={this.state.listaServicos.length > 5}
                    rowsPerPageOptions={[5,10,25]}
                    rows={10}
                    // first={this.state.first}
                    // onPage={event => this.onPageChange(event)}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Exibindo {first} até {last} de {totalRecords} Parâmetros">                    
                    <Column field="servico" header="Serviço" />
                    <Column field="descricao" header="Parâmetro" />
                    <Column field="tipo" header="Tipo" body={this.tipoBodyTemplate}></Column>
                    <Column body={this.actionBodyTemplate} style={{ width: '15%' }} ></Column>
                </DataTable>

                <Dialog visible={this.state.servicoDialog} style={{ width: '550px' }} header="Serviço" modal className="p-fluid" footer={servicoDialogFooter}
                    onHide={this.hideDialog}>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12">
                            <label htmlFor="servico">Serviço</label>
                            <InputText id="servico" value={this.state.servico.servico} onChange={(e) => this.onInputChange(e, 'servico')}
                                required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.servico.servico })}
                                 />
                            {this.state.submitted && !this.state.servico.servico && <small className="p-invalid">Serviço é requerido.</small>}
                        </div>
                        <div className="p-field p-col-12">
                            <label htmlFor="idparametroservico">Parâmetro</label>
                            <Dropdown inputId="idparametroservico" optionValue="id" optionLabel="descricao" value={this.state.servico.idparametroservico} options={this.state.listaParametros}
                                onChange={(e) => this.onParametroChange(e)} placeholder="Selecione    " />
                        </div>
                        <div className="p-field p-col-12">
                            <div>
                                <div className="p-mb-2">
                                    Tipo
                            </div>
                                <div className="p-fluid p-formgrid p-grid">
                                    <div className="p-field-radiobutton p-col-3">
                                        <RadioButton inputId="direto" name="tipodireto" value="D" onChange={(e) => this.onTipoChange(e)} checked={this.state.servico.tipo === 'D'} />
                                        <label htmlFor="direto">Direto</label>
                                    </div>
                                    <div className="p-field-radiobutton p-col-3">
                                        <RadioButton inputId="indireto" name="tipoindireto" value="I" onChange={(e) => this.onTipoChange(e)} checked={this.state.servico.tipo === 'I'} />
                                        <label htmlFor="indireto">Indireto</label>
                                    </div>
                                </div>
                                {this.state.submitted && !this.state.servico.tipo && <small className="p-invalid">O tipo de serviço é requerido.</small>}
                            </div>
                        </div>
                        <div className="p-field p-col-12">
                            <div>
                                <div className="p-field-checkbox p-mt-3">
                                    <Checkbox inputId="incluirpadrao" value="Padrão" onChange={this.onPadraoChange} checked={this.state.servico.incluirpadrao}></Checkbox>
                                    <label htmlFor="incluirpadrao" className="p-checkbox-label">Padrão</label>
                                </div>
                                <div className="p-field-checkbox p-mt-3">
                                    <Checkbox inputId="ativo" value="Ativo" onChange={this.onAtivoChange} checked={this.state.servico.ativo}></Checkbox>
                                    <label htmlFor="ativo" className="p-checkbox-label">Ativo</label>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </Dialog>
                <Dialog visible={this.state.deleteServicoDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteServicoDialogFooter}
                    onHide={this.hideDeleteServicoDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        {this.state.servico && <span>Tem certeza que deseja excluir <b>{this.state.servico.servico}</b>?</span>}
                    </div>
                </Dialog>
            </div>
        )
    }
}
