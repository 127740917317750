import React, { Component } from 'react';
import api from '../../service/api';
import { PropTypes } from 'prop-types';
import { InputNumber } from '../../components/inputnumber/InputNumber';
import { Panel } from '../../components/panel/Panel';

export class Painel extends Component {

    static defaultProps = {
        idDimensionamento: 1
    }

    static propTypes = {
        idDimensionamento: PropTypes.number
    }

    emptyPainel = {
        "idnegociodimensionamento": null,
        "id": null,
        "rentabilidade": 0,
        "comissao": 0,
        "impostoServico": 0,
        "impostoMaterial": 0
    };

    constructor(props) {
        super(props);

        this.state = {
            painel: this.emptyPainel,
            submitted: false,
            edicao: false,
            servicoDialog: false,
            deleteServicoDialog: false
        }

        this.originalRows = {};

        this.onRowEditInit = this.onRowEditInit.bind(this);
        this.onRowEditCancel = this.onRowEditCancel.bind(this);
        this.onRowEditSave = this.onRowEditSave.bind(this);
    }

    async componentDidMount() {
        /*const response = await api.get(`/painel`);*/
         const response = await api.get(`/painel/${this.props.idNegocioDimensionamento}`);

        this.setState({
            painel: response.data
        });

        console.table(response.data);
    }

    editPainel(painel) {
        this.setState({
            painel: { ...painel },
        });
    }

    onRowEditInit(event) {
        this.originalRows[event.index] = { ...this.state.painel[event.index] };
    }

    onRowEditCancel(event) {

    }

    async onRowEditSave(event) {

    }

    onEditorNumberValueChange(listaName, props, value) {

    }


    inputNumberEditor(listaName, props, field) {
        return <InputNumber mode="decimal" locale="pt-BR" minFractionDigits={2} value={props.rowData[field]}
        onValueChange={(e) => this.onEditorNumberValueChange(listaName, props, e.value)} inputStyle={{ width: '100%' }} />;
    }

    paramValueEditor(listaName, props) {
        return this.inputNumberEditor(listaName, props, 'valor');
    }

    formatarCurrency(rowData, field) {
        return rowData[field].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 });
    }

    render() {
        return (
            <div className="p-field p-col-12 p-md-6">
                <Panel header="Painel" >
                    <table className="table table-bordered" >
                        <tbody>
                            <tr>
                                <td><b>Rentabilidade</b></td>
                                <td>{this.state.painel.rentabilidade}</td>
                            </tr>
                            <tr>
                                <td><b>Comissão</b></td>
                                <td>{this.state.painel.comissao}</td>
                            </tr>
                            <tr>
                                <td><b>Imposto de Serviços</b></td>
                                <td>{this.state.painel.impostoServico}</td>
                            </tr>
                            <tr>
                                <td><b>Imposto de Materiais</b></td>
                                <td>{this.state.painel.impostoMaterial}</td>
                            </tr>
                            <tr>
                                <td><b>Total de Serviços e Materiais</b></td>
                            </tr>
                        </tbody>
                    </table>
                </Panel>
            </div>
        );
    }
}
 
/*

            <div className="content-section implementation">
                <Toast ref={(el) => this.toast = el} />
                <div className="p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <Panel header="Painel" toggleable>
                            <table className="table table-bordered" >
                                <tbody>
                                    <tr>
                                        <td><b>Rentabilidade</b></td>
                                        <td>{this.editNumerTemplate("rentabilidade", 2)}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Comissão</b></td>
                                        <td>{this.editNumerTemplate("comissao", 2)}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Imposto Materiais</b></td>
                                        <td>{this.editNumerTemplate("impostoServico", 2)}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Imposto Serviços</b></td>
                                        <td>{this.editNumerTemplate("impostoMaterial", 0)}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="p-d-inline-flex">
                                {this.footerTemplate()}
                            </div>
                        </Panel>
                    </div>
                </div>
            </div>
*/