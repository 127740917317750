import React, { Component } from 'react';
import api from '../../service/api';
import { PropTypes } from 'prop-types';
import { Card } from '../../components/card/Card';

export class NegocioDimensionamentoResultado extends Component {

    emptyDimensionamento = {
        "idnegocio": 0,
        "eficiencia": 0.00,
        "areautilgeracao": 0.00,
        "quantidademodulos": 0.00,
        "potencia": 0.00
    };

    Consumo = {
        "consumomedia": 0,
        "mediavalortarifa": 0,
        "consumominimo": 0,
        "dimensionamentodesejado": 0
    }

    ParamSolPlenoDia = {
        "id": null,
        "jan": 0.00,
        "fev": 0.00,
        "mar": 0.00,
        "abr": 0.00,
        "mai": 0.00,
        "jun": 0.00,
        "jul": 0.00,
        "ago": 0.00,
        "set": 0.00,
        "out": 0.00,
        "nov": 0.00,
        "dez": 0.00,
        "media": 0.00
    }

    static defaultProps = {
        idnegocio: 0,
        eficiencia: 0.00,
        areautilgeracao: 0.00,
        quantidademodulos: 0.00,
        potencia: 0.00
    }

    static propTypes = {
        idnegocio: PropTypes.number,
        eficiencia: PropTypes.number,
        areautilgeracao: PropTypes.number,
        quantidademodulos: PropTypes.number,
        potencia: PropTypes.number
    }

    constructor(props) {
        super(props);

        this.state = {
            Dimensionamento: this.emptyDimensionamento,
            paramPerdaTotal: { valorperda: 0 },
        }

    }

    async componentDidMount() {
        await api.get(`/contas/resumo/negocio/${this.props.idnegocio}`)
            .then(res => {
                this.Consumo = res.data[0]
            })
        await api.get(`/solpleno/negocio/${this.props.idnegocio}`)
            .then(res => {
                this.ParamSolPlenoDia = res.data[0];
            })
        const PerdaTotal = await api.get(`/parametrossolarimetricos/total/negocio/${this.props.idnegocio}`);

        let Dimensionamento = { ...this.state.Dimensionamento };
        Dimensionamento.idnegocio = this.props.idnegocio
        Dimensionamento.eficiencia = this.props.eficiencia;
        Dimensionamento.areautilgeracao = this.props.areautilgeracao;
        Dimensionamento.potencia = this.props.potencia;
        Dimensionamento.quantidademodulos = this.props.quantidademodulos;

        this.setState({ 
            Dimensionamento: Dimensionamento, 
            paramPerdaTotal: PerdaTotal.data[0] 
        })
    }

    // static getDerivedStateFromProps(nextProps, prevState){
    //     if (nextProps !== prevState){

    //     }
    // }

    calcularPotenciaDesejada() {
        if (this.state.Dimensionamento.idnegocio !== 0) {
            let MediaSolPleno = this.ParamSolPlenoDia.media;
            let Perda = this.state.paramPerdaTotal.valorperda;
            let Dimensionamento = this.Consumo.dimensionamentodesejado;

            Dimensionamento = this.round_abnt(Dimensionamento / 30.4 / this.round_abnt(((100 - Perda) / 100 * MediaSolPleno), 2), 2);
            return Dimensionamento.toLocaleString('pt-BR', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
        }

    }

    componentWillReceiveProps(nextProps) {
        if ((nextProps.eficiencia !== this.props.eficiencia)
            || (nextProps.potencia !== this.props.potencia)
            || (nextProps.areautilgeracao !== this.props.areautilgeracao)
            || (nextProps.quantidademodulos !== this.props.quantidademodulos)) {

            let Dimensionamento = { ...this.state.Dimensionamento };
            Dimensionamento.idnegocio = nextProps.idnegocio
            Dimensionamento.eficiencia = nextProps.eficiencia;
            Dimensionamento.areautilgeracao = nextProps.areautilgeracao;
            Dimensionamento.potencia = nextProps.potencia;
            Dimensionamento.quantidademodulos = nextProps.quantidademodulos;

            this.setState({ Dimensionamento })

        }
    }

    calcularPotenciaPico() {
        if (this.state.Dimensionamento.idnegocio !== 0) {
            let potencia = this.state.Dimensionamento.potencia;
            let qtdModulos = this.state.Dimensionamento.quantidademodulos;
            return potencia * qtdModulos / 1000;
        }
        else {
            return 0;

        }
    }

    calcularPotenciaInversor() {
        if (this.state.Dimensionamento.idnegocio !== 0) {
            return this.calcularPotenciaPico() * 0.85;
        }
        else {
            return 0;
        }
    }

    round_abnt(nValor, nDecimais) {

        var nRetorno = nValor;
        var spl = nValor.toString().split(".");
        var cDecimais = spl[1];
        var nSubsequente = nDecimais;

        if (nDecimais < 1) {
            return parseInt(nRetorno);
        }

        if (!cDecimais || cDecimais.length <= nDecimais) {
            return parseFloat(nRetorno);
        }

        //Se a casa decimal SUBSEQUENTE for DIFERENTE de 5
        if (cDecimais.substr(nSubsequente, 1) >= '5' || cDecimais.substr(nSubsequente, 1) < '5') {
            nRetorno = nRetorno.toFixed(2); //Math.round((nRetorno + 0.00001) * 100) / 100; //ARREDONDA
        }
        //Se a casa decimal SUBSEQUENTE for IGUAL a 5
        else if (cDecimais.substr(nSubsequente, 1) == '5') {

            //Se a casa decimal que será CONSERVADA, for IMPAR
            if ((cDecimais.substr(nDecimais - 1, 1) % 2) != 0) {
                nRetorno = nRetorno.toFixed(2); //Math.round((nRetorno + 0.00001) * 100) / 100; //ARREDONDA
            }
            //Se a casa decimal que será CONSERVADA, for PAR
            else
                //Se APÓS a casa decimal SUBSEQUENTE, houver ALGUM algarismo MAIOR que ZERO
                if (cDecimais.substr(nSubsequente + 1, 1) > 0) {
                    nRetorno = nRetorno.toFixed(2); //Math.round((nRetorno + 0.00001) * 100) / 100; //ARREDONDA
                }
                //Se APÓS a casa decimal SUBSEQUENTE, não houver NENHUM outro algarismo ou TODOS forem iguais a ZERO
                else {
                    //TRUNCA (Esse é o único momento em que o "arredondamento ABNT" se diferencia do "arredondamento normal")
                    nRetorno = this.Truncate(nValor, nDecimais);
                }
        }
        return parseFloat(nRetorno);
    }

    Truncate(nValor, nDecimais) {

        var nRetorno = nValor;
        var spl = nValor.toString().split(".");
        var cDecimais = spl[1];

        if (nDecimais < 1) {
            return parseInt(nRetorno);
        }

        if (cDecimais.length <= nDecimais) {
            return nRetorno;
        }

        //Pega a parte inteira do número e concatena com a substring sem alterar, pois é PAR e vai manter!
        nRetorno = parseInt(nValor.toString()) + '.' + cDecimais.substr(0, nDecimais);
        nRetorno = parseFloat(nRetorno);

        return nRetorno;
    }

    formatarNumero(valor, casas) {
        return valor.toLocaleString('pt-BR', { style: 'decimal', maximunFractionDigits: casas, minimumFractionDigits: casas });
    }

    calcularGeracaoMediaMensal() {
        if (this.state.Dimensionamento.idnegocio !== 0) {
            let eficiencia = this.state.Dimensionamento.eficiencia / 100;
            let area = this.state.Dimensionamento.areautilgeracao;
            let perda = (100 - this.state.paramPerdaTotal.valorperda) / 100;
            let modulos = this.state.Dimensionamento.quantidademodulos;
            let fator = 30.4 * eficiencia * area * perda * modulos;
            let jan = this.round_abnt(this.round_abnt(this.ParamSolPlenoDia.jan, 2) * fator, 2);
            let fev = this.round_abnt(this.round_abnt(this.ParamSolPlenoDia.fev, 2) * fator, 2);
            let mar = this.round_abnt(this.round_abnt(this.ParamSolPlenoDia.mar, 2) * fator, 2);
            let abr = this.round_abnt(this.round_abnt(this.ParamSolPlenoDia.abr, 2) * fator, 2);
            let mai = this.round_abnt(this.round_abnt(this.ParamSolPlenoDia.mai, 2) * fator, 2);
            let jun = this.round_abnt(this.round_abnt(this.ParamSolPlenoDia.jun, 2) * fator, 2);
            let jul = this.round_abnt(this.round_abnt(this.ParamSolPlenoDia.jul, 2) * fator, 2);
            let ago = this.round_abnt(this.round_abnt(this.ParamSolPlenoDia.ago, 2) * fator, 2);
            let set = this.round_abnt(this.round_abnt(this.ParamSolPlenoDia.set, 2) * fator, 2);
            let out = this.round_abnt(this.round_abnt(this.ParamSolPlenoDia.out, 2) * fator, 2);
            let nov = this.round_abnt(this.round_abnt(this.ParamSolPlenoDia.nov, 2) * fator, 2);
            let dez = this.round_abnt(this.round_abnt(this.ParamSolPlenoDia.dez, 2) * fator, 2);
            return Math.round((jan + fev + mar + abr + mai + jun + jul + ago + set + out + nov + dez) / 12);
        }
        else {
            return 0;
        }

    }

    calcularPercentualAtendido() {
        if (this.state.Dimensionamento.idnegocio !== 0) {
            let gerecao = this.calcularGeracaoMediaMensal();
            let consumo = this.Consumo.dimensionamentodesejado;
            console.log('Atendimento %: ' + (gerecao / consumo * 100));
            return (gerecao / consumo * 100).toFixed();
        }
        else {
            return 0;
        }
    }

    getBadge(texto) {
        return (
            <div style={{ color: 'white', backgroundColor: '#0078d4' }}><b>{texto}</b></div>
            // <div className="badges p-text-center">
            //     <span className="p-tag p-badge-xl p-m-2"> {texto} </span>
            // </div>
        )
    }

    render() {

        return (

            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-3">
                    <Card
                        className="p-text-center"
                        header={this.getBadge('kWp')}
                        title={this.calcularPotenciaDesejada()}
                        subTitle="Potência Desejada">
                    </Card>
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Card
                        className="p-text-center"
                        header={this.getBadge('kWp')}
                        title={this.calcularPotenciaPico().toLocaleString('pt-BR', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                        subTitle="Potência Pico do Sistema">
                    </Card>
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Card
                        //style={{backgroundColor: 'red'}}
                        className="p-text-center"
                        header={this.getBadge('kWh')}
                        title={this.calcularGeracaoMediaMensal().toLocaleString('pt-BR', { maximumFractionDigits: 0, minimumFractionDigits: 0 })}
                        subTitle="Geração Média Mensal">
                    </Card>
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Card
                        className="p-text-center"
                        header={this.getBadge('%')}
                        title={
                            (this.ParamSolPlenoDia.media === 0) ? (imprimeZero => 0) :
                                this.calcularPercentualAtendido().toLocaleString('pt-BR', { maximumFractionDigits: 2, minimumFractionDigits: 2 })
                        }
                        subTitle="Percentual atendido">
                    </Card>
                </div>
            </div>

            // <Panel header="Sistema Indicado" toggleable>
            //     <table className="table table-bordered" >
            //         <tbody>
            //             <tr>
            //                 <td><b>Potência Pico do Sistema Fotovoltaico (kW)</b></td>
            //                 <td>{this.calcularPotenciaPico().toLocaleString('pt-BR')}</td>
            //             </tr>
            //             <tr>
            //                 <td><b>Potência do(s) Inversor(es) de Frequência(kW)</b></td>
            //                 <td>{this.calcularPotenciaInversor().toLocaleString('pt-BR')}</td>
            //             </tr>
            //             <tr>
            //                 <td><b>Geração Média Mensal Estimada (kWh)</b></td>
            //                 <td>{this.calcularGeracaoMediaMensal().toLocaleString('pt-BR')}</td>
            //             </tr>
            //             <tr>
            //                 <td><b>Percentual atendido</b></td>
            //                 <td>{this.calcularPercentualAtendido().toLocaleString('pt-BR')}</td>
            //             </tr>
            //         </tbody>
            //     </table>
            // </Panel>
        );
    }

}