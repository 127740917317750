import { set } from 'date-fns';
import { Skeleton } from 'primereact/skeleton';
import React, { useState, useEffect } from 'react';
import api from '../../service/api';

export const CardsFinanceiros = (props) => {

    const emptyFinanceiro = {
        "custototal": 0.00,
        "receitabrutatotal": 0.00,
        "rswpmedia": 0.00
    };

    const [financeiro, setFinanceiro] = useState(emptyFinanceiro);
    const [loading, setLoading] = useState(true)
        ;
    useEffect(() => {
        setLoading(true);
        api.get(`/dashboard/financeiro/${props.Ini}/${props.Fim}`)
            .then(res => {
                setFinanceiro(res.data[0]);
                setLoading(false);
            });
    }, [props.Ini, props.Fim]);

    const formatarNumero = (valor, casas) => {
        if (!valor){
            valor = 0;
        }
        if (!casas){
            casas = 2;
        }        
        return valor.toLocaleString('pt-BR', { style: 'decimal', maximunFractionDigits: casas, minimumFractionDigits: casas });
    }

    return (
        <>
            {!loading && (
                <>
                    <div className="p-col-12 p-lg-4">
                        <div className="card summary">
                            <span className="title">Custo</span>
                            <span className="detail">Total do custo</span>
                            <span className="count visitors">R${formatarNumero(financeiro.custototal, 2)}</span>
                        </div>
                    </div>
                    <div className="p-col-12 p-lg-4">
                        <div className="card summary">
                            <span className="title">R$/Wp</span>
                            <span className="detail">Média de R$/Wp</span>
                            <span className="count purchases">{formatarNumero(financeiro.rswpmedia, 2)}</span>
                        </div>
                    </div>
                    <div className="p-col-12 p-lg-4">
                        <div className="card summary">
                            <span className="title">Receita bruta</span>
                            <span className="detail">Total da receita bruta</span>
                            <span className="count revenue">R${formatarNumero(financeiro.receitabrutatotal, 2)}</span>
                        </div>
                    </div>
                </>
            )}
            {loading && (
                <>
                    <div className="p-col-12 p-lg-4">
                        <Skeleton height="5rem" />
                    </div>
                    <div className="p-col-12 p-lg-4">
                        <Skeleton height="5rem" />
                    </div>
                    <div className="p-col-12 p-lg-4">
                        <Skeleton height="5rem" />
                    </div>
                </>
            )}
        </>
    );
}