import api from "./api";

export const consultaCEP = (cep) => new Promise((resolve, reject) => {

    let retorno = {
        logradouro: '',
        bairro: '',
        idCidade: null,
        cidade: '',
        idEstado: null,
        uf: '',
        ibge: ''
    }
    
    if (cep.trim()) {
        cep = cep.replace('.', '');
        cep = cep.replace('-', '');
        if (cep.length === 8) {
            api.get(`/consultacep/${cep}`)
                .then(res => {
                    if (res.status === 200) {
                        if (typeof res.data.erro === "undefined") {
                            api.get(`/cidades/ibge/${res.data.ibge}`)
                                .then(cidade => {
                                    retorno.logradouro = res.data.logradouro.toUpperCase();
                                    retorno.bairro = res.data.bairro.toUpperCase();
                                    retorno.idCidade = cidade.data[0].idcidade;
                                    retorno.cidade = cidade.data[0].cidade.toUpperCase();
                                    retorno.idEstado = cidade.data[0].idestado;
                                    retorno.uf = cidade.data[0].uf.toUpperCase();
                                    resolve(retorno);
                                });
                        }
                        else {
                            reject(`CEP ${cep} não localizado`)
                        }
                    }
                })
                .catch(error => {
                    reject(`Ocorreu algum problema ao tentar consultar o CEP ${cep} Error: ${error}`);
                })
        }
        else {
            reject(`CEP ${cep} inválido`)
        }
    }
})