import React from 'react';
import { Panel } from '../../../components/panel/Panel';
import { ParametroServico } from '../components/parametroServico';
import { Prazos } from '../components/prazos';
import { Servico } from '../components/servico';

export const ServicoConfiguracao = () => {
    return (
        <div>
            <div className="content-section introduction">
                <h5>Serviço Configuração</h5>
            </div>
            <div className="p-grid crud-demo">
                <div className="card">
                    <Panel header="Parâmetros">
                        <ParametroServico />
                    </Panel>
                    <Panel className="p-mt-4" header="Serviços">
                        <Servico />
                    </Panel>
                    <Panel className="p-mt-4" header="Prazos">
                        <Prazos />
                    </Panel>   
                </div>
            </div>
        </div>
    )
}  