import React, { Component } from 'react';
import classNames from 'classnames';
import api from '../../service/api';
import { Button } from '../../components/button/Button';
import { InputNumber } from '../../components/inputnumber/InputNumber';
import { Toast } from '../../components/toast/Toast';
import { Toolbar } from '../../components/toolbar/Toolbar';
import { InputText } from '../../components/inputtext/InputText';
import { Dialog } from '../../components/dialog/Dialog';
import { DataTable } from '../../components/datatable/DataTable';
import { Column } from '../../components/column/Column';
import { PropTypes } from 'prop-types';

export class DimensionamentoConfigIncluso extends Component {

    static defaultProps = {
        idNegocioDimensionamento: 0,
        idstatus: 1
    }

    static propTypes = {
        idNegocioDimensionamento: PropTypes.number,
        idstatus: PropTypes.number,
    }

    emptyNaoInclusosProposta = {
        "id": null,
        "descricao": '',
        "ordem": null,
        "idnegociodimensionamento": null,

    }

    constructor(props) {
        super(props);

        this.state = {
            naoInclusoProposta: this.emptyNaoInclusosProposta,
            listaNaoInclusosProposta: [],
            submitted: false,
            edicao: false,
            editDialog: false,
            deleteDialog: false
        }

        this.originalRows = {};

        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.openNew = this.openNew.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.save = this.save.bind(this);
        this.edit = this.edit.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.hideDeleteDialog = this.hideDeleteDialog.bind(this);
        this.delete = this.delete.bind(this);
        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.rightToolbarTemplate = this.rightToolbarTemplate.bind(this);
        this.exibirPaginacao = this.exibirPaginacao.bind(this);
    }

    async componentDidMount() {

        const listaNaoInclusosProposta = await api.get(`/dimensionamentoinclusoproposta/dimensionamento/${this.props.idNegocioDimensionamento}`);
        this.setState({
            listaNaoInclusosProposta: listaNaoInclusosProposta.data
        });
    }

    hideDeleteDialog() {
        this.setState({ deleteDialog: false });
    }

    confirmDelete(naoInclusoProposta) {
        this.setState({
            naoInclusoProposta,
            deleteDialog: true
        });
    }

    delete() {
        api.delete(`/dimensionamentoinclusoproposta/${this.state.naoInclusoProposta.id}`)
            .then(res => {
                if (res.status === 204) {
                    let listaNaoInclusosProposta = this.state.listaNaoInclusosProposta.filter(val => val.id !== this.state.naoInclusoProposta.id);
                    this.setState({
                        listaNaoInclusosProposta,
                        deleteDialog: false,
                        naoInclusoProposta: this.emptyNaoInclusosProposta
                    });
                    this.toast.show({ severity: 'success', summary: 'Successo', detail: 'Item excluído', life: 3000 });
                }
            })
            .catch(error => {
                this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu excluir o item', life: 3000 });
                //console.log(error.response.data.description);
            })
    }

    edit(naoInclusoProposta) {
        this.setState({
            naoInclusoProposta: { ...naoInclusoProposta },
            editDialog: true
        });
    }

    openNew() {
        this.setState({
            naoInclusoProposta: this.emptyNaoInclusosProposta,
            submitted: false,
            editDialog: true
        });
    }

    hideDialog() {
        this.setState({
            submitted: false,
            editDialog: false
        });
    }

    async save() {
        let state = { submitted: true }
        if (this.state.naoInclusoProposta.descricao.trim() && this.state.naoInclusoProposta.ordem) {
            let listaNaoInclusosProposta = [...this.state.listaNaoInclusosProposta];
            let naoInclusoProposta = { ...this.state.naoInclusoProposta };
            if (this.state.naoInclusoProposta.id) {
                const index = this.findIndexById(this.state.naoInclusoProposta.id);
                api.put(`/dimensionamentoinclusoproposta/${naoInclusoProposta.id}`, naoInclusoProposta)
                    .then(res => {
                        if (res.status === 204) {
                            listaNaoInclusosProposta[index] = naoInclusoProposta;
                            state = {
                                submitted: false,
                                listaNaoInclusosProposta,
                                editDialog: false,
                                naoInclusoProposta: this.emptyNaoInclusosProposta
                            };
                            this.setState(state);
                            this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Item atualizado', life: 3000 });
                        }
                    })
                    .catch(error => {
                        this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu atualizar o item', life: 3000 });
                        //console.log(error.response.data.description);
                    })
            }
            else {
                naoInclusoProposta.id = 0;
                naoInclusoProposta.idnegociodimensionamento = this.props.idNegocioDimensionamento;
                console.table(naoInclusoProposta);
                api.post('/dimensionamentoinclusoproposta', naoInclusoProposta)
                    .then(res => {
                        if (res.status === 201) {
                            naoInclusoProposta.id = res.headers.id;
                            listaNaoInclusosProposta.push(naoInclusoProposta);
                            state = {
                                submitted: false,
                                listaNaoInclusosProposta,
                                editDialog: false,
                                naoInclusoProposta: this.emptyNaoInclusosProposta
                            };
                            this.setState(state);
                            this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Item inserido', life: 3000 });
                        }
                    })
                    .catch(error => {
                        this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu inserir o item', life: 3000 });
                        //console.log(error.response.data.description);
                    })
            }
        }
        else {
            this.setState(state);
        }
    }

    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button 
                    icon="pi pi-pencil" 
                    className="p-button-rounded p-mr-2" 
                    onClick={() => this.edit(rowData)} 
                    disabled={this.props.idstatus > 2} />
                <Button 
                    icon="pi pi-trash" 
                    className="p-button-rounded p-button-danger" 
                    onClick={() => this.confirmDelete(rowData)}
                    disabled={this.props.idstatus > 2} />
            </React.Fragment>
        );
    }

    onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        const input = e.target;
        const start = input.selectionStart;
        const end = input.selectionEnd;
        let naoInclusoProposta = { ...this.state.naoInclusoProposta };
        naoInclusoProposta[`${name}`] = val.toUpperCase();
        this.setState({ naoInclusoProposta: naoInclusoProposta },
            () => input.setSelectionRange(start, end));
    }

    onInputNumberChange(e, name) {
        const val = e.value;
        let naoInclusoProposta = { ...this.state.naoInclusoProposta };
        naoInclusoProposta[`${name}`] = val;
        this.setState({ naoInclusoProposta: naoInclusoProposta });
    }

    findIndexById(id) {
        let index = -1;
        for (let i = 0; i < this.state.listaNaoInclusosProposta.length; i++) {
            if (this.state.listaNaoInclusosProposta[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    onUnidadeChange(e) {
        let naoInclusoProposta = { ...this.state.naoInclusoProposta };
        naoInclusoProposta.idund = e.value;
        this.setState({ naoInclusoProposta });
    }

    formatarNumero(rowData, field) {
        return rowData[field].toLocaleString('pt-BR', { style: 'decimal', minimumFractionDigits: 2 });
    }

    formatarCurrency(rowData, field) {
        return rowData[field].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 });
    }

    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button 
                    label="Novo" 
                    icon="pi pi-plus" 
                    className="p-button p-mr-2" 
                    onClick={this.openNew}
                    disabled={this.props.idstatus > 2} />
            </React.Fragment>
        )
    }

    rightToolbarTemplate() {

    }

    exibirPaginacao() {
        let exibir = false;
        if (this.state.listaNaoInclusosProposta.length > 5) { exibir = true }
        return exibir
    }

    render() {
        const header = (
            <div className="table-header">
                <h5 className="p-m-0">Listagem</h5>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Localizar..." />
                </span>
            </div>
        );

        const dialogFooter = (
            <React.Fragment>
                <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={this.save} />
            </React.Fragment>
        );

        const deleteDialogFooter = (
            <React.Fragment>
                <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteDialog} />
                <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={this.delete} />
            </React.Fragment>
        );

        return (
            <div className=" datatable-crud-demo">
                <Toast ref={(el) => this.toast = el} />
                <Toolbar className="p-mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
                <DataTable
                    className="p-datatable-gridlines p-datatable-sm"
                    ref={(el) => this.dt = el}
                    value={this.state.listaNaoInclusosProposta}
                    paginator={this.exibirPaginacao()}
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Exibindo {first} até {last} de {totalRecords} Parâmetros"
                    globalFilter={this.state.globalFilter}
                    header={header}>
                    <Column field="descricao" header="Descrição" ></Column>
                    <Column field="ordem" header="Ordem" style={{ width: '10%' }}></Column>
                    <Column body={this.actionBodyTemplate} style={{ width: '15%' }}></Column>
                </DataTable>

                <Dialog visible={this.state.editDialog} style={{ width: '550px' }} header="Item incluso" modal className="p-fluid" footer={dialogFooter}
                    onHide={this.hideDialog}>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12">
                            <label htmlFor="descricao">Descrição</label>
                            <InputText id="descricao" value={this.state.naoInclusoProposta.descricao} onChange={(e) => this.onInputChange(e, 'descricao')}
                                required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.naoInclusoProposta.descricao })}
                                 />
                            {this.state.submitted && !this.state.naoInclusoProposta.descricao && <small className="p-invalid">Descrição é requerido.</small>}
                        </div>
                        <div className="p-field p-col-12">
                            <label htmlFor="ordem">Ordem</label>
                            <InputNumber id="ordem" value={this.state.naoInclusoProposta.ordem} onValueChange={(e) => this.onInputNumberChange(e, 'ordem')}
                                required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.naoInclusoProposta.ordem })}
                                mode="decimal" locale="pt-BR" minFractionDigits={2}  />
                            {this.state.submitted && !this.state.naoInclusoProposta.ordem && <small className="p-invalid">Ordem é requerido.</small>}
                        </div>
                    </div>
                </Dialog>

                <Dialog visible={this.state.deleteDialog} style={{ width: '450px' }} header="Confirme" modal footer={deleteDialogFooter}
                    onHide={this.hideDeleteDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        {this.state.naoInclusoProposta && <span>Tem certeza que deseja excluir <b>{this.state.naoInclusoProposta.descricao}</b>?</span>}
                    </div>
                </Dialog>
            </div>
        )
    }
}
