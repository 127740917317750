import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import React, { useContext, useState, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { UserContext } from '../../context';
import auth from '../../service/auth';
import { Toast } from 'primereact/toast';
import jwt_decode from "jwt-decode";
import { Password } from 'primereact/password';


const Login = () => {

    const history = useHistory();
    const context = useContext(UserContext);
    const [myUser, setUser] = useState();
    const toast = useRef(null);

    const handleInputChange = ev => {
        let name = [ev.target.name];
        let value = ev.target.value;
        setUser(
            { ...myUser, [name]: value }
        )
    }

    const handleSubmit = ev => {
        ev.preventDefault();

        // let jo = {
        //     "jwtusername":"user1",
        //     "jwtpassword":"pwduser1"
        // };

        auth.post('/login', myUser)
            .then(res => {
                let decoded = jwt_decode(res.data.token); 

                sessionStorage.setItem('rotatoken', res.data.token);
                sessionStorage.setItem('usuario', decoded.usuario);
                sessionStorage.setItem('idusuario', decoded.idusuario);

                if (!!decoded.trocarsenha) {
                    // console.log('!trocarsenha');
                    context.alterLogin(true);
                    history.push('/');
                    window.location.reload();
                }
                else {
                    // console.log('else');
                    sessionStorage.setItem('trocarsenha', decoded.trocarsenha);
                    history.push('/trocarsenha');
                    window.location.reload();
                }
            })
            .catch(error => {
                if (!error.response){
                    alert('Verifique sua conexão com a internet');
                }
                else{
                    if (error.response.data.statuscode === 403){
                        alert('Usuário ou senha inválidos');
                    }
                    else{
                        alert(error.response.data.message);
                    }                    
                }
            })
    }

    return (
        <div>
            <Toast ref={toast} />
            <div className="p-p-4 p-d-flex p-jc-center">
                <div className="card p-mr-2">
                    <div className="p-d-flex p-jc-center">
                        <img className="photo" src={`assets/rota/Logo.png`}
                            style={{width: '150px'}}>
                        </img>
                    </div>
                    <div className='p-mt-4'>
                        <h1 style={{ textAlign: "center" }}>Bem vindo</h1>
                        <h4 style={{ textAlign: "center" }}>Login Rota Solar. </h4>

                        <br></br>
                        <form onSubmit={handleSubmit}>
                            <div className="p-mr-2">
                                {/* <span className="p-float-label"> */}
                                {/* <label htmlFor="usuario">E-mail</label> */}
                                <br></br>
                                <InputText id="usuario" type="text" name="jwtusername" placeholder='E-mail' onChange={handleInputChange}
                                    className="p-inputtext-lg p-d-block" style={{ width: "370px" }} />
                                {/* <br></br> */}
                                {/* </span> */}
                            </div>

                            <div className="p-mr-2">
                                {/* <span className="p-float-label"> */}
                                {/* <label htmlFor="senha">Senha</label> */}
                                <br></br>
                                {/* <InputText id="senha" type="password" name="jwtpassword" placeholder='Senha' onChange={handleInputChange}
                                    className="p-inputtext-lg p-d-block" style={{ width: "370px" }} /> */}
                                <Password id="senha" name="jwtpassword" placeholder='Senha' onChange={handleInputChange}
                                    className="p-inputtext-lg p-d-block" inputStyle={{ width: "370px" }}  toggleMask />
                                {/* </span> */}

                            </div>
                            <div className="p-mr-2">
                                <br />
                                <br></br>
                                <Button type="submit" label="Entrar" className="p-button-lg p-d-block p-mx-auto" style={{ width: "370px" }} />
                                <br></br>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Login