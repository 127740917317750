import React, { Component } from 'react';
import api from '../../service/api';
import { DataTable } from '../../components/datatable/DataTable';
import { Column } from '../../components/column/Column';
import { Toast } from '../../components/toast/Toast';
import { Toolbar } from '../../components/toolbar/Toolbar';
import { Dialog } from '../../components/dialog/Dialog';
import { InputText } from '../../components/inputtext/InputText';
import classNames from 'classnames';
import { Button } from '../../components/button/Button';

export class TipoMaterial extends Component {

    emptyTipoMaterial = {
        "id": null,
        "descricao": ''
    };

    constructor(props) {
        super(props);

        this.state = {
            listaTipos: [],
            tipo: this.emptyTipoMaterial,
            submitted: false,
            tipoDialog: false,
            deleteDialog: false,
            rows: 10,
            first: 1,
        };

        this.originalRows = {};

        this.openNew = this.openNew.bind(this);
        this.edit = this.edit.bind(this);
        this.save = this.save.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.hideDeleteDialog = this.hideDeleteDialog.bind(this);
        this.delete = this.delete.bind(this);
        this.findIndexById = this.findIndexById.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.exibirPaginacao = this.exibirPaginacao.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
    }

    /******************************************************************************************************** */

    async componentDidMount() {
        const listaTipos = await api.get(`/tipomaterial`);
        this.setState({ listaTipos: listaTipos.data });
    }

    /******************************************************************************************************** */

    openNew() {
        this.setState({
            tipo: this.emptyTipoMaterial,
            submitted: false,
            tipoDialog: true
        })
    }

    edit(tipo) {
        this.setState({
            tipo: { ...tipo },
            tipoDialog: true
        });
    }

    save() {
        let state = { submitted: true };

        if (this.state.tipo.descricao.trim()) {

            let listaTipos = [...this.state.listaTipos];
            let tipo = { ...this.state.tipo };
            if (this.state.tipo.id) {
                const index = this.findIndexById(this.state.tipo.id);
                api.put(`/tipomaterial/${this.state.tipo.id}`, tipo)
                    .then(res => {
                        if (res.status === 204) {
                            listaTipos[index] = tipo;
                            state = {
                                ...state,
                                listaTipos: listaTipos,
                                tipoDialog: false,
                                tipo: this.emptyTipoMaterial
                            };
                            this.setState(state);
                            this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Tipo atualizado', life: 3000 });
                        }
                    })
                    .catch(error => {
                        this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu atualizar o Tipo', life: 3000 });
                        //console.log(error.response.data.description);
                    })
            }
            else {
                tipo.id = 0;
                api.post('/tipomaterial', tipo)
                    .then(res => {
                        if (res.status === 201) {
                            tipo.id = res.headers.id;
                            listaTipos.push(tipo);
                            state = {
                                ...state,
                                listaTipos: listaTipos,
                                tipoDialog: false,
                                tipo: this.emptyTipoMaterial
                            };
                            this.setState(state);
                            this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Tipo inserido', life: 3000 });
                        }
                    })
                    .catch(error => {
                        this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu inserir o Tipo', life: 3000 });
                        // console.log(error.response.data.description);
                    })
            }
        }
        else {
            this.setState(state);
        }
    }

    hideDialog() {
        this.setState({
            submitted: false,
            tipoDialog: false
        });
    }

    confirmDelete(tipo) {
        this.setState({
            tipo,
            deleteDialog: true
        });
    }

    hideDeleteDialog() {
        this.setState({ deleteDialog: false });
    }

    delete() {
        api.delete(`/tipomaterial/${this.state.tipo.id}`)
            .then(res => {
                if (res.status === 204) {
                    let listaTipos = this.state.listaTipos.filter(val => val.id !== this.state.tipo.id);
                    this.setState({
                        listaTipos,
                        deleteDialog: false,
                        tipo: this.emptyTipoMaterial
                    });
                    this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Tipo excluído', life: 3000 });
                }
                else {
                    console.log(res);
                }
            })
            .catch(error => {
                this.setState({ deleteDialog: false });
                this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu excluir o Tipo', life: 3000 });
                //console.log(error.response.data.description);
            })
    }

    findIndexById(id) {
        let index = -1;
        for (let i = 0; i < this.state.listaTipos.length; i++) {
            if (this.state.listaTipos[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        const input = e.target;
        const start = input.selectionStart;
        const end = input.selectionEnd;
        let tipo = { ...this.state.tipo };
        tipo[`${name}`] = val.toUpperCase();
        this.setState({ tipo: tipo },
            () => input.setSelectionRange(start, end));
    }

    /******************************************************************************************************* */

    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="Novo" icon="pi pi-plus" className="p-button p-mr-2" onClick={this.openNew} />
            </React.Fragment>
        )
    }

    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button p-mr-2"
                    onClick={() => this.edit(rowData)}
                    tooltip="Alterar"
                    tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} />
                <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger"
                    onClick={() => this.confirmDelete(rowData)}
                    tooltip="Excluir"
                    tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} />
            </React.Fragment>
        );
    }

    exibirPaginacao() {
        let exibir = false;
        if (this.state.listaTipos.length > 5) { exibir = true }
        return exibir
    }

    onPageChange = (event) => {
        this.setState({rows : event.rows, first : event.first});
    }

    /******************************************************************************************************* */

    render() {
        const usuarioDialogFooter = (
            <React.Fragment>
                <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={this.save} />
            </React.Fragment>
        );
        const deleteDialogFooter = (
            <React.Fragment>
                <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteDialog} />
                <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={this.delete} />
            </React.Fragment>
        );
        /** ---------------------------------------------------------------------------------------------------------------------- */
        return (
            <div>
                <div className="content-section introduction">
                    <h5>Tipos de Materiais</h5>
                </div>

                <div className="p-grid crud-demo">
                    <Toast ref={(el) => this.toast = el} />

                    <div className="card">
                        <Toolbar className="p-mb-4" left={this.leftToolbarTemplate} ></Toolbar>

                        <DataTable
                            className="p-datatable-gridlines p-datatable-sm"
                            ref={(el) => this.dt = el}
                            value={this.state.listaTipos}
                            selection={this.state.selectedUsuarios}
                            onSelectionChange={(e) => this.setState({ selectedUsuarios: e.value })}
                            dataKey="id"
                            paginator={this.exibirPaginacao()}
                            rows={10}
                            // first={this.state.first}
                            rowsPerPageOptions={[5, 10, 25]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Exibindo {first} até {last} de {totalRecords} Usuários"
                            globalFilter={this.state.globalFilter}
                            // onPage={event => this.onPageChange(event)}
                            >
                            <Column field="descricao" header="Desccrição" ></Column>
                            <Column body={this.actionBodyTemplate} style={{ width: '15%' }}></Column>
                        </DataTable>

                        <Dialog visible={this.state.tipoDialog} style={{ width: '550px' }} header="Tipo" modal className="p-fluid"
                            footer={usuarioDialogFooter} onHide={this.hideDialog}>
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-12">
                                    <label htmlFor="descricao">Descrição</label>
                                    <InputText id="descricao" value={this.state.tipo.descricao} onChange={(e) => this.onInputChange(e, 'descricao')}
                                        required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.tipo.descricao })}
                                         />
                                    {this.state.submitted && !this.state.tipo.descricao && <small className="p-invalid">DDescrição é requerido.</small>}
                                </div>
                            </div>
                        </Dialog>

                        <Dialog visible={this.state.deleteDialog} style={{ width: '450px' }} header="Confirm" modal
                            footer={deleteDialogFooter} onHide={this.hideDeleteDialog}>
                            <div className="confirmation-content">
                                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                                {this.state.tipo && <span>Tem certeza que deseja excluir <b>{this.state.tipo.descricao}</b>?</span>}
                            </div>
                        </Dialog>
                    </div>
                </div>
            </div>
        )
    }
}   