import React, { Component } from 'react';
import api from '../../service/api';
import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import { Button } from '../../components/button/Button';
import { Panel } from '../../components/panel/Panel';
import { Dialog } from '../../components/dialog/Dialog';
import { Dropdown } from '../../components/dropdown/Dropdown';
import { DataTable } from '../../components/datatable/DataTable';
import { Column } from '../../components/column/Column'
import { InputText } from '../../components/inputtext/InputText';
import { Toast } from '../../components/toast/Toast';
import { InputNumber } from '../../components/inputnumber/InputNumber';
import { EstruturaSoloGarantias } from './materiais/estruturaSoloGarantia';

export class EstruturaEdit extends Component {

    static defaultProps = {
        idDimensionamento: 1,
        idstatus: 1
    }

    static propTypes = {
        idDimensionamento: PropTypes.number,
        idstatus: PropTypes.number
    }

    emptyEstrutura = {
        "id": null,
        "idnegociodimensionamento": null,
        "descricao": '',
        "quantidade": 0,
        "idund": null,
        "und": "",
        "valorunitario": 0,
        "total": 0,
        "unidade": ''
    }

    atualizarTotal = () => {
        this.props.atualizarTotal(this.props.value);
    }

    constructor(props) {
        super(props);

        this.state = {
            estrutura: this.emptyEstrutura,
            listaEstruturas: [],
            unidades: [],
            submitted: false,
            edicao: false,
            estruturaDialog: false,
            deleteEstruturaDialog: false,
            garantiaDialog: false,
        }

        this.originalRows = {};

        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.openNewestrutura = this.openNewestrutura.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.saveEstrutura = this.saveEstrutura.bind(this);
        this.editEstrutura = this.editEstrutura.bind(this);
        this.confirmDeleteestrutura = this.confirmDeleteestrutura.bind(this);
        this.hideDeleteEstruturaDialog = this.hideDeleteEstruturaDialog.bind(this);
        this.deleteEstrutura = this.deleteEstrutura.bind(this);
        this.editGarantiaDialog = this.editGarantiaDialog.bind(this);
        this.hideGarantiaDialog = this.hideGarantiaDialog.bind(this);
    }

    async componentDidMount() {
        const listaEstruturas = await api.get(`/estruturas/dimensionamento/${this.props.idDimensionamento}`);
        const unidades = await api.get('/unidadesmedida');
        this.setState({
            listaEstruturas: listaEstruturas.data,
            unidades: unidades.data
        });
    }

    hideDeleteEstruturaDialog() {
        this.setState({ deleteEstruturaDialog: false });
    }

    confirmDeleteestrutura(estrutura) {
        this.setState({
            estrutura,
            deleteEstruturaDialog: true
        });
    }

    deleteEstrutura() {
        api.delete(`/estruturas/${this.state.estrutura.id}`)
            .then(res => {
                if (res.status === 204) {
                    let listaEstruturas = this.state.listaEstruturas.filter(val => val.id !== this.state.estrutura.id);
                    this.setState({
                        listaEstruturas,
                        deleteEstruturaDialog: false,
                        estrutura: this.emptyEstrutura
                    });
                    this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Estrutura excluída', life: 3000 });
                    this.atualizarTotal();
                }
            })
    }

    editEstrutura(estrutura) {
        this.setState({
            estrutura: { ...estrutura },
            estruturaDialog: true
        });
    }

    openNewestrutura() {
        this.setState({
            estrutura: this.emptyEstrutura,
            submitted: false,
            estruturaDialog: true
        });
    }

    hideDialog() {
        this.setState({
            submitted: false,
            estruturaDialog: false
        });
    }

    async saveEstrutura() {
        let state = { submitted: true };

        if (this.state.estrutura.descricao.trim() && this.state.estrutura.valorunitario && this.state.estrutura.valorunitario > 0
            && this.state.estrutura.quantidade && this.state.estrutura.quantidade > 0 && this.state.estrutura.idund) {

            let listaEstruturas = [...this.state.listaEstruturas];
            let estrutura = { ...this.state.estrutura };
            if (this.state.estrutura.id) {
                const index = this.findIndexById(this.state.estrutura.id);
                api.put(`/estruturas/${this.state.estrutura.id}`, estrutura)
                    .then(res => {
                        if (res.status === 204) {
                            listaEstruturas[index] = estrutura;
                            state = {
                                ...state,
                                listaEstruturas,
                                estruturaDialog: false,
                                estrutura: this.emptyEstrutura
                            };
                            this.setState(state);
                            this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'estrutura atualizado', life: 3000 });
                            this.atualizarTotal();
                        }
                    })
                    .catch(error => {
                        this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu atualizar a Estrutura de Fixação', life: 3000 });
                        // console.log(error.response.data.description);
                    })
            }
            else {
                estrutura.id = 0;
                estrutura.idnegociodimensionamento = this.props.idDimensionamento;
                api.post('/estruturas', estrutura)
                    .then(res => {
                        if (res.status === 201) {
                            estrutura.id = res.headers.id;
                            listaEstruturas.push(estrutura);
                            state = {
                                ...state,
                                listaEstruturas,
                                estruturaDialog: false,
                                estrutura: this.emptyEstrutura
                            };
                            this.setState(state);
                            this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'estrutura inserido', life: 3000 });
                            this.atualizarTotal();
                        }
                    })
                    .catch(error => {
                        this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu inserir a Estrutura de Fixação', life: 3000 });
                        // console.log(error.response.data.description);
                    })
            }
        }
        else {
            this.setState(state);
        }
    }

    hideGarantiaDialog() {
        this.setState({ garantiaDialog: false });
    }

    editGarantiaDialog(estrutura) {
        this.setState({
            estrutura: { ...estrutura },
            garantiaDialog: true
        });
    }

    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button p-mr-2"
                    onClick={() => this.editEstrutura(rowData)}
                    tooltip="Alterar"
                    tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} 
                    disabled={this.props.idstatus > 2} />
                <Button
                    icon="pi pi-tags"
                    className="p-button-rounded p-button-warning p-mr-2"
                    onClick={() => this.editGarantiaDialog(rowData)}
                    tooltip="Garantia"
                    tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} 
                    disabled={this.props.idstatus > 2} />
                <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger"
                    onClick={() => this.confirmDeleteestrutura(rowData)}
                    tooltip="Excluir"
                    tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} 
                    disabled={this.props.idstatus > 2} />
            </React.Fragment>
        );
    }

    onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        const input = e.target;
        const start = input.selectionStart;
        const end = input.selectionEnd;
        let estrutura = { ...this.state.estrutura };
        estrutura[`${name}`] = val.toUpperCase();
        this.setState({ estrutura: estrutura },
            () => input.setSelectionRange(start, end));
    }

    onInputNumberChange(e, name) {
        const val = e.value || 0;
        let total = 0;
        let estrutura = { ...this.state.estrutura };
        estrutura[`${name}`] = val;
        if ((estrutura.valorunitario) && (estrutura.quantidade)) {
            total = estrutura.valorunitario * estrutura.quantidade;
        }
        estrutura.total = total;
        this.setState({ estrutura: estrutura });
    }

    findIndexById(id) {
        let index = -1;
        for (let i = 0; i < this.state.listaEstruturas.length; i++) {
            if (this.state.listaEstruturas[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    findUnidade(id) {
        let index = -1;
        for (let i = 0; i < this.state.unidades.length; i++) {
            if (this.state.unidades[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    onUnidadeChange(e) {
        let estrutura = { ...this.state.estrutura };
        estrutura.idund = e.value;
        estrutura.unidade = this.state.unidades[this.findUnidade(e.value)].unidade;
        this.setState({ estrutura });
    }

    formatarNumero(rowData, field) {
        return rowData[field].toLocaleString('pt-BR', { style: 'decimal', minimumFractionDigits: 2 });
    }

    formatarCurrency(rowData, field) {
        return rowData[field].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 });
    }

    render() {
        const header = (
            <div className="table-header" left={this.busca}>
                <Button 
                    label="Novo" 
                    icon="pi pi-plus" 
                    className="p-button p-mr-2"
                    onClick={this.openNewestrutura} 
                    disabled={this.props.idstatus > 2} />
            </div>
        );

        const estruturaDialogFooter = (
            <React.Fragment>
                <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={this.saveEstrutura} />
            </React.Fragment>
        );

        const deleteEstruturaDialogFooter = (
            <React.Fragment>
                <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteEstruturaDialog} />
                <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={this.deleteEstrutura} />
            </React.Fragment>
        );

        return (
            <div>
                <Toast ref={(el) => this.toast = el} />
                <DataTable header={header} value={this.state.listaEstruturas} className="p-datatable-sm p-datatable-gridlines">
                    <Column field="id" header="Id" style={{ width: '6%' }} />
                    <Column className="p-editable-column p-cell-editing" field="descricao" header="Descrição" style={{ width: '30%' }} />
                    <Column field="quantidade" header="Qtd." body={(rowData) => this.formatarNumero(rowData, 'quantidade')} style={{ width: '8%' }} />
                    <Column field="unidade" header="Und" style={{ width: '8%' }} />
                    <Column field="valorunitario" header="Valor" body={(rowData) => this.formatarCurrency(rowData, 'valorunitario')} style={{ width: '12%' }} />
                    <Column field="total" header="Total" body={(rowData) => this.formatarCurrency(rowData, 'total')} style={{ width: '12%' }} />
                    <Column body={this.actionBodyTemplate} style={{ width: '14%' }} ></Column>
                </DataTable>

                <Dialog visible={this.state.estruturaDialog} style={{ width: '550px' }} header="Estrutura de Fixação" modal className="p-fluid" footer={estruturaDialogFooter}
                    onHide={this.hideDialog}>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12">
                            <label htmlFor="descricao">Descrição</label>
                            <InputText id="descricao" value={this.state.estrutura.descricao} onChange={(e) => this.onInputChange(e, 'descricao')}
                                required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.estrutura.descricao })}
                                 />
                            {this.state.submitted && !this.state.estrutura.descricao && <small className="p-invalid">Descrição é requerido.</small>}
                        </div>
                        <div className="p-field p-col-12">
                            <label htmlFor="quantidade">Quantidade</label>
                            <InputNumber id="quantidade" value={this.state.estrutura.quantidade} onValueChange={(e) => this.onInputNumberChange(e, 'quantidade')}
                                required className={classNames({ 'p-invalid': this.state.submitted && !this.state.estrutura.quantidade })}
                                mode="decimal" locale="pt-BR" minFractionDigits={2}  />
                            {this.state.submitted && !this.state.estrutura.quantidade && <small className="p-invalid">Quantidade é requerido.</small>}
                        </div>
                        <div className="p-field p-col-12">
                            <label htmlFor="idund">Unidade</label>
                            <Dropdown inputId="idund" optionValue="id" optionLabel="unidade" value={this.state.estrutura.idund} options={this.state.unidades}
                                onChange={(e) => this.onUnidadeChange(e)} placeholder="Unidade"
                                required className={classNames({ 'p-invalid': this.state.submitted && !this.state.estrutura.idund })} />
                            {this.state.submitted && !this.state.estrutura.idund && <small className="p-invalid">A unidade de medida é requerido.</small>}
                        </div>
                        <div className="p-field p-col-12">
                            <label htmlFor="valorunitario">Valor Unitário</label>
                            <InputNumber id="valorunitario" value={this.state.estrutura.valorunitario} onValueChange={(e) => this.onInputNumberChange(e, 'valorunitario')}
                                required className={classNames({ 'p-invalid': this.state.submitted && !this.state.estrutura.valorunitario })}
                                mode="decimal" locale="pt-BR" minFractionDigits={2}  />
                            {this.state.submitted && !this.state.estrutura.valorunitario && <small className="p-invalid">Valor unitário é requerido.</small>}
                        </div>
                        <div className="p-field p-col-12">
                            <label htmlFor="total">Total</label>
                            <InputNumber inputStyle={{ color: 'blue' }} mode="currency" currency="BRL" locale="pt-BR" id="total" value={this.state.estrutura.total} minFractionDigits={2} disabled />
                        </div>
                    </div>
                </Dialog>
                <Dialog visible={this.state.deleteEstruturaDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteEstruturaDialogFooter}
                    onHide={this.hideDeleteEstruturaDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        {this.state.estrutura && <span>Tem certeza que deseja excluir <b>{this.state.estrutura.descricao}</b>?</span>}
                    </div>
                </Dialog>
                <Dialog visible={this.state.garantiaDialog} style={{ width: '600px' }} header="Garantia" modal onHide={this.hideGarantiaDialog}>
                    <Panel header={this.state.estrutura.descricao}>
                        <EstruturaSoloGarantias idEstrutura={this.state.estrutura.id} />
                    </Panel>
                </Dialog>
            </div>
        )
    }
}               