import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import api from '../../service/api';
import { Panel } from '../../components/panel/Panel';
import { Button } from '../../components/button/Button';
import { InputNumber } from '../../components/inputnumber/InputNumber';
import { Toast } from '../../components/toast/Toast';
import { Dialog } from '../../components/dialog/Dialog';
import { Card } from '../../components/card/Card';
import { TabPanel, TabView } from '../../components/tabview/TabView';
import { DimensionamentoPS } from './dimensionamentoParametroServico';
import { DimensionamentoServicoTipo } from './dimensionamentoServicoTipo';
import { InputText } from '../../components/inputtext/InputText';
import { DimensionamentoPgto } from './dimensionamentoPgto';

export class Custos extends Component {

    static defaultProps = {
        idNegocioDimensionamento: 0,
        totalMateriaisAC: 0.00,
        totalMateriaisDC: 0.00,
        totalObracivil: 0.00,
        totalEstrutura: 0.00,
        totalFrete: 0.00,
        totalServico: 0.00,
        qtdModulos: 0,
        potencia: 0,
        idstatus: 1
    }

    static propTypes = {
        idNegocioDimensionamento: PropTypes.number,
        totalMateriaisAC: PropTypes.number,
        totalMateriaisDC: PropTypes.number,
        totalObracivil: PropTypes.number,
        totalEstrutura: PropTypes.number,
        totalFrete: PropTypes.number,
        totalServico: PropTypes.number,
        qtdModulos: PropTypes.number,
        potencia: PropTypes.number,
        idstatus: PropTypes.number,
    }

    emptyPainel = {
        "id": 0,
        "idnegociodimensionamento": 0,
        "rentabilidade": 0.00,
        "comissao": 0.00,
        "nome": '',
        "impostoservico": 0.00,
        "impostomaterial": 0.00,
    };

     
    painelAtual = this.emptyPainel;

    constructor(props) {
        super(props);

        this.state = {
            idnegociodimensionamento: null,
            painel: this.emptyPainel,
            editPainel: false,
            totalServico: 0.00,
            totalMateriaisAC: 0.00,
            totalMateriaisDC: 0.00,
            totalFrete: 0.00,
            totalObracivil: 0.00,
            totalEstrutura: 0.00,
            totalMateriais: 0.00,
            totalMateriaisServicos: 0.00,
            totalDialog: false,
            WPDialog: false,
            servicosDialog: false,
            exibirServicosDialog: false,
            exibirTotalDialog: false,
            exibirWPDialog: false,
            nonUsedKey: Date.now(),
            totalServicoDireto: 0.00,
            totalServicoIndireto: 0.00,
        };

        this.originalRows = {};

        this.formatarCurrency = this.formatarCurrency.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onInputNumberChange = this.onInputNumberChange.bind(this);
        this.calcularCustoTotalProposta = this.calcularCustoTotalProposta.bind(this);
        this.calcularReceitaBruta = this.calcularReceitaBruta.bind(this);
        this.exibirServicos = this.exibirServicos.bind(this);
        this.exibirTotal = this.exibirTotal.bind(this);
        this.exibirWP = this.exibirWP.bind(this);
        this.hideDialogs = this.hideDialogs.bind(this);
        this.calculatTotalServicos = this.calculatTotalServicos.bind(this);
        this.refresh = this.refresh.bind(this);
        this.round_abnt = this.round_abnt.bind(this);
        this.RSWPCR = this.RSWPCR.bind(this);
        this.RSPlaca = this.RSPlaca.bind(this);
        this.editTextoTemplate = this.editTextoTemplate.bind(this);
        this.onInputTextChange = this.onInputTextChange.bind(this);
    }

    async componentDidMount() {
        const painelObj = await api.get(`/painel/dimensionamento/${this.props.idNegocioDimensionamento}`);
        const custoServicos = await api.get(`/dimensionamentoservicos/total/${this.props.idNegocioDimensionamento}`);
        const materiaisDC = await api.get(`/materiaisdc/total/${this.props.idNegocioDimensionamento}`);
        const materiaisAC = await api.get(`/materiaisac/total/${this.props.idNegocioDimensionamento}`);
        const obracivil = await api.get(`/obracivil/total/${this.props.idNegocioDimensionamento}`);
        const frete = await api.get(`/fretes/total/${this.props.idNegocioDimensionamento}`);
        const estrutura = await api.get(`/estruturas/total/${this.props.idNegocioDimensionamento}`);
        const custoMateriais = materiaisDC.data[0].total + materiaisAC.data[0].total + obracivil.data[0].total + frete.data[0].total + estrutura.data[0].total;
        const custoMateriaisServicos = custoServicos.data[0].total + custoMateriais;
        const custoServicoDireto = await api.get(`/dimensionamentoservicos/total/${this.props.idNegocioDimensionamento}/tipo/d`);
        const custoServicoIndireto = await api.get(`/dimensionamentoservicos/total/${this.props.idNegocioDimensionamento}/tipo/i`);

        this.setState({
            totalServico: custoServicos.data[0].total,
            totalMateriaisDC: materiaisDC.data[0].total,
            totalMateriaisAC: materiaisAC.data[0].total,
            totalObracivil: obracivil.data[0].total,
            totalFrete: frete.data[0].total,
            totalEstrutura: estrutura.data[0].total,
            totalMateriais: custoMateriais,
            totalMateriaisServicos: custoMateriaisServicos,
            painel: painelObj.data[0],
            totalServicoDireto: custoServicoDireto.data[0].total,
            totalServicoIndireto: custoServicoIndireto.data[0].total
        });
    }

    exibirServicos() { this.setState({ exibirServicosDialog: true }); }
    exibirTotal() { this.setState({ exibirTotalDialog: true }); }
    exibirWP() { this.setState({ exibirWPDialog: true }); }

    onEdit() {
        this.painelAtual = { ...this.state.painel };
        this.setState({ editPainel: true })
    }

    onSave() {
        if((this.state.painel.nome != null) && (!this.state.painel.nome.trim()) && (this.state.painel.comissao > 0)){
            this.toast.show({ severity: 'warn', summary: 'Atenção', detail: 'Informe o nome de quem irá receber a comissão', life: 3000 });
        }
        else if((this.state.painel.nome != null) && (this.state.painel.nome.trim()) && (this.state.painel.comissao === 0)){
            this.toast.show({ severity: 'warn', summary: 'Atenção', detail: 'Informe o valor da comissão', life: 3000 });
        }
        else{
            api.put(`/painel/${this.state.painel.id}`, this.state.painel)
            .then(res => {
                if (res.status === 204) {
                    this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Dado(s) atualizado(s)', life: 3000 });
                    this.setState({ editPainel: false })
                }
            })
            .catch(error => {
                this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu atualizar os dados', life: 3000 });
                console.log(error.data);
            })
        }
    }

    onCancel() {
        this.setState({
            painel: this.painelAtual,
            editPainel: false
        })

    }

    onInputNumberChange(e, name) {
        const val = e.value || 0;
        let paineledit = { ...this.state.painel };
        paineledit[`${name}`] = val;
        this.setState({ painel: paineledit });
    }

    onInputTextChange(e, name) {
        const val = (e.target && e.target.value) || '';
        const input = e.target;
        const start = input.selectionStart;
        const end = input.selectionEnd;
        let paineledit = { ...this.state.painel };
        paineledit[`${name}`] = val.toUpperCase();
        this.setState({ painel: paineledit },
            () => input.setSelectionRange(start, end));
    }

    editTextoTemplate(name) {

        if (this.state.editPainel) {
            return (
                <React.Fragment>
                    <InputText
                        id='nome'
                        //   className="p-inputtext-sm" 
                        value={this.state.painel[name] || ''}
                        onChange={(e) => this.onInputTextChange(e, name)}
                    //   style={{ width: '100%' }}
                    />
                </React.Fragment>
            )
        } else {
            return this.state.painel[name];
        }
    }

    editNumerTemplate(name, FractionDigits) {
        if (this.state.editPainel) {
            return (
                <React.Fragment>
                    <InputNumber className="p-inputtext-sm" value={this.state.painel[name]} inputStyle={{ width: '100%' }}
                        onValueChange={(e) => this.onInputNumberChange(e, name)} mode="decimal" locale="pt-BR" minFractionDigits={FractionDigits} />
                </React.Fragment>
            )
        } else {
            return this.formatarPercent(this.state.painel[name])
        }
    }

    footerTemplate() {
        if (this.state.editPainel) {
            return (
                <React.Fragment>
                    <Button type="Button" label="Salvar" icon="pi pi-check" className="p-button-success p-mr-2" onClick={this.onSave} />
                    <Button type="Button" label="Cancelar" icon="pi pi-times" className="p-button-warning p-mr-2" onClick={this.onCancel} />
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <Button 
                        type="Button" 
                        label="Alterar" 
                        icon="pi pi-pencil" 
                        className="p-button p-mr-2" 
                        onClick={this.onEdit} 
                        disabled={this.props.idstatus > 2} />
                </React.Fragment>
            )

        }
    }

    formatarCurrency(value) {
        let ret = value || 0.00;
        return ret.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 });
    }

    formatarPercent(value) {
        let ret = (value || 0) / 100;
        return ret.toLocaleString('pt-BR', { style: 'percent', minimumFractionDigits: 2 });
    }

    round_abnt(nValor, nDecimais) {

        var nRetorno = nValor;
        var spl = nValor.toString().split(".");
        var cDecimais = spl[1];
        var nSubsequente = nDecimais;

        if (nDecimais < 1) {
            return parseInt(nRetorno);
        }

        if (!cDecimais) {
            //  || cDecimais.length <= nDecimais) {
            return parseFloat(nRetorno);
        }

        //Se a casa decimal SUBSEQUENTE for DIFERENTE de 5
        if (cDecimais.substr(nSubsequente, 1) >= '5' || cDecimais.substr(nSubsequente, 1) < '5') {
            nRetorno = Math.round((nRetorno + 0.00001) * 100) / 100; //ARREDONDA nRetorno.toFixed(nDecimais); //
        }
        //Se a casa decimal SUBSEQUENTE for IGUAL a 5
        else if (cDecimais.substr(nSubsequente, 1) == '5') {

            //Se a casa decimal que será CONSERVADA, for IMPAR
            if ((cDecimais.substr(nDecimais - 1, 1) % 2) != 0) {
                nRetorno = nRetorno.toFixed(2); //Math.round((nRetorno + 0.00001) * 100) / 100; //ARREDONDA
            }
            //Se a casa decimal que será CONSERVADA, for PAR
            else
                //Se APÓS a casa decimal SUBSEQUENTE, houver ALGUM algarismo MAIOR que ZERO
                if (cDecimais.substr(nSubsequente + 1, 1) > 0) {
                    nRetorno = nRetorno.toFixed(2); //Math.round((nRetorno + 0.00001) * 100) / 100; //ARREDONDA
                }
                //Se APÓS a casa decimal SUBSEQUENTE, não houver NENHUM outro algarismo ou TODOS forem iguais a ZERO
                else {
                    //TRUNCA (Esse é o único momento em que o "arredondamento ABNT" se diferencia do "arredondamento normal")
                    nRetorno = this.Truncate(nValor, nDecimais);
                }
        }
        return parseFloat(nRetorno);
    }

    Truncate(nValor, nDecimais) {

        var nRetorno = nValor;
        var spl = nValor.toString().split(".");
        var cDecimais = spl[1];

        if (nDecimais < 1) {
            return parseInt(nRetorno);
        }

        if (cDecimais.length <= nDecimais) {
            return nRetorno;
        }

        //Pega a parte inteira do número e concatena com a substring sem alterar, pois é PAR e vai manter!
        nRetorno = parseInt(nValor.toString()) + '.' + cDecimais.substr(0, nDecimais);
        nRetorno = parseFloat(nRetorno);

        return nRetorno;
    }

    calcularCustoTotalProposta() {
        let ret = 0.00;
        if (this.state.painel.idnegociodimensionamento !== 0) {
            let rentabilidade = (this.state.painel.rentabilidade / 100);
            let total = this.state.totalMateriaisServicos;
            ret = total > 0 ? this.round_abnt((rentabilidade * total) + total, 2) : 0.00
        }
        return ret;

    }

    calcularReceitaBruta() {
        if (this.state.painel.idnegociodimensionamento !== 0) {
            let rentabilidade = (this.state.painel.rentabilidade / 100);
            let totalMaoDeObra = this.state.totalMateriaisServicos;
            let custoTotalProposta = totalMaoDeObra > 0 ? this.round_abnt((rentabilidade * totalMaoDeObra) + totalMaoDeObra, 2) : 0.00;
            let total = (custoTotalProposta - totalMaoDeObra);
            // let total = (this.calcularCustoTotalProposta() - this.state.totalMateriaisServicos);
            return total;
        } else {
            return 0.00;
        }
    }

    calcularNotaFiscal() {
        if (this.state.painel.idnegociodimensionamento !== 0) {
            let receita = this.calcularReceitaBruta();
            let custoServicos = this.state.totalServico;
            return receita + custoServicos;
        } else {
            return 0.00;
        }
    }

    calcularTotalImpostos() {
        if (this.state.painel.idnegociodimensionamento !== 0) {
            let percImpostoServico = (this.state.painel.impostoservico) / 100; // INICIA COM VALOR ZERO!!!!
            let percImpostoMateriais = (this.state.painel.impostomaterial) / 100; // INICIA COM VALOR ZERO!!!!
            let notaFiscalServicos = this.calcularNotaFiscal();
            let impostoservico = percImpostoServico * notaFiscalServicos;
            let impostomaterial = percImpostoMateriais * this.state.totalMateriaisServicos;

            impostoservico = impostoservico > 0 ? this.round_abnt(impostoservico, 2) : 0;
            impostomaterial = impostomaterial > 0 ? this.round_abnt(impostomaterial, 2) : 0;

            return impostoservico + impostomaterial;
        } else {
            return 0.00;
        }
    }

    calcularComissao() {
        if (this.state.painel.idnegociodimensionamento !== 0) {
            let percComissao = (this.state.painel.comissao) / 100; // INICIA COM VALOR ZERO!!!!
            let custoTotalProposta = this.calcularCustoTotalProposta();
            let resultado = percComissao * custoTotalProposta > 0 ? this.round_abnt(percComissao * custoTotalProposta, 2) : 0;
            return resultado;
        } else {
            return 0.00;
        }
    }

    calcularReceitaLiquida() {
        if (this.state.painel.idnegociodimensionamento !== 0) {
            let receitaBruta = this.calcularReceitaBruta();
            let totalImpostos = this.calcularTotalImpostos();
            let comissao = this.calcularComissao();

            return (receitaBruta - totalImpostos - comissao);
        } else {
            return 0.00;
        }
    }

    calcularCustoWattPico() {
        let ret = 0.00;
        if (this.state.painel.idnegociodimensionamento !== 0) {
            let custoTotalProposta = this.calcularCustoTotalProposta();
            let potenciaPicoDoSistemaFotovoltaico = this.props.potencia * this.props.qtdModulos;
            let custoWp = custoTotalProposta / (potenciaPicoDoSistemaFotovoltaico);
            ret = custoWp > 0 ? this.round_abnt(custoWp, 4) : 0;
        }
        return ret;
    }

    percentualServico() {
        //let resultado = 0;
        //let servicoPercentual = (this.state.totalServico / this.calcularCustoTotalProposta()) * 100;
    }

    RSWPCR() {
        let resultado = 0;
        let custoTotalProposta = this.calcularCustoTotalProposta();
        let custoWattPico = this.calcularCustoWattPico();
        let servico = this.state.totalServico / custoTotalProposta > 0 ? this.round_abnt(custoWattPico * this.state.totalServico / custoTotalProposta, 4) : 0;
        let materiaisAC = this.state.totalMateriaisAC / custoTotalProposta > 0 ? this.round_abnt(custoWattPico * this.state.totalMateriaisAC / custoTotalProposta, 4) : 0;
        let impostos = this.calcularTotalImpostos() / custoTotalProposta > 0 ? this.round_abnt(custoWattPico * this.calcularTotalImpostos() / custoTotalProposta, 4) : 0;
        let comissao = this.calcularComissao() / custoTotalProposta > 0 ? this.round_abnt(custoWattPico * this.calcularComissao() / custoTotalProposta, 4) : 0;
        let receitaLiquida = this.calcularReceitaLiquida() / custoTotalProposta > 0 ? this.round_abnt(custoWattPico * this.calcularReceitaLiquida() / custoTotalProposta, 4) : 0;
        resultado = servico + materiaisAC + impostos + comissao + receitaLiquida;
        return resultado;
    }

    RSPlaca() {
        let servico = this.state.totalServico;
        let modulos = this.props.qtdModulos;
        return servico / modulos;
    }

    hideDialogs() {
        this.setState({
            exibirServicosDialog: false,
            exibirTotalDialog: false,
            exibirWPDialog: false
        });
    }

    getBadge(texto) {
        return (<div style={{ height: '50px', color: 'white', backgroundColor: '#0078d4' }}><b>{texto}</b></div>)
    }

    percentualReceitaLiquida() {
        let custoTotal = this.calcularCustoTotalProposta();
        let receitaLiquida = this.calcularReceitaLiquida();
        let valor = custoTotal > 0 ? receitaLiquida / custoTotal : 0;
        return valor.toLocaleString('pt-BR', { style: 'percent', minimumFractionDigits: 2 });
    }

    getDimensionamentoParametroServico() {
        return (
            <React.Fragment>
                <DimensionamentoPS
                    idNegocioDimensionamento={this.props.idNegocioDimensionamento}
                    idstatus={this.props.idstatus}
                    refresh={this.refresh}
                    qtdModulos={this.props.qtdModulos}
                    atualizarTotal={this.calculatTotalServicos} />
            </React.Fragment>
        )
    }

    async calculatTotalServicos() {
        const custoServicoDireto = await api.get(`/dimensionamentoservicos/total/${this.props.idNegocioDimensionamento}/tipo/d`);
        const custoServicoIndireto = await api.get(`/dimensionamentoservicos/total/${this.props.idNegocioDimensionamento}/tipo/i`);
        const custoServicoTotal = await api.get(`/dimensionamentoservicos/total/${this.props.idNegocioDimensionamento}`);

        this.setState({
            totalServico: custoServicoTotal.data[0].total,
            totalServicoDireto: custoServicoDireto.data[0].total,
            totalServicoIndireto: custoServicoIndireto.data[0].total,
            totalMateriaisServicos: custoServicoTotal.data[0].total + this.state.totalMateriais,
            nonUsedKey: Date.now()
        })
    }

    async refresh() {
        const custoServicoDireto = await api.get(`/dimensionamentoservicos/total/${this.props.idNegocioDimensionamento}/tipo/d`);
        const custoServicoIndireto = await api.get(`/dimensionamentoservicos/total/${this.props.idNegocioDimensionamento}/tipo/i`);
        const custoServicoTotal = await api.get(`/dimensionamentoservicos/total/${this.props.idNegocioDimensionamento}`);

        this.setState({
            totalServico: custoServicoTotal.data[0].total,
            totalServicoDireto: custoServicoDireto.data[0].total,
            totalServicoIndireto: custoServicoIndireto.data[0].total,
            totalMateriaisServicos: custoServicoTotal.data[0].total + this.state.totalMateriais,
            nonUsedKey: Date.now()
        })
    }

    render() {
        const custoTotalProposta = this.calcularCustoTotalProposta();
        const custoWattPico = this.calcularCustoWattPico();

        const titulo = "";
        const headerAccordion = (
            <div className="table-header">
                <span>{titulo}</span>
            </div>

        );

        return (
            <div>
                <Toast ref={(el) => this.toast = el} />

                <Panel>
                    <TabView>
                        <TabPanel header="Parâmetros">
                            {this.getDimensionamentoParametroServico()}
                        </TabPanel>

                        <TabPanel header={
                            this.titulo = "Serviços | " + this.formatarCurrency(this.state.totalServico)}>
                            <TabView>
                                <TabPanel header={
                                    this.titulo = "Diretos | " + this.formatarCurrency(this.state.totalServicoDireto)}>
                                    <DimensionamentoServicoTipo 
                                        idNegocioDimensionamento={this.props.idNegocioDimensionamento} 
                                        idstatus={this.props.idstatus}
                                        nonUsedKey={this.state.nonUsedKey}
                                        atualizarTotal={this.calculatTotalServicos} 
                                        tipo="d" />
                                </TabPanel>
                                <TabPanel header={headerAccordion,
                                    this.titulo = "Indiretos | " + this.formatarCurrency(this.state.totalServicoIndireto)}>
                                    <DimensionamentoServicoTipo 
                                        idNegocioDimensionamento={this.props.idNegocioDimensionamento} 
                                        idstatus={this.props.idstatus}
                                        nonUsedKey={this.state.nonUsedKey}
                                        atualizarTotal={this.calculatTotalServicos} 
                                        tipo="i" />
                                </TabPanel>
                            </TabView>
                        </TabPanel>
                    </TabView>
                </Panel>

                <br />

                <div className="p-formgrid p-grid">
                    <div className="p-grid" style={{ width: '100%' }}>
                        <div className="p-col" style={{ width: '25%' }}>
                            <Card
                                style={{ height: '100%', backgroundColor: '#0078d4', color: 'white' }}
                                className="p-text-center"
                                header={this.getBadge('')}
                                title={this.formatarCurrency(custoTotalProposta)}>
                                <Button disabled label="Custo Total" onClick={this.exibirTotal} style={{ color: 'white' }} className="p-button-link"></Button>
                            </Card>
                        </div>
                        <div className="p-col" style={{ width: '25%' }}>
                            <Card
                                style={{ height: '100%', backgroundColor: '#0078d4', color: 'white' }}
                                className="p-text-center"
                                header={this.getBadge('')}
                                title={this.formatarCurrency(custoWattPico)}>
                                <Button label="R$/Wp" onClick={this.exibirWP} style={{ color: 'white' }} className="p-button-link"></Button>
                            </Card>
                        </div>
                        <div className="p-col" style={{ width: '25%' }}>
                            <Card
                                style={{ height: '100%', backgroundColor: '#0078d4', color: 'white' }}
                                className="p-text-center"
                                header={this.getBadge('')}
                                title={this.formatarCurrency(this.state.totalMateriaisServicos)}>
                                <Button label="Material e Mão de Obra" onClick={this.exibirServicos} style={{ color: 'white' }} className="p-button-link"></Button>
                            </Card>
                        </div>
                    </div>
                </div>
                <br />
                <div className="p-formgrid p-grid">
                    <div className="p-fluid p-formgrid p-grid" style={{ width: '100%' }}>
                        <div className="p-field p-col-12 p-md-6">
                            <Panel header="Painel" >
                                <table className="tabela-simples" >
                                    <tbody>
                                        <tr>
                                            <td><b>Rentabilidade</b></td>
                                            <td>{this.editNumerTemplate("rentabilidade", 2)}</td>
                                        </tr>
                                        <tr>
                                            <td><b>Comissão</b></td>
                                            <td>{this.editNumerTemplate("comissao", 2)}</td>
                                        </tr>
                                        <tr>
                                            <td><b>Nome</b></td>
                                            <td>{this.editTextoTemplate("nome")}</td>
                                        </tr>
                                        <tr>
                                            <td><b>Imposto de Serviços</b></td>
                                            <td>{this.editNumerTemplate("impostoservico", 2)}</td>
                                        </tr>
                                        <tr>
                                            <td><b>Imposto de Materiais</b></td>
                                            <td>{this.editNumerTemplate("impostomaterial", 2)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="p-d-inline-flex p-mt-3">
                                    {this.footerTemplate()}
                                </div>
                            </Panel>
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <Panel header="Geral" >
                                <table className="tabela-simples" >
                                    <tbody>
                                        <tr>
                                            <td colSpan="2"><b>Receita</b></td>
                                            <td align="right">
                                                {this.formatarCurrency(this.calcularReceitaBruta())}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2"><b>Total de Impostos</b></td>
                                            <td align="right">
                                                {this.formatarCurrency(this.calcularTotalImpostos())}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2"><b>Comissão</b></td>
                                            <td align="right">
                                                {this.formatarCurrency(this.calcularComissao())}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2"><b>Receita Bruta - NF</b></td>
                                            <td align="right">
                                                {this.formatarCurrency(this.calcularNotaFiscal())}
                                            </td>
                                        </tr>
                                        <tr style={{ backgroundColor: '#0078d4', color: 'white' }}>
                                            <td><b>Margem de Contribuição</b></td>
                                            <td align="right"><b>{this.percentualReceitaLiquida()}</b></td>
                                            <td align="right">
                                                <b>{this.formatarCurrency(this.calcularReceitaLiquida())}</b>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Panel>
                        </div>
                    </div>
                </div>
                <Dialog visible={this.state.exibirTotalDialog} modal style={{ width: '550px' }}
                    className="p-fluid" onHide={this.hideDialogs}>
                    <div className="p-field p-col-12 p-md-12">
                        <Panel header="Valor da Proposta" >
                            <table className="table table-bordered" >
                                <tbody>
                                    <tr>
                                        <td><b>Receita Bruta</b></td>
                                        <td>
                                            {this.formatarCurrency(this.calcularReceitaBruta())}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b>Total de Impostos</b></td>
                                        <td>
                                            {this.formatarCurrency(this.calcularTotalImpostos())}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b>Comissão</b></td>
                                        <td>
                                            {this.formatarCurrency(this.calcularComissao())}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b>Valor da Nota Fiscal</b></td>
                                        <td>
                                            {this.formatarCurrency(this.calcularNotaFiscal())}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b>Receita Líquida</b></td>
                                        <td>
                                            {this.formatarCurrency(this.calcularReceitaLiquida())}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Panel>
                    </div>
                </Dialog>

                <Dialog visible={this.state.exibirServicosDialog} modal style={{ width: '550px' }}
                    className="p-fluid" onHide={this.hideDialogs}>
                    <div className="p-field p-col-12 p-md-12">
                        <Panel header="Material e Mão de Obra - Detalhamento" >
                            <table className="tabela-simples" >
                                <tbody>
                                    <tr>
                                        <td><b>Descrição</b></td>
                                        <td><b>Valor</b></td>
                                    </tr>
                                    <tr>
                                        <td><b>Materiais DC</b></td>
                                        <td>{this.formatarCurrency(this.state.totalMateriaisDC)}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Materiais AC</b></td>
                                        <td>{this.formatarCurrency(this.state.totalMateriaisAC)}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Obras Adicionais</b></td>
                                        <td>{this.formatarCurrency(this.state.totalObracivil)}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Estruturas de Fixação</b></td>
                                        <td>{this.formatarCurrency(this.state.totalEstrutura)}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Serviços</b></td>
                                        <td>{this.formatarCurrency(this.state.totalServico)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Panel>
                    </div>
                </Dialog>

                <Dialog visible={this.state.exibirWPDialog} modal style={{ width: '550px' }}
                    className="p-fluid" onHide={this.hideDialogs}>
                    <div className="p-field p-col-12 p-md-12">
                        {this.percentualServico()}
                        <Panel header="Percentuais Wp">
                            <table className="tabela-simples" >
                                <tbody>
                                    <tr>
                                        <td><b>Descrição</b></td>
                                        <td><b>R$/Wp</b></td>
                                        <td><b>%</b></td>
                                    </tr>
                                    <tr>
                                        <td><b>Serviços</b></td>
                                        <td>{this.formatarCurrency(this.state.totalServico / custoTotalProposta > 0 ? this.round_abnt(custoWattPico * this.state.totalServico / custoTotalProposta, 4) : 0)}</td>
                                        {/* <td>{this.formatarCurrency(custoWattPico * (this.state.totalServico / custoTotalProposta))}</td> */}
                                        <td>{this.formatarPercent((this.state.totalServico / custoTotalProposta) * 100)}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Materiais AC</b></td>
                                        <td>{this.formatarCurrency(this.state.totalMateriaisAC / custoTotalProposta > 0 ? this.round_abnt(custoWattPico * this.state.totalMateriaisAC / custoTotalProposta, 4) : 0)}</td>
                                        {/* <td>{this.formatarCurrency(custoWattPico * (this.state.totalMateriaisAC / custoTotalProposta))}</td> */}
                                        <td>{this.formatarPercent((this.state.totalMateriaisAC / custoTotalProposta) * 100)}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Materiais DC</b></td>
                                        {/* <td>{this.formatarCurrency(this.state.totalMateriaisDC / custoTotalProposta > 0 ? this.round_abnt(custoWattPico * this.state.totalMateriaisDC / custoTotalProposta, 4) : 0)}</td> */}
                                        {/* <td>{this.formatarCurrency(custoWattPico * (this.state.totalMateriaisDC / custoTotalProposta))}</td> */}
                                        <td>{this.formatarCurrency((this.state.totalMateriais - this.state.totalMateriaisAC) / custoTotalProposta > 0 ? this.round_abnt(custoWattPico * (this.state.totalMateriais - this.state.totalMateriaisAC) / custoTotalProposta, 4) : 0)}</td>
                                        <td>{this.formatarPercent(((this.state.totalMateriais - this.state.totalMateriaisAC) / custoTotalProposta) * 100)}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Impostos</b></td>
                                        <td>{this.formatarCurrency(this.calcularTotalImpostos() / custoTotalProposta > 0 ? this.round_abnt(custoWattPico * this.calcularTotalImpostos() / custoTotalProposta, 4) : 0)}</td>
                                        {/* <td>{this.formatarCurrency(custoWattPico * (this.calcularTotalImpostos() / custoTotalProposta))}</td> */}
                                        <td>{this.formatarPercent((this.calcularTotalImpostos() / custoTotalProposta) * 100)}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Comissão</b></td>
                                        <td>{this.formatarCurrency(this.calcularComissao() / custoTotalProposta > 0 ? this.round_abnt(custoWattPico * this.calcularComissao() / custoTotalProposta, 4) : 0)}</td>
                                        {/* <td>{this.formatarCurrency(custoWattPico * (this.calcularComissao() / custoTotalProposta))}</td> */}
                                        <td>{this.formatarPercent((this.calcularComissao() / custoTotalProposta) * 100)}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Lucro líquido</b></td>
                                        <td>{this.formatarCurrency(this.calcularReceitaLiquida() / custoTotalProposta > 0 ? this.round_abnt(custoWattPico * this.calcularReceitaLiquida() / custoTotalProposta, 4) : 0)}</td>
                                        {/* <td>{this.formatarCurrency(custoWattPico * (this.calcularReceitaLiquida() / custoTotalProposta))}</td> */}
                                        <td>{this.formatarPercent((this.calcularReceitaLiquida() / custoTotalProposta) * 100)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Panel>
                        <div className="p-fluid" >
                            <table className="tabela-simples" >
                                <tbody>
                                    <tr style={{ backgroundColor: '#0078d4', color: 'white' }}>
                                        <td><b>R$/Wp/CR</b></td>
                                        <td>{this.formatarCurrency(this.RSWPCR())}</td>
                                    </tr>
                                    <tr style={{ backgroundColor: '#0078d4', color: 'white' }}>
                                        <td><b>R$/Placa</b></td>
                                        <td>{this.formatarCurrency(this.RSPlaca())}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Dialog>
            </div>
        );
    }
}
