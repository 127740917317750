import { Skeleton } from 'primereact/skeleton';
import React, { useState, useEffect } from 'react';
import api from '../../service/api';

export const CardsCadastros = (props) => {

    const emptyCadastro = {
        "negocioscadastrados": 0,
        "negociosconfirmados": 0,
        "conversao": 0.0
    };

    const [cadastro, setCadastro] = useState(emptyCadastro);
    const [loading, setLoading] = useState(true);

    console.log('dataIni: ' + props.Ini);
    console.log('dataFim: ' + props.Fim);

    useEffect(() => {
        setLoading(true);
        api.get(`/dashboard/cadastros/${props.Ini}/${props.Fim}`)
            .then(res => {
                setCadastro(res.data[0]);
                setLoading(false);
                console.log(res.data[0]);
            });
    }, [props.Ini, props.Fim]);

    return (
        <>
            {!loading && (
                <>
                    <div className="p-col-12 p-lg-4">
                        <div className="card summary">
                            <span className="title">Negócios</span>
                            <span className="detail">Quantidade de negócios cadastrados</span>
                            <span className="count revenue">{cadastro.negocioscadastrados}</span>
                            {/* revenue = azul */}
                        </div>
                    </div>
                    <div className="p-col-12 p-lg-4">
                        <div className="card summary">
                            <span className="title">Aceitos</span>
                            <span className="detail">Quantidade de negócios confirmados</span>
                            <span className="count visitors">{cadastro.negociosconfirmados}</span>
                            {/* visitors = verde */}
                        </div>
                    </div>
                    <div className="p-col-12 p-lg-4">
                        <div className="card summary">
                            <span className="title">Conversão</span>
                            <span className="detail">Percentual de conversão</span>
                            <span className="count visitors">{cadastro.conversao}%</span>
                            {/* purchases = amarelo */}
                        </div>
                    </div>
                </>
            )}
            {loading && (
                <>
                    <div className="p-col-12 p-lg-4">
                        <Skeleton height="5rem" />
                    </div>
                    <div className="p-col-12 p-lg-4">
                        <Skeleton height="5rem" />
                    </div>
                    <div className="p-col-12 p-lg-4">
                        <Skeleton height="5rem" />
                    </div>
                </>
            )}
        </>
    );
}