import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';
import api from '../../service/api';
import { Skeleton } from 'primereact/skeleton';

export const GraficoFornecedores = (props) => {

    const lightOptions = {
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        }
    };

    const [fornecedores, setFornecedores] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        api.get(`/dashboard/fornecedores/${props.Ini}/${props.Fim}`)
          .then(res => {
              setFornecedores(res.data);
              setLoading(false);
        });
    }, [props.Ini, props.Fim]);    

    return(
        <>
            {!loading && (
                <Chart type="doughnut" height='400' data={fornecedores} options={lightOptions} style={{ position: 'relative', width: '100%' }} />
            )}
            {loading && (
                <Skeleton  height='23rem' />
            )}
            
        </>
    );
}