import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { DataTable } from '../../components/datatable/DataTable';
import { Column } from '../../components/column/Column';
import { Toast } from '../../components/toast/Toast';
import { Button } from '../../components/button/Button';
import { Rating } from '../../components/rating/Rating';
import { Toolbar } from '../../components/toolbar/Toolbar';
import { Dialog } from '../../components/dialog/Dialog';
import { InputText } from '../../components/inputtext/InputText';
import { Dropdown } from '../../components/dropdown/Dropdown';
import api from '../../service/api';
import { InputMask } from '../../components/inputmask/InputMask';
import CpfCnpjInput from 'react-cpf-cnpj-input';
import { format, isThisHour, parseISO } from "date-fns";


export class Clientes extends Component {

    emptyCliente = {
        "id": null,
        "nome": '',
        "cpfcnpj": '',
        "logradouro": '',
        "numero": '',
        "idcidade": null,
        "idestado": null,
        "bairro": '',
        "cep": '',
        "email": '',
        "site": '',
        "telefonefixo": '',
        "telefonecelular": '',
        "complemento": '',
        "tipo": '',
        "fantasiaapelido": '',
        "indicacao": '',
    };

    constructor(props) {
        super(props);
        this.CpfCnpjInputRef = React.createRef();

        this.state = {
            Clientes: [],
            ClienteDialog: false,
            deleteClienteDialog: false,
            deleteClientesDialog: false,
            Cliente: this.emptyCliente,
            selectedClientes: null,
            submitted: false,
            globalFilter: null,
            estados: [],
            cidades: [],
            rows: 10,
            first: 1,
        };

        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.rightToolbarTemplate = this.rightToolbarTemplate.bind(this);
        this.imageBodyTemplate = this.imageBodyTemplate.bind(this);
        this.priceBodyTemplate = this.priceBodyTemplate.bind(this);
        this.ratingBodyTemplate = this.ratingBodyTemplate.bind(this);
        this.statusBodyTemplate = this.statusBodyTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);

        this.openNew = this.openNew.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.saveCliente = this.saveCliente.bind(this);
        this.editCliente = this.editCliente.bind(this);
        this.confirmDeleteCliente = this.confirmDeleteCliente.bind(this);
        this.deleteCliente = this.deleteCliente.bind(this);
        this.exportCSV = this.exportCSV.bind(this);
        this.confirmDeleteSelected = this.confirmDeleteSelected.bind(this);
        this.deleteSelectedClientes = this.deleteSelectedClientes.bind(this);
        this.onCidadeChange = this.onCidadeChange.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onInputNumberChange = this.onInputNumberChange.bind(this);
        this.hideDeleteClienteDialog = this.hideDeleteClienteDialog.bind(this);
        this.hideDeleteClientesDialog = this.hideDeleteClientesDialog.bind(this);
        this.consultaCEP = this.consultaCEP.bind(this);
        this.dateTemplate = this.dateTemplate.bind(this);
        this.onInputMaskChange = this.onInputMaskChange.bind(this);
        this.exportExcel = this.exportExcel.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
    }

    async componentDidMount() {
        const response = await api.get('/clientes');
        this.setState({ Clientes: response.data });
        //console.table(response.data);
        await api.get('/estados')
            .then(res => {
                this.setState({ estados: res.data });
            })
    }

    formatCurrency(value) {
        return value.toLocaleString('pt-BR', { style: 'currency', currency: 'USD' });
    }

    openNew() {
        this.setState({
            Cliente: this.emptyCliente,
            submitted: false,
            ClienteDialog: true
        })
    }

    paginaNegociosCliente = (id) => {
        //console.log(this.state.Cliente.id)
        this.props.history.push(`/clientes/${id}`);
    }

    hideDialog() {
        this.setState({
            submitted: false,
            ClienteDialog: false
        });
    }

    hideDeleteClienteDialog() {
        this.setState({ deleteClienteDialog: false });
    }

    hideDeleteClientesDialog() {
        this.setState({ deleteClientesDialog: false });
    }

    async saveCliente() {
        let state = { submitted: true };

        if ((this.state.Cliente.nome.trim()) && (this.state.Cliente.cpfcnpj.trim())
            && (this.state.Cliente.idestado) && (this.state.Cliente.idcidade)) {
            let Clientes = [...this.state.Clientes];
            let Cliente = { ...this.state.Cliente };

            Cliente.cpfcnpj = this.CpfCnpjInputRef.current.getValue();

            if (Cliente.cpfcnpj.length === 14) {
                Cliente.tipo = 'F';
            }
            else if (Cliente.cpfcnpj.length === 18) {
                Cliente.tipo = 'J';
            }

            if (this.state.Cliente.id) {
                const index = this.findIndexById(this.state.Cliente.id);

                const res = await api.put(`/clientes/${Cliente.id}`, Cliente);
                if (res.status === 204) {
                    Clientes[index] = Cliente;
                    this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Cliente atualizado', life: 3000 });

                }
            }
            else {
                Cliente.id = 0;
                const res = await api.post('/clientes', Cliente);
                //console.table(Cliente)
                if (res.status === 201) {
                    Cliente.id = res.headers.id;
                    Clientes.push(Cliente);

                    this.setState({
                        ...state,
                        Clientes,
                        ClienteDialog: false,
                        //Cliente: this.emptyCliente
                    }, this.paginaNegociosCliente(Cliente.id));

                    //this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Cliente inserido', life: 3000 });
                }
            }


        }
        else {
            state = {
                ...state
            };
        }

        this.setState(state);
    }


    editCliente(Cliente) {
        this.setState({
            Cliente: { ...Cliente },
            ClienteDialog: true
        });

        api.get(`/cidades/uf/${Cliente.idestado}`)
            .then(res => {
                this.setState({ cidades: res.data });
            });
    }

    confirmDeleteCliente(Cliente) {
        this.setState({
            Cliente,
            deleteClienteDialog: true
        });
    }

    deleteCliente() {
        api.delete(`/clientes/${this.state.Cliente.id}`)
            .then(res => {
                if (res.status === 204) {
                    let Clientes = this.state.Clientes.filter(val => val.id !== this.state.Cliente.id);
                    this.setState({
                        Clientes,
                        deleteClienteDialog: false,
                        Cliente: this.emptyCliente
                    });
                    this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Cliente excluído', life: 3000 });
                }
                else {
                    console.log(res);
                }
            })
            .catch(error => {
                this.setState({ deleteClienteDialog: false });
                this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu excluir o cliente', life: 3000 });
                //console.log(error.response.data.description);
            })
    }

    findIndexById(id) {
        let index = -1;
        for (let i = 0; i < this.state.Clientes.length; i++) {
            if (this.state.Clientes[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    createId() {
        let id = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return id;
    }

    exportCSV() {
        this.dt.exportCSV();
    }

    saveAsExcelFile(buffer, fileName) {
        import('file-saver').then(FileSaver => {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        });
    }


    exportExcel() {
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(this.state.Clientes);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, 'clientes');
        });
    }

    confirmDeleteSelected() {
        this.setState({ deleteClientesDialog: true });
    }

    deleteSelectedClientes() {
        let Clientes = this.state.Clientes.filter(val => !this.state.selectedClientes.includes(val));
        this.setState({
            Clientes,
            deleteClientesDialog: false,
            selectedClientes: null
        });
        this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Cliente excluído', life: 3000 });
    }

    onCidadeChange(e) {
        const val = (e.target && e.target.value) || '';
        let Cliente = { ...this.state.Cliente };
        Cliente.idcidade = val;
        this.setState({ Cliente });
    }

    onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        const input = e.target;
        const start = input.selectionStart;
        const end = input.selectionEnd;
        let Cliente = { ...this.state.Cliente };
        Cliente[`${name}`] = val.toUpperCase();

        this.setState({ Cliente },
            () => input.setSelectionRange(start, end));
    }

    onInputMaskChange(e, name) {
        const val = (e.target && e.target.value) || '';
        let Cliente = { ...this.state.Cliente };
        Cliente[`${name}`] = val;

        this.setState({ Cliente });
    }

    onEstadoChange(e) {
        let Cliente = { ...this.state.Cliente };
        Cliente.idestado = e.value;
        this.setState({ Cliente });
        api.get(`/cidades/uf/${Cliente.idestado}`)
            .then(res => {
                this.setState({ cidades: res.data });
            });
    }

    onInputNumberChange(e, name) {
        const val = e.value || 0;
        let Cliente = { ...this.state.Cliente };
        Cliente[`${name}`] = val;

        this.setState({ Cliente });


    }

    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="Novo" icon="pi pi-plus" className="p-button p-mr-2" onClick={this.openNew} />
                {/* <Button label="Novo" icon="pi pi-plus" className="p-button p-mr-2" onClick={this.openNew} /> */}
                {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={this.confirmDeleteSelected} disabled={!this.state.selectedClientes || !this.state.selectedClientes.length} /> */}
            </React.Fragment>
        )
    }

    rightToolbarTemplate() {
        return (
            <React.Fragment>
                {/* <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="p-mr-2 p-d-inline-block" /> */}
                <Button label="Exportar" icon="pi pi-upload" className="p-button-help" onClick={this.exportExcel} />
            </React.Fragment>
        )
    }

    imageBodyTemplate(rowData) {
        return <img src={`showcase/demo/images/Cliente/${rowData.image}`} onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={rowData.image} className="Cliente-image" />
    }

    priceBodyTemplate(rowData) {
        return this.formatCurrency(rowData.price);
    }

    ratingBodyTemplate(rowData) {
        return <Rating value={rowData.rating} readonly cancel={false} />;
    }

    statusBodyTemplate(rowData) {
        return <span className={`Cliente-badge status-${rowData.inventoryStatus.toLowerCase()}`}>{rowData.inventoryStatus}</span>;
    }

    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Link to={`/clientes/${rowData['id']}`}><Button icon="pi pi-pencil" className="p-button-rounded p-mr-2" /></Link>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => this.confirmDeleteCliente(rowData)} />
            </React.Fragment>
        );
    }

    consultaCEP() {
        let cep = this.state.Cliente.cep;
        if (cep.trim()) {
            let cepAux = cep.replace('.', '');
            cepAux = cepAux.replace('-', '');
            if (cepAux.length === 8) {
                api.get(`/consultacep/${cepAux}`)
                    .then(res => {
                        if (res.status === 200) {
                            if (typeof res.data.erro === "undefined") {
                                let Cliente = { ...this.state.Cliente };
                                let ibge = res.data.ibge;
                                api.get(`/cidades/ibge/${ibge}`)
                                    .then(cidade => {
                                        Cliente.logradouro = res.data.logradouro.toUpperCase();
                                        Cliente.bairro = res.data.bairro.toUpperCase();
                                        Cliente.cidades = res.data.ibge;
                                        Cliente.idestado = cidade.data[0].idestado;
                                        api.get(`/cidades/uf/${cidade.data[0].idestado}`)
                                            .then(_res => {
                                                this.setState({ cidades: _res.data });
                                                Cliente.idcidade = cidade.data[0].idcidade;
                                                this.setState({ Cliente: Cliente })                                                
                                            });
                                    })
                            }
                            else {
                                this.toast.show({ severity: 'error', summary: 'Erro', detail: `O CEP ${cep} não foi localizado`, life: 3000 });
                            }
                        }
                    })
                    .catch(error => {
                        this.toast.show({ severity: 'error', summary: 'Erro', detail: `Ocorreu algum problema ao tentar consultar o CEP ${cep}`, life: 3000 });
                        console.log(error.data);
                    })
            }
            else {
                alert(`CEP ${cep} inválido`)
            }
        }
    }

    onTipoChange(e) {
        let Cliente = { ...this.state.Cliente };
        Cliente.tipo = e.value;
        this.setState({ Cliente: Cliente });
    }

    dateTemplate(rowData) {
        const DataCadastro = parseISO(rowData['datacadastro']);
        const DataAtualizacao = parseISO(rowData['dataatualizacao']);
        return format(DataCadastro, "dd/MM/yyyy HH:mm") + ' ' + format(DataAtualizacao, "dd/MM/yyyy HH:mm");
    }

    onPageChange = (event) => {
        console.log(event);
        this.setState({
            rows : event.rows,
            first: event.first
        });
   };    

    render() {
        const header = (
            <div className="table-header">
                <h5 className="p-m-0">Listagem</h5>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Localizar..." />
                </span>
            </div>
        );
        const ClienteDialogFooter = (
            <React.Fragment>
                <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                {/* <Link to={`/negocios/${rowData['id']}`}  > */}
                <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={this.saveCliente} />
                {/* </Link> */}
            </React.Fragment>
        );
        const deleteClienteDialogFooter = (
            <React.Fragment>
                <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteClienteDialog} />
                <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={this.deleteCliente} />
            </React.Fragment>
        );
        const deleteClientesDialogFooter = (
            <React.Fragment>
                <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteClientesDialog} />
                <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={this.deleteSelectedClientes} />
            </React.Fragment>
        );

        return (
            <div>
                <div className="content-section introduction">
                    <h5>Clientes</h5>
                </div>

                <div className="p-grid crud-demo">

                    <Toast ref={(el) => this.toast = el} />

                    <div className="card">
                        <Toolbar className="p-mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>

                        <DataTable
                            className="p-datatable-gridlines p-datatable-sm"
                            ref={(el) => this.dt = el}
                            value={this.state.Clientes}
                            selection={this.state.selectedClientes}
                            onSelectionChange={(e) => this.setState({ selectedClientes: e.value })}
                            dataKey="id"
                            paginator
                            rows={10}
                            rowsPerPageOptions={[5, 10, 25]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Exibindo {first} até {last} de {totalRecords} Clientes"
                            globalFilter={this.state.globalFilter}
                            header={header}
                            //onPage={event => this.onPageChange(event)}
                            //first={this.state.first}
                            >
                            <Column field="nome" header="Nome/Razão Social" sortable></Column>
                            <Column field="fantasiaapelido" header="Apelido/Nome Fantasia" sortable></Column>
                            <Column field="cpfcnpj" header="Cpf Cnpj" style={{ width: '14%' }} sortable></Column>
                            <Column field="telefonecelular" header="Telefone" style={{ width: '13%' }} sortable></Column>
                            {/* <Column field="email" header="E-mail" sortable></Column> */}
                            <Column field="dataatualizacao" header="Inclusão / Atualização" style={{ width: '125px' }} sortable body={(rowData) => this.dateTemplate(rowData)}></Column>
                            <Column body={this.actionBodyTemplate} style={{ width: '80px' }}></Column>
                        </DataTable>
                    </div>

                    <Dialog visible={this.state.ClienteDialog} style={{ width: '600px' }} header="Cliente" modal className="p-fluid" footer={ClienteDialogFooter} onHide={this.hideDialog}>

                        <div className="p-fluid p-formgrid p-grid">

                            <div className="p-field p-col-12">
                                <label htmlFor="nome">Nome/Razão Social</label>
                                <InputText id="nome" value={this.state.Cliente.nome} onChange={(e) => this.onInputChange(e, 'nome')}
                                    required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.Cliente.nome })}
                                />
                                {this.state.submitted && !this.state.Cliente.nome && <small className="p-invalid">Nome é requerido.</small>}
                            </div>
                        </div>

                        {/* <div>
                            <div className="p-mb-2">
                                Tipo
                            </div>
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field-radiobutton p-col-3">
                                    <RadioButton inputId="pf" name="tipo" value="F" onChange={(e) => this.onTipoChange(e)} checked={this.state.Cliente.tipo === 'F'} />
                                    <label htmlFor="pf">Pessoa Física</label>
                                </div>
                                <div className="p-field-radiobutton p-col-3">
                                    <RadioButton inputId="pj" name="tipo" value="J" onChange={(e) => this.onTipoChange(e)} checked={this.state.Cliente.tipo === 'J'} />
                                    <label htmlFor="pj">Pessoa Jurídica</label>
                                </div>
                            </div>
                        </div> */}
                        <div className="p-mb-2">
                            CPF/CNPJ
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12">
                                {/* <label htmlFor="cpfcnpj">CPF / CNPJ</label> */}
                                <CpfCnpjInput id="cpfcnpj" value={this.state.Cliente.cpfcnpj} onChange={(e) => this.onInputMaskChange(e, 'cpfcnpj')}
                                    required className={classNames({ 'p-invalid': this.state.submitted && !this.state.Cliente.cpfcnpj })}
                                    style={{ width: '100%', height: '38px' }}
                                    ref={this.CpfCnpjInputRef} />
                                {this.state.submitted && !this.state.Cliente.cpfcnpj && <small className="p-invalid">CPF / CNPJ é requerido.</small>}
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12">
                                <label htmlFor="fantasiaapelido">Apelido/Nome Fantasia</label>
                                <InputText id="fantasiaapelido" value={this.state.Cliente.fantasiaapelido} onChange={(e) => this.onInputChange(e, 'fantasiaapelido')}
                                />
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-10">
                                <label htmlFor="logradouro">Logradouro</label>
                                <InputText id="logradouro" value={this.state.Cliente.logradouro} onChange={(e) => this.onInputChange(e, 'logradouro')}
                                />
                            </div>

                            <div className="p-field p-col-2">
                                <label htmlFor="numero">Número</label>
                                <InputText id="numero" value={this.state.Cliente.numero} onChange={(e) => this.onInputChange(e, 'numero')} />
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12">
                                <label htmlFor="complemento">Complemento</label>
                                <InputText id="complemento" value={this.state.Cliente.complemento} onChange={(e) => this.onInputChange(e, 'complemento')}
                                />
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-2">
                                <label htmlFor="idestado">UF</label>
                                <Dropdown inputId="idestado" optionValue="idestado" optionLabel="uf" value={this.state.Cliente.idestado} options={this.state.estados} onChange={(e) => this.onEstadoChange(e)} placeholder="Selecione a UF"
                                    required className={classNames({ 'p-invalid': this.state.submitted && !this.state.Cliente.idestado })} />
                                {this.state.submitted && !this.state.Cliente.idestado && <small className="p-invalid">UF é requerido.</small>}
                            </div>

                            <div className="p-field p-col-10">
                                <label htmlFor="idcidade">Cidade</label>
                                <Dropdown inputId="idcidade" optionValue="idcidade" optionLabel="cidade" value={this.state.Cliente.idcidade} options={this.state.cidades} onChange={(e) => this.onCidadeChange(e)} placeholder="Selecione a cidade"
                                    disabled={!this.state.Cliente.idestado}
                                    required className={classNames({ 'p-invalid': this.state.submitted && !this.state.Cliente.idcidade })} />
                                {this.state.submitted && !this.state.Cliente.idcidade && <small className="p-invalid">Cidade é requerido.</small>}
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-8">
                                <label htmlFor="bairro">Bairro</label>
                                <InputText id="bairro" value={this.state.Cliente.bairro} onChange={(e) => this.onInputChange(e, 'bairro')} />
                            </div>

                            <div className="p-field p-col-4">
                                <label htmlFor="cep">CEP</label>
                                <div className="p-inputgroup">
                                    <InputMask id="cep" value={this.state.Cliente.cep} onChange={(e) => this.onInputMaskChange(e, 'cep')} mask="99.999-999" />
                                    <Button icon="pi pi-search" className="p-button" onClick={this.consultaCEP} />
                                </div>
                            </div>

                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-12">
                                <label htmlFor="email">E-mail</label>
                                <InputText id="email" value={this.state.Cliente.email} onChange={(e) => this.onInputChange(e, 'email')} />
                            </div>

                            {/* <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="site">Site</label>
                                <InputText id="site" value={this.state.Cliente.site} onChange={(e) => this.onInputChange(e, 'site')} />
                            </div> */}
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="telefonefixo">Tel. Fixo</label>
                                <InputMask id="telefonefixo" value={this.state.Cliente.telefonefixo} onChange={(e) => this.onInputMaskChange(e, 'telefonefixo')}
                                    mask="(99)9999-9999" />
                            </div>

                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="telefonecelular">Tel. Celular</label>
                                <InputMask id="telefonecelular" value={this.state.Cliente.telefonecelular} onChange={(e) => this.onInputMaskChange(e, 'telefonecelular')}
                                    mask="(99)99999-9999" />
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12">
                                <label htmlFor="indicacao">Indicação</label>
                                <InputText id="indicacao" value={this.state.Cliente.indicacao} onChange={(e) => this.onInputChange(e, 'indicacao')}
                                />
                            </div>
                        </div>
                    </Dialog>

                    <Dialog visible={this.state.deleteClienteDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteClienteDialogFooter} onHide={this.hideDeleteClienteDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {this.state.Cliente && <span>Tem certeza que deseja excluir <b>{this.state.Cliente.name}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={this.state.deleteClientesDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteClientesDialogFooter} onHide={this.hideDeleteClientesDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {this.state.Cliente && <span>Tem certeza que deseja exluir todos os clientes selecionados?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        );
    }
}
