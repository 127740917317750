import React, { Component } from 'react';
import api from '../../service/api';
import { Toast } from '../../components/toast/Toast';
import { InputText } from '../../components/inputtext/InputText';
import classNames from 'classnames';
import { Button } from '../../components/button/Button';
import { InputMask } from '../../components/inputmask/InputMask';
import { Panel } from '../../components/panel/Panel';
import { Dropdown } from '../../components/dropdown/Dropdown';
import { InputNumber } from '../../components/inputnumber/InputNumber';
import { consultaCEP } from '../../service/cepService';

export class Empresa extends Component {

    emptyEmpresa = {
        "id": null,
        "razaosocial": '',
        "nomefantasia": '',
        "cnpj": '',
        "logradouro": '',
        "numero": '',
        "bairro": '',
        "idcidade": null,
        "idestado": null,
        "cep": '',
        "email": '',
        "site": '',
        "telefone": '',
        "anofundacao": null
    };

    constructor(props) {
        super(props);

        this.state = {
            empresa: this.emptyEmpresa,
            listaEmpresa: [],
            estados: [],
            cidades: [],
            submitted: false,
            edicao: false,
            EmpresaAux: {}
        };

        this.originalRows = {};

        this.edit = this.edit.bind(this);
        this.save = this.save.bind(this);
        this.cancelEditEmpresa = this.cancelEditEmpresa.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onEstadoChange = this.onEstadoChange.bind(this);
        this.onCidadeChange = this.onCidadeChange.bind(this);
        this.consultaCEP = this.consultaCEP.bind(this);
        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.onInputMaskChange = this.onInputMaskChange.bind(this);
    }

    /******************************************************************************************************** */

    async componentDidMount() {
        const estados = await api.get('/estados');
        let Empresa = await api.get(`/empresa`);
        if (Empresa.data.length > 0) {
            api.get(`/cidades/uf/${Empresa.data[0].idestado}`)
                .then(cidades => {
                    this.setState({
                        empresa: Empresa.data[0],
                        estados: estados.data,
                        cidades: cidades.data,
                    });
                });

        }
        else {
            this.setState({
                estados: estados.data
            });
        }
    }

    /******************************************************************************************************** */

    async save() {
        let state = { submitted: true };

        if (this.state.empresa.nomefantasia.trim() && this.state.empresa.razaosocial.trim() &&
            this.state.empresa.cnpj && this.state.empresa.idestado && this.state.empresa.idcidade) {
            let empresa = { ...this.state.empresa };
            if (this.state.empresa.id) {
                api.put(`/empresa/${empresa.id}`, empresa)
                    .then(res => {
                        if (res.status === 204) {
                            state = {
                                ...state,
                                edicao: false,
                            };
                            this.setState(state);
                            this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Empresa atualizada', life: 3000 });
                            console.log(res.headers);
                        }
                    })
                    .catch(error => {
                        this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu atualizar a Empresa', life: 3000 });
                        //console.log(error.response.data.description);
                    })
            }
            else {
                empresa.id = 0;
                const res = await api.post('/empresa', empresa);
                if (res.status === 201) {
                    empresa.id = res.data.id;
                    this.setState({
                        ...state,
                        empresa,
                        edicao: false,
                    });
                    this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'empresa inserido', life: 3000 });
                }
            }



        }
        else {
            this.setState(state);
        }
    }

    edit() {
        let empresaAux = { ...this.state.empresa };
        this.setState({
            edicao: true,
            EmpresaAux: empresaAux
        });
    }

    cancelEditEmpresa() {
        let empresa = { ...this.state.EmpresaAux };
        this.setState({
            submitted: false,
            edicao: false,
            empresa: empresa
        });
    }

    
    onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        const input = e.target;
        const start = input.selectionStart;
        const end = input.selectionEnd;
        let empresa = { ...this.state.empresa };
        empresa[`${name}`] = val.toUpperCase();
        this.setState({ empresa: empresa },
            () => input.setSelectionRange(start, end));
    }

    onInputMaskChange(e, name) {
        const val = (e.target && e.target.value) || '';
        let empresa = { ...this.state.empresa };
        empresa[`${name}`] = val;
        this.setState({ empresa: empresa });
    }

    onInputNumberChange(e, name) {
        //const val = (e.target && e.target.value) || 0;
        const val = e.value || 0;
        let empresa = { ...this.state.empresa };
        empresa[`${name}`] = val;
        this.setState({ empresa: empresa });
    }


    onCidadeChange(e) {
        const val = (e.target && e.target.value) || '';
        let Empresa = { ...this.state.empresa };
        Empresa.idcidade = val;
        this.setState({ empresa: Empresa });
    }

    onEstadoChange(e) {
        let Empresa = { ...this.state.empresa };
        Empresa.idestado = e.value;
        this.setState({ empresa: Empresa });
        api.get(`/cidades/uf/${Empresa.idestado}`)
            .then(res => {
                this.setState({ cidades: res.data });
            });
    }

    consultaCEP() {

        consultaCEP(this.state.empresa.cep)
            .then(ret => {
                if (ret) {
                    let empresa = { ...this.state.empresa };
                    empresa.logradouro = ret.logradouro;
                    empresa.bairro = ret.bairro;
                    empresa.idcidade = ret.idCidade;
                    empresa.idestado = ret.idEstado;
                    empresa.uf = ret.uf;
                    empresa.ibge = ret.ibge;
                    api.get(`/cidades/uf/${empresa.idestado}`)
                        .then(res => {
                            this.setState({
                                cidades: res.data,
                                empresa: empresa
                            });
                        });
                }
            })
            .catch(error => {
                this.toast.show({ severity: 'error', summary: 'Erro', detail: `O CEP ${this.state.contrato.cep} não foi localizado`, life: 3000 });
                console.log(error);
            })        

        // let cep = this.state.empresa.cep;
        // if (cep.trim()) {
        //     let cepAux = cep.replace('.', '');
        //     cepAux = cepAux.replace('-', '');
        //     if (cepAux.length === 8) {
        //         api.get(`/consultacep/${cepAux}`)
        //             .then(res => {
        //                 if (res.status === 200) {
        //                     if (typeof res.data.erro === "undefined") {
        //                         let empresa = { ...this.state.empresa };
        //                         let ibge = res.data.ibge;
        //                         api.get(`/cidades/ibge/${ibge}`)
        //                             .then(cidade => {
        //                                 api.get(`/cidades/uf/${cidade.data.idestado}`)
        //                                     .then(res => {
        //                                         this.setState({ cidades: res.data });
        //                                     });
        //                                 empresa.idcidade = cidade.data.idcidade;
        //                                 empresa.idestado = cidade.data.idestado;
        //                             })
        //                         empresa.logradouro = res.data.logradouro.toUpperCase();
        //                         empresa.bairro = res.data.bairro.toUpperCase();
        //                         empresa.cidade = res.data.ibge;
        //                         this.setState({ empresa: empresa })
        //                     }
        //                     else {
        //                         this.toast.show({ severity: 'error', summary: 'Erro', detail: `O CEP ${cep} não foi localizado`, life: 3000 });
        //                     }
        //                 }
        //             })
        //             .catch(error => {
        //                 this.toast.show({ severity: 'error', summary: 'Erro', detail: `Ocorreu algum problema ao tentar consultar o CEP ${cep}`, life: 3000 });
        //                 console.log(error.data);
        //             })
        //     }
        //     else {
        //         alert(`CEP ${cep} inválido`)
        //     }
        // }
    }



    /******************************************************************************************************* */

    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="Novo" icon="pi pi-plus" className="p-button p-mr-2" onClick={this.openNew} />
            </React.Fragment>
        )
    }

    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button p-mr-2"
                    onClick={() => this.edit(rowData)}
                    tooltip="Alterar"
                    tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} />
            </React.Fragment>
        );
    }

    /******************************************************************************************************* */

    render() {
        const empresaDialogFooter = (
            <React.Fragment>
                <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={this.cancelEditEmpresa} />
                <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={this.save} />
            </React.Fragment>
        );
        /* ---------------------------------------------------------------------------------------------------------------------- */
        return (
            <div>
                <div>
                    <Toast ref={(el) => this.toast = el} />
                    <div className="card">
                        <Panel header="Empresa">
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-12" >
                                    <label htmlFor="nomefantasia">Nome Fantasia</label>
                                    <InputText id="nomefantasia" value={this.state.empresa.nomefantasia} onChange={(e) => this.onInputChange(e, 'nomefantasia')}
                                        required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.empresa.nomefantasia })}
                                         readOnly={!this.state.edicao} disabled={!this.state.edicao} />
                                    {this.state.submitted && !this.state.empresa.nomefantasia && <small className="p-invalid">Nome Fantasia é requerido.</small>}
                                </div>
                            </div>

                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-6" >
                                    <label htmlFor="razaosocial">Razão Social</label>
                                    <InputText id="razaosocial" value={this.state.empresa.razaosocial} onChange={(e) => this.onInputChange(e, 'razaosocial')}
                                        required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.empresa.razaosocial })}
                                         readOnly={!this.state.edicao} disabled={!this.state.edicao} />
                                    {this.state.submitted && !this.state.empresa.razaosocial && <small className="p-invalid">Razão Social é requerido.</small>}
                                </div>
                                <div className="p-field p-col-6">
                                    <label htmlFor="cnpj">CNPJ</label>
                                    <InputMask id="cnpj" value={this.state.empresa.cnpj} onChange={(e) => this.onInputMaskChange(e, 'cnpj')}
                                        autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.empresa.cnpj })}
                                        disabled={!this.state.edicao} mask="99.999.999/9999-99"
                                         readonly={!this.state.edicao} />
                                    {this.state.submitted && !this.state.empresa.cnpj && <small className="p-invalid">CNPJ é requerido.</small>}
                                </div>
                            </div>
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-10">
                                    <label htmlFor="logradouro">Logradouro</label>
                                    <InputText id="logradouro" value={this.state.empresa.logradouro} onChange={(e) => this.onInputChange(e, 'logradouro')}
                                         readOnly={!this.state.edicao} disabled={!this.state.edicao} />
                                </div>

                                <div className="p-field p-col-2">
                                    <label htmlFor="numero">Número</label>
                                    <InputText id="numero" value={this.state.empresa.numero} onChange={(e) => this.onInputChange(e, 'numero')}
                                         readOnly={!this.state.edicao} disabled={!this.state.edicao} />
                                </div>
                            </div>
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-2">
                                    <label htmlFor="idestado">UF</label>
                                    <Dropdown inputId="idestado" optionValue="idestado" optionLabel="uf" value={this.state.empresa.idestado} options={this.state.estados} onChange={(e) => this.onEstadoChange(e)} placeholder="Selecione a UF"
                                        required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.empresa.idestado })}
                                        disabled={!this.state.edicao} />
                                    {this.state.submitted && !this.state.empresa.idestado && <small className="p-invalid">UF é requerido.</small>}
                                </div>

                                <div className="p-field p-col-10">
                                    <label htmlFor="idcidade">Cidade</label>
                                    <Dropdown inputId="idcidade" optionValue="idcidade" optionLabel="cidade" value={this.state.empresa.idcidade} options={this.state.cidades} onChange={(e) => this.onCidadeChange(e)} placeholder="Selecione a cidade"
                                        disabled={!this.state.empresa.idestado || !this.state.edicao}
                                        required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.empresa.idcidade })} />
                                    {this.state.submitted && !this.state.empresa.idcidade && <small className="p-invalid">Cidade é requerido.</small>}
                                </div>
                            </div>
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-8">
                                    <label htmlFor="bairro">Bairro</label>
                                    <InputText id="bairro" value={this.state.empresa.bairro} onChange={(e) => this.onInputChange(e, 'bairro')}
                                         readOnly={!this.state.edicao} disabled={!this.state.edicao} />
                                </div>

                                <div className="p-field p-col-4">
                                    <label htmlFor="cep">CEP</label>
                                    <div className="p-inputgroup">
                                        <InputMask id="cep" value={this.state.empresa.cep} onChange={(e) => this.onInputMaskChange(e, 'cep')}
                                            mask="99.999-999"  readonly={!this.state.edicao} disabled={!this.state.edicao} />
                                        <Button icon="pi pi-search" className="p-button" onClick={this.consultaCEP} disabled={!this.state.edicao} />
                                    </div>
                                </div>
                            </div>
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-12 p-md-6">
                                    <label htmlFor="telefone">Telefone(s)</label>
                                    <InputMask id="telefone" value={this.state.empresa.telefone} onChange={(e) => this.onInputMaskChange(e, 'telefone')}
                                        mask="(99)99999-9999"  readonly={!this.state.edicao} disabled={!this.state.edicao} />
                                </div>

                                {/* <div className="p-field p-col-12 p-md-6">
                                    <label htmlFor="anofundacao">Ano de Fundação</label>
                                    <InputMask id="anofundacao" mask="9999" value={this.state.empresa.anofundacao}
                                        onChange={(e) => this.onInputNumberChange(e, 'anofundacao')} readonly={!this.state.edicao}  
                                         readOnly={!this.state.edicao} disabled={!this.state.edicao}/>
                                </div> */}

                                <div className="p-field p-col-12 p-md-6">
                                    <label htmlFor="anofundacao">Ano de Fundação</label>
                                    <InputNumber id="anofundacao" value={this.state.empresa.anofundacao} useGrouping={false}
                                        onChange={(e) => this.onInputNumberChange(e, 'anofundacao')} readonly={!this.state.edicao}  
                                         readOnly={!this.state.edicao} disabled={!this.state.edicao}/>
                                </div>

                            </div>
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-12 p-md-12">
                                    <label htmlFor="email">E-mail</label>
                                    <InputText id="email" value={this.state.empresa.email} onChange={(e) => this.onInputChange(e, 'email')}
                                         readOnly={!this.state.edicao} disabled={!this.state.edicao} />
                                </div>
                            </div>
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-12 p-md-12">
                                    <label htmlFor="site">Site</label>
                                    <InputText id="site" value={this.state.empresa.site} onChange={(e) => this.onInputChange(e, 'site')}
                                         readOnly={!this.state.edicao} disabled={!this.state.edicao} />
                                </div>
                            </div>
                            <div>
                                <React.Fragment>
                                    {!this.state.edicao && <Button label="Editar" icon="pi pi-pencil" className="p-button p-mr-2" onClick={this.edit} />}
                                    {this.state.edicao && <Button label="Salvar" icon="pi pi-check" className="p-button-success p-mr-2" onClick={this.save} />}
                                    {this.state.edicao && <Button label="Cancelar" icon="pi pi-times" className="p-button-warning p-mr-2" onClick={this.cancelEditEmpresa} />}
                                </React.Fragment>
                            </div>
                        </Panel>
                    </div>
                </div>
            </div>
        )
    }
}
