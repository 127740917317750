import React, { Component } from 'react';
//import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import { DataTable } from '../../components/datatable/DataTable';
import { Column } from '../../components/column/Column';
import { Toast } from '../../components/toast/Toast';
import api from '../../service/api'
//import { Link, Redirect } from 'react-router-dom';
import { InputNumber } from '../../components/inputnumber/InputNumber';
//import { Button } from '../../components/button/Button';

export class DimensionamentoPS extends Component {

    emptyDimensionamentoPS = {
        "id": null,
        "idnegociodimensionamento": null,
        "idparametroservico": null,
        "quantidade": 0,
        "descricao": "",
        "unidade": ""
    };

    static defaultProps = {
        idNegocioDimensionamento: 0,
        idstatus: 1
    }

    static propTypes = {
        idNegocioDimensionamento: PropTypes.number,
        idstatus: PropTypes.number
    }

    refresh = () => {
        this.props.refresh();
    }

    atualizarTotal = () => {
        this.props.atualizarTotal();
    }

    constructor(props) {
        super(props);

        this.state = {
            dimensionamentoPS: this.emptyDimensionamentoPS,
            listaDimensionamentosPS: [],
        };

        this.originalRows = {};


        this.onRowEditInit = this.onRowEditInit.bind(this);
        this.onRowEditCancel = this.onRowEditCancel.bind(this);
        this.onRowEditSave = this.onRowEditSave.bind(this);
    }

    async componentDidMount() {
        const dimensionamentosPS = await api.get(`/dimensionamentoparametrosservico/dimensionamento/${this.props.idNegocioDimensionamento}`);
        this.setState({ listaDimensionamentosPS: dimensionamentosPS.data });
    }

    async componentWillReceiveProps(nextProps) {
        if (nextProps.qtdModulos !== this.props.qtdModulos) {
            const dimensionamentosPS = await api.get(`/dimensionamentoparametrosservico/dimensionamento/${this.props.idNegocioDimensionamento}`);
            this.setState({ listaDimensionamentosPS: dimensionamentosPS.data });
        }
    }

    onRowEditInit(event) {
        this.originalRows[event.index] = { ...this.state.listaDimensionamentosPS[event.index] };
    }

    onRowEditCancel(event) {
        let Params = [...this.state.listaDimensionamentosPS];
        Params[event.index] = this.originalRows[event.index];
        delete this.originalRows[event.index];
        this.setState({ listaDimensionamentosPS: Params });
    }

    async onRowEditSave(event) {
        let lista = [...this.state.listaDimensionamentosPS];
        let Param = { ...this.state.listaDimensionamentosPS[event.index] };
        let Valor1 = 0;
        let Valor2 = 0;
        api.put(`/dimensionamentoparametrosservico/${Param.id}`, Param)
            .then(res => {
                if (res.status === 204) {
                    if ((Param.idparametroservico === 2) || (Param.idparametroservico === 4)) {
                        for (let i = 0; i < this.state.listaDimensionamentosPS.length; i++) {
                            if (this.state.listaDimensionamentosPS[i].idparametroservico === 2) {
                                Valor1 = this.state.listaDimensionamentosPS[i].quantidade;
                            }
                            else if (this.state.listaDimensionamentosPS[i].idparametroservico === 4) {
                                Valor2 = this.state.listaDimensionamentosPS[i].quantidade;
                            }
                            else if (this.state.listaDimensionamentosPS[i].idparametroservico === 5) {
                                lista[i].quantidade = Valor1 * Valor2;
                            }
                        }
                        this.setState({ listaDimensionamentosPS: lista });
                    }
                    this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Parâmetro atualizado com sucesso', life: 3000 });
                    this.refresh();
                }
            })
            .catch(error => {
                this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu atualizar o parâmetro', life: 3000 });
                console.log(error.data);
            })
    }

    onEditorNumberValueChange(listaName, props, value) {
        let updatedParams = [...props.value];
        updatedParams[props.rowIndex][props.field] = value || 0;
        this.setState({ [`${listaName}`]: updatedParams });
    }


    inputNumberEditor(listaName, props, field) {

        if (props.rowData['idparametroservico'] === 5 || props.rowData['idparametroservico'] === 3) {
            return <InputNumber disabled mode="decimal" locale="pt-BR" minFractionDigits={2} value={props.rowData[field]}
            onValueChange={(e) => this.onEditorNumberValueChange(listaName, props, e.value)} inputStyle={{ width: '100%' }} />;
        }
        else {
            return <InputNumber mode="decimal" locale="pt-BR" minFractionDigits={2} value={props.rowData[field]}
            onValueChange={(e) => this.onEditorNumberValueChange(listaName, props, e.value)} inputStyle={{ width: '100%' }} />;
        }
    }

    render() {
        /* const header = (
            <div className="table-header">
                <h5 className="p-m-0">Parâmetros</h5>
            </div>
        );

        const headerParametros = (
            <div className="table-header" left={this.busca}>
                <Button label="Novo" icon="pi pi-plus" className="p-button p-mr-2" onClick={this.openNewMaterialDC} />
            </div>
        ); */
        return (
            <div>
                <Toast ref={(el) => this.toast = el} />

                <DataTable
                    className="editable-cells-table p-datatable-sm p-datatable-gridlines"
                    ref={(el) => this.dt = el}
                    value={this.state.listaDimensionamentosPS}
                    editMode={this.props.idstatus < 3 ? "row" : ""}
                    dataKey="id"
                    //header={headerParametros}
                    onRowEditInit={this.onRowEditInit}
                    onRowEditCancel={this.onRowEditCancel}
                    onRowEditSave={this.onRowEditSave}>

                    <Column field="descricao" header="Descrição" style={{ width: '50%' }}></Column>
                    <Column
                        className="p-editable-column p-cell-editing"
                        field="quantidade" header="Quantidade"
                        editor={(props) => this.inputNumberEditor('listaDimensionamentosPS', props, 'quantidade')}>
                    </Column>
                    <Column field="unidade" header="Unidade"></Column>
                    <Column rowEditor headerStyle={{ width: '7rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                </DataTable>
            </div>
        );
    }
}
