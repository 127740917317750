import React, { Component } from 'react';
import classNames from 'classnames';
import { Button } from '../../components/button/Button';
import { PropTypes } from 'prop-types';
import api from '../../service/api';
import { InputText } from '../../components/inputtext/InputText';
import { Dropdown } from '../../components/dropdown/Dropdown';
import { Toast } from '../../components/toast/Toast';
import { InputNumber } from '../../components/inputnumber/InputNumber';
import { Dialog } from '../../components/dialog/Dialog';
import { InputMask } from '../../components/inputmask/InputMask';
import { Checkbox } from '../../components/checkbox/Checkbox';

export class NegocioEndereco extends Component {

    static defaultProps = {
        idnegocio: 1,
        idstatus: 1
    }

    static propTypes = {
        idnegocio: PropTypes.number,
        idstatus: PropTypes.number
    }

    emptyEndereco = {
        "id": -1,
        "logradouro": "",
        "numero": "",
        "complemento": "",
        "idcidade": 0,
        "idestado": 0,
        "bairro": "",
        "cep": "",
        "latitude": 0.0,
        "longitude": 0.0,
        "cidade": "",
        "uf": "",
        "utilizarcoordenadas": false,
        "latitudegrau": 0,
        "longitudegrau": 0,
        "latitudeminuto": 0,
        "longitudeminuto": 0,
        "latitudesegundo": 0.0,
        "longitudesegundo": 0.0
    }

    enderecoAtual = this.emptyEndereco;

    constructor(props) {
        super(props);

        this.state = {
            endereco: this.emptyEndereco,
            //editEndereco: false,
            estados: [],
            cidades: [],
            submitted: false,
            enderecoDialog: false,
        };

        this.onInputChange = this.onInputChange.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.onSave = this.onSave.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.saveEndereco = this.saveEndereco.bind(this);
        this.editTemplateCidade = this.editTemplateCidade.bind(this);
        this.onInputNumberChange = this.onInputNumberChange.bind(this);
        this.consultaCEP = this.consultaCEP.bind(this);
        this.onUtilizarCoordenadasChange = this.onUtilizarCoordenadasChange.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onInputCoordenadasChange = this.onInputCoordenadasChange.bind(this);
        this.converterCoordenadas = this.converterCoordenadas.bind(this);
        this.onCidadeChange = this.onCidadeChange.bind(this);
        this.findUF = this.findUF.bind(this);
        this.findCidade = this.findCidade.bind(this);
    }

    async componentDidMount() {
        api.get(`/negociosendereco/${this.props.idnegocio}`)
            .then(res => {
                if (res.status === 200){
                    this.setState({ endereco: res.data[0] });
                }
            })
    }

    onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        let Endereco = { ...this.state.endereco };
        const input = e.target;
        const start = input.selectionStart;
        const end = input.selectionEnd;
        Endereco[`${name}`] = val.toUpperCase();
        this.setState({ endereco: Endereco },
            () => input.setSelectionRange(start, end));
    }

    onInputMaskChange(e, name) {
        const val = (e.target && e.target.value) || '';
        let Endereco = { ...this.state.endereco };
        Endereco[`${name}`] = val;
        this.setState({ endereco: Endereco });
    }

    onInputNumberChange(e, name) {
        const val = e.value || 0;
        let Endereco = { ...this.state.endereco };
        Endereco[`${name}`] = val;
        this.setState({ endereco: Endereco });
    }

    onInputCoordenadasChange(e, name) {
        const val = e.value; 
        let Endereco = { ...this.state.endereco };
        Endereco[`${name}`] = val;
        this.setState({ endereco: Endereco });
    }

    onEdit() {
        this.enderecoAtual = { ...this.state.endereco };
        this.setState({
            enderecoDialog: true
        });

        api.get('/estados')
            .then(res => {
                this.setState({ estados: res.data });
            });
        api.get(`/cidades/uf/${this.state.endereco.idestado}`)
            .then(res => {
                this.setState({ cidades: res.data });
            });
    }

    findUF(id) {
        let index = -1;
        for (let i = 0; i < this.state.estados.length; i++) {
            if (this.state.estados[i].idestado === id) {
                index = i;
                break;
            }
        }
        return index;
    }
    
    findCidade(id) {
        let index = -1;
        for (let i = 0; i < this.state.cidades.length; i++) {
            if (this.state.cidades[i].idcidade === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    async onSave() {
        if (this.state.endereco.logradouro.trim() && (this.state.endereco.idestado) && (this.state.endereco.idcidade)) {
            if (this.state.endereco.id) {
                let endereco = { ...this.state.endereco };
                endereco.latitude = !endereco.latitude ? 0 : endereco.latitude > 0 ? endereco.latitude * -1 : endereco.latitude;
                endereco.longitude = !endereco.longitude ? 0 : endereco.longitude > 0 ? endereco.longitude * -1 : endereco.longitude;
                
                endereco.latitudegrau = !endereco.latitudegrau ? 0 : endereco.latitudegrau;
                endereco.latitudeminuto = !endereco.latitudeminuto ? 0 : endereco.latitudeminuto;
                endereco.latitudesegundo = !endereco.latitudesegundo ? 0 : endereco.latitudesegundo;

                endereco.longitudegrau = !endereco.longitudegrau ? 0 : endereco.longitudegrau;
                endereco.longitudeminuto = !endereco.longitudeminuto ? 0 : endereco.longitudeminuto;
                endereco.longitudesegundo = !endereco.longitudesegundo ? 0 : endereco.longitudesegundo;

                api.put(`/negociosendereco/${endereco.id}`, endereco)
                    .then(res => {
                        if (res.status === 204) {
                            this.setState({
                                enderecoDialog: false,
                                submitted: false,
                                endereco: endereco
                            })
                            this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Endereço atualizado', life: 3000 });
                        }
                    })
                    .catch(error => {
                        this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu atualizar o Endereço', life: 3000 });
                        // console.log(error.response.data.description);
                    })

            }
        }
        else {
            this.setState({ submitted: true });
        }
    }

    onCancel() {
        this.setState({
            endereco: this.enderecoAtual,
            submitted: false,
            enderecoDialog: false,
        })

    }

    onEstadoChange(e) {

        console.log(e)
        let Endereco = { ...this.state.endereco };

        Endereco.idestado = e.value;
        Endereco.idcidade = 0;
        Endereco.uf = this.state.estados[this.findUF(e.value)].uf;
        this.setState({ endereco: Endereco });

        api.get(`/cidades/uf/${Endereco.idestado}`)
            .then(res => {
                this.setState({ cidades: res.data });
            });
    }

    onCidadeChange(e) {
        //const val = (e.target && e.target.value) || '';
        let endereco = { ...this.state.endereco };
        endereco.idcidade = e.value;
        endereco.cidade = this.state.cidades[this.findCidade(e.value)].cidade;
        this.setState({ endereco });
    }

    editTemplateCidade() {
        return (

            <React.Fragment>
                <Dropdown inputId="idcidade" optionValue="idcidade" optionLabel="cidade"
                    value={this.state.endereco.idcidade} options={this.state.cidades} onChange={(e) => this.onInputChange(e, 'idcidade')} />
            </React.Fragment>
        )

    }

    consultaCEP() {
        let cep = this.state.endereco.cep;
        if (cep.trim()) {
            let cepAux = cep.replace('.', '');
            cepAux = cepAux.replace('-', '');
            if (cepAux.length === 8) {
                api.get(`/consultacep/${cepAux}`)
                    .then(res => {
                        if (res.status === 200) {
                            if (typeof res.data.erro === "undefined") {
                                let endereco = { ...this.state.endereco };
                                let ibge = res.data.ibge;
                                api.get(`/cidades/ibge/${ibge}`)
                                    .then(cidade => {
                                        endereco.logradouro = res.data.logradouro;
                                        endereco.bairro = res.data.bairro;
                                        //endereco.cidades = res.data.ibge;
                                        endereco.idestado = cidade.data[0].idestado;
                                        api.get(`/cidades/uf/${cidade.data[0].idestado}`)
                                            .then(res => {
                                                this.setState({ cidades: res.data });
                                                endereco.idcidade = cidade.data[0].idcidade;
                                                this.setState({ endereco: endereco })
                                            });
                                    })
                            }
                            else {
                                this.toast.show({ severity: 'error', summary: 'Erro', detail: `O CEP ${cep} não foi localizado`, life: 3000 });
                            }
                        }
                    })
                    .catch(error => {
                        this.toast.show({ severity: 'error', summary: 'Erro', detail: `Ocorreu algum problema ao tentar consultar o CEP ${cep}`, life: 3000 });
                        console.log(error.data);
                    })
            }
            else {
                alert(`CEP ${cep} inválido`)
            }
        }
    }

    hideDialog() {
        this.setState({
            submitted: false,
            enderecoDialog: false,
        });
    }

    saveEndereco() {
        this.setState({
            submitted: true,
            enderecoDialog: false
        });
    }

    onUtilizarCoordenadasChange(e) {
        let endereco = { ...this.state.endereco };
        if (e.checked)
            endereco.utilizarcoordenadas = true;
        else
            endereco.utilizarcoordenadas = false;
        this.setState({ endereco });
    }

    converterCoordenadas() {
        if (this.state.endereco.latitudegrau !== null && this.state.endereco.latitudeminuto !== null && this.state.endereco.latitudesegundo !== null &&
            this.state.endereco.longitudegrau !== null && this.state.endereco.longitudeminuto !== null && this.state.endereco.longitudesegundo !== null) {
            let latitudegrau = this.state.endereco.latitudegrau;
            let latitudeminuto = this.state.endereco.latitudeminuto / 60;
            let latitudesegundo = this.state.endereco.latitudesegundo / 3600;
            let latitude = latitudegrau + latitudeminuto + latitudesegundo;
            latitude = latitude > 0 ? latitude * -1 : latitude;

            let longitudegrau = this.state.endereco.longitudegrau;
            let llongitudeminuto = this.state.endereco.longitudeminuto / 60;
            let longitudesegundo = this.state.endereco.longitudesegundo / 3600;
            let longitude = longitudegrau + llongitudeminuto + longitudesegundo;
            longitude = longitude > 0 ? longitude * -1 : longitude;

            let endereco = { ...this.state.endereco };
            endereco.latitude = latitude;
            endereco.longitude = longitude;
            this.setState({ endereco: endereco });

        }
    }

    render() {
        const EnderecoDialogFooter = (
            <React.Fragment>
                <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={this.onCancel} />
                <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={this.onSave} />
            </React.Fragment>
        );

        return (
            <div>
                <Toast ref={(el) => this.toast = el} />
                <table className="tabela-simples">
                    <tbody>
                        <tr>
                            <td><b>Logradouro</b></td>
                            <td>{this.state.endereco.logradouro}</td>
                        </tr>
                        <tr>
                            <td><b>Número</b></td>
                            <td>{this.state.endereco.numero}</td>
                        </tr>
                        <tr>
                            <td><b>Complemento</b></td>
                            <td>{this.state.endereco.complemento}</td>
                        </tr>
                        <tr>
                            <td><b>Bairro</b></td>
                            <td>{this.state.endereco.bairro}</td>
                        </tr>
                        <tr>
                            <td><b>Estado</b></td>
                            <td>{this.state.endereco.uf}</td>
                        </tr>
                        <tr>
                            <td><b>Cidade</b></td>
                            <td>{this.state.endereco.cidade}</td>
                        </tr>
                        <tr>
                            <td><b>CEP</b></td>
                            <td>{this.state.endereco.cep}</td>
                        </tr>
                        <tr>
                            <td><b>Utilizar Coordenadas</b></td>
                            <td>{this.state.endereco.utilizarcoordenadas ? 'Sim' : 'Não'}</td>
                        </tr>
                        <tr>
                            <td><b>Latitude</b></td>
                            <td>{this.state.endereco.latitude && this.state.endereco.latitude.toLocaleString('pt-BR', { maximumFractionDigits: 6 })}</td>
                        </tr>
                        <tr>
                            <td><b>Longitude</b></td>
                            <td>{this.state.endereco.longitude && this.state.endereco.longitude.toLocaleString('pt-BR', { maximumFractionDigits: 6 })}</td>
                        </tr>
                    </tbody>
                </table>
                <div className="p-d-inline-flex p-mt-5">
                    <Button 
                        type="Button" 
                        label="Alterar" 
                        icon="pi pi-pencil" 
                        className="p-button p-mr-2" 
                        onClick={() => this.onEdit()} 
                        disabled={this.props.idstatus > 2} />
                </div>


                <Dialog visible={this.state.enderecoDialog} style={{ width: '600px' }} header="Endereço do Negócio" modal className="p-fluid" footer={EnderecoDialogFooter} onHide={this.hideDialog}>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-10" style={{ width: '100%' }}>
                            <label htmlFor="logradouro">Logradouro</label>
                            <InputText id="logradouro" value={this.state.endereco.logradouro} onChange={(e) => this.onInputChange(e, 'logradouro')}
                                required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.endereco.logradouro })}
                                 />
                            {this.state.submitted && !this.state.endereco.logradouro && <small className="p-invalid">Logradouro é requerido.</small>}
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-2">
                            <label htmlFor="numero">Número</label>
                            <InputText id="numero" value={this.state.endereco.numero} onChange={(e) => this.onInputChange(e, 'numero')}
                                 />
                        </div>

                        <div className="p-field p-col-10">
                            <label htmlFor="complemento">Complemento</label>
                            <InputText id="complemento" value={this.state.endereco.complemento} onChange={(e) => this.onInputChange(e, 'complemento')}
                                 />
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-2">
                            <label htmlFor="idestado">UF</label>
                            <Dropdown inputId="idestado" optionValue="idestado" optionLabel="uf" value={this.state.endereco.idestado} options={this.state.estados} onChange={(e) => this.onEstadoChange(e)} placeholder="Selecione a UF"
                                required className={classNames({ 'p-invalid': this.state.submitted && !this.state.endereco.idestado })} />
                            {this.state.submitted && !this.state.endereco.idestado && <small className="p-invalid">UF é requerido.</small>}
                        </div>

                        <div className="p-field p-col-10">
                            <label htmlFor="idcidade">Cidade</label>
                            <Dropdown inputId="idcidade" optionValue="idcidade" optionLabel="cidade" value={this.state.endereco.idcidade} options={this.state.cidades} onChange={(e) => this.onCidadeChange(e, 'idcidade')} placeholder="Selecione a cidade"
                                required className={classNames({ 'p-invalid': this.state.submitted && !this.state.endereco.idcidade })} />
                            {this.state.submitted && !this.state.endereco.idcidade && <small className="p-invalid">Cidade é requerido.</small>}
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-8">
                            <label htmlFor="bairro">Bairro</label>
                            <InputText id="bairro" value={this.state.endereco.bairro} onChange={(e) => this.onInputChange(e, 'bairro')}
                                 />
                        </div>

                        <div className="p-field p-col-4">
                            <label htmlFor="cep">CEP</label>
                            <div className="p-inputgroup">
                                <InputMask id="cep" value={this.state.endereco.cep} onChange={(e) => this.onInputMaskChange(e, 'cep')} mask="99.999-999"
                                     />
                                <Button icon="pi pi-search" className="p-button" onClick={this.consultaCEP} />
                            </div>
                        </div>

                    </div>
                    <div className="p-field-checkbox p-mt-3">
                        <Checkbox inputId="utilizarcoordenadas" value="Utilizar Coordenadas" onChange={this.onUtilizarCoordenadasChange} checked={this.state.endereco.utilizarcoordenadas}></Checkbox>
                        <label htmlFor="utilizarcoordenadas" className="p-checkbox-label">Utilizar Coordenadas</label>
                    </div>

                    <div className="p-fluid p-grid p-formgrid">
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="latitude">Latitude</label>
                            <InputNumber id="latitude" value={this.state.endereco.latitude} onValueChange={(e) => this.onInputCoordenadasChange(e, 'latitude')}
                                mode="decimal" locale="pt-BR" minFractionDigits={6} maxFractionDigits={6} InputMask="99,999999"  />
                        </div>

                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="longitude">Longitude</label>
                            <InputNumber id="longitude" value={this.state.endereco.longitude} onValueChange={(e) => this.onInputCoordenadasChange(e, 'longitude')}
                                mode="decimal" locale="pt-BR" minFractionDigits={6} maxFractionDigits={6} InputMask="99,999999"  />
                        </div>
                    </div>

                    <div className="p-fluid p-grid p-formgrid">
                        <div className="p-col-6">
                            <div className="p-fluid p-grid p-formgrid">
                                <div className="p-col-12">
                                    <label htmlFor="latitudegrau">Latitude (Graus, minutos e segundos)</label>
                                    <div className="p-fluid p-grid p-formgrid">
                                        <div className="p-col-4">
                                        <div className="p-inputgroup">
                                            <InputNumber id="latitudegrau" value={this.state.endereco.latitudegrau} onValueChange={(e) => this.onInputNumberChange(e, 'latitudegrau')}
                                                locale="pt-BR"  />
                                                 <span className="p-inputgroup-addon">º</span>
                                            </div>
                                        </div>
                                        <div className="p-col-4">
                                        <div className="p-inputgroup">
                                            <InputNumber id="latitudeminuto" value={this.state.endereco.latitudeminuto} onValueChange={(e) => this.onInputNumberChange(e, 'latitudeminuto')}
                                                locale="pt-BR"  />
                                                 <span className="p-inputgroup-addon">'</span>
                                            </div>
                                        </div>
                                        <div className="p-col-4">
                                        <div className="p-inputgroup">
                                            <InputNumber id="latitudesegundo" value={this.state.endereco.latitudesegundo} onValueChange={(e) => this.onInputNumberChange(e, 'latitudesegundo')}
                                                locale="pt-BR"  />
                                                 <span className="p-inputgroup-addon">"</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-col-6">
                            <div className="p-fluid p-grid p-formgrid">
                                <div className="p-col-12">
                                    <label htmlFor="longitudegrau">Longitude (Graus, minutos e segundos)</label>
                                    <div className="p-fluid p-grid p-formgrid">
                                        <div className="p-col-4">
                                            <div className="p-inputgroup">
                                                <InputNumber id="longitudegrau" value={this.state.endereco.longitudegrau} onValueChange={(e) => this.onInputNumberChange(e, 'longitudegrau')}
                                                    locale="pt-BR"  />
                                                <span className="p-inputgroup-addon">º</span>
                                            </div>
                                        </div>
                                        <div className="p-col-4">
                                            <div className="p-inputgroup">
                                                <InputNumber id="longitudeminuto" value={this.state.endereco.longitudeminuto} onValueChange={(e) => this.onInputNumberChange(e, 'longitudeminuto')}
                                                    locale="pt-BR"  />
                                                <span className="p-inputgroup-addon">'</span>
                                            </div>
                                        </div>
                                        <div className="p-col-4">
                                            <div className="p-inputgroup">
                                                <InputNumber id="longitudesegundo" value={this.state.endereco.longitudesegundo} onValueChange={(e) => this.onInputNumberChange(e, 'longitudesegundo')}
                                                    locale="pt-BR"  />
                                                <span className="p-inputgroup-addon">"</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-d-inline-flex p-mt-2">
                        <Button type="Button" label="Converter" icon="pi pi-map-marker" className="p-button p-mr-2" onClick={() => this.converterCoordenadas()} />
                    </div>
                </Dialog>
            </div >
        );
    }
}    