import { Skeleton } from 'primereact/skeleton';
import React, { useState, useEffect } from 'react';
import { Panel } from '../../components/panel/Panel';
import api from '../../service/api';

export const CardsGeracaoPotencia = (props) => {

    const emptyGeracaoPotencia = {
        "mediapotencia": 0.0,
        "mediageracao": 0.0,
        "totalpotencia": 0,
        "totalgeracao": 0
    }

    const [geracaoPotencia, setGeracaoPotencia] = useState(emptyGeracaoPotencia);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        api.get(`/dashboard/geracaopotencia/${props.Ini}/${props.Fim}`)
            .then(res => {
                setGeracaoPotencia(res.data[0]);
                setLoading(false);
            })
    }, [props.Ini, props.Fim]);

    const formatarNumero = (valor, casas) => {
        if (!valor){
            valor = 0;
        }
        if (!casas){
            casas = 2;
        }
        return valor.toLocaleString('pt-BR', { style: 'decimal', maximunFractionDigits: casas, minimumFractionDigits: casas });
    }

    return (
        <>
            <div className="p-col-6">
                <Panel header="Geração">
                    <div className="p-grid p-fluid">
                        <div className="p-col-12 p-lg-6">
                            {!loading && (
                                <div className="card summary">
                                    <span className="title">Média</span>
                                    <span className="detail">KWh</span>
                                    <span className="count visitors">{formatarNumero(geracaoPotencia.mediageracao, 2)}</span>
                                </div>
                            )}
                            {loading && (
                                <Skeleton height='5rem' />
                            )}
                        </div>
                        <div className="p-col-12 p-lg-6">
                            {!loading && (
                                <div className="card summary">
                                    <span className="title">Total</span>
                                    <span className="detail">KWh</span>
                                    <span className="count purchases">{formatarNumero(geracaoPotencia.totalgeracao, 2)}</span>
                                </div>
                            )}
                            {loading && (
                                <Skeleton height='5rem' />
                            )}
                        </div>
                    </div>
                </Panel>
            </div>

            <div className="p-col-6">
                <Panel header="Potência">
                    <div className="p-grid p-fluid">
                        <div className="p-col-12 p-lg-6">
                            {!loading && (
                                <div className="card summary">
                                    <span className="title">Média</span>
                                    <span className="detail">KWp</span>
                                    <span className="count visitors">{formatarNumero(geracaoPotencia.mediapotencia, 2)}</span>
                                </div>
                            )}
                            {loading && (
                                <Skeleton height='5rem' />
                            )}
                        </div>
                        <div className="p-col-12 p-lg-6">

                            {!loading && (
                                <div className="card summary">
                                    <span className="title">Total</span>
                                    <span className="detail">KWp</span>
                                    <span className="count purchases">{formatarNumero(geracaoPotencia.totalpotencia, 2)}</span>
                                </div>
                            )}
                            {loading && (
                                <Skeleton height='5rem' />
                            )}
                        </div>
                    </div>
                </Panel>
            </div>
        </>
    );
}