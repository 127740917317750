import React, { Component } from 'react';
import api from '../../../service/api';
import classNames from 'classnames';
import { Button } from '../../../components/button/Button';
import { Dialog } from '../../../components/dialog/Dialog';
import { DataTable } from '../../../components/datatable/DataTable';
import { Column } from '../../../components/column/Column'
import { InputText } from '../../../components/inputtext/InputText';
import { Toast } from '../../../components/toast/Toast';
import { Toolbar } from '../../../components/toolbar/Toolbar';
import { RadioButton } from '../../../components/radiobutton/RadioButton';
import { Checkbox } from '../../../components/checkbox/Checkbox';

export class Garantias extends Component {

    emptyGarantia = {
        "id": 0,
        "descricao": "",
        "garantia": "",
        "identificador": "",
        "tipo": "",
        "exibirproposta": false
    }

    constructor(props) {
        super(props);

        this.state = {
            garantia: this.emptyGarantia,
            listaGarantia: [],
            submitted: false,
            edicao: false,
            editDialog: false,
            deleteDialog: false,
            rows: 10,
            first: 1,
        }

        this.originalRows = {};

        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.openNew = this.openNew.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.save = this.save.bind(this);
        this.edit = this.edit.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.hideDeleteDialog = this.hideDeleteDialog.bind(this);
        this.delete = this.delete.bind(this);
        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.rightToolbarTemplate = this.rightToolbarTemplate.bind(this);
        this.tipoBodyTemplate = this.tipoBodyTemplate.bind(this);
        this.onTipoChange = this.onTipoChange.bind(this);
        this.onExibirPropostaChange = this.onExibirPropostaChange.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
    }

    async componentDidMount() {
        const listaGarantia = await api.get(`/garantias`);
        this.setState({
            listaGarantia: listaGarantia.data
        });
    }

    hideDeleteDialog() {
        this.setState({ deleteDialog: false });
    }

    confirmDelete(garantia) {
        this.setState({
            garantia,
            deleteDialog: true
        });
    }

    delete() {
        api.delete(`/garantias/${this.state.garantia.id}`)
            .then(res => {
                if (res.status === 204) {
                    let listaGarantia = this.state.listaGarantia.filter(val => val.id !== this.state.garantia.id);
                    this.setState({
                        listaGarantia,
                        deleteDialog: false,
                        garantia: this.emptyGarantia
                    });
                    this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Garantia excluído', life: 3000 });
                }
            })
            .catch(error => {
                this.setState({deleteDialog: false});
                this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu excluir a Garantia', life: 3000 });
                // console.log(error.response.data.description);
            })
    }

    edit(garantia) {
        this.setState({
            garantia: { ...garantia },
            editDialog: true
        });
    }

    openNew() {
        this.setState({
            garantia: this.emptyGarantia,
            submitted: false,
            editDialog: true
        });
    }

    hideDialog() {
        this.setState({
            submitted: false,
            editDialog: false
        });
    }

    async save() {
        let state = { submitted: true };

        if (this.state.garantia.descricao.trim() && this.state.garantia.prazo.trim() &&
            this.state.garantia.identificador.trim() && this.state.garantia.tipo.trim()) {
            let listaGarantia = [...this.state.listaGarantia];
            let garantia = { ...this.state.garantia };
            if (this.state.garantia.id) {
                const index = this.findIndexById(this.state.garantia.id);
                api.put(`/garantias/${this.state.garantia.id}`, garantia)
                    .then(res => {
                        if (res.status === 204) {
                            listaGarantia[index] = garantia;
                            state = {
                                ...state,
                                listaGarantia,
                                editDialog: false,
                                garantia: this.emptyGarantia
                            };
                            this.setState(state);
                            this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Garantia atualizada', life: 3000 });
                        }
                    })
                    .catch(error => {
                        this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu atualizar a Garantia', life: 3000 });
                        // console.log(error.response.data.description);
                    })
            }
            else {
                garantia.id = 0;
                garantia.idnegociodimensionamento = this.props.idDimensionamento;
                api.post('/garantias', garantia)
                    .then(res => {
                        if (res.status === 201) {
                            garantia.id = res.headers.id;
                            listaGarantia.push(garantia);
                            state = {
                                ...state,
                                listaGarantia,
                                editDialog: false,
                                garantia: this.emptyGarantia
                            };
                            this.setState(state);
                            this.toast.show({ severity: 'success', summary: 'Sucesso', detail: 'Garantia inserida', life: 3000 });
                        }
                    })
                    .catch(error => {
                        this.toast.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu inserir a Garantia', life: 3000 });
                        // console.log(error.response.data.description);
                    })
            }
        }
        else {
            this.setState(state);
        }
    }

    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-mr-2"
                    onClick={() => this.edit(rowData)}
                    tooltip="Alterar"
                    tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} />
                <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger"
                    onClick={() => this.confirmDelete(rowData)}
                    tooltip="Excluir"
                    tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} />
            </React.Fragment>
        );
    }

    onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        const input = e.target;
        const start = input.selectionStart;
        const end = input.selectionEnd;
        let garantia = { ...this.state.garantia };
        garantia[`${name}`] = val.toUpperCase();
        this.setState({ garantia: garantia },
            () => input.setSelectionRange(start, end));
    }

    onInputNumberChange(e, name) {
        const val = e.value || 0;
        let total = 0;
        let garantia = { ...this.state.garantia };
        garantia[`${name}`] = val;
        if ((garantia.valorunitario) && (garantia.quantidade)) {
            total = garantia.valorunitario * garantia.quantidade;
        }
        garantia.total = total;
        this.setState({ garantia: garantia });
    }

    onTipoChange(e) {
        let garantia = { ...this.state.garantia };
        garantia.tipo = e.value;
        this.setState({ garantia: garantia });
    }

    onExibirPropostaChange(e) {
        let garantia = { ...this.state.garantia };
        garantia.exibirproposta = e.checked;
        this.setState({ garantia: garantia });
    }

    findIndexById(id) {
        let index = -1;
        for (let i = 0; i < this.state.listaGarantia.length; i++) {
            if (this.state.listaGarantia[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    onUnidadeChange(e) {
        let garantia = { ...this.state.garantia };
        garantia.idund = e.value;
        this.setState({ garantia });
    }

    formatarNumero(rowData, field) {
        return rowData[field].toLocaleString('pt-BR', { style: 'decimal', minimumFractionDigits: 2 });
    }

    formatarCurrency(rowData, field) {
        return rowData[field].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 });
    }

    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="Novo" icon="pi pi-plus" className="p-button p-mr-2" onClick={this.openNew} />
            </React.Fragment>
        )
    }

    rightToolbarTemplate() {

    }

    tipoBodyTemplate(rowData) {
        let estilo;
        let texto = 'PRODUTO';
        if (rowData.tipo.toLowerCase() === 'p') {
            estilo = "p-tag p-tag-success";
        }
        else {
            estilo = "p-tag p-tag-warning";
            texto = 'SERVIÇO';
        }
        return <span className={estilo}>{texto}</span>;
    };

    onPageChange = (event) => {
        this.setState({
            rows : event.rows, 
            first : event.first
        });
    };

    render() {
        const header = (
            <div className="table-header">
                <h5 className="p-m-0">Listagem</h5>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Localizar..." />
                </span>
            </div>
        );

        const dialogFooter = (
            <React.Fragment>
                <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={this.save} />
            </React.Fragment>
        );

        const deleteDialogFooter = (
            <React.Fragment>
                <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteDialog} />
                <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={this.delete} />
            </React.Fragment>
        );

        return (
            <div>
                <div className="content-section introduction">
                    <h5>Garantias</h5>
                </div>

                <div className="p-grid crud-demo">
                    <Toast ref={(el) => this.toast = el} />
                    <div className="card">
                        <Toolbar className="p-mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>

                        <DataTable
                            className="p-datatable-gridlines p-datatable-sm"
                            ref={(el) => this.dt = el}
                            value={this.state.listaGarantia}
                            paginator={this.state.listaGarantia.length > 5}
                            rows={10}
                            rowsPerPageOptions={[5, 10, 25]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Exibindo {first} até {last} de {totalRecords} Parâmetros"
                            globalFilter={this.state.globalFilter}
                            header={header}
                            // onPage={event => this.onPageChange(event)}
                            // first={this.state.first}
                            >
                            <Column field="id" header="Id" style={{ width: '6%' }}></Column>
                            <Column field="descricao" header="Descrição" style={{ width: '50%' }}></Column>
                            <Column field="prazo" header="Prazo" style={{ width: '10%' }}></Column>
                            <Column field="identificador" header="Identificador" ></Column>
                            <Column field="tipo" header="Tipo" body={this.tipoBodyTemplate}></Column>
                            <Column body={this.actionBodyTemplate} style={{ width: '15%' }}></Column>
                        </DataTable>
                    </div>

                    <Dialog visible={this.state.editDialog} style={{ width: '550px' }} header="Garantia" modal className="p-fluid" footer={dialogFooter}
                        onHide={this.hideDialog}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12">
                                <label htmlFor="descricao">Descrição</label>
                                <InputText id="descricao" value={this.state.garantia.descricao} onChange={(e) => this.onInputChange(e, 'descricao')}
                                    required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.garantia.descricao })} 
                                    />
                                {this.state.submitted && !this.state.garantia.descricao && <small className="p-invalid">Descrição é requerido.</small>}
                            </div>
                            <div className="p-field p-col-6">
                                <label htmlFor="prazo">Prazo</label>
                                <InputText id="prazo" value={this.state.garantia.prazo} onChange={(e) => this.onInputChange(e, 'prazo')}
                                    required className={classNames({ 'p-invalid': this.state.submitted && !this.state.garantia.prazo })} 
                                    />
                                {this.state.submitted && !this.state.garantia.prazo && <small className="p-invalid">Prazo é requerido.</small>}
                            </div>
                            <div className="p-field p-col-6">
                                <label htmlFor="identificador">Identificador</label>
                                <InputText id="identificador" value={this.state.garantia.identificador} onChange={(e) => this.onInputChange(e, 'identificador')}
                                    required className={classNames({ 'p-invalid': this.state.submitted && !this.state.garantia.identificador })} 
                                    />
                                {this.state.submitted && !this.state.garantia.identificador && <small className="p-invalid">Identificador é requerido.</small>}
                            </div>
                            <div className="p-field p-col-12">
                                <div className="p-mb-2">
                                    Tipo
                                </div>
                                <div className="p-field-radiobutton">
                                    <RadioButton inputId="tipoproduto" name="tipo" value="P" onChange={(e) => this.onTipoChange(e)} checked={this.state.garantia.tipo === 'P'} />
                                    <label htmlFor="tipoproduto">Produto</label>
                                </div>
                                <div className="p-field-radiobutton">
                                    <RadioButton inputId="tiposervico" name="tipo" value="S" onChange={(e) => this.onTipoChange(e)} checked={this.state.garantia.tipo === 'S'} />
                                    <label htmlFor="tiposervico">Serviço</label>
                                </div>
                            </div>
                            <div className="p-field-checkbox">
                                <Checkbox inputId="ExibirProposta" checked={this.state.garantia.exibirproposta} onChange={(e) => this.onExibirPropostaChange(e)} />
                                <label htmlFor="ExibirProposta">Exibir na proposta</label>
                            </div>                            
                        </div>
                    </Dialog>

                    <Dialog visible={this.state.deleteDialog} style={{ width: '450px' }} header="Confirme" modal footer={deleteDialogFooter}
                        onHide={this.hideDeleteDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {this.state.garantia && <span>Tem certeza que deseja excluir <b>{this.state.garantia.descricao}</b>?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        )
    }
}
