import React, {useState, createContext} from 'react';

export const UserContext = createContext();

const UserProvider = ({ children }) => {


    const [data, setData] = useState({
        logger:  (sessionStorage.getItem('rotatoken') && !sessionStorage.getItem('trocarsenha'))
    });

    // console.log('arquivo context');
    // console.log(!sessionStorage.getItem('trocarsenha'));

    const isLogger = data.logger;

    // console.log('arquivo context - isLogger');
    // console.log(isLogger);    

    const alterLogin = value => {
        setData({...data, logger : value})
    }

    return(
        <UserContext.Provider value={{isLogger, alterLogin}}>
            {children}
        </UserContext.Provider>
    )
}

export default UserProvider;
